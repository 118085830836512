import BaseUserModel from "../interfaces/BaseUserModel";

const getUserName = (userObject: BaseUserModel | null) => {
  if (!userObject) return "";
  return userObject ? userObject.FirstName + " " + userObject.LastName : "";
};
const baseUserHelper = {
  getUserName,
};

export default baseUserHelper;
