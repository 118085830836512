import { createContext, ReactNode } from "react";
import BaseUserModel from "./../interfaces/BaseUserModel";

interface ContextProps {
  viewAsUser: BaseUserModel | null;
  setViewAsUser: React.Dispatch<React.SetStateAction<BaseUserModel | null>>;
}
interface Props extends ContextProps {
  children: ReactNode;
}
export const ViewAsContext = createContext<ContextProps>({
  viewAsUser: null,
  setViewAsUser: () => {},
});

export default function ViewAsContextProvider({
  viewAsUser,
  setViewAsUser,
  children,
}: Props) {
  return (
    <ViewAsContext.Provider
      value={{
        viewAsUser,
        setViewAsUser,
      }}
    >
      {children}
    </ViewAsContext.Provider>
  );
}
