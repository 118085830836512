import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import pvpApi from "../../../api/pvp";
import PVPModel from "../../../interfaces/pvp/PVPModel";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<PVPModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
  searchCriteria?: Object;
}

export default function ProviderPaymentVoucherAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  searchCriteria,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: "ProviderPaymentVoucherNumber",
      header: translate(strings.NUMBER),
      width: "100px",
    },
    {
      field: "PVPStatusModel",
      header: translate(strings.STATUS),
      width: "175px",
    },
    {
      field: "ProviderModel",
      header: translate(strings.PROVIDER),
      width: "175px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return pvpApi.getProviderPaymentVouchers(
      {
        ProviderPaymentVoucherIDStartsWith: inputString,
        ...searchCriteria,
      },
      { Page: 0, PageSize: pageDefaultSize }
    );
  };

  return (
    <AppAutoComplete<PVPModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay="ProviderPaymentVoucherNumber"
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      searchCriteria={searchCriteria}
      {...rest}
    />
  );
}
