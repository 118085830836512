import { Suspense, useContext } from "react";
import { Navigate } from "react-router-dom";
import SuspenseComponent from "../../pages/general/SuspenseComponent";
import baseRoutes from "../base/baseRoutes";
import { AuthenticationContext } from "../context/authenticationContext";

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const { isAuthenticated }: any = useContext(AuthenticationContext);
  return isAuthenticated() ? (
    <Suspense fallback={<SuspenseComponent />}>
      <Component {...rest} />
    </Suspense>
  ) : (
    <Navigate to={baseRoutes.HOME} />
  );
};

export default ProtectedRoute;
