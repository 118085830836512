import React, { RefObject, useContext } from "react";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { LocalizationContext } from "../../context/localizationContext";
import MenuItemData from "../../ui/MenuItemData";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  anchor: RefObject<any>;
  menuItems: MenuItemData[];
}
export default function PopupMenu({ show, setShow, anchor, menuItems }: Props) {
  const contentRef = React.createRef<HTMLDivElement>();
  //   const blurTimeoutRef = React.createRef();
  const { isRTL, translate } = useContext(LocalizationContext);

  const onOpen = (e: any) => {
    contentRef.current?.focus();
  };

  //   const onFocus = () => {
  //     // the user is still inside the content
  //     clearTimeout(blurTimeoutRef.current);
  //   };

  // const onBlurTimeout = () => {
  // the user is now outside the popup
  //setShow(false);
  // };

  const onBlur = () => {
    // clearTimeout(blurTimeoutRef.current);
    // blurTimeoutRef.current = setTimeout(onBlurTimeout, 200);
    setShow(false);
  };
  const getCssClass = (level: number) => {
    return isRTL
      ? "popup-menu-lv" +
          (level > 3 ? "3" : level.toString()) +
          " popup-menu-common popup-menu-rtl"
      : "popup-menu-lv" +
          (level > 3 ? "3" : level.toString()) +
          " popup-menu-common popup-menu-ltr";
  };
  const getMenuItem = (item: MenuItemData, level: number) => {
    if (item.visible) {
      if (!item.items) {
        return (
          <MenuItem
            disabled={item.disabled}
            key={item.key}
            text={translate(item.text)}
            icon={item.icon}
            data={item.action}
            cssClass={
              getCssClass(level) +
              (item.additionalCss ? " " + item.additionalCss : "")
            }
          />
        );
      } else {
        return (
          <MenuItem
            key={item.key}
            text={translate(item.text)}
            icon={item.icon}
            data={item.action}
            cssClass={
              getCssClass(level) +
              (item.additionalCss ? " " + item.additionalCss : "")
            }
          >
            {item.items.map((innerItem: MenuItemData) => {
              return getMenuItem(innerItem, level + 1);
            })}
          </MenuItem>
        );
      }
    }
  };
  return (
    <Popup
      anchor={anchor?.current}
      show={show}
      popupClass="app-popup-menu"
      onOpen={onOpen}
      anchorAlign={{ horizontal: "center", vertical: "bottom" }}
      popupAlign={{ horizontal: isRTL ? "left" : "right", vertical: "top" }}
      //   collision={{
      //     horizontal: "fit",
      //     vertical: "flip",
      //   }}
    >
      <div
        ref={contentRef}
        className="popup-menu-div"
        tabIndex={0}
        //   onFocus={onFocus}
        onBlur={onBlur}
        // style={{ display: "block", min-width: "234px" , marginRight: 20 }}
      >
        <Menu
          vertical={true}
          //   className={isRTL ? "popup-menu-rtl" : "popup-menu-ltr"}
          // openOnClick={true}
          onSelect={(event: MenuSelectEvent) => {
            if (event.item.data) event.item.data();
            // const filtered = menuItems.filter(
            //   (item) => item.key === event.item.data
            // );
            // if (filtered !== null && filtered.length === 1) {
            //   const menuItem = filtered[0];
            //   if (menuItem && menuItem.action) menuItem.action();
            // }
          }}
        >
          {menuItems.map((item) => {
            return getMenuItem(item, 1);
          })}
        </Menu>
      </div>
      {/* <div style={{ margin: 10, width: 100, height: 200 }}>POPUP CONTENT</div> */}
    </Popup>
  );
}
