import { useContext } from "react";
import { Card, CardProps } from "@progress/kendo-react-layout";
import { LocalizationContext } from "../../context/localizationContext";

interface Props extends CardProps {
  cardWidth?: string;
  hoverable?: boolean;
}
export default function AppCard({
  cardWidth,
  orientation,
  children,
  hoverable = false,
  ...rest
}: Props) {
  const { isRTL } = useContext(LocalizationContext);
  return (
    <Card
      className={hoverable ? "hoverable-card cardWrapper" : "cardWrapper"}
      orientation={orientation}
      dir={isRTL ? "rtl" : "ltr"}
      style={{ width: cardWidth }}
      {...rest}
    >
      {children}
    </Card>
  );
}
