const baseStrings = {
  GROUPED_BY: "GROUPED_BY",
  DRAG_HEADERS_HERE_TO_GROUP_BY: "DRAG_HEADERS_HERE_TO_GROUP_BY",
  OF: "OF",
  ROWS_PER_PAGE: "ROWS_PER_PAGE",
  ROWS: "ROWS",
  FIRST_PAGE: "FIRST_PAGE",
  LAST_PAGE: "LAST_PAGE",
  PREVIOUS: "PREVIOUS",
  NEXT_PAGE: "NEXT_PAGE",
  ROWS_SELECTED: "ROWS_SELECTED",
  SHOW_COLUMNS: "SHOW_COLUMNS",
  EXPORT: "EXPORT",
  EXPORT_AS_CSV: "EXPORT_AS_CSV",
  SEARCH: "SEARCH",
  MORE: "MORE",
  NO_RECORDS_TO_DISPLAY: "NO_RECORDS_TO_DISPLAY",
  ADD: "ADD",
  DELETE: "DELETE",
  EDIT: "EDIT",
  CANCEL: "CANCEL",
  SAVE: "SAVE",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ROW:
    "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ROW",
  FILTER: "FILTER",
  ACTIONS: "ACTIONS",
  NO_OPTIONS: "NO_OPTIONS",
  LOADING: "LOADING",
  CLEAR: "CLEAR",
  CLOSE: "CLOSE",
  OPEN: "OPEN",
  PROFILE: "PROFILE",
  LOGOUT: "LOGOUT",
  MANAGE: "MANAGE",
  SUBMIT: "SUBMIT",
  NEXT: "NEXT",
  RESET: "RESET",
  STATUS: "STATUS",
  USERNAME: "USERNAME",
  PASSWORD: "PASSWORD",
  SIZE: "SIZE",
  DATE: "DATE",
  USER_NAME: "USER_NAME",
  INFO: "INFO",
  ERROR: "ERROR",
  ERROR_OCCURRED: "ERROR_OCCURRED",
  PLEASE_CONFIRM: "PLEASE_CONFIRM",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ITEM:
    "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ITEM",
  YES: "YES",
  NO: "NO",
  TRUE: "TRUE",
  FALSE: "FALSE",
  UNSET_THIS_VALUE: "UNSET_THIS_VALUE",
  SET_THIS_VALUE: "SET_THIS_VALUE",
  REFRESH: "REFRESH",
  DOWNLOAD: "DOWNLOAD",
  TIMEOUT: "TIMEOUT",
  TIMEOUT_MESSAGE: "TIMEOUT_MESSAGE",
  RESUME_SESSION: "RESUME_SESSION",
  UPDATE_INFORMATION: "UPDATE_INFORMATION",
  BACK: "BACK",
  RESOURCE_NOT_FOUND: "RESOURCE_NOT_FOUND",
  SELECT_WITH_THREE_DOTS: "SELECT_WITH_THREE_DOTS",
  ADD_MORE_WITH_THREE_DOTS: "ADD_MORE_WITH_THREE_DOTS",
  VALIDATE_NUMBER_FORMAT: "VALIDATE_NUMBER_FORMAT",
  VALIDATE_NUMBER_INTEGER_FORMAT: "VALIDATE_NUMBER_INTEGER_FORMAT",
  VALIDATE_FIELD_IS_NOT_NULL: "VALIDATE_FIELD_IS_NOT_NULL",
  NAME: "NAME",
  CODE: "CODE",
  CHECKOUT: "CHECKOUT",
  CHECKIN: "CHECKIN",
  SELECT_ALL: "SELECT_ALL",
  CONTINUE: "CONTINUE",
  FROM: "FROM",
  TO: "TO",
  CREATE: "CREATE",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  PERMISSION_CONTACT_ADMIN: "PERMISSION_CONTACT_ADMIN",
  RETRY: "RETRY",
  COMMENT: "COMMENT",
  ID: "ID",
  SHOWING: "SHOWING",
  ITEMS: "ITEMS",
  PROGRESS: "PROGRESS",
  CHECKED_OUT_BY: "CHECKED_OUT_BY",
  OK: "OK",
  STOP: "STOP",
  PERCENTAGE: "PERCENTAGE",
  WORKING: "WORKING",
  TO_TIME: "TO_TIME",
  FROM_TIME: "FROM_TIME",
  IMAGE: "IMAGE",
  ADDITIONAL_INFO: "ADDITIONAL_INFO",
  ENTER_THE_TEXT_IN_THE_IMAGE: "ENTER_THE_TEXT_IN_THE_IMAGE",
  EXPORT_DETAILS: "EXPORT_DETAILS",
  MORE_FILTERS: "MORE_FILTERS",
  DONE: "DONE",
};

export default baseStrings;
