import { useContext } from "react";
import { Error, Label } from "@progress/kendo-react-labels";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { LocalizationContext } from "../../context/localizationContext";
import AppMultiColumnComboBox from "../inputs/AppMultiColumnComboBox";

interface Props extends FieldRenderProps {
  dataList: any[];
  columns: any[];
}

export default function FormMultiColumnComboBox({
  validationMessage,
  valid,
  visited,
  label,
  dataList,
  columns,
  ...rest
}: any) {
  const { translate } = useContext(LocalizationContext);

  const showValidationMessage: string | false | null =
    visited && validationMessage;

  return (
    <FieldWrapper>
      {label ? <Label>{translate(label)}</Label> : null}
      <AppMultiColumnComboBox
        size={"small"}
        // label={translate(label)}
        dataList={dataList}
        columns={columns}
        {...rest}
        // header={<div style={{ textAlign: "center" }} />}
        // popupSettings={{ popupClass: "dropDownSettingsPopup" }}
      />
      {showValidationMessage && <Error>{translate(validationMessage)}</Error>}
    </FieldWrapper>
  );
}
