import { useContext } from "react";
import { StackLayout, StackLayoutAlign } from "@progress/kendo-react-layout";
import { LocalizationContext } from "../../context/localizationContext";

interface Props {
  title: string;
  children?: React.ReactNode;
  layoutAlignment?: StackLayoutAlign;
  overrideMargin?: boolean;
  onClick?: () => void;
}

const SectionTitle = ({
  title,
  layoutAlignment = { horizontal: "start" },
  overrideMargin,
  children,
  onClick,
}: Props) => {
  const { translate } = useContext(LocalizationContext);

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <StackLayout
      className="section-title"
      align={layoutAlignment}
      style={overrideMargin ? { marginTop: "0px" } : {}}
    >
      <span onClick={handleClick}>{translate(title)}</span>
      {children}
    </StackLayout>
  );
};

export default SectionTitle;
