// import { parseNumber } from "@telerik/kendo-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ELanguageCodes from "../base/languageCodes";
import BusinessObjectModel from "../interfaces/BusinessObjectModel";
import ErrorMessageModel from "../interfaces/ErrorMessageModel";
import { CSSProperties } from "react";
import { colorCategory } from "../base/colorCategory";

const ERROR_CODE_KEY = "KEY:";
const ERROR_CODE_VALUE = ",VALUE:";

const getTimeValue = (date: Date | null) => {
  if (date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return hours + ":" + minutes;
  }
};

const getDateValue = (dateText: string | undefined) => {
  if (!dateText) return undefined;
  const [dateComponents] = dateText.split(" ");
  // const [dateComponents] = dateText.split(" ");
  const [day, month, year] = dateComponents.split("/");
  const dayInt = Number.parseInt(day);
  const monthInt = Number.parseInt(month);
  const yearInt = Number.parseInt(year);

  // T21:00:00.000Z

  // const isoFormat = `${yearInt}-${monthInt}-${dayInt} 00:00:00`;
  const isoFormat = `${yearInt}-${monthInt}-${dayInt}`;

  return new Date(isoFormat);
};

const getRequiredDateValue = (dateText: string | undefined) => {
  const date = getDateValue(dateText);
  return date || new Date();
};

const getDateString = (date: Date) => {
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
};

const getCustomFormatDateString = (date: Date) => {
  // yyyy-MM-dd
  if (
    date instanceof Date &&
    date !== null &&
    !isNaN(date.getFullYear()) &&
    !isNaN(date.getMonth()) &&
    !isNaN(date.getDate())
  ) {
    return (
      date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
    );
  }
};

const getDateFormatQueryString = (date?: Date) => {
  // Use this format if the dateTime is send as query parameter inside the route path
  if (date)
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
};

const formatDateTimeZoneOffset = (date: Date) => {
  const offset = -date.getTimezoneOffset();
  const offsetDate = new Date();
  offsetDate.setTime(date.getTime() + offset * 60000);

  return offsetDate;
};

const isNullOrEmptyArray = (array: Array<any> | any[] | null | undefined) => {
  if (!array) return true;
  if (array === null) return true;
  if (!(array.length > 0)) return true;

  return false;
};

const isNullOrEmptyString = (
  stream: string | null | undefined,
  checkTrimmed: boolean = false
) => {
  if (!stream) return true;
  if (stream === null) return true;
  if (checkTrimmed && !(stream.trim().length > 0)) return true;
  if (!checkTrimmed && !(stream.length > 0)) return true;

  return false;
};

const isNullOrEmptyNumber = (number: number | null | undefined) => {
  if (!number) return true;
  if (number === null) return true;
  if (number === Number.MAX_VALUE) return true;

  return false;
};

const isNullOrEmptyInteger = (number: number | null | undefined) => {
  if (!number) return true;
  if (number === null) return true;
  if (number === 0 || number === 2147483647) return true;

  return false;
};
const getObjectProperty = (obj: any, path: string) => {
  if (!path) return null;
  //path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  //path = path.replace(/^\./, ""); // strip a leading dot
  const properties = path.split(".");
  for (let i = 0, n = properties.length; i < n; ++i) {
    if (obj === null) return null;
    const property = properties[i];
    if (property in obj) {
      obj = obj[property];
    } else {
      return null;
    }
  }
  return obj;
};

// //Not-tested
// function parseDate(str: string) {
//   var mdy: Array<string> = str.split('/');
//   return new Date(parseNumber(mdy[2]) , parseNumber(mdy[0])-1, parseNumber(mdy[1]));
// }

// //Not-tested
// function datediff(first: Date, second: Date) {
//   // Take the difference between the dates and divide by milliseconds per day.
//   // Round to nearest whole number to deal with DST.
//   // return Math.round((second - first)/(1000*60*60*24));
//   return (second.getDate() - first.getDate());
// }

const getFieldValue = (obj: any, property: string) => {
  if (!property) return;
  if (property.indexOf(".") === -1) return obj[property];
  let bindObj = obj;
  let varLbl = property;
  let val;
  while (varLbl.indexOf(".") !== -1) {
    const first = varLbl.split(".")[0];

    varLbl = varLbl.substring(varLbl.indexOf(".") + 1);

    if (bindObj[first]) val = bindObj[first];
    else return;

    bindObj = val;
  }

  return bindObj[varLbl];
};

const matchSingleDay = (counter: number, locale: string) => {
  let returnValue;
  if (locale === ELanguageCodes.ARABIC.Code)
    returnValue = !!(
      counter === 0 ||
      counter === 1 ||
      counter === 2 ||
      counter > 10
    );
  else returnValue = counter <= 1;
  return returnValue;
};

const getDifferenceBetweenDatesInDays = (
  date1: any,
  date2: any,
  displayDaysLabel: boolean = true,
  locale: string = "en"
) => {
  if (!displayDaysLabel)
    return Math.ceil(
      Math.abs(date1 - date2) / (1000 * 60 * 60 * 24)
    ).toString();

  const diff = Math.ceil(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));

  return diff.toString() + getDaysLabel(diff, locale);
};

const getDaysLabel = (counter: number, locale: string) => {
  return locale === ELanguageCodes.ENGLISH.Code
    ? matchSingleDay(counter, locale)
      ? " Day"
      : " Days"
    : matchSingleDay(counter, locale)
      ? " يوم"
      : " أيام";
};

const getItemByID = (array: any[], id: number) => {
  if (isNullOrEmptyArray(array)) return null;

  let item = null;
  array.forEach((element) => {
    if (element.ID === id) {
      item = element;
    }
  });

  return item;
};

const formatNumber = (number: number, maximumFractionDigits: number = 2) => {
  //equals to string.format("{0:n2}", number);  - Includes rounding
  // number.toFixed(2) only added the fraction of 2 digits without including commas in the number (ex. 1,234,332.45)
  return number
    ? isNullOrEmptyNumber(number)
      ? " - "
      : number.toLocaleString(undefined, {
        maximumFractionDigits: maximumFractionDigits,
      })
    : "0";
};
//According to: https://dev.to/mohitkyadav/how-to-trap-focus-in-react-3in8
const getFirstFocusableElement = (
  parent: HTMLDivElement,
  excludeButtons = false
) => {
  // here we query all focusable elements, customize as your own need
  const focusableElements = parent.querySelectorAll(
    "a[href], textarea, input, select" +
    (!excludeButtons ? " ,button:not([disabled])" : "")
  );

  return focusableElements[0];
};

const getLastFocusableElement = (
  parent: HTMLDivElement,
  excludeButtons = false
) => {
  // here we query all focusable elements, customize as your own need
  const focusableElements = parent.querySelectorAll(
    "a[href], textarea, input, select" +
    (!excludeButtons ? " ,button:not([disabled])" : "")
  );

  return focusableElements[focusableElements.length - 1];
};

// const wrapErrorMessage = (message: string, order: number) => {
//   let error: ErrorMessageModel = {
//     Message: message,
//     ID: -1,
//     LocalName: "",
//     ForeignName: "",
//     Order: order,
//   };
//   return error;
// };

const wrapErrorMessage = (
  errorCode: string,
  order: number,
  messageKey: string = ""
) => {
  const error: ErrorMessageModel = {
    MessageKey: messageKey,
    ErrorCode: errorCode,
    ID: -1,
    LocalName: "",
    ForeignName: "",
    Order: order,
  };
  return error;
};

const getObjectFormListByName = (list: BusinessObjectModel[], name: string) => {
  let obj: BusinessObjectModel | null = null;
  if (isNullOrEmptyArray(list)) return null;
  list.forEach((element) => {
    if (element.LocalName === name || element.ForeignName === name) {
      obj = element;
      return obj;
    }
  });
  return obj;
};

const setDocumentTitle = (newTitle?: string) => {
  if (newTitle) return (document.title = newTitle);
  else return (document.title = process.env.REACT_APP_NAME + "");
};

const getYesterdayDate = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today.toISOString().split("T")[0];
};

const errorCodeHasKey = (message: string) => {
  //"KEY:(someKey),VALUE:(someValue)"
  return (
    message.indexOf(ERROR_CODE_KEY) !== -1 &&
    message.indexOf(ERROR_CODE_VALUE) !== -1
  );
};

const getErrorCodeValue = (message: string) => {
  return message.substring(message.lastIndexOf(ERROR_CODE_VALUE) + 7);
};

const getErrorCodeKey = (message: string) => {
  return message.substring(
    message.lastIndexOf(ERROR_CODE_KEY) + 4,
    message.lastIndexOf(ERROR_CODE_VALUE)
  );
};

const isCustomIcon = (icon: string) => {
  if (icon.slice(0, 2) === "fa") return true;
  else return false;
};

const renderCustomIcon = (
  iconFactory: {
    getIcon: (iconKey: string) => JSX.Element;
  },
  icon: string,
  // iconSize: SizeProp = "1x",
  additionStyle: CSSProperties = {}
) => {
  return (
    <span
      style={{ flex: 0.1, ...additionStyle }}
      className={"k-icon k-font-icon "}
    >
      {iconFactory.getIcon(icon)}
    </span>
  );
};

const getActualObjectID = (obj: BusinessObjectModel | null | undefined) => {
  return !obj || obj.ID === -1 ? null : obj.ID;
};

const getMaritalStatusList = () => {
  return [
    {
      LocalName: "أعزب",
      ForeignName: "Single",
      Code: "S",
    },
    {
      LocalName: "متزوج",
      ForeignName: "Married",
      Code: "M",
    },
    {
      LocalName: "مطلّق",
      ForeignName: "Divorced",
      Code: "D",
    },
    {
      LocalName: "أرمل",
      ForeignName: "Widowed",
      Code: "W",
    },
  ];
};

const getNationalityList = () => {
  return [
    {
      LocalName: "سوري",
      ForeignName: "Syrian",
    },
    {
      LocalName: "مصري",
      ForeignName: "Egyptian",
    },
    {
      LocalName: "سوداني",
      ForeignName: "Sudanese",
    },
    {
      LocalName: "كويتي",
      ForeignName: "Kuwaity",
    },
  ];
};

const getAlertNotificationTitle = (
  alertKey: string,
  object: {},
  fieldname: string
) => {
  return object ? alertKey + " " + fieldname : "";
};

const getAlertNotificationStatus = (object: {}, color: string) => {
  return object
    ? color === "Green"
      ? colorCategory.SUCCESS
      : color === "Orange"
        ? colorCategory.WARNING
        : colorCategory.ERROR
    : colorCategory.DISABLED;
};

const uiHelper = {
  getDateValue,
  getRequiredDateValue,
  getDateString,
  formatDateTimeZoneOffset,
  isNullOrEmptyArray,
  isNullOrEmptyString,
  getObjectProperty,
  getFieldValue,
  matchSingleDay,
  getDifferenceBetweenDatesInDays,
  getDaysLabel,
  getItemByID,
  formatNumber,
  getFirstFocusableElement,
  getLastFocusableElement,
  getCustomFormatDateString,
  wrapErrorMessage,
  getObjectFormListByName,
  getDateFormatQueryString,
  getTimeValue,
  setDocumentTitle,
  getYesterdayDate,
  isNullOrEmptyNumber,
  getErrorCodeValue,
  getErrorCodeKey,
  errorCodeHasKey,
  isCustomIcon,
  renderCustomIcon,
  isNullOrEmptyInteger,
  getActualObjectID,
  getMaritalStatusList,
  getNationalityList,
  getAlertNotificationTitle,
  getAlertNotificationStatus,
};

export default uiHelper;
