import { Dispatch, SetStateAction, useContext, useState } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDatePicker from "../../common/components/inputs/AppDatePicker";
import AppBooleanSearchField from "../../common/components/search/AppBooleanSearchField";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppProviderClaimsSearchFieldKeys = {
  HasClaims: "HasClaims",
  FromAccidentDate: "FromAccidentDate",
  ToAccidentDate: "ToAccidentDate",
};
export default function AppProviderClaimsSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { translate } = useContext(LocalizationContext);
  const [showDate, setShowDate] = useState(false);

  const handleHasClaimsChange = async (event: any, value?: boolean) => {
    if (value === undefined) {
      setShowDate(false);
      removeSearchFieldValue(AppProviderClaimsSearchFieldKeys.HasClaims);
      removeSearchFieldValue(AppProviderClaimsSearchFieldKeys.FromAccidentDate);
      removeSearchFieldValue(AppProviderClaimsSearchFieldKeys.ToAccidentDate);
    } else {
      setShowDate(true);
      const searchValue: boolean = value;
      setSearchFieldValues(
        AppProviderClaimsSearchFieldKeys.HasClaims,
        searchValue
      );
    }
  };

  const handleFromDateChange = async (event: any) => {
    const value = event.value;

    if (value) {
      const searchValue: Date = value;
      setSearchFieldValues(
        AppProviderClaimsSearchFieldKeys.FromAccidentDate,
        searchValue
      );
    } else {
      removeSearchFieldValue(AppProviderClaimsSearchFieldKeys.FromAccidentDate);
    }
  };

  const handleToDateChange = async (event: any) => {
    const value = event.value;

    if (value) {
      const searchValue: Date = value;
      setSearchFieldValues(
        AppProviderClaimsSearchFieldKeys.ToAccidentDate,
        searchValue
      );
    } else {
      removeSearchFieldValue(AppProviderClaimsSearchFieldKeys.ToAccidentDate);
    }
  };

  return (
    <div style={{ display: "inline-flex" }}>
      <span className="search-component">
        <AppBooleanSearchField
          removeSearchFieldValue={removeSearchFieldValue}
          setSearchFieldValues={setSearchFieldValues}
          criteriaTitle={AppProviderClaimsSearchFieldKeys.HasClaims}
          defaultShow={false}
          title={translate(strings.HAS_CLAIMS)}
          onChange={handleHasClaimsChange}
        />
      </span>
      {showDate && (
        <AppDatePicker
          className="search-component"
          onChange={handleFromDateChange}
          placeholder={translate(strings.FROM_DATE)}
          rounded="full"
          fillMode={"outline"}
        />
      )}
      {showDate && (
        <AppDatePicker
          className="search-component"
          onChange={handleToDateChange}
          placeholder={translate(strings.TO_DATE)}
          rounded="full"
          fillMode={"outline"}
        />
      )}
    </div>
  );
}
