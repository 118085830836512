import React, {
  createRef,
  useContext,
  useEffect,
  useState,
  cloneElement,
  CSSProperties,
} from "react";
import { LocalizationContext } from "../../context/localizationContext";
import {
  DropDownList,
  DropDownListBlurEvent,
  DropDownListChangeEvent,
  DropDownListCloseEvent,
  DropDownListFilterChangeEvent,
  DropDownListProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import uiHelper from "../../helpers/uiHelper";
import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import baseStrings from "../../base/baseStrings";
import { dummyItemID } from "../../constants/appConstants";

interface Props extends DropDownListProps {
  objectBinding?: boolean;
  defaultValueOnClose?: boolean;
  bindingProperty?: string;
  addDummyItem?: boolean;
  transparentMode?: boolean;
  resetValueOnChange?: boolean;
  enableFiltering?: boolean;
  autoSelectSingleItem?: boolean;
  dropDownStyle?: CSSProperties;
  dropDownClasses?: string;
  filterByLocalAndForeignNames?: boolean;
}

export default function AppDropDownList({
  data = [],
  textField,
  objectBinding,
  value,
  onChange,
  onBlur,
  onClose,
  defaultItem,
  defaultValueOnClose = false,
  bindingProperty,
  // dataItemKey,
  addDummyItem,
  transparentMode = false,
  defaultValue,
  disabled,
  resetValueOnChange = true,
  enableFiltering = true,
  autoSelectSingleItem = true,
  dropDownStyle,
  dropDownClasses,
  filterByLocalAndForeignNames = false,
  ...rest
}: Props) {
  const ref = createRef<HTMLElement>();

  const { translateIn, getName, getNameField, translate, isRTL } =
    useContext(LocalizationContext);

  const [dropDownValue, setDropDownValue] = useState<any>(value);

  const dummyItem: any = objectBinding
    ? {
        ID: dummyItemID,
        LocalName: translateIn(baseStrings.SELECT_WITH_THREE_DOTS, "ar"),
        ForeignName: translateIn(baseStrings.SELECT_WITH_THREE_DOTS, "en"),
        Name:
          getNameField() === "LocalName"
            ? translateIn(baseStrings.SELECT_WITH_THREE_DOTS, "ar")
            : translateIn(baseStrings.SELECT_WITH_THREE_DOTS, "en"),
        Code: "_",
        ...(textField &&
        textField != "LocalName" &&
        textField != "ForeignName" &&
        textField != "Code"
          ? {
              [textField]:
                getNameField() === "LocalName"
                  ? translateIn(baseStrings.SELECT_WITH_THREE_DOTS, "ar")
                  : translateIn(baseStrings.SELECT_WITH_THREE_DOTS, "en"),
            }
          : {}),
      }
    : translate(baseStrings.SELECT_WITH_THREE_DOTS);

  const [dropDownListData, setDropDownListData] = useState<any[]>([]);
  const [filteredDropDownListData, setFilteredDropDownListData] = useState<
    any[]
  >([]);

  const handleChange = (e: DropDownListChangeEvent) => {
    if (onChange) onChange(e);
    setDropDownValue(e.value);
  };

  const handleBlur = (e: DropDownListBlurEvent) => {
    if (onBlur) onBlur(e);
  };

  const handleClose = (e: DropDownListCloseEvent) => {
    if (onClose) onClose(e);
    if (defaultValueOnClose) setDropDownValue(defaultItem);
  };

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>
        {objectBinding
          ? bindingProperty
            ? itemProps.dataItem.ID === dummyItemID
              ? getDisplayValue(itemProps.dataItem)
              : uiHelper.getObjectProperty(
                    itemProps.dataItem,
                    bindingProperty
                  ) instanceof Object
                ? getDisplayValue(
                    uiHelper.getObjectProperty(
                      itemProps.dataItem,
                      bindingProperty
                    )
                  )
                : uiHelper.getObjectProperty(
                    itemProps.dataItem,
                    bindingProperty
                  )
            : getDisplayValue(itemProps.dataItem)
          : li.props.children}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
  ) => {
    if (!value) {
      return element;
    }

    const valueItem = getItemValue(value);
    const children = [<span key={1}>{valueItem}</span>];

    return cloneElement(element, { ...element.props }, children);
  };

  const getDisplayValue = (item: any) => {
    return textField ? item[textField] : getName(item);
  };

  const getItemValue = (value: any) => {
    let valueItem;
    if (value instanceof Object) {
      if (value.ID === dummyItemID) {
        if (textField) valueItem = value[textField];
        else valueItem = value.ID;
      } else if (bindingProperty) {
        //ApprovalDiagnosisModel.[DiagnosisModel = bindingProperty].ID
        if (
          uiHelper.getObjectProperty(value, bindingProperty) instanceof Object
        ) {
          const bindigObject = uiHelper.getObjectProperty(
            value,
            bindingProperty
          );
          if (textField) valueItem = bindigObject[textField];
          else valueItem = bindigObject.ID;
        } else valueItem = uiHelper.getObjectProperty(value, bindingProperty);
      } else {
        if (textField) valueItem = value[textField];
        else valueItem = value.ID;
      }
    } else valueItem = value;

    return valueItem;
  };

  useEffect(() => {
    if (ref.current && defaultItem && onChange) {
      const event: any = { target: ref.current, value: defaultItem };
      onChange(event);
    }
    // }, [defaultItem]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDropDownListData(addDummyItem ? [dummyItem, ...(data || [])] : data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (ref.current && onChange && dropDownListData) {
      if (
        autoSelectSingleItem &&
        dropDownListData.length === 1 &&
        !addDummyItem
      ) {
        const event: any = { target: ref.current, value: dropDownListData[0] };
        onChange(event);
      } else if (
        autoSelectSingleItem &&
        dropDownListData.length === 2 &&
        addDummyItem
      ) {
        const event: any = { target: ref.current, value: dropDownListData[1] };
        onChange(event);
      } else {
        if (!resetValueOnChange) setDropDownValue(value);
        else defaultItem ? setDropDownValue(defaultItem) : setDropDownValue("");
      }
    } else {
      defaultItem ? setDropDownValue(defaultItem) : setDropDownValue("");
    }

    setFilteredDropDownListData(dropDownListData);
    // eslint-disable-next-line
  }, [dropDownListData, isRTL]);

  useEffect(() => {
    setDropDownValue(value);
    // eslint-disable-next-line
  }, [value]);

  const filterData = (
    // filter: FilterDescriptor | CompositeFilterDescriptor,
    field: string | Function | undefined,
    operator: string | Function,
    value: string
  ) => {
    if (value.length === 0) return dropDownListData;
    else {
      let newFilter: FilterDescriptor | CompositeFilterDescriptor;
      if (!objectBinding) {
        newFilter = {
          field,
          operator, //"contains",
          ignoreCase: true,
          value,
        };
      } else if (textField && !filterByLocalAndForeignNames)
        newFilter = {
          field: bindingProperty
            ? bindingProperty + "." + textField
            : textField,
          operator, //"contains",
          ignoreCase: true,
          value,
        };
      else
        newFilter = {
          logic: "or",
          filters: [
            {
              field: bindingProperty
                ? bindingProperty + "." + "LocalName"
                : "LocalName",
              operator, //"contains",
              ignoreCase: true,
              value,
            },
            {
              field: bindingProperty
                ? bindingProperty + "." + "ForeignName"
                : "ForeignName",
              operator, //"contains",
              ignoreCase: true,
              value,
            },
          ],
        };

      const data = dropDownListData.slice();
      return filterBy(data, newFilter);
    }
  };

  const filterChange = (event: DropDownListFilterChangeEvent) => {
    setFilteredDropDownListData(
      filterData(
        // event.filter,
        event.filter.field,
        event.filter.operator,
        event.filter.value
      )
    );
  };

  return (
    <DropDownList
      ref={ref}
      defaultItem={
        defaultItem instanceof Object
          ? getItemValue(
              objectBinding
                ? dropDownListData.find((data) => data.ID === defaultItem?.ID)
                : dropDownListData.find((data) => data === defaultItem)
            )
          : defaultItem
      }
      // data={dropDownListData}
      data={filteredDropDownListData || []}
      size="small"
      // textField={textField}
      itemRender={itemRender}
      valueRender={valueRender}
      defaultValue={defaultValue}
      value={dropDownValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onClose={handleClose}
      popupSettings={{ popupClass: "popup" }}
      style={{
        ...(dropDownStyle || {}),
        ...(transparentMode && !disabled
          ? { backgroundColor: "transparent" }
          : {}),
      }}
      className={
        "app-dropdown pickerStyle" +
        (transparentMode && !disabled ? " transparent-drop-down" : "") +
        (dropDownClasses ? " " + dropDownClasses : "")
      }
      disabled={disabled}
      rounded="full"
      filterable={enableFiltering}
      onFilterChange={filterChange}
      {...rest}
    />
  );
}
