import {
  DropDownButton,
  DropDownButtonItemClickEvent,
  DropDownButtonProps,
} from "@progress/kendo-react-buttons";
import { useContext } from "react";
import { ApplicationContext } from "../../context/applicationContext";
import uiHelper from "../../helpers/uiHelper";

interface Props extends DropDownButtonProps {
  children?: any;
}
export default function AppDropDownButton({
  children,
  onItemClick,
  items,
  itemRender,
  textField,
  ...rest
}: Props) {
  const { iconFactory } = useContext(ApplicationContext);
  const handleFocus = (/*event: DropDownButtonFocusEvent*/) => {};
  const handleItemClick = (event: DropDownButtonItemClickEvent) => {
    onItemClick && onItemClick(event);
  };
  return (
    <DropDownButton
      {...rest}
      onFocus={handleFocus}
      onItemClick={handleItemClick}
      rounded="full"
      items={items}
      itemRender={
        !itemRender
          ? (event: any) => (
              <>
                {event.item.icon &&
                  (uiHelper.isCustomIcon(event.item.icon) ? (
                    uiHelper.renderCustomIcon(iconFactory, event.item.icon)
                  ) : (
                    <span className={"k-icon k-font-icon " + event.item.icon} />
                  ))}
                {!textField
                  ? typeof event.item === "object"
                    ? event.item.text
                    : event.item
                  : uiHelper.getFieldValue(event.item, textField)}
              </>
            )
          : itemRender
      }
    >
      {children}
    </DropDownButton>
  );
}
