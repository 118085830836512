import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import VendorModel from "../../../interfaces/vendors/VendorModel";
import vendorsApi from "../../../api/vendors";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<VendorModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
  searchCriteria?: Object;
}

export default function VendorAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  searchCriteria,
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: getNameField(),
      header: translate(strings.NAME),
      width: "120px",
    },
    {
      field: "Country",
      header: translate(strings.COUNTRY),
      width: "120px",
    },
    {
      field: "City",
      header: translate(strings.CITY),
      width: "120px",
    },
    {
      field: "RelatedEntity",
      header: translate(strings.ENTITY),
      width: "120px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return vendorsApi.getVendors({
      VendorName: inputString,
      Page: 0,
      PageSize: pageDefaultSize,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<VendorModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      searchCriteria={searchCriteria}
      {...rest}
    />
  );
}
