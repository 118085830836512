import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const approvals = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    { field: "CardNumber", header: strings.CARD_NO, width: "120px" },
    {
      field: "InsuredPersonInformationModel",
      header: strings.INSURED,
      width: "250px",
    },
    {
      field: "ApprovalStatusModel",
      header: strings.STATUS,
      width: "175px",
    },
    {
      field: "ApprovalTypeModel",
      header: strings.TYPE,
      width: "150px",
    },
    {
      field: "ClientModel",
      header: strings.POLICY_HOLDER,
      width: "250px",
    },
    {
      field: "AccidentDate",
      header: strings.ACCIDENT_DATE,
      width: "100px",
    },
  ],
  autoCompleteEndpoint: endpoints.APPROVALS,
  autoCompleteSearchKey: "ID",
};

export default approvals;
