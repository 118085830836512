import { useContext } from "react";
import { Button, ButtonProps } from "@progress/kendo-react-buttons";
import { LocalizationContext } from "../../context/localizationContext";

interface Props extends ButtonProps {
  title: string;
  disabled?: boolean;
}
const SubmitButton = ({ title, disabled, rounded }: Props) => {
  const { translate } = useContext(LocalizationContext);

  return (
    // /*use button insteadof kendo Button to be able to call .focus(), cause autoFocus not work with disabled property
    <Button
      autoFocus={true}
      type="submit"
      // themeColor="primary"
      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base primary-button"
      disabled={disabled}
      rounded={rounded}
    >
      {translate(title)}
    </Button>
  );
};

export default SubmitButton;
