import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const providers = {
  columns: [
    {
      field: "ProviderNumber",
      header: strings.PROVIDER_NUMBER,
      width: "150px",
    },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "150px",
    },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "150px",
    },
    {
      field: "ProviderCode",
      header: strings.CODE,
      width: "150px",
    },
    {
      field: "CityModel",
      header: strings.CITY,
      width: "100px",
    },
    {
      field: "LocationModel",
      header: strings.LOCATION,
      width: "100px",
    },
    {
      field: "ProviderTypeModel",
      header: strings.PROVIDER_TYPE,
      width: "150px",
    },
  ],
  autoCompleteEndpoint: endpoints.PROVIDERS,
  autoCompleteSearchKey: "ProviderNumberOrName",
};

export default providers;
