import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppAutoComplete from "../autocomplete/AppAutoComplete";
import ProviderAutoComplete from "../../controls/providers/base/ProviderAutoComplete";
import AppLinkedDropDowns from "../../common/components/dropDown/AppLinkedDropDowns";
import ProviderModel from "../../interfaces/providers/ProviderModel";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppSearchProviderSearchFieldKeys = {
  ProviderID: "ProviderID",
};
export default function AppSearchProviderSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const [provider, setProvider] = useState<ProviderModel | null>();
  const [searchBy, setSearchBy] = useState<any | undefined>({
    ID: 2,
    Name: translate(strings.PROVIDER_NUMBER),
    Code: "ProviderNumber",
  });

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleProviderChange = async (event: any) => {
    const value = event.value;
    if (!value) {
      setProvider(null);
      removeSearchFieldValue(AppSearchProviderSearchFieldKeys.ProviderID);
    } else {
      const searchValue: number = value.ID;
      setProvider(value);
      setSearchFieldValues(
        AppSearchProviderSearchFieldKeys.ProviderID,
        searchValue
      );
    }
  };

  const handleKeyChange = async (event: any) => {
    const value = event.value;
    removeSearchFieldValue(AppSearchProviderSearchFieldKeys.ProviderID);
    setProvider(null);

    if (!value) {
      setSearchBy(undefined);
    } else {
      setSearchBy(value);
    }
  };

  return (
    <span style={{ display: "inline-flex" }}>
      <AppLinkedDropDowns>
        <AppAutoComplete<any>
          className="search-component"
          dataList={[
            {
              ID: 1,
              Name: translate(strings.PROVIDER_NAME),
              Code: "ProviderName",
            },
            {
              ID: 2,
              Name: translate(strings.PROVIDER_NUMBER),
              Code: "ProviderNumber",
            },
            {
              ID: 3,
              Name: translate(strings.PROVIDER_CODE),
              Code: "ProviderCode",
            },
          ]}
          columns={[
            {
              field: "Name",
              header: translate(strings.SEARCH_BY),
              width: "100",
            },
          ]}
          textFieldDisplay={"Name"}
          placeholder={translate(strings.PROVIDER_SEARCH)}
          defaultValue={{
            ID: 2,
            Name: translate(strings.PROVIDER_NUMBER),
            Code: "ProviderNumber",
          }}
          onChange={handleKeyChange}
        />
        {searchBy && (
          <ProviderAutoComplete
            className="search-component"
            columns={[]}
            onChange={handleProviderChange}
            placeholder={translate(strings.PROVIDER)}
            searchKey={searchBy.Code}
            dataList={[]}
            charNumber={1}
            loadDataOnOpen
            value={provider}
          />
        )}
      </AppLinkedDropDowns>
    </span>
  );
}
