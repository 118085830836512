import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const insuranceCompanies = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    {
      field: "Organization",
      header: strings.ORGANIZATION,
      width: "120px",
    },
  ],
  autoCompleteEndpoint: endpoints.INSURANCE_COMPANIES,
  autoCompleteSearchKey: "InsuranceCompanyName",
};

export default insuranceCompanies;
