import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.NOTIFICATIONS;

const route_notification = "/notification";
const act_user_notifications = "/user-notifications";
const act_set_is_viewed = "/set-is-viewed";
const act_user_notifications_count = "/user-notifications-count";

const getUserNotifications = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_user_notifications,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const setNotificationIsViewed = (notificationID) => {
  return apiClient.put(
    endpoint + route_notification + "/" + notificationID + act_set_is_viewed
  );
};

const getUserNotificationsCount = (searchCriteria) => {
  return apiClient.post(
    endpoint + act_user_notifications_count,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const notificationsApi = {
  getUserNotifications,
  setNotificationIsViewed,
  getUserNotificationsCount,
};

export default notificationsApi;
