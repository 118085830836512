import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.SEARCH_FIELDS;

const getSearchFields = (workFlowObject, criteria) => {
  return apiClient.post(
    endpoint + "/" + workFlowObject,
    JSON.stringify(criteria),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const searchFields = {
  getSearchFields,
};

export default searchFields;
