import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import errorHandler from "../../common/utility/errorHandler";
import banksApi from "../../api/banks";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import AppDropDownFilterSearchField from "../../common/components/search/AppDropDownFilterSearchField";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppBankWithBranchesSearchFieldKeys = {
  BankID: "BankID",
  BankBranchID: "BankBranchID",
};
export default function AppBankWithBranchesSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const [bankID, setBankID] = useState<number>();
  const [showBankBranch, setShowBankBranch] = useState<boolean>(false);

  const getBankBranches = async () => {
    const response = await banksApi.getBankBranches(bankID);

    if (!response.ok) {
      setErrorMessage(errorHandler.getErrorMessage(response));
      return [];
    } else return response.data;
  };

  const handleBankClick = async (
    event: DropDownListChangeEvent,
    searchValue: string
  ) => {
    if (searchValue) {
      await setShowBankBranch(false);
      await setBankID(parseInt(searchValue));
      await setShowBankBranch(true);
    } else {
      setShowBankBranch(false);
      setBankID(0);
    }
  };

  useEffect(() => {
    setBankID(bankID);
  }, [showBankBranch]);

  return (
    <div style={{ display: "contents" }}>
      <AppDropDownFilterSearchField
        definitionType={"CBankDefinition"}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={AppBankWithBranchesSearchFieldKeys.BankID}
        defaultShow={true}
        title={translate(strings.BANK)}
        searchFieldName={searchFieldName}
        onChange={handleBankClick}
      />
      {showBankBranch && (
        <AppDropDownFilterSearchField
          definitionType={""}
          setErrorMessage={setErrorMessage}
          removeSearchFieldValue={removeSearchFieldValue}
          setSearchFieldValues={setSearchFieldValues}
          criteriaTitle={AppBankWithBranchesSearchFieldKeys.BankBranchID}
          defaultShow={true}
          title={translate(strings.BANK_BRANCH)}
          searchFieldName={searchFieldName}
          useCustomBinding
          reset={showBankBranch}
          getCustomList={getBankBranches}
        />
      )}
    </div>
  );
}
