import { Field, FieldProps } from "@progress/kendo-react-form";

// interface Props extends FieldProps {}
export default function AppField({
  breakAfter,
  breakBefore,
  ...rest
}: FieldProps) {
  return <Field {...rest} />;
}
