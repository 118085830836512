import { MobileValue } from "../components/formComponents/FormLabelMobile";
import CountryModel from "../interfaces/geography/CountryModel";

const validMobileNumber = (mobilValue: MobileValue) => {
  if (
    mobilValue.defaultTelFormat === undefined ||
    mobilValue.mobileDialingCode === undefined ||
    mobilValue.mobileNumber === undefined ||
    mobilValue.mobileNumber.length !==
      mobilValue.mobileNumberLengthWithoutPrefix
  )
    return false;
  else return true;
};

const getMobileValue = (
  mobileNumber: string,
  country: CountryModel
): MobileValue => {
  const number = mobileNumber?.slice(-country.MobileNumberLengthWithoutPrefix);
  const defaultTelFormat = mobileNumber?.slice(
    0,
    country.DefaultTelFormat.length
  );
  const prefix = mobileNumber?.slice(
    0,
    -country.MobileNumberLengthWithoutPrefix
  );

  const mobileDialingCode = prefix?.split(defaultTelFormat)[1];

  return {
    mobileNumber: number,
    mobileNumberLengthWithoutPrefix: country.MobileNumberLengthWithoutPrefix,
    defaultTelFormat: country.DefaultTelFormat,
    mobileDialingCode,
  };
};

const customUiHelper = {
  validMobileNumber,
  getMobileValue,
};

export default customUiHelper;
