import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import checkupApi from "../../../api/checkup";
import CheckupInvoiceModel from "../../../interfaces/checkup/CheckupInvoiceModel";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<CheckupInvoiceModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
}
export default function CheckupInvoiceAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: "CheckupInvoiceStatus",
      header: translate(strings.STATUS),
      width: "120px",
    },
    {
      field: "CheckupAgreement.ID",
      header: translate(strings.CHECKUP_AGREEMENT),
      width: "120px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return checkupApi.getCheckupInvoices(
      {
        ClientName: inputString,
        ...searchCriteria,
      },
      { Page: 0, PageSize: pageDefaultSize }
    );
  };

  return (
    <AppAutoComplete<CheckupInvoiceModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay="CheckupInvoiceName"
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
