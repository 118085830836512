import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import ICMPaymentVoucherGroupModel from "../../../interfaces/pvi/ICMPaymentVoucherGroupModel";
import pviApi from "../../../api/pvi";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<ICMPaymentVoucherGroupModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
}
export default function ICMPaymentVoucherGroupAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: "InsuranceCompanyModel",
      header: translate(strings.INSURANCE_COMPANY),
      width: "120px",
    },
    {
      field: "InsuranceCompanyBranchModel",
      header: translate(strings.INSURANCE_COMPANY_BRANCH),
      width: "230px",
    },
    {
      field: "IcmPaymentVoucherGroupStatusModel",
      header: translate(strings.STATUS),
      width: "175px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return pviApi.getICMPaymentVoucherGroups(
      {
        ICMPaymentVoucherGroupIDStartsWith: inputString,
        ...searchCriteria,
      },
      { Page: 0, PageSize: pageDefaultSize }
    );
  };

  return (
    <AppAutoComplete<ICMPaymentVoucherGroupModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay="ICMPaymentVoucherGroupName"
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
