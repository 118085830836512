import { Dispatch, SetStateAction, useContext, useState } from "react";
import AppDialog from "./AppDialog";
import AppDialogActionsBar from "./AppDialogActionsBar";
import { LocalizationContext } from "../../context/localizationContext";
import baseStrings from "../../base/baseStrings";
import { Trans } from "react-i18next";

export interface ConfirmationDialogFormModel {
  showConfirmation: boolean;
  confirmationMessage: string;
  action: string;
  dataItem: { workspace: any; step: number; setWorkspaceSummary: any };
  setLoadingExternally?: Dispatch<SetStateAction<boolean>>;
}

interface ConfirmDialogProps {
  onNoClick?: any;
  onYesClick?: any;
  title: string;
  message: string;
  action?: string;
  dataItem?: any;
  onClose?: () => void;
  hideNoButton?: boolean;
  customYesButtonText?: string;
  dialogWidth?:
    | "small"
    | "medium"
    | "large"
    | "xlarge"
    | "xxlarge"
    | "xxxlarge";
}
export default function ConfirmDialog({
  onNoClick = () => {},
  onYesClick = (/*dataItem: any, action: string*/) => {},
  title,
  message,
  action,
  dataItem,
  onClose,
  hideNoButton = false,
  customYesButtonText = baseStrings.YES,
  dialogWidth = "small",
}: ConfirmDialogProps) {
  const { translate } = useContext(LocalizationContext);
  const [visible, setVisible] = useState<boolean>(true);

  const toggleDialog = () => {
    if (onClose) onClose();
    //self Close
    else setVisible(!visible);
  };
  if (visible === false) return null;

  return (
    <AppDialog
      title={translate(title)}
      onClose={toggleDialog}
      dialogWidth={dialogWidth}
    >
      <div
        style={{
          padding: "20px",
          fontSize: "14px",
          // textAlign: "center",
          whiteSpace: "pre-wrap", //replaced <p> by <div> to use pre-wrap and parsing \n line breaks
        }}
      >
        <Trans
        /*  ns={[
            translationNamespaces.TRANSLATION,
            translationNamespaces.ERROR_CODES,
            translationNamespaces.BASE,
          ]} */
        >
          {message}
        </Trans>
      </div>
      <AppDialogActionsBar>
        {!hideNoButton && (
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={() => {
              toggleDialog();
              onNoClick();
            }}
          >
            {translate(baseStrings.NO)}
          </button>
        )}
        <button
          // className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base primary-button"
          onClick={() => {
            toggleDialog();
            onYesClick(dataItem, action);
          }}
          autoFocus={true}
        >
          {translate(customYesButtonText)}
        </button>
      </AppDialogActionsBar>
    </AppDialog>
  );
}
