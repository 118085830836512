import { Dispatch, SetStateAction, useContext, useState } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownSearchField from "../../common/components/search/AppDropDownSearchField";
import CustomerAutoComplete from "../../controls/customers/base/CustomerAutoComplete";
import VendorAutoComplete from "../../controls/vendors/base/VendorAutoComplete";
import EmployeeAutoComplete from "../../controls/employees/base/EmployeeAutoComplete";
import AppTextSearchField from "../../common/components/search/AppTextSearchField";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppTreasuryTransactionEntitySearchFieldKeys = {
  EntityType: "EntityType",
  EntityID: "EntityID",
  BeneficiaryName: "BeneficiaryName",
};
export default function AppTreasuryTransactionEntitySearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "Code",
  setErrorMessage,
}: Props) {
  const { translate, translateIn } = useContext(LocalizationContext);

  const [entityType, setEntityType] = useState<string>();

  const EEntities = {
    CUSTOMER: translate(strings.CUSTOMER),
    VENDOR: translate(strings.VENDOR),
    EMPLOYEE: translate(strings.EMPLOYEE),
    OTHER: translate(strings.OTHER),
  };

  const getEntityTypes = async () => {
    const entityTypes = [
      {
        ID: 1,
        LocalName: translateIn(strings.CUSTOMER, "ar"),
        ForeignName: translateIn(strings.CUSTOMER, "en"),
        Code: "CUSTOMER",
      },
      {
        ID: 2,
        LocalName: translateIn(strings.VENDOR, "ar"),
        ForeignName: translateIn(strings.VENDOR, "en"),
        Code: "VENDOR",
      },
      {
        ID: 3,
        LocalName: translateIn(strings.EMPLOYEE, "ar"),
        ForeignName: translateIn(strings.EMPLOYEE, "en"),
        Code: "EMPLOYEE",
      },
      {
        ID: 4,
        LocalName: translateIn(strings.OTHER, "ar"),
        ForeignName: translateIn(strings.OTHER, "en"),
        Code: "OTHER",
      },
    ];

    return entityTypes;
  };

  const handleEntityTypeChange = async (
    event: DropDownButtonItemClickEvent,
    searchValue: string
  ) => {
    removeSearchFieldValue(
      AppTreasuryTransactionEntitySearchFieldKeys.EntityID
    );

    if (entityType === EEntities.OTHER || entityType === "")
      if (
        event.itemIndex === 1 ||
        event.itemIndex === 2 ||
        event.itemIndex === 3
      )
        removeSearchFieldValue(
          AppTreasuryTransactionEntitySearchFieldKeys.BeneficiaryName
        );

    if (event.itemIndex !== 0) {
      setEntityType(event.item);
    } else {
      setEntityType("");
    }
  };

  const renderAutoComplete = (entityType?: string) => {
    switch (entityType) {
      case EEntities.CUSTOMER:
        return (
          <CustomerAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.CUSTOMER)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppTreasuryTransactionEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppTreasuryTransactionEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.VENDOR:
        return (
          <VendorAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.VENDOR)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppTreasuryTransactionEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppTreasuryTransactionEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.EMPLOYEE:
        return (
          <EmployeeAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.EMPLOYEE)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppTreasuryTransactionEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppTreasuryTransactionEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.OTHER:
      default:
        return (
          <AppTextSearchField
            removeSearchFieldValue={removeSearchFieldValue}
            setSearchFieldValues={setSearchFieldValues}
            criteriaTitle="BeneficiaryName"
            defaultShow={true}
            title={translate(strings.BENEFICIARY)}
          />
        );
    }
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AppDropDownSearchField
        definitionType={""}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={AppTreasuryTransactionEntitySearchFieldKeys.EntityType}
        defaultShow={false}
        title={translate(strings.ENTITY)}
        searchFieldName={searchFieldName}
        onChange={handleEntityTypeChange}
        useCustomBinding
        getCustomList={getEntityTypes}
      />
      {renderAutoComplete(entityType)}
    </div>
  );
}
