const ELanguageCodes = {
  // Code: To show Language Code in drop down List.
  // Localization: Same field name in Localizations Object.
  // rtlActive: To know if the language written from left to right or right to left
  ARABIC: {
    Name: "العربية",
    Code: "ar",
    Localization: "ArabicLocalization",
    rtlActive: true,
  },
  ENGLISH: {
    Name: "English",
    Code: "en",
    Localization: "EnglishLocalization",
    rtlActive: false,
  },
};

export default ELanguageCodes;
