import { useContext, useEffect, useState } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { formatNumber } from "@progress/kendo-intl";
import uiHelper from "../../helpers/uiHelper";
import { LocalizationContext } from "../../context/localizationContext";
import { colorCategory } from "../../base/colorCategory";
import { ProgressBar } from "@progress/kendo-react-progressbars";

interface Props extends GridCellProps {
  handleOnRowClick?: (e: any) => void;
  tooltipTitle?: string;
  tooltipField?: string;
  renderBarColor?: (dataItem: any, field: string) => colorCategory;
  showContentTooltip?: boolean;
}
export default function AppProgressCell({
  field = "",
  dataItem,
  handleOnRowClick,
  tooltipTitle,
  tooltipField,
  renderBarColor,
  showContentTooltip,
  ...rest
}: Props) {
  const { getName } = useContext(LocalizationContext);
  const [value, setValue] = useState<string>(
    uiHelper.getFieldValue(dataItem, field)
      ? uiHelper.getFieldValue(dataItem, field) instanceof Object
        ? getName(uiHelper.getFieldValue(dataItem, field))
        : formatNumber(
            parseFloat(uiHelper.getFieldValue(dataItem, field)),
            "n2"
          )
      : ""
  );

  useEffect(() => {
    const fieldValue = uiHelper.getFieldValue(dataItem, field);
    setValue(
      fieldValue //for handling Complex Binding ADG.DM.getName
        ? fieldValue instanceof Object
          ? getName(fieldValue)
          : fieldValue
        : ""
    );
  }, [dataItem[field]]);

  const getBarClassColor = () => {
    let color: colorCategory = colorCategory.NONE;
    let returnValue: string = "";

    if (renderBarColor) color = renderBarColor(dataItem, field);

    switch (color) {
      case colorCategory.SUCCESS:
        returnValue = " success-bkgcolor";
        break;
      case colorCategory.ERROR:
        returnValue = " error-bkgcolor";
        break;
      case colorCategory.WARNING:
        returnValue = " warning-bkgcolor";
        break;
      default:
        returnValue = ""; //transparent
        break;
    }
    return returnValue;
  };
  return (
    <td
      role={"gridcell"}
      // tabIndex={0}
      onClick={() => {
        if (handleOnRowClick) handleOnRowClick({ dataItem });
      }}
      title={showContentTooltip ? value : ""}
    >
      <ProgressBar
        value={parseFloat(value)}
        labelVisible={true}
        labelPlacement={"center"}
        label={(props) => {
          return (
            // <strong>
            <span>{props.value ? formatNumber(props.value, "n2") : 0}%</span>
          );
        }}
        // max={100}
        // max={300}
        progressClassName={getBarClassColor()}
      />
    </td>
  );
}
