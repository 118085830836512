// import secureLocalStorage from "react-secure-storage";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";

const cookies = new Cookies();
const secret = !process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY
  ? "qrqwgt@1131451HHFHJhkjH^^&*&$###"
  : process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY;

const tokenKey = process.env.REACT_APP_NAME + "SCT";
const userKey = process.env.REACT_APP_NAME + "SCL";
const viewAsUserKey = process.env.REACT_APP_NAME + "VAU";
const userCurrentStatusKey = process.env.REACT_APP_NAME + "UCS";
const passwordKey = process.env.REACT_APP_NAME + "PSW";

function setViewAsUser(userObject: any) {
  cookies.set(
    viewAsUserKey,
    CryptoJS.AES.encrypt(JSON.stringify(userObject), secret).toString(),
    {
      path: "/",
      // secure: true,
    }
  );
}

function getViewAsUser() {
  const encryptedViewAsUser = cookies.get(viewAsUserKey);
  if (!encryptedViewAsUser) return;
  const decryptedViewAsUser = CryptoJS.AES.decrypt(encryptedViewAsUser, secret);
  if (!decryptedViewAsUser) return;
  const descryptedString = decryptedViewAsUser.toString(CryptoJS.enc.Utf8);
  if (!descryptedString) return null;

  let userInfo = null;
  try {
    userInfo = JSON.parse(descryptedString);
  } catch (exception) {}
  return userInfo;
}

function removeViewAsUser() {
  cookies.remove(viewAsUserKey, { path: "/" });
}

function setToken(jwt: string) {
  cookies.set(tokenKey, CryptoJS.AES.encrypt(jwt, secret).toString(), {
    path: "/",
    // secure: true,
  });
}

function getToken() {
  const encryptedToken = cookies.get(tokenKey);
  if (!encryptedToken) return;
  const decryptedToken = CryptoJS.AES.decrypt(encryptedToken, secret);
  if (!decryptedToken) return;
  let tokenString;
  try {
    tokenString = decryptedToken.toString(CryptoJS.enc.Utf8);
  } catch (exception) {}
  return tokenString;
}
function removeToken() {
  cookies.remove(tokenKey, { path: "/" });
}
function setUserInfo(userObject: any) {
  cookies.set(
    userKey,
    CryptoJS.AES.encrypt(JSON.stringify(userObject), secret).toString(),
    {
      path: "/",
      // secure: true,
    }
  );
}

function getUserInfo() {
  const encryptedUserInfo = cookies.get(userKey);
  if (!encryptedUserInfo) return null;

  const decryptedUser = CryptoJS.AES.decrypt(encryptedUserInfo, secret);
  if (!decryptedUser) return null;
  const descryptedString = decryptedUser.toString(CryptoJS.enc.Utf8);
  // if (userInfo) return JSON.parse(userInfo.toString());
  if (!descryptedString) return null;

  let userInfo = null;
  try {
    userInfo = JSON.parse(descryptedString);
  } catch (exception) {}
  return userInfo;
}
function removeUserInfo() {
  cookies.remove(userKey, { path: "/" });
}

function getUserID() {
  const userInfo = getUserInfo();
  if (userInfo) {
    return userInfo.ID;
  } else {
    return null;
  }
}

function isAuthenticated() {
  const sessionToken = getToken();
  if (sessionToken !== null && sessionToken !== undefined) {
    return true;
  } else {
    return false;
  }
}

//  function clearStorage()
//  {
//       localStorage.removeItem("SCT");
//       localStorage.removeItem("SCL");
//  }

// function setLastOpened(date: number) {
//   secureLocalStorage.setItem("LOP", date.toString());
// }
// function getLastOpened() {
//   return secureLocalStorage.getItem("LOP");
// }
// function setLastClosed(date: number) {
//   secureLocalStorage.setItem("LCL", date.toString());
// }
// function getLastClosed() {
//   return secureLocalStorage.getItem("LCL");
// }
// function setTotalWindows(num: number) {
//   secureLocalStorage.setItem("TOW", num.toString());
// }
// function getTotalWindows() {
//   return secureLocalStorage.getItem("TOW");
// }

function setDefaultCountryID(defaultCountryID: string) {
  cookies.set(
    "DEFAULT_COUNTRY",
    CryptoJS.AES.encrypt(defaultCountryID, secret).toString(),
    {
      path: "/",
    }
  );
}

function getDefaultCountryID() {
  const encryptedCountryID = cookies.get("DEFAULT_COUNTRY");
  if (!encryptedCountryID) return;
  const decryptedCountryID = CryptoJS.AES.decrypt(encryptedCountryID, secret);
  if (!decryptedCountryID) return;
  let defaultCountryID;
  try {
    defaultCountryID = decryptedCountryID.toString(CryptoJS.enc.Utf8);
  } catch (exception) {}
  return defaultCountryID;
}
function removeDefaultCountryID() {
  cookies.remove("DEFAULT_COUNTRY", { path: "/" });
}

function setUserCurrentStatus(userCurrentStatus: any) {
  localStorage.setItem(
    userCurrentStatusKey,
    CryptoJS.AES.encrypt(JSON.stringify(userCurrentStatus), secret).toString()
  );
}

function getUserCurrentStatus() {
  const encryptedUserCurrentStatus = localStorage.getItem(userCurrentStatusKey);
  if (!encryptedUserCurrentStatus) return null;

  const decryptedUserCurrentStatus = CryptoJS.AES.decrypt(
    encryptedUserCurrentStatus,
    secret
  );
  if (!decryptedUserCurrentStatus) return null;
  const descryptedString = decryptedUserCurrentStatus.toString(
    CryptoJS.enc.Utf8
  );

  if (!descryptedString) return null;

  let userCurrentStatus = null;
  try {
    userCurrentStatus = JSON.parse(descryptedString);
  } catch (exception) {}
  return userCurrentStatus;
}

function removeUserCurrentStatus() {
  localStorage.removeItem(userCurrentStatusKey);
}

function setUserPassword(password: string) {
  cookies.set(passwordKey, CryptoJS.AES.encrypt(password, secret).toString(), {
    path: "/",
  });
}

function getUserPassword() {
  const encryptedPassword = cookies.get(passwordKey);
  if (!encryptedPassword) return;
  const decryptedPassword = CryptoJS.AES.decrypt(encryptedPassword, secret);
  if (!decryptedPassword) return;
  let passwordString;
  try {
    passwordString = decryptedPassword.toString(CryptoJS.enc.Utf8);
  } catch (exception) {}
  return passwordString;
}

function removeUserPassword() {
  cookies.remove(passwordKey, { path: "/" });
}

const authStorage = {
  secret,
  setViewAsUser,
  getViewAsUser,
  removeViewAsUser,
  setToken,
  getToken,
  removeToken,
  setUserInfo,
  getUserInfo,
  removeUserInfo,
  isAuthenticated,
  //clearStorage,
  getUserID,
  getDefaultCountryID,
  setDefaultCountryID,
  removeDefaultCountryID,
  setUserCurrentStatus,
  getUserCurrentStatus,
  removeUserCurrentStatus,
  setUserPassword,
  getUserPassword,
  removeUserPassword,
  // setLastOpened,
  // getLastOpened,
  // setLastClosed,
  // getLastClosed,
  // setTotalWindows,
  // getTotalWindows,
};

export default authStorage;
