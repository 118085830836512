import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const premiumFunders = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    {
      field: "OrganizationModel",
      header: strings.ORGANIZATION,
      width: "120px",
    },
  ],
  autoCompleteEndpoint: endpoints.PARTIES + "/premium-funders",
  autoCompleteSearchKey: "Name",
};

export default premiumFunders;
