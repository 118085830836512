export default Object.freeze({
  NONE: "NONE",
  REPORTS: "REPORTS",
  PROVIDER_GROUP_REPORTS: "PROVIDER_GROUP_REPORTS",
  CLIENT_GROUP_REPORTS: "CLIENT_GROUP_REPORTS",
  INSURED_GROUP_REPORTS: "INSURED_GROUP_REPORTS",
  INSURANCE_COMPANY_GROUP_REPORTS: "INSURANCE_COMPANY_GROUP_REPORTS",
  POLICY_REPORTS: "POLICY_HEADER_CONTEXTUAL",
  INSURANCE_COMPANY_POLICY_HEADER_CONTEXTUAL:
    "INSURANCE_COMPANY_POLICY_HEADER_CONTEXTUAL",
  INSURANCE_COMPANY_POLICY_CONTEXTUAL: "INSURANCE_COMPANY_POLICY_CONTEXTUAL",
  TRANSMITTAL_CONTEXTUAL: "TRANSMITTAL_CONTEXTUAL",
  TRANSMITTAL_PROVIDER_COPY: "TRANSMITTAL_PROVIDER_COPY",
  POLICY_HEADER_CONTEXTUAL: "POLICY_HEADER_CONTEXTUAL",
  POLICY_REVISION_CONTEXTUAL: "POLICY_REVISION_CONTEXTUAL",
  PVP_GROUP_CONTEXTUAL: "PVP_GROUP_CONTEXTUAL",
  PVC_GROUP_CONTEXTUAL: "PVC_GROUP_CONTEXTUAL",
  CDV_GROUP_CONTEXTUAL: "CDV_GROUP_CONTEXTUAL",
  CDV_CONTEXTUAL: "CDV_CONTEXTUAL",
  PVI_CONTEXTUAL: "PVI_CONTEXTUAL",
  PVI_GROUP_CONTEXTUAL: "PVI_GROUP_CONTEXTUAL",
  PROVIDER_CONTEXTUAL: "PROVIDER_CONTEXTUAL",
  PROVIDER_GROUP_CONTEXTUAL: "PROVIDER_GROUP_CONTEXTUAL",
  PRICING_SCHEME_CONTEXTUAL: "PRICING_SCHEME_CONTEXTUAL",
  PACK_CONTEXTUAL: "PACK_CONTEXTUAL",
  REINSURER_GROUP_REPORTS: "REINSURER_GROUP_REPORTS",
  REIMBURSEMENT_CONTEXTUAL: "REIMBURSEMENT_CONTEXTUAL",
  // POLICY_HEADER_CONTEXTUAL_PORTAL: "POLICY_HEADER_CONTEXTUAL_PORTAL",
});
