import { createContext, ReactElement, ReactNode } from "react";
import AutoCompleteObject from "../interfaces/AutoCompleteObject";
import uiHelper from './../helpers/uiHelper';
import { formatNumber } from '@progress/kendo-intl';

interface ContextProps {
  CustomSearchField: (props: any) => JSX.Element | null;
  customComponentIncludesSearchField: (
    customFieldKey: string,
    searchFieldKey: string
  ) => boolean;
  autocompleteObjects: {
    [key: string]: AutoCompleteObject;
  };
  definitions: any;
  getSearchFields: (workFlowObject: any, criteria: any) => Promise<any>;
  iconFactory: {
    getIcon: (iconKey: string) => JSX.Element;
  };
  defaultSystemDecimalRounding: number;
  formatNumber: (number: number) => string;
}
interface Props extends Omit<ContextProps, "formatNumber"> {
  children: ReactNode;
}
export const ApplicationContext = createContext<ContextProps>({
  CustomSearchField: () => <></>,
  customComponentIncludesSearchField: (
    customFieldKey: string,
    searchFieldKey: string
  ) => {
    return false;
  },
  autocompleteObjects: {},
  definitions: {},
  getSearchFields: (workFlowObject: any, criteria: any) => {
    return new Promise((resolve, reject) => {
      resolve(false);
    });
  },
  iconFactory: {
    getIcon: (iconKey: string) => {
      return <></>;
    },
  },
  defaultSystemDecimalRounding: 2,
  formatNumber: (number: number) => number.toString()
});

export default function ApplicationContextProvider({
  CustomSearchField,
  customComponentIncludesSearchField,
  autocompleteObjects,
  definitions,
  getSearchFields,
  iconFactory,
  defaultSystemDecimalRounding,
  children,
}: Props) {
  const formatNumber = (number: number) => {
    return uiHelper.formatNumber(number, defaultSystemDecimalRounding);
  };
  return (
    <ApplicationContext.Provider
      value={{
        CustomSearchField,
        customComponentIncludesSearchField,
        autocompleteObjects,
        definitions,
        getSearchFields,
        iconFactory,
        defaultSystemDecimalRounding,
        formatNumber,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
}
