import { useEffect, useState } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import AppNumericTextBox from "../inputs/AppNumericTextBox";
import uiHelper from "../../helpers/uiHelper";

interface Props extends GridCellProps {
  fieldList: Array<string>;
  editable: boolean;
  separator: string;
  unitLabel?: string;
  onCloseEdit?: (e: any) => Promise<any>;
  handleOnRowClick?: (e: any) => void;
  customCellStatus?: (dataItem: any) => any;
  disabled?: boolean;
  dependencyField?: string;
  renderCellStatusBasedOnDependencyField?: boolean;
}

export default function AppTwoTextBoxesCell({
  fieldList,
  unitLabel,
  separator,
  dataItem,
  editable,
  onCloseEdit,
  onChange,
  handleOnRowClick,
  disabled,
  field,
  customCellStatus,
  dataIndex,
  dependencyField = "",
  renderCellStatusBasedOnDependencyField,
  ...rest
}: Props) {
  const MIN_WIDTH = "50px";
  const ITEM_1 = "ITEM_1";
  const ITEM_2 = "ITEM_2";

  const [firstValue, setFirstValue] = useState(
    uiHelper.getFieldValue(dataItem, fieldList[0])
  );
  const [secondValue, setSecondValue] = useState(
    uiHelper.getFieldValue(dataItem, fieldList[1])
  );

  const [originalFirstValue] = useState(
    uiHelper.getFieldValue(dataItem, fieldList[0])
  );
  const [originalSecondValue] = useState(
    uiHelper.getFieldValue(dataItem, fieldList[1])
  );

  // const [firstCellStatus, setFirstCellStatus] = useState({ Enabled: true });
  // const [secondCellStatus, setSecondCellStatus] = useState({ Enabled: true });

  const [isEditable, setIsEditable] = useState<boolean>(
    !!(dataItem.inEdit && editable)
  );

  useEffect(() => {
    // setFirstValue(dataItem[fieldList[0]]);
    // setSecondValue(dataItem[fieldList[1]]);
    setIsEditable(dataItem.inEdit && editable);
    // eslint-disable-next-line
  }, [dataItem.inEdit]);

  useEffect(() => {
    setFirstValue(uiHelper.getFieldValue(dataItem, fieldList[0]));
    // eslint-disable-next-line
  }, [dataItem[fieldList[0]]]);

  useEffect(() => {
    setSecondValue(uiHelper.getFieldValue(dataItem, fieldList[1]));
    // eslint-disable-next-line
  }, [dataItem[fieldList[1]]]);

  // useEffect(() => {
  //   if (renderCellStatusBasedOnDependencyField) {
  //     setFirstCellStatus({ Enabled: IsCellEnabled(1) });
  //     setSecondCellStatus({ Enabled: IsCellEnabled(2) });
  //   }
  // }, [dataItem[dependencyField]]);

  const handleOnItemChanged = (e: any) => {
    const newItemValue = e.target.value ? e.target.value : 0;
    if (e.target.element.id === ITEM_1) setFirstValue(newItemValue);
    else setSecondValue(newItemValue);
  };

  const handleClick = (e: any) => {
    if (handleOnRowClick) handleOnRowClick({ dataItem });

    if (disabled === false) setIsEditable(true);
  };

  const handleOnKeyDown = async (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      await invokeUpdate(e);
    } else if ((e.key === "Tab" || e.keyCode === 9) && dataItem.isNewItem) {
      const focusableModalElements =
        e.target.parentElement.parentElement.parentElement.parentElement.querySelectorAll(
          // "a[href], button:not([disabled]), textarea, input, select"
          "textarea, input:not([disabled]), select"
        );

      const firstElement = focusableModalElements[0];
      const lastElement =
        focusableModalElements[focusableModalElements.length - 1];

      // if going forward by pressing tab and lastElement is active shift focus to first focusable element
      if (!e.shiftKey && document.activeElement === lastElement) {
        (firstElement as HTMLElement).focus();
        return e.preventDefault();
      }

      // if going backward by pressing tab and firstElement is active shift focus to last focusable element
      if (e.shiftKey && document.activeElement === firstElement) {
        (lastElement as HTMLElement).focus();
        e.preventDefault();
      }
    }
  };

  const invokeUpdate = async (e: any) => {
    /*
    Note: this code was commented to trigger the event whatever the value is, even if it was the old value.
    Example: if the quantity was 1 and we put it 2 then return it back to 1 will not update the value until we refresh the page.
    so if the user update it the quantity by mistake will not be able to return it back until he reloads the page.
    
    if (
      firstValue === originalFirstValue &&
      secondValue === originalSecondValue &&
      !dataItem.isNewItem
    ) {
      setIsEditable(false);
      return;
    }  */
    // if (onCloseEdit && !dataItem.isNewItem) {
    if (onCloseEdit) {
      // const succeed = await onCloseEdit({
      await onCloseEdit({
        dataIndex,
        dataItem,
        // field: field,
        syntheticEvent: e.syntheticEvent,
        value: {
          Item1: firstValue,
          Item2: secondValue,
        },
      });
      //   Item1: firstValue,
      //   Item2: secondValue,
      // });
      // if (succeed === true) {
      //   setValue(newValue);
      // } else {
      //   setValue(originalValue);
      // }
    } //else setValue(newValue);

    setIsEditable(false);
  };

  const getUnitName = () => {
    if (!unitLabel) return;
    return uiHelper.getFieldValue(dataItem, unitLabel);
  };

  const handleOnBlur = (e: any, fieldNum: number) => {
    if (!dataItem.isNewItem) return; //Using blur to fire onChange in AddMode only, but InEdit should use Enter key (onKeyDown event)
    if (fieldNum === 1) {
      if (onChange)
        onChange({
          dataIndex,
          dataItem,
          field: fieldList[0],
          syntheticEvent: e.syntheticEvent,
          value: firstValue,
        });
    } else {
      if (onChange)
        onChange({
          dataIndex,
          dataItem,
          field: fieldList[1],
          syntheticEvent: e.syntheticEvent,
          value: secondValue,
        });
    }
  };

  const IsCellEnabled = (fieldNum: number) => {
    if (customCellStatus) {
      const config = customCellStatus(dataItem);

      if (
        fieldNum === 1 &&
        config.CELL_1 &&
        config.CELL_1.hasOwnProperty("Enabled")
      )
        return config.CELL_1.Enabled;
      if (
        fieldNum === 2 &&
        config.CELL_2 &&
        config.CELL_2.hasOwnProperty("Enabled")
      ) {
        return config.CELL_2.Enabled;
      }
    }
    return true;
  };

  const unitModel = unitLabel
    ? getUnitName()
      ? getUnitName()
      : undefined
    : undefined;

  const unitModelReadOnly = unitModel || "";

  return (
    <td
      role={"gridcell"}
      onClick={handleClick}
      onKeyDown={handleOnKeyDown}
      onMouseLeave={() => {
        if (isEditable) setIsEditable(false);
      }}
    >
      {isEditable ? (
        <div style={{ display: "flex" }}>
          <AppNumericTextBox
            id={ITEM_1}
            onChange={handleOnItemChanged}
            value={firstValue}
            onBlur={(e) => handleOnBlur(e, 1)}
            style={{ minWidth: MIN_WIDTH }}
            showSpinners={false}
            disabled={!IsCellEnabled(1)}
            // disabled={!firstCellStatus.Enabled}
          />
          {unitModel && (
            <input
              disabled
              value={unitModel}
              style={{ width: MIN_WIDTH, textOverflow: "ellipsis" }}
            />
          )}
          <span>{separator}</span>
          <AppNumericTextBox
            id={ITEM_2}
            onChange={handleOnItemChanged}
            value={secondValue}
            onBlur={(e) => handleOnBlur(e, 2)}
            style={{ minWidth: MIN_WIDTH }}
            showSpinners={false}
            disabled={!IsCellEnabled(2)}
            // disabled={!secondCellStatus.Enabled}
          />
        </div>
      ) : !uiHelper.getFieldValue(dataItem, fieldList[0]) &&
        !uiHelper.getFieldValue(dataItem, fieldList[1]) ? (
        " - "
      ) : (
        (uiHelper.getFieldValue(dataItem, fieldList[0])
          ? uiHelper.getFieldValue(dataItem, fieldList[0])
          : 0) +
        " " +
        unitModelReadOnly +
        separator +
        (uiHelper.getFieldValue(dataItem, fieldList[1])
          ? uiHelper.getFieldValue(dataItem, fieldList[1])
          : 0)
      )}
    </td>
  );
}
