import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.INSURANCE_COMPANIES;

const route_insurance_company_branches = "/insurance-company-branches";

const act_update = "/update";
const act_activate = "/activate";
const act_deactivate = "/deactivate";
const act_update_code = "/update-code";

const getInsuranceCompanies = (criteria) =>
  apiClient.post(endpoint, JSON.stringify({ ...criteria }), {
    headers: { "X-HTTP-Method-Override": "GET" },
  });

const getInsuranceCompanyBranches = (criteria) =>
  apiClient.post(
    endpoint + route_insurance_company_branches,
    JSON.stringify({ ...criteria }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );

const getInsuranceCompany = (insuranceCompanyID) =>
  apiClient.get(endpoint + "/" + insuranceCompanyID);

const addInsuranceCompany = (data) => apiClient.post(endpoint, data);

const updateInsuranceCompany = (insuranceCompanyID, data) =>
  apiClient.put(endpoint + "/" + insuranceCompanyID + act_update, data);

const deleteInsuranceCompany = (insuranceCompanyID) =>
  apiClient.delete(endpoint + "/" + insuranceCompanyID);

const activateInsuranceCompany = (insuranceCompanyID, data) =>
  apiClient.put(endpoint + "/" + insuranceCompanyID + act_activate, data);

const deactivateInsuranceCompany = (insuranceCompanyID, data) =>
  apiClient.put(endpoint + "/" + insuranceCompanyID + act_deactivate, data);

const addInsuranceCompanyBranch = (insuranceCompanyID, data) =>
  apiClient.post(
    endpoint + "/" + insuranceCompanyID + route_insurance_company_branches,
    data
  );

const updateInsuranceCompanyBranch = (
  insuranceCompanyID,
  insuranceCompanyBranchID,
  data
) =>
  apiClient.put(
    endpoint +
      "/" +
      insuranceCompanyID +
      route_insurance_company_branches +
      "/" +
      insuranceCompanyBranchID,
    data
  );

const removeInsuranceCompanyBranch = (
  insuranceCompanyID,
  insuranceCompanyBranchID
) =>
  apiClient.delete(
    endpoint +
      "/" +
      insuranceCompanyID +
      route_insurance_company_branches +
      "/" +
      insuranceCompanyBranchID
  );

const updateInsuranceCompanyCode = (insuranceCompanyID, data) =>
  apiClient.put(endpoint + "/" + insuranceCompanyID + act_update_code, data);
  
export default {
  getInsuranceCompanies,
  getInsuranceCompany,
  getInsuranceCompanyBranches,
  addInsuranceCompany,
  updateInsuranceCompany,
  deleteInsuranceCompany,
  activateInsuranceCompany,
  deactivateInsuranceCompany,
  addInsuranceCompanyBranch,
  updateInsuranceCompanyBranch,
  removeInsuranceCompanyBranch,
  updateInsuranceCompanyCode,
};
