import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import AdvancePaymentModel from "../../../interfaces/advancePayments/AdvancePaymentModel";
import advancePaymentsApi from "../../../api/advancePayments";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<AdvancePaymentModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
}
export default function AdvancePaymentAutocomplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: "AdvancePaymentStatusModel",
      header: translate(strings.STATUS),
      width: "120px",
    },
    {
      field: "ProviderModel",
      header: translate(strings.PROVIDER),
      width: "120px",
    },
    {
      field: "TransmittalModel",
      header: translate(strings.TRANSMITTAL),
      width: "120px",
    },
    {
      field: "DueDate",
      header: translate(strings.DUE_DATE),
      width: "75px",
    },
  ];

  const getData = (
    inputString?: string,
    searchCriteria?: Record<string, any>
  ) => {
    return advancePaymentsApi.getAdvancePayments(
      {
        ProviderName: inputString,
      },
      {
        page: 0,
        pageSize: pageDefaultSize,
      }
    );
  };

  return (
    <AppAutoComplete<AdvancePaymentModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay={"AdvancePaymentName"}
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
