import { useContext } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import policiesApi from "../../../api/policy";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import PolicyModel from "./../../../interfaces/policy/PolicyModel";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<PolicyModel>;
  loadDataOnOpen?: boolean;
  searchCriteria?: Object;
  searchKey?: string;
}

export default function PolicyAutoComplete({
  dataList,
  loadDataOnOpen,
  columns,
  searchKey = "ClientName", //IDStartWith
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    {
      field: "ID",
      header: translate(strings.REVISION_ID),
      width: "100px",
    },
    {
      field: "PolicyHeaderID",
      header: translate(strings.POLICY_ID),
      width: "70px",
    },
    {
      field: "ClientModel",
      header: translate(strings.POLICY_HOLDER),
      width: "250px",
    },
    {
      field: "PolicyRenewalNumber",
      header: translate(strings.RENEWAL_NUMBER),
      width: "180px",
    },
    {
      field: "PolicyStartDate",
      header: translate(strings.START_DATE),
      width: "100px",
    },
    {
      field: "PolicyEndDate",
      header: translate(strings.END_DATE),
      width: "100px",
    },
    {
      field: "UnderwritingYear",
      header: translate(strings.UNDERWRITING_YEAR),
      width: "120px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return policiesApi.getPolicies({
      [searchKey]: inputString,
      PageSize: 10,
      Page: 0,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<PolicyModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay={"ClientModel." + getNameField()}
      getData={getData}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
