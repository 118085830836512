import { useContext } from "react";
import { Label } from "@progress/kendo-react-labels";
import { LocalizationContext } from "../../context/localizationContext";

interface Props {
  skipTranslate?: boolean;
  labelTitle: string;
  minWidth: number;
  additionalClasses?: string;
  overrideTextTransformation?: boolean;
}

export default function LabelOnly({
  skipTranslate = false,
  labelTitle,
  minWidth,
  additionalClasses = "",
  overrideTextTransformation = true,
}: Props) {
  const { translate } = useContext(LocalizationContext);
  return (
    <Label>
      {/* className="labelValue" */}
      <span
        className={"labelTitle " + additionalClasses}
        style={{
          minWidth: minWidth + "px",
          ...(overrideTextTransformation ? { textTransform: "none" } : {}),
          // fontWeight: "500",
        }}
      >
        {skipTranslate ? labelTitle : translate(labelTitle)}
      </span>
    </Label>
  );
}
