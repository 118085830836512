import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownButton from "../../common/components/buttons/AppDropDownButton";
import definitions from "../../api/definitions";
import { getDefinitionList } from "../../common/api/searchDefinitionHelper";
import countriesAPI from "../../api/countries";
import errorHandler from "../../common/utility/errorHandler";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import BusinessObjectModel from "../../common/interfaces/BusinessObjectModel";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppCountryCitySearchFieldKeys = {
  CountryID: "CountryID",
  CityID: "CityID",
};
export default function AppCountryCitySearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { getNameField, translate, getName } = useContext(LocalizationContext);

  const resetObject = {
    ID: 0,
    LocalName: translate(strings.UNSET_THIS_VALUE),
    ForeignName: translate(strings.UNSET_THIS_VALUE),
  };

  const [countryFieldName, setCountryFieldName] = useState(
    translate(strings.COUNTRY)
  );
  const [cityFieldName, setCityFieldName] = useState(translate(strings.CITY));

  const [showCity, setShowCity] = useState(false);

  const [countries, setCountries] = useState<BusinessObjectModel[]>([]);
  const [cities, setCities] = useState<BusinessObjectModel[]>([]);

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  const getCities = async (countryID: number) => {
    const response = await countriesAPI.getCities(countryID);

    if (response.ok) {
      setCities([resetObject, ...(response.data ?? [])]);
    } else setErrorMessage(errorHandler.getErrorMessage(response));
  };

  const getCountries = async () => {
    const countryObjectType = definitions.CountryDefinition.objectType;
    const countryList: BusinessObjectModel[] = await getDefinitionList<
      typeof countryObjectType
    >(
      definitions.CountryDefinition.endpoint,
      definitions.CountryDefinition.callType,
      setErrorMessage
    );
    setCountries([resetObject, ...countryList]);
  };

  const getObjectSearchValue = (dropDownObjectList: any[], value: string) => {
    let returnedValue: string = "";
    dropDownObjectList.filter((object) => {
      if (getName(object) === value) returnedValue = object[searchFieldName];
      return returnedValue;
    });
    return returnedValue;
  };

  const handleCountryCLick = async (event: DropDownButtonItemClickEvent) => {
    const value = event.item;
    if (!value) setShowCity(false);
    setCityFieldName(translate(strings.CITY));
    if (getName(event.item) === getName(resetObject)) {
      removeSearchFieldValue(AppCountryCitySearchFieldKeys.CountryID);
      setCountryFieldName(translate(strings.COUNTRY));
      setShowCity(false);
      onCityChanged(resetObject);
    } else {
      getCities(event.item.ID);
      setCountryFieldName(
        translate(strings.COUNTRY) + ": " + getName(event.item)
      );
      setShowCity(true);
      const searchValue: string = getObjectSearchValue(
        countries,
        getName(value)
      );
      onCityChanged(resetObject);
      setSearchFieldValues(
        AppCountryCitySearchFieldKeys.CountryID,
        searchValue
      );
    }
  };

  const onCityChanged = (city: any) => {
    if (!city) setShowCity(false);
    if (getName(city) === getName(resetObject)) {
      removeSearchFieldValue(AppCountryCitySearchFieldKeys.CityID);
      setCityFieldName(translate(strings.CITY));
    } else {
      setCityFieldName(translate(strings.CITY) + ": " + getName(city));
      const searchValue: string = getObjectSearchValue(cities, getName(city));
      setSearchFieldValues(AppCountryCitySearchFieldKeys.CityID, searchValue);
    }
  };
  const handleCityClick = async (event: DropDownButtonItemClickEvent) => {
    const value = event.item;
    onCityChanged(value);
  };

  return (
    <>
      <AppDropDownButton
        className="search-component"
        text={countryFieldName}
        icon="k-icon k-font-icon k-i-arrow-chevron-down"
        items={countries}
        textField={getNameField()}
        popupSettings={{ popupClass: "popup" }}
        themeColor={"primary"}
        onItemClick={handleCountryCLick}
      />
      {showCity && (
        <AppDropDownButton
          className="search-component"
          text={cityFieldName}
          icon="k-icon k-font-icon k-i-arrow-chevron-down"
          items={cities}
          textField={getNameField()}
          popupSettings={{ popupClass: "popup" }}
          themeColor={"primary"}
          onItemClick={handleCityClick}
        />
      )}
    </>
  );
}
