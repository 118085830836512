import React, { useContext } from "react";
import SlidingPane from "react-sliding-pane";
import { LocalizationContext } from "../../context/localizationContext";
import useBreakpoint from "use-breakpoint";
import { Breakpoints } from "../../constants/breakpoints";
import "react-sliding-pane/dist/react-sliding-pane.css";
import LoadingPanel from "../general/LoadingPanel";
import breakpointKeys from "../../constants/breakpointKeys";

interface Props {
  size: "small" | "medium" | "large";
  tabletSize?: "small" | "medium" | "large";
  mobileSize?: "small" | "medium" | "large";
  highresSize?: "small" | "medium" | "large";
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
  children: React.ReactNode;
  loading?: boolean;
  onAfterClose?: () => void;
  forceOpen?: boolean;
}
export default function AppSlidingPanel({
  size,
  tabletSize,
  mobileSize,
  highresSize,
  isVisible,
  setVisible,
  children,
  loading,
  onAfterClose,
  forceOpen = false,
}: Props) {
  const { isRTL } = useContext(LocalizationContext);
  const { breakpoint } = useBreakpoint(Breakpoints, breakpointKeys.DESKTOP);

  const getSize = () => {
    switch (breakpoint) {
      case breakpointKeys.TABLET:
        return tabletSize || size;
      case breakpointKeys.MOBILE:
        return mobileSize || tabletSize || size;
      case breakpointKeys.HIGH_RES:
        return highresSize || size;
      default:
        return size;
    }
  };
  const getClassName = () => {
    const actualSize = getSize();
    switch (actualSize) {
      case "small":
        return "small-window";
      case "large":
        return "large-window";
      case "medium":
      default:
        return "medium-window";
    }
  };
  return (
    <SlidingPane
      hideHeader={true}
      className={"sliding-pane " + getClassName()}
      isOpen={isVisible}
      onRequestClose={() => {
        if (!loading && !forceOpen) setVisible(false);
      }}
      onAfterClose={onAfterClose}
      from={isRTL ? "left" : "right"}
    >
      <div
        style={{ height: "100%", width: "100%" }}
        dir={isRTL === true ? "RTL" : "LTR"}
      >
        {children}
      </div>
      {loading && <LoadingPanel />}
    </SlidingPane>
  );
}
