import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const cards = {
  columns: [
    { field: "ID", header: strings.ID, width: "80px" },
    {
      field: "CardNumber",
      header: strings.CARD_NO,
      width: "120px",
    },
    {
      field: "InsuredPersonInformationModel",
      header: strings.INSURED,
      width: "200px",
    },
    {
      field: "ClientModel",
      header: strings.POLICY_HOLDER,
      width: "200px",
    },
    {
      field: "PolicyHeaderModel",
      header: strings.POLICY,
      width: "100px",
    },
    {
      field: "IssuanceDate",
      header: strings.ISSUANCE_DATE,
      width: "120px",
    },
  ],
  autoCompleteEndpoint: endpoints.CARDS,
  autoCompleteSearchKey: "cardNumberStartsWith",
};

export default cards;
