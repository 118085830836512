import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const relationOfficers = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    {
      field: "RelationOfficerType",
      header: strings.TYPE,
      width: "120px",
    },
    {
      field: "Person",
      header: strings.PERSON,
      width: "120px",
    },
  ],
  autoCompleteEndpoint: endpoints.CORPORATE_RELATION + "/relation-officers",
  autoCompleteSearchKey: "PersonName",
};

export default relationOfficers;
