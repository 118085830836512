import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import AccountingFiscalYearModel from "../../../interfaces/accountingFiscalYears/AccountingFiscalYearModel";
import accountingFiscalYearsApi from "../../../api/accountingFiscalYears";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<AccountingFiscalYearModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
}
export default function AccountingFiscalYearAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "70px" },
    {
      field: getNameField(),
      header: translate(strings.NAME),
      width: "100px",
    },
    {
      field: "FromDate",
      header: translate(strings.FROM_DATE),
      width: "100px",
    },
    {
      field: "ToDate",
      header: translate(strings.TO_DATE),
      width: "100px",
    },
    {
      field: "InstallmentPeriod",
      header: translate(strings.PERIOD_TYPE),
      width: "100px",
    },
  ];

  const getData = (
    inputString?: string,
    searchCriteria?: Record<string, any>
  ) => {
    return accountingFiscalYearsApi.getAccountingFiscalYears(
      {
        AccountingFiscalYearName: inputString,
        ...searchCriteria,
      },
      { page: 0, pageSize: pageDefaultSize }
    );
  };

  return (
    <AppAutoComplete<AccountingFiscalYearModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
