import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../context/authenticationContext";
import urlBuilder from "../helpers/urlBuilder";
import Alert from "../components/general/Alert";
import errorHandler from "../utility/errorHandler";
import baseRoutes from "../base/baseRoutes";
import baseStrings from "../base/baseStrings";
import { UserPortalContext } from "../context/userPortalContext";
import translationNamespaces from "../enums/locales/translationNamespaces";
import { useTranslation } from "react-i18next";
import { LocalizationContext } from "./../context/localizationContext";

export default function useLocalization(
  namespaces: string[] = [
    translationNamespaces.TRANSLATION,
    translationNamespaces.BASE,
  ]
) {
  const { t } = useTranslation(namespaces);
  const { locale, translationAdditionalProps } =
    useContext(LocalizationContext);

  const translate = (value: string) => {
    return t(value, {
      lng: locale,
      ns: namespaces,
      ...(translationAdditionalProps || {}),
    });
  };

  return {
    translate,
  };
}
