import { CSSProperties, useContext } from "react";
import { Error } from "@progress/kendo-react-labels";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { LocalizationContext } from "../../context/localizationContext";
import AppCaptcha from "../inputs/AppCaptcha";

interface Props extends FieldRenderProps {
  fieldWrapperStyle?: CSSProperties;
  showOnlyMonthPicker?: boolean;
}
export default function FormCaptcha({
  fieldWrapperStyle,
  validationMessage,
  valid,
  visited,
  value,
  onChange,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);
  const showValidationMessage: string | false | null =
    visited && validationMessage;

  return (
    <FieldWrapper style={fieldWrapperStyle}>
      <AppCaptcha
        captchaValue={value}
        onChange={(
          e: any,
          captchaValue: { captchaText: string; userInput: string }
        ) => onChange({ target: e.target, value: captchaValue })}
        {...rest}
      />
      {showValidationMessage && <Error>{translate(validationMessage)}</Error>}
    </FieldWrapper>
  );
}
