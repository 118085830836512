import { useContext, useEffect, useState } from "react";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import strings from "../../../base/strings";
import { LocalizationContext } from "../../context/localizationContext";
import SearchFieldModel from "../../interfaces/SearchFieldModel";
import AppDropDownButton from "../buttons/AppDropDownButton";
import AppCheckBox from "../inputs/AppCheckBox";
import AppStackLayout from "../stack/AppStackLayout";

interface Props extends SearchFieldModel {
  onChange?: (
    event: DropDownButtonItemClickEvent,
    searchValue?: boolean
  ) => void;
}

export default function AppBooleanSearchField({
  removeSearchFieldValue,
  setSearchFieldValues,
  criteriaTitle,
  title,
  defaultShow,
  reset,
  defaultValue,
  onChange,
}: Props) {
  const { translate, isRTL } = useContext(LocalizationContext);
  const items = [translate(strings.TRUE), translate(strings.FALSE)];
  const [fieldName, setFieldName] = useState(title);
  const [checkedValue, setCheckedValue] = useState<boolean>();
  const [opened, setOpened] = useState<boolean>(false);

  const setBooleanState = (
    title: string | undefined,
    checkedValue: boolean | undefined
  ) => {
    setFieldName(title);
    setCheckedValue(checkedValue);
  };

  useEffect(() => {
    setOpened(!(defaultShow || !reset));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFieldName(title);
    if (defaultValue) {
      const value = defaultValue === translate(strings.TRUE);
      setBooleanState(translate(title) + ": " + defaultValue, defaultValue);
      setSearchFieldValues(criteriaTitle, defaultValue);
    }
    // eslint-disable-next-line
  }, [isRTL]);

  const onItemClick = (event: DropDownButtonItemClickEvent) => {
    const value = event.item === translate(strings.TRUE);
    if (event.item === checkedValue) {
      setBooleanState(title, undefined);
      removeSearchFieldValue(criteriaTitle);
      onChange && onChange(event, undefined);
    } else {
      setBooleanState(translate(title) + ": " + event.item, event.item);
      setSearchFieldValues(criteriaTitle, event.item);
      onChange && onChange(event, value);
    }
    setOpened(false);
  };

  const itemRender = (props: { item: any; itemIndex: number }) => {
    return (
      <AppStackLayout align={{ horizontal: "start" }}>
        <span>
          <AppCheckBox
            name={props.item}
            checked={props.item === checkedValue}
            readOnly={true}
          />
        </span>
        {`       ${props.item}`}
      </AppStackLayout>
    );
  };

  return (
    <AppDropDownButton
      className="search-component"
      text={fieldName}
      popupSettings={{ popupClass: "dropDownPopup" }}
      items={items}
      themeColor={"primary"}
      iconClass="k-icon k-font-icon k-i-arrow-chevron-down"
      onItemClick={onItemClick}
      itemRender={itemRender}
      opened={opened}
      onClose={() => {
        opened ? setOpened(false) : setOpened(opened);
      }}
      onOpen={() => {
        !opened ? setOpened(true) : setOpened(opened);
      }}
    />
  );
}
