import { useContext } from "react";
import { LocalizationContext } from "../../common/context/localizationContext";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import strings from "../../base/strings";
import InsuredPersonInformationModel from "../../interfaces/insured/InsuredPersonInformationModel";
import AppAutoComplete from "../../components/autocomplete/AppAutoComplete";
import insured from "../../api/insured";

interface InsuredPersonInformationAutoCompleteProps
  extends MultiColumnComboBoxProps {
  dataList?: Array<InsuredPersonInformationModel>;
  insuredPersonInformation?: InsuredPersonInformationModel | null;
  searchCriteria?: Object;
  charNumber?: number;
  loadDataOnOpen?: boolean;
  searchKey?: string;
}

export default function InsuredPersonInformationAutoComplete({
  dataList,
  columns,
  insuredPersonInformation,
  charNumber = 2,
  loadDataOnOpen,
  searchCriteria,
  searchKey = "CardNumberStartWithOrName", //"CardNumberStartsWith",
  ...rest
}: InsuredPersonInformationAutoCompleteProps) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    { field: getNameField(), header: translate(strings.Name), width: "300px" },
    {
      field: "Identity",
      header: translate(strings.IDENTITY),
      width: "120px",
    },
    {
      field: "CardNumber",
      header: translate(strings.CARD_NO),
      width: "120px",
    },
    {
      field: "Country",
      header: translate(strings.COUNTRY),
      width: "100px",
    },
    {
      field: "City",
      header: translate(strings.CITY),
      width: "100px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return insured.getInsuredPersonInformations({
      [searchKey]: inputString,
      PageSize: 50,
      Page: 0,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<InsuredPersonInformationModel>
      dataList={dataList || []}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      loadDataOnOpen={loadDataOnOpen}
      charNumber={charNumber}
      searchCriteria={searchCriteria}
      {...rest}
    />
  );
}
