import { useContext, useState, useEffect } from "react";
import { FormRenderProps } from "@progress/kendo-react-form";
import providersApi from "../../api/providers";
import strings from "../../base/strings";
import AppForm from "../../common/components/forms/AppForm";
import AppFormSection from "../../common/components/forms/AppFormSection";
import AppField from "../../common/components/forms/AppField";
import ProviderTypeModel from "../../interfaces/providers/ProviderTypeModel";
import ProviderSpecialtyModel from "../../interfaces/providers/ProviderSpecialtyModel";
import CountryModel from "../../interfaces/geography/CountryModel";
import CityModel from "../../interfaces/geography/CityModel";
import LocationModel from "../../interfaces/geography/Location";
import FormLabelInput from "../../common/components/formComponents/formLabelInput";
import FormDropDownList from "../../common/components/formComponents/formDropDownList";
import { LocalizationContext } from "../../common/context/localizationContext";
import definitions from "../../api/definitions";
import { getDefinitionList } from "../../common/api/searchDefinitionHelper";
import errorHandler from "../../common/utility/errorHandler";
import countriesAPI from "../../api/countries";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { dummyItemID } from "../../common/constants/appConstants";
import AppLinkedDropDowns from "../../common/components/dropDown/AppLinkedDropDowns";
import Alert from "../../common/components/general/Alert";
import AppCard from "../../common/components/cards/AppCard";
import AppCardBody from "../../common/components/cards/AppCardBody";
import LabelTitle from "../../common/components/Labels/LabelTitle";
import {
  emailValidator,
  requiredItem,
  requiredNotEmptyValidator,
  requiredValidCaptcha,
  requiredValidator,
} from "../../common/components/validators/validators";
import uiHelper from "../../common/helpers/uiHelper";
import FormDatePicker from "../../common/components/formComponents/formDatePicker";
import FormLabelTextArea from "../../common/components/formComponents/FormLabelTextArea";
import FormLabelMobile, {
  MobileValue,
} from "../../components/formComponents/FormLabelMobile";
import customUiHelper from "../../helpers/customUiHelper";
import { requiredMobileNumber } from "../../components/validators/customValidators";
import BankModel from "../../interfaces/banks/BankModel";
import BankBranchModel from "../../interfaces/banks/BankBranchModel";
import banksApi from "../../api/banks";
import LoadingPanel from "../../common/components/general/LoadingPanel";
import FormCaptcha from "../../common/components/formComponents/FormCaptcha";
import MainAppBar from "../../common/layout/MainAppBar";
import images from "../../base/images";
import ContentContainer from "../../common/components/container/ContentContainer";
import AppStackLayout from "../../common/components/stack/AppStackLayout";
import AppScrollbar from "../../common/components/scrollbar/AppScrollbar";
import { alertTypes } from "../../common/components/base/alertTypes";
import messages from "../../base/messages";

interface ProviderWebRequestFormData {
  LocalName: string;
  ForeignName: string;
  DoctorLocalName: string;
  DoctorForeignName: string;
  IdentityNumber: string;
  ProviderType: ProviderTypeModel | null;
  ProviderSpecialty: ProviderSpecialtyModel | null;
  SpecialtyDate: Date | undefined;
  Country: CountryModel | null;
  City: CityModel | null;
  Location: LocationModel | null;
  Address: string;
  LicenseCode: string;
  LicenseDate: Date;
  PhoneNumber: string;
  MobileNumber: MobileValue | null;
  Email: string;
  Fax: string;
  Hotline: string;
  Bank: BankModel | null;
  BankBranch: BankBranchModel | null;
  AccountNumber: string;
  AccountHolder: string;
  AccountHolderIdentityNumber: string;
  Captcha: { captchaText: string; userInput: string };
}

enum EProviderWebRequestFields {
  LOCAL_NAME = "LocalName",
  FOREIGN_NAME = "ForeignName",
  DOCTOR_LOCAL_NAME = "DoctorLocalName",
  DOCTOR_FOREIGN_NAME = "DoctorForeignName",
  IDENTITY_NUMBER = "IdentityNumber",
  PROVIDER_TYPE = "ProviderType",
  PROVIDER_SPECIALTY = "ProviderSpecialty",
  SPECIALTY_DATE = "SpecialtyDate",
  COUNTRY = "Country",
  CITY = "City",
  LOCATION = "Location",
  ADDRESS = "Address",
  LICENSE_CODE = "LicenseCode",
  LICENSE_DATE = "LicenseDate",
  PHONE_NUMBER = "PhoneNumber",
  MOBILE_NUMBER = "MobileNumber",
  EMAIL = "Email",
  FAX = "Fax",
  HOTLINE = "Hotline",
  BANK = "Bank",
  BANK_BRANCH = "BankBranch",
  ACCOUNT_NUMBER = "AccountNumber",
  ACCOUNT_HOLDER = "AccountHolder",
  ACCOUNT_HOLDER_IDENTITY_NUMBER = "AccountHolderIdentityNumber",
  CAPTCHA = "Captcha",
}

export default function CreateProviderWebRequestPage() {
  const { getNameField, translate } = useContext(LocalizationContext);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [providerTypes, setProviderTypes] = useState<ProviderTypeModel[]>([]);
  const [providerSpecialties, setProviderSpecialties] = useState<
    ProviderSpecialtyModel[]
  >([]);
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [cities, setCities] = useState<CityModel[]>([]);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [banks, setBanks] = useState<BankModel[]>([]);
  const [bankBranches, setBankBranches] = useState<BankBranchModel[]>([]);

  const getProviderTypes = async () => {
    const providerTypeObjectType =
      definitions.CProviderTypeDefinition.objectType;
    const providerTypeList: ProviderTypeModel[] = await getDefinitionList<
      typeof providerTypeObjectType
    >(
      definitions.CProviderTypeDefinition.endpoint,
      definitions.CProviderTypeDefinition.callType,
      setErrorMessage
    );

    if (providerTypeList && providerTypeList.length > 0) {
      setProviderTypes([...providerTypeList]);
    } else {
      setProviderTypes([]);
    }
  };

  const getCountries = async () => {
    const countryObjectType = definitions.CountryDefinition.objectType;
    const countryList: CountryModel[] = await getDefinitionList<
      typeof countryObjectType
    >(
      definitions.CountryDefinition.endpoint,
      definitions.CountryDefinition.callType,
      setErrorMessage
    );

    if (countryList && countryList.length > 0) {
      setCountries([...countryList]);
    } else {
      setCountries([]);
    }
  };

  const getBanks = async () => {
    const bankObjectType = definitions.CBankDefinition.objectType;
    const bankList: BankModel[] = await getDefinitionList<
      typeof bankObjectType
    >(
      definitions.CBankDefinition.endpoint,
      definitions.CBankDefinition.callType,
      setErrorMessage
    );

    if (bankList && bankList.length > 0) {
      setBanks([...bankList]);
    } else {
      setBanks([]);
    }
  };

  const getCities = async (countryID: number) => {
    setLoading(true);
    const response = await countriesAPI.getCities(countryID);

    setLoading(false);

    if (response.ok) {
      setCities(response.data ?? []);
    } else setErrorMessage(errorHandler.getErrorMessage(response));
  };

  const getLocations = async (cityID?: number) => {
    if (!cityID) setLocations([]);
    else {
      setLoading(true);
      const response = await countriesAPI.getLocations(cityID);

      setLoading(false);
      if (response.ok) {
        setLocations(response.data ?? []);
      } else setErrorMessage(errorHandler.getErrorMessage(response));
    }
  };

  const getProviderSpecialties = async (providerTypeID: number) => {
    setLoading(true);

    const response =
      await providersApi.getProviderTypeSpecialitiesByProviderTypeID(
        providerTypeID
      );

    setLoading(false);
    if (response.ok) {
      setProviderSpecialties(response.data);
    } else setErrorMessage(errorHandler.getErrorMessage(response));
  };

  const initializeFormData = async () => {
    setLoading(true);

    await getProviderTypes();
    await getCountries();
    await getBanks();
    setIsInitialized(true);

    setLoading(false);
  };

  useEffect(() => {
    initializeFormData();
    // eslint-disable-next-line
  }, []);

  const mapFormData = (data: ProviderWebRequestFormData) => {
    return {
      LocalName: data.LocalName,
      ForeignName: data.ForeignName,
      DoctorLocalName: data.DoctorLocalName,
      DoctorForeignName: data.DoctorForeignName,
      IdentityNumber: data.IdentityNumber,
      ProviderTypeID: data.ProviderType?.ID,
      ProviderSpecialtyID:
        data.ProviderSpecialty && data.ProviderSpecialty?.ID !== dummyItemID
          ? data.ProviderSpecialty.ID
          : undefined,
      SpecialtyDate: uiHelper.getDateFormatQueryString(data.SpecialtyDate),
      CountryID: data.Country?.ID,
      CityID: data.City?.ID,
      LocationID: data.Location?.ID,
      Address: data.Address,
      LicenseCode: data.LicenseCode,
      LicenseDate: uiHelper.getDateFormatQueryString(data.LicenseDate),
      PhoneNumber: data.PhoneNumber,
      MobileNumber:
        data.MobileNumber && customUiHelper.validMobileNumber(data.MobileNumber)
          ? data.MobileNumber.defaultTelFormat +
            data.MobileNumber.mobileDialingCode +
            data.MobileNumber.mobileNumber
          : undefined,
      Email: data.Email,
      Fax: data.Fax,
      Hotline: data.Hotline,
      BankID: data.Bank?.ID,
      BankBranchID: data.BankBranch?.ID,
      AccountNumber: data.AccountNumber,
      AccountHolder: data.AccountHolder,
      AccountHolderIdentityNumber: data.AccountHolderIdentityNumber,
    };
  };

  const handleSubmit = async (data: { [name: string]: any }, event: any) => {
    const ProviderWebRequestFormData = data as ProviderWebRequestFormData;

    const mappedData = mapFormData(ProviderWebRequestFormData);

    setLoading(true);

    const response = await providersApi.createProviderWebRequest(mappedData);

    setLoading(false);

    if (!response.ok) {
      setErrorMessage(errorHandler.getErrorMessage(response));
    } else {
      setAlertMessage(messages.SUBMITTED_SUCCESSFULLY);
    }
  };

  const handleOnProviderTypeChanged = async (
    event: DropDownListChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    formRenderProps.onChange(EProviderWebRequestFields.PROVIDER_SPECIALTY, {
      value: null,
    });

    setProviderSpecialties([]);

    if (event.value) {
      const providerType: ProviderTypeModel = event.value;
      if (providerType.IsDoctor) await getProviderSpecialties(event.value.ID);
    }
  };

  const handleOnCountryChanged = async (
    event: DropDownListChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    formRenderProps.onChange(EProviderWebRequestFields.CITY, {
      value: null,
    });
    formRenderProps.onChange(EProviderWebRequestFields.LOCATION, {
      value: null,
    });
    formRenderProps.onChange(EProviderWebRequestFields.MOBILE_NUMBER, {
      value: null,
    });

    setCities([]);
    setLocations([]);

    if (event.value) await getCities(event.value.ID);
  };

  const handleOnCityChanged = async (
    event: DropDownListChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    formRenderProps.onChange(EProviderWebRequestFields.LOCATION, {
      value: null,
    });

    setLocations([]);

    if (event.value) await getLocations(event.value.ID);
  };

  const enableProviderSpeicalty = (formRenderProps: FormRenderProps) => {
    const providerType: ProviderTypeModel = formRenderProps.valueGetter(
      EProviderWebRequestFields.PROVIDER_TYPE
    );

    if (!providerType) return false;
    if (!providerType.IsDoctor) return false;

    return true;
  };

  const getBankBranches = async (bankID: number) => {
    setLoading(true);

    const response = await banksApi.getBankBranches(bankID);

    setLoading(false);

    if (response.ok) {
      if (response.data) setBankBranches(response.data);
      else setBankBranches([]);
    } else setErrorMessage(errorHandler.getErrorMessage(response));
  };

  const handleOnBankChanged = async (
    event: DropDownListChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    setBankBranches([]);
    formRenderProps.onChange(EProviderWebRequestFields.BANK_BRANCH, {
      value: null,
    });

    if (event.value) {
      await getBankBranches(event.value.ID);
    }
  };

  const handleResetForm = () => {
    setProviderSpecialties([]);
    setCities([]);
    setLocations([]);
    setBankBranches([]);
  };

  return (
    <>
      <MainAppBar
        handleClick={() => {}}
        logo={images.ETPA_TEXT_LOGO}
        showLanguage
        hideUserContextMenu
        hideDrawerButton
      />
      <AppStackLayout className="external-container">
        <ContentContainer>
          <AppScrollbar>
            <AppStackLayout className="form-container">
              <img
                src={process.env.REACT_APP_COMPANY_LOGO}
                alt="etpa logo"
                style={{ objectFit: "contain", width: "400px" }}
              />
              {isInitialized && (
                <AppForm
                  addActionWrapper
                  isFormVisible
                  popupTitle={strings.PROVIDER_WEB_REQUEST}
                  onSubmit={handleSubmit}
                  submitButtonTitle={strings.SUBMIT}
                  cancelButtonTitle={strings.RESET}
                  loading={loading}
                  setLoading={setLoading}
                  ignoreModified
                  initialValues={{
                    Captcha: { captchaText: "", userInput: "" },
                    LicenseDate: new Date(),
                  }}
                  onClose={() => handleResetForm()}
                  render={(formRenderProps: FormRenderProps) => (
                    <AppCard style={{ marginTop: "2%" }}>
                      <LabelTitle
                        title={translate(strings.PROVIDER_REGISTRATION_FORM)}
                        isExtremeLarge
                      />
                      <AppCardBody
                        hasTitle={false}
                        orientation={"horizontal"}
                        minComponentWidth={0}
                      >
                        <AppFormSection numColumns={3}>
                          <AppField
                            id={EProviderWebRequestFields.LOCAL_NAME}
                            name={EProviderWebRequestFields.LOCAL_NAME}
                            label={strings.FULL_NAME_LOCAL}
                            component={FormLabelInput}
                            validator={requiredNotEmptyValidator}
                          />
                          <AppField
                            id={EProviderWebRequestFields.FOREIGN_NAME}
                            name={EProviderWebRequestFields.FOREIGN_NAME}
                            label={strings.FULL_NAME_FORIEGN}
                            component={FormLabelInput}
                            validator={requiredNotEmptyValidator}
                            breakAfter
                          />
                          <AppField
                            id={EProviderWebRequestFields.DOCTOR_LOCAL_NAME}
                            name={EProviderWebRequestFields.DOCTOR_LOCAL_NAME}
                            label={strings.COMMERCIAL_LOCAL_NAME}
                            component={FormLabelInput}
                            validator={requiredNotEmptyValidator}
                            placeholder={translate(
                              strings.IN_CASE_OF_DOCTOR_PLEASE_ENTER_FIRST_LAST_NAMES
                            )}
                          />
                          <AppField
                            id={EProviderWebRequestFields.DOCTOR_FOREIGN_NAME}
                            name={EProviderWebRequestFields.DOCTOR_FOREIGN_NAME}
                            label={strings.COMMERCIAL_FOREIGN_NAME}
                            component={FormLabelInput}
                            validator={requiredNotEmptyValidator}
                            placeholder={translate(
                              strings.IN_CASE_OF_DOCTOR_PLEASE_ENTER_FIRST_LAST_NAMES
                            )}
                            breakAfter
                          />
                          <AppField
                            id={EProviderWebRequestFields.IDENTITY_NUMBER}
                            name={EProviderWebRequestFields.IDENTITY_NUMBER}
                            label={strings.IDENTITY}
                            component={FormLabelInput}
                            validator={requiredNotEmptyValidator}
                            breakAfter
                          />
                          <AppLinkedDropDowns colSpan={3}>
                            <AppField
                              id={EProviderWebRequestFields.PROVIDER_TYPE}
                              name={EProviderWebRequestFields.PROVIDER_TYPE}
                              label={strings.PROVIDER_TYPE}
                              component={FormDropDownList}
                              textField={getNameField()}
                              useObjectBinding={true}
                              data={providerTypes}
                              resetValueOnChange={false}
                              onChange={(event) =>
                                handleOnProviderTypeChanged(
                                  event,
                                  formRenderProps
                                )
                              }
                              validator={requiredItem}
                            />
                            <AppField
                              id={EProviderWebRequestFields.PROVIDER_SPECIALTY}
                              name={
                                EProviderWebRequestFields.PROVIDER_SPECIALTY
                              }
                              label={strings.PROVIDER_SPECIALTY}
                              component={FormDropDownList}
                              textField={getNameField()}
                              useObjectBinding={true}
                              data={providerSpecialties}
                              resetValueOnChange={false}
                              enableFiltering={true}
                              filterByLocalAndForeignNames={true}
                              disabled={
                                !enableProviderSpeicalty(formRenderProps)
                              }
                              validator={
                                enableProviderSpeicalty(formRenderProps)
                                  ? requiredItem
                                  : undefined
                              }
                              addDummyItem={true}
                            />
                            <AppField
                              id={EProviderWebRequestFields.SPECIALTY_DATE}
                              name={EProviderWebRequestFields.SPECIALTY_DATE}
                              label={strings.SPECIALTY_DATE}
                              component={FormDatePicker}
                              disabled={
                                !enableProviderSpeicalty(formRenderProps)
                              }
                              validator={
                                enableProviderSpeicalty(formRenderProps)
                                  ? requiredValidator
                                  : undefined
                              }
                            />
                          </AppLinkedDropDowns>
                          <AppLinkedDropDowns colSpan={3}>
                            <AppField
                              id={EProviderWebRequestFields.COUNTRY}
                              name={EProviderWebRequestFields.COUNTRY}
                              label={strings.COUNTRY}
                              component={FormDropDownList}
                              textField={getNameField()}
                              useObjectBinding={true}
                              data={countries}
                              resetValueOnChange={false}
                              onChange={(event) =>
                                handleOnCountryChanged(event, formRenderProps)
                              }
                              validator={requiredItem}
                            />
                            <AppField
                              id={EProviderWebRequestFields.CITY}
                              name={EProviderWebRequestFields.CITY}
                              label={strings.CITY}
                              component={FormDropDownList}
                              textField={getNameField()}
                              useObjectBinding={true}
                              data={cities}
                              resetValueOnChange={false}
                              enableFiltering={true}
                              filterByLocalAndForeignNames={true}
                              onChange={(event) =>
                                handleOnCityChanged(event, formRenderProps)
                              }
                              validator={requiredItem}
                            />
                            <AppField
                              id={EProviderWebRequestFields.LOCATION}
                              name={EProviderWebRequestFields.LOCATION}
                              label={strings.LOCATION}
                              component={FormDropDownList}
                              textField={getNameField()}
                              useObjectBinding={true}
                              data={locations}
                              resetValueOnChange={false}
                              enableFiltering={true}
                              filterByLocalAndForeignNames={true}
                              validator={requiredItem}
                            />
                          </AppLinkedDropDowns>
                          <AppField
                            id={EProviderWebRequestFields.ADDRESS}
                            name={EProviderWebRequestFields.ADDRESS}
                            label={strings.ADDRESS}
                            component={FormLabelTextArea}
                            rows={3}
                            validator={requiredNotEmptyValidator}
                            breakAfter
                          />
                          <AppLinkedDropDowns colSpan={2}>
                            <AppField
                              id={EProviderWebRequestFields.LICENSE_CODE}
                              name={EProviderWebRequestFields.LICENSE_CODE}
                              label={strings.LICENSE_CODE}
                              component={FormLabelInput}
                              validator={requiredNotEmptyValidator}
                            />
                            <AppField
                              id={EProviderWebRequestFields.LICENSE_DATE}
                              name={EProviderWebRequestFields.LICENSE_DATE}
                              label={strings.LICENSE_DATE}
                              component={FormDatePicker}
                              validator={requiredValidator}
                              breakAfter
                            />
                          </AppLinkedDropDowns>
                          <AppField
                            id={EProviderWebRequestFields.PHONE_NUMBER}
                            name={EProviderWebRequestFields.PHONE_NUMBER}
                            label={strings.PHONE_NUMBER}
                            component={FormLabelInput}
                            validator={requiredNotEmptyValidator}
                            type={"tel"}
                            placeholder={translate(
                              strings.ENTER_CITY_CODE_WITH_PHONE_NUMBER
                            )}
                          />
                          <AppField
                            id={EProviderWebRequestFields.MOBILE_NUMBER}
                            name={EProviderWebRequestFields.MOBILE_NUMBER}
                            label={strings.MOBILE}
                            component={FormLabelMobile}
                            disabled={
                              !formRenderProps.valueGetter(
                                EProviderWebRequestFields.COUNTRY
                              )
                            }
                            country={
                              !formRenderProps.valueGetter(
                                EProviderWebRequestFields.COUNTRY
                              )
                                ? null
                                : formRenderProps.valueGetter(
                                    EProviderWebRequestFields.COUNTRY
                                  )
                            }
                            validator={requiredMobileNumber}
                            type={"tel"}
                          />
                          <AppField
                            id={EProviderWebRequestFields.EMAIL}
                            name={EProviderWebRequestFields.EMAIL}
                            label={strings.EMAIL_ADDRESS}
                            component={FormLabelInput}
                            validator={emailValidator}
                            type={"email"}
                          />
                          <AppField
                            id={EProviderWebRequestFields.FAX}
                            name={EProviderWebRequestFields.FAX}
                            label={strings.FAX}
                            component={FormLabelInput}
                          />
                          <AppField
                            id={EProviderWebRequestFields.HOTLINE}
                            name={EProviderWebRequestFields.HOTLINE}
                            label={strings.HOTLINE}
                            component={FormLabelInput}
                            breakAfter
                          />
                          <AppField
                            id={EProviderWebRequestFields.BANK}
                            name={EProviderWebRequestFields.BANK}
                            label={strings.BANK}
                            component={FormDropDownList}
                            textField={getNameField()}
                            useObjectBinding={true}
                            data={banks}
                            resetValueOnChange={false}
                            onChange={(event) =>
                              handleOnBankChanged(event, formRenderProps)
                            }
                            validator={requiredItem}
                            enableFiltering={true}
                            filterByLocalAndForeignNames={true}
                          />
                          <AppField
                            id={EProviderWebRequestFields.BANK_BRANCH}
                            name={EProviderWebRequestFields.BANK_BRANCH}
                            label={strings.BANK_BRANCH}
                            component={FormDropDownList}
                            data={bankBranches}
                            textField={getNameField()}
                            resetValueOnChange={false}
                            useObjectBinding={true}
                            addDummyItem={true}
                            validator={requiredItem}
                          />
                          <AppField
                            id={EProviderWebRequestFields.ACCOUNT_NUMBER}
                            name={EProviderWebRequestFields.ACCOUNT_NUMBER}
                            label={strings.ACCOUNT_NUMBER}
                            component={FormLabelInput}
                            validator={requiredNotEmptyValidator}
                          />
                          <AppField
                            id={EProviderWebRequestFields.ACCOUNT_HOLDER}
                            name={EProviderWebRequestFields.ACCOUNT_HOLDER}
                            label={strings.BANK_ACCOUNT_HOLDER}
                            component={FormLabelInput}
                            validator={requiredNotEmptyValidator}
                          />
                          <AppField
                            id={
                              EProviderWebRequestFields.ACCOUNT_HOLDER_IDENTITY_NUMBER
                            }
                            name={
                              EProviderWebRequestFields.ACCOUNT_HOLDER_IDENTITY_NUMBER
                            }
                            label={strings.ACCOUNT_HOLDER_IDENTITY_NUMBER}
                            component={FormLabelInput}
                            breakAfter
                          />
                          <AppField
                            id={EProviderWebRequestFields.CAPTCHA}
                            name={EProviderWebRequestFields.CAPTCHA}
                            component={FormCaptcha}
                            validator={requiredValidCaptcha}
                            breakAfter
                          />
                        </AppFormSection>
                      </AppCardBody>
                    </AppCard>
                  )}
                />
              )}
            </AppStackLayout>
          </AppScrollbar>
        </ContentContainer>
      </AppStackLayout>
      <div
        style={{
          height: "29px",
          display: "flex",
          marginTop: "1px",
          flexDirection: "row-reverse",
          paddingInlineEnd: "30px",
        }}
      >
        <img src={images.POWERED_BY_TRIAMETIS} alt="Powered By Triametis" />
      </div>
      {errorMessage && (
        <Alert message={errorMessage} setMessage={setErrorMessage} />
      )}
      {alertMessage && (
        <Alert
          message={alertMessage}
          setMessage={setAlertMessage}
          type={alertTypes.INFO}
        />
      )}
      {loading && <LoadingPanel />}
    </>
  );
}
