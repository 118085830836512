/** @format */

import { ChangeEvent, useEffect, useState, useContext } from "react";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Error } from "@progress/kendo-react-labels";

import { LocalizationContext } from "../../context/localizationContext";
import uiHelper from "../../helpers/uiHelper";
import AppDropDownList from "../dropDown/AppDropDownList";
import baseStrings from "../../base/baseStrings";
import baseMessages from "../../base/baseMessages";
import { dummyItemID } from "../../constants/appConstants";

interface CustomCellProps extends GridCellProps {
  dropDownListData: any[];
  editable: boolean;
  editableInNew: boolean;
  required?: boolean;
  textField: string;
  bindingObject?: string;
  objectBinding: boolean;
  addDummyItem: boolean;
  onCloseEdit?: (e: any) => Promise<any>;
  lazyLoadData?: (prop: any) => Promise<any>;
  handleOnRowClick?: (e: any) => void;
  defaultItem: any;
  readOnlyDisplayProperty?: string;
}

export default function AppDropDownCell({
  dataItem,
  bindingObject,
  dropDownListData,
  editable,
  textField,
  objectBinding,
  addDummyItem,
  onCloseEdit,
  lazyLoadData,
  onChange,
  handleOnRowClick,
  required,
  editableInNew,
  field = "",
  rowType,
  defaultItem,
  readOnlyDisplayProperty,
  ...rest
}: CustomCellProps) {
  const { getName, translateIn, translate } = useContext(LocalizationContext);

  let dummyItem: any = objectBinding
    ? {
        ID: -1,
        LocalName: translateIn(baseStrings.SELECT_WITH_THREE_DOTS, "ar"),
        ForeignName: translateIn(baseStrings.SELECT_WITH_THREE_DOTS, "en"),
        Code: "_",
      }
    : baseStrings.SELECT_WITH_THREE_DOTS;

  if (dummyItem && bindingObject)
    dummyItem = {
      ...dummyItem,
      [bindingObject]: {
        ID: -1,
        LocalName: translateIn(baseStrings.SELECT_WITH_THREE_DOTS, "ar"),
        ForeignName: translateIn(baseStrings.SELECT_WITH_THREE_DOTS, "en"),
        Code: "_",
      },
    };

  const [value, setValue] = useState(dataItem[field] ? dataItem[field] : "");
  const [originalValue, setOriginalValue] = useState(
    dataItem[field] ? dataItem[field] : ""
  );

  const [visited, setVisited] = useState(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  const [isEditable, setIsEditable] = useState<boolean>(
    dataItem.inEdit && editable
  );

  const [data, setData] = useState<any[]>(dropDownListData);

  useEffect(() => {
    if (defaultItem && dataItem.isNewItem && !dataItem[field]) {
      setValue(defaultItem);
      if (onChange)
        //This trigger on change to make grid care of the default value for the cell
        onChange({
          dataIndex: 0,
          dataItem,
          field,
          syntheticEvent: {
            target: { value: defaultItem },
          } as ChangeEvent<HTMLInputElement>, //A dummy event to pass the call
          value: defaultItem,
        });
    } else setValue(dataItem[field]);

    setOriginalValue(dataItem[field]);
    setIsEditable(dataItem.inEdit && editable);
    // eslint-disable-next-line
  }, [dataItem]);

  const handleChange = async (e: DropDownListChangeEvent) => {
    // setValue(e.target.value);
    const validated = required ? validateRequired(e.target.value) : true;
    if (dataItem.isNewItem === true) {
      if (onChange) {
        onChange({
          dataIndex: 0,
          dataItem: Object.assign({ IsValid: validated }, dataItem),
          field,
          syntheticEvent: e.syntheticEvent,
          value: e.target.value,
        });
      }
      // setValue(e.target.value);
    } else {
      const newValue = e.target.value ? e.target.value : null;

      await invokeUpdate(e, newValue, false);
    }
  };

  const validateRequired = (value: any) => {
    let isValid = true;
    if (!value) isValid = false;
    if (value instanceof Object) if (value.ID === dummyItemID) isValid = false;
    // dataItem.IsValid = isValid;//Useless

    setIsValid(isValid);
    return isValid;
  };

  const handleOnBlur = async (e: any) => {
    const newValue = e.target.value ? e.target.value : null;

    await invokeUpdate(e, newValue, true);
  };

  const handleClick = async (e: any) => {
    if (handleOnRowClick) handleOnRowClick({ dataItem });

    if (dataItem.isNewItem && !editableInNew) return;
    if (lazyLoadData && uiHelper.isNullOrEmptyArray(data)) {
      const resultData = await lazyLoadData({ dataItem });
      setData(resultData);
    }
    setIsEditable(true);
    setVisited(true);
  };

  const handleOnKeyDown = async (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      const newValue = e.target.value ? e.target.value : null;

      await invokeUpdate(e, newValue, false);
    }
  };

  const getUpdatedDataItem = (newValue: any) => {
    const validated = required ? validateRequired(newValue) : true;
    // const updatedDataItem = Object.assign({ IsValid: validated }, dataItem);
    let updatedDataItem = { IsValid: true };
    if ("IsValid" in dataItem)
      updatedDataItem = { ...dataItem, IsValid: validated };
    else updatedDataItem = Object.assign({ IsValid: validated }, dataItem);

    return updatedDataItem;
  };

  const invokeUpdate = async (e: any, newValue: any, isBlured: boolean) => {
    setVisited(false);

    const updatedDataItem = getUpdatedDataItem(newValue);

    if (!updatedDataItem.IsValid) {
      //Op1
      // setValue(originalValue);
      // setIsEditable(false);

      //Op2
      setVisited(true); //to keep validation message
      return;
    }

    if (
      (objectBinding === false && e.target.value === originalValue) ||
      (objectBinding === true && e.target.value.ID === originalValue?.ID)
    ) {
      // setValue(originalValue);
      if (isBlured) setIsEditable(false);
      return;
    }
    if (onCloseEdit && !dataItem.isNewItem) {
      // const succeed = await onCloseEdit({
      await onCloseEdit({
        dataIndex: 0,
        dataItem: updatedDataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: newValue,
      });
      // if (succeed === true) {
      //   setValue(newValue);
      // } else {
      //   setValue(originalValue);
      // }
    } //else setValue(newValue);
    setIsEditable(false);
  };

  if (rowType === "groupHeader") {
    return null;
  }
  return (
    <td
      onClick={handleClick}
      onKeyDown={handleOnKeyDown}
      // tabIndex={isEditable ? -1 : 0}
    >
      {isEditable ? (
        <AppDropDownList
          defaultItem={addDummyItem ? dummyItem : null}
          onChange={handleChange}
          value={value}
          data={data}
          textField={textField}
          required={!!required}
          onBlur={handleOnBlur}
          objectBinding={objectBinding}
          bindingProperty={bindingObject}
          resetValueOnChange={false}
        />
      ) : readOnlyDisplayProperty ? (
        dataItem[readOnlyDisplayProperty]
      ) : value instanceof Object ? (
        textField ? (
          bindingObject && value[bindingObject] ? (
            value[bindingObject][textField]
          ) : (
            value[textField]
          )
        ) : (
          getName(bindingObject ? value[bindingObject] : value)
        )
      ) : (
        value
      )}
      {/* {!validateRequired(props.dataItem[field]) && ( */}
      {required && visited && !isValid && (
        <Error>{translate(baseMessages.FIELD_IS_REQUIRED)}</Error>
      )}
    </td>
  );
}
