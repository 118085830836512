import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const providerPaymentVoucherGroups = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    {
      field: "ProviderPaymentVoucherGroupNumber",
      header: strings.NAME,
      width: "120px",
    },
    {
      field: "ProviderPaymentVoucherGroupStatusModel",
      header: strings.STATUS,
      width: "120px",
    },
  ],
  autoCompleteEndpoint: endpoints.PVP + "/provider-payment-voucher-groups",
  autoCompleteSearchKey: "ProviderPaymentVoucherGroupIDStartsWith",
};

export default providerPaymentVoucherGroups;
