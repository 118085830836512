import sortingValues from "../helpers/sortingValues";
import apiClient from "../common/api/client";
import endpoints from "./endpoints";
import BaseResponse from "../common/interfaces/api/BaseResponse";
import CountryModel from "../interfaces/geography/CountryModel";
import CityModel from "../interfaces/geography/CityModel";
import LocationModel from "../interfaces/geography/Location";
import GovernorateModel from "../interfaces/geography/GovernorateModel";
import GeographicalScopeModel from "../interfaces/geography/GeographicalScopeModel";
import { CountryFormData } from "../controls/geography/forms/CountryForm";
import {
  CityRequestModel,
  GovernorateRequestModel,
  LocationRequestModel,
} from "../interfaces/api/CountriesApiModels";
import { GeographicalScopeFormData } from "../controls/geography/forms/GeographicalScopeForm";

const endpoint = endpoints.COUNTRIES;
const route_cities = "/cities";
const route_locations = "/locations";
const route_governorates = "/governorates";
const route_geographical_scopes = "/geographical-scopes";

const route_countries_available_on_mobile = "/countries-available-on-mobile";

const act_set_available_on_mobile = "/set-available-on-mobile";

const getSortingParameter = (userLanguage: string) => {
  return userLanguage === "AR" || userLanguage === "ar"
    ? sortingValues.NAME_LOCAL
    : sortingValues.NAME_FOREIGN;
};

const getCountries = (criteria?: Record<string, any>): BaseResponse<CountryModel[]> =>
  apiClient.post(
    endpoint,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );

const getCities = (
  countryID: number,
  userLanguage?: any
): BaseResponse<CityModel[]> =>
  apiClient.get(
    endpoint +
      "/" +
      countryID +
      route_cities +
      "/" +
      getSortingParameter(userLanguage)
  );

const getCityList = (criteria: Record<string, any>): BaseResponse<CityModel[]> =>
  apiClient.post(
    endpoint + route_cities,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );

const getLocations = (cityID: number): BaseResponse<LocationModel[]> =>
  apiClient.get(
    endpoint + route_cities + "/" + cityID + route_locations
    // + "/" +
    // getSortingParameter(userLanguage)
  );

const getLocationList = (criteria: Record<string, any>): BaseResponse<LocationModel[]> =>
  apiClient.post(
    endpoint + route_locations,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );

// const getCountriesAvailableOnMobile = (): BaseResponse<CountryModel[]> => 
//   apiClient.get(endpoint + route_countries_available_on_mobile);

const getGovernorates = (
  criteria: Record<string, any>
): BaseResponse<GovernorateModel[]> =>
  apiClient.post(
    endpoint + route_governorates,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );

const getGeographicalScopes = (
  criteria: Record<string, any>
): BaseResponse<GeographicalScopeModel[]> =>
  apiClient.post(
    endpoint + route_geographical_scopes,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );

const addCountry = (data: CountryFormData): BaseResponse<CountryModel> =>
  apiClient.post(
    endpoint,
    JSON.stringify({
      ...data,
    })
  );

const updateCountry = (
  countryID: number,
  data: CountryFormData
): BaseResponse<CountryModel> =>
  apiClient.put(
    endpoint + "/" + countryID,
    JSON.stringify({
      ...data,
    })
  );

const deleteCountry = (countryID: number): BaseResponse<null> =>
  apiClient.delete(endpoint + "/" + countryID);

const addCity = (data: CityRequestModel): BaseResponse<CityModel> =>
  apiClient.post(
    endpoint + route_cities,
    JSON.stringify({
      ...data,
    })
  );

const updateCity = (
  cityID: number,
  data: CityRequestModel
): BaseResponse<CityModel> =>
  apiClient.put(
    endpoint + route_cities + "/" + cityID,
    JSON.stringify({
      ...data,
    })
  );

const deleteCity = (cityID: number): BaseResponse<null> =>
  apiClient.delete(endpoint + route_cities + "/" + cityID);

const addLocation = (data: LocationRequestModel): BaseResponse<LocationModel> =>
  apiClient.post(
    endpoint + route_locations,
    JSON.stringify({
      ...data,
    })
  );

const updateLocation = (
  locationID: number,
  data: LocationRequestModel
): BaseResponse<LocationModel> =>
  apiClient.put(
    endpoint + route_locations + "/" + locationID,
    JSON.stringify({
      ...data,
    })
  );

const deleteLocation = (locationID: number): BaseResponse<null> =>
  apiClient.delete(endpoint + route_locations + "/" + locationID);

const addGovernorate = (
  data: GovernorateRequestModel
): BaseResponse<GovernorateModel> =>
  apiClient.post(
    endpoint + route_governorates,
    JSON.stringify({
      ...data,
    })
  );

const updateGovernorate = (
  governorateID: number,
  data: GovernorateRequestModel
): BaseResponse<GovernorateModel> =>
  apiClient.put(
    endpoint + route_governorates + "/" + governorateID,
    JSON.stringify({
      ...data,
    })
  );

const deleteGovernorate = (governorateID: number): BaseResponse<null> =>
  apiClient.delete(endpoint + route_governorates + "/" + governorateID);

const addGeographicalScope = (
  data: GeographicalScopeFormData
): BaseResponse<GeographicalScopeModel> =>
  apiClient.post(
    endpoint + route_geographical_scopes,
    JSON.stringify({
      ...data,
    })
  );

const updateGeographicalScope = (
  geographicalScopeID: number,
  data: GeographicalScopeFormData
): BaseResponse<GeographicalScopeModel> =>
  apiClient.put(
    endpoint + route_geographical_scopes + "/" + geographicalScopeID,
    JSON.stringify({
      ...data,
    })
  );

const deleteGeographicalScope = (
  geographicalScopeID: number
): BaseResponse<null> =>
  apiClient.delete(
    endpoint + route_geographical_scopes + "/" + geographicalScopeID
  );

const setCountryAvailableOnMobile = (
  countryID: number,
  isAvailable: boolean
): BaseResponse<CountryModel> => {
  return apiClient.put(
    endpoint +
      "/" +
      countryID +
      act_set_available_on_mobile +
      (isAvailable ? "/true" : "")
  );
};

const countriesAPI = {
  getCities,
  getCountries,
  getLocations,
  getCityList,
  // getCountriesAvailableOnMobile,
  getLocationList,
  getGovernorates,
  getGeographicalScopes,
  addCountry,
  updateCountry,
  deleteCountry,
  addCity,
  updateCity,
  deleteCity,
  addGovernorate,
  updateGovernorate,
  deleteGovernorate,
  addGeographicalScope,
  updateGeographicalScope,
  deleteGeographicalScope,
  setCountryAvailableOnMobile,
  addLocation,
  updateLocation,
  deleteLocation,
};

export default countriesAPI;
