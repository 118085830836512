import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.PARTIES;

const route_premium_funders = "/premium-funders";

const getPremiumFunders = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_premium_funders,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addPremiumFunder = (data) => {
  return apiClient.post(endpoint + route_premium_funders, data);
};

const updatePremiumFunder = (premiumFunderID, data) => {
  return apiClient.put(
    endpoint + route_premium_funders + "/" + premiumFunderID,
    data
  );
};

const deletePremiumFunder = (premiumFunderID) => {
  return apiClient.delete(
    endpoint + route_premium_funders + "/" + premiumFunderID
  );
};

const partiesApi = {
  getPremiumFunders,
  addPremiumFunder,
  updatePremiumFunder,
  deletePremiumFunder,
};

export default partiesApi;
