import { Button, ButtonProps } from "@progress/kendo-react-buttons";
import { colorCategory } from "../../base/colorCategory";

interface Props extends ButtonProps {
  pillType: colorCategory;
  additionalClasses?: string;
}
export default function PillButton({
  pillType,
  children,
  additionalClasses,
  ...rest
}: Props) {
  const getPillTypeColorClassName = () => {
    switch (pillType) {
      case colorCategory.SUCCESS:
        return " success-bkgcolor";
      case colorCategory.ERROR:
        return " error-bkgcolor";
      case colorCategory.WARNING:
        return " warning-bkgcolor";
      case colorCategory.DISABLED:
        return " disabled-bkgcolor";
      case colorCategory.UNDER_PROCESSING:
        return " under-processing-bkgcolor";
      case colorCategory.NEEDS_SUBMISSION:
        return " needs-submission-bkgcolor";
      case colorCategory.ORANGE:
        return " orange-bkgcolor";
      case colorCategory.YELLOW:
        return "";
      case colorCategory.LINK:
        return " link-bkgcolor";
      case colorCategory.PURPLE:
        return " purple-bkgcolor";
      case colorCategory.BLUE:
        return " blue-bkgcolor";
      case colorCategory.REJECTED:
        return " rejected-bkgcolor";
      case colorCategory.TRANSPARENT:
        return "";
    }
  };
  const getButtonClassNames = () => {
    if (pillType === colorCategory.TRANSPARENT)
      return "pill-button-transparent";
    else return "pill-button";
  };
  return (
    <Button
      className={
        getButtonClassNames() +
        getPillTypeColorClassName() +
        (additionalClasses ? " " + additionalClasses : "")
      }
      {...rest}
    >
      {children}
    </Button>
  );
}
