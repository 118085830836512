import { CSSProperties, useContext } from "react";
import { Error, Label } from "@progress/kendo-react-labels";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { LocalizationContext } from "../../context/localizationContext";
import AppDatePicker from "../inputs/AppDatePicker";

interface Props extends FieldRenderProps {
  fieldWrapperStyle?: CSSProperties;
  showOnlyMonthPicker?: boolean;
}
export default function FormDatePicker({
  fieldWrapperStyle,
  label,
  validationMessage,
  valid,
  visited,
  showOnlyMonthPicker,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);
  const showValidationMessage: string | false | null =
    visited && validationMessage;

  return (
    <FieldWrapper style={fieldWrapperStyle}>
      {label ? <Label>{translate(label)}</Label> : null}
      <AppDatePicker
        size={"small"}
        showOnlyMonthPicker={showOnlyMonthPicker}
        //label={translate(label)}
        {...rest}
      />
      {showValidationMessage && <Error>{translate(validationMessage)}</Error>}
    </FieldWrapper>
  );
}
