export default Object.freeze({
  LOCATION: "LOCATION",
  PROVIDER: "PROVIDER",
  SERVICE_CHAPTER: "SERVICE_CHAPTER",
  STACKHOLDER: "STACKHOLDER",
  INVOICE_ENTITY: "INVOICE_ENTITY",
  BILL_ENTITY: "BILL_ENTITY",
  BILL_PAYMENT_ENTITY: "BILL_PAYMENT_ENTITY",
  COUNTRY_CITY: "COUNTRY_CITY",
  AUTHORIZED_SIGNATORY_TYPE_CLASS: "AUTHORIZED_SIGNATORY_TYPE_CLASS",
  REIMBURSEMENT_BATCH_CLIENT: "REIMBURSEMENT_BATCH_CLIENT",
  CUSTOMER_ENTITY: "CUSTOMER_ENTITY",
  VENDOR_ENTITY: "VENDOR_ENTITY",
  PROVIDER_CLAIMS: "PROVIDER_CLAIMS",
  BANK_WITH_BRANCHES: "BANK_WITH_BRANCHES",
  ACCOUNTING_TRANSACTION_FISCAL_YEAR_PERIOD_ENTITY:
    "ACCOUNTING_TRANSACTION_FISCAL_YEAR_PERIOD_ENTITY",
  ACCOUNTING_TRANSACTION_ENTITY: "ACCOUNTING_TRANSACTION_ENTITY",
  ACCOUNTING_TRANSACTION_SYSTEM_ENTITY: "ACCOUNTING_TRANSACTION_SYSTEM_ENTITY",
  CLAIM_FORM_TYPE_CLAIM_TYPE: "CLAIM_FORM_TYPE_CLAIM_TYPE",
  COUNTRY_GOVERNORATE: "COUNTRY_GOVERNORATE",
  FILE_REVIEW_STAKEHOLDER: "FILE_REVIEW_STAKEHOLDER",
  SERVICE: "SERVICE",
  SEARCH_PROVIDER: "SEARCH_PROVIDER",
  TREASURY_TRANSACTION_ENTITY: "TREASURY_TRANSACTION_ENTITY",
  REIMBURSEMENT_REQUEST_CLIENT: "REIMBURSEMENT_REQUEST_CLIENT",
  SERVICE_PRICING_SERVICE: "SERVICE_PRICING_SERVICE",
});
