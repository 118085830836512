import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const accounts = {
  columns: [
    { field: "ID", header: strings.ID, width: "50px" },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "100px",
    },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "100px",
    },
    {
      field: "AccountNumber",
      header: strings.ACCOUNT_NUMBER,
      width: "100px",
    },
    {
      field: "AccountType",
      header: strings.TYPE,
      width: "100px",
    },
    {
      field: "AccountCategory",
      header: strings.CATEGORY,
      width: "100px",
    },
    {
      field: "Year",
      header: strings.YEAR,
      width: "50px",
    },
  ],
  autoCompleteEndpoint: endpoints.ACCOUNTS + "/accounts",
  autoCompleteSearchKey: "AccountName",
};

export default accounts;
