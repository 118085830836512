export const enum EDisplayModes {
  INSURED_HISTORY,
  FULL,
  APPROVAL_HISTORY,
  DRUGS_ONLY,
  NEEDS_SUBMISSION_ISSUANCE_ITEMS,
  PENDING_ISSUANCE_ITEMS,
  PROVIDER_ONLINE_SUBMISSION,
  PROVIDER_BRANCHES,
  COMPACT_TRANSMITTAL_CLAIM_FORMS,
  COMPACT_BATCH_CLAIM_FORMS,
  COMPACT,
  TRANSMITTAL,
  INSURED_COMPACT,
  COMPACT_PLAN,
  NEW,
  EDIT,
  READ_ONLY,
  COMPACT_MEDICAL_FILE,
  ICM_COMPACT,
  REVISE,
  PVP_GROUP_PROVIDER_PAYMENT_REPORT,
  FUND_INSTALLMENT_READ_ONLY,
  RENEWAL_COMPACT,
  SEARCH,
  PENDING_REQUEST,
  REIMBURSEMENT,
  PROVIDER_USER,
  SEARCH_MEDICAL_NETWORK,
  SHARED_MEDICAL_FILE,
  SIMILAR_REQUESTS,
  PVI_GROUP,
  CLIENT_APPROVAL,
}
