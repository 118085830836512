import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppAutoComplete from "../autocomplete/AppAutoComplete";
import ServiceAutoComplete from "../../controls/services/base/ServiceAutoComplete";
import AppLinkedDropDowns from "../../common/components/dropDown/AppLinkedDropDowns";
import ServiceModel from "../../interfaces/services/ServiceModel";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppServiceSearchFieldKeys = {
  ServiceID: "ServiceID",
};
export default function AppServiceSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const [service, setService] = useState<ServiceModel | null>();
  const [searchBy, setSearchBy] = useState<any | undefined>({
    ID: 1,
    Name: translate(strings.SERVICE_NAME),
    Code: "ServiceName",
  });

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleServiceChange = async (event: any) => {
    const value = event.value;
    if (!value) {
      setService(null);
      removeSearchFieldValue(AppServiceSearchFieldKeys.ServiceID);
    } else {
      const searchValue: number = value.ID;
      setService(value);
      setSearchFieldValues(AppServiceSearchFieldKeys.ServiceID, searchValue);
    }
  };

  const handleKeyChange = async (event: any) => {
    const value = event.value;
    removeSearchFieldValue(AppServiceSearchFieldKeys.ServiceID);
    setService(null);

    if (!value) {
      setSearchBy(undefined);
    } else {
      setSearchBy(value);
    }
  };

  return (
    <span style={{ display: "inline-flex" }}>
      <AppLinkedDropDowns>
        <AppAutoComplete<any>
          className="search-component"
          dataList={[
            {
              ID: 1,
              Name: translate(strings.SERVICE_NAME),
              Code: "ServiceName",
            },
            {
              ID: 2,
              Name: translate(strings.SERVICE_CODE),
              Code: "ServiceCode",
            },
          ]}
          columns={[
            {
              field: "Name",
              header: translate(strings.SEARCH_BY),
              width: "200",
            },
          ]}
          textFieldDisplay={"Name"}
          placeholder={translate(strings.SERVICE_SEARCH)}
          defaultValue={{
            ID: 1,
            Name: translate(strings.SERVICE_NAME),
            Code: "ServiceName",
          }}
          onChange={handleKeyChange}
        />
        {searchBy && (
          <ServiceAutoComplete
            className="search-component"
            columns={[]}
            onChange={handleServiceChange}
            placeholder={translate(strings.SERVICE)}
            searchKey={searchBy.Code}
            dataList={[]}
            loadDataOnOpen
            value={service}
          />
        )}
      </AppLinkedDropDowns>
    </span>
  );
}
