import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.SYSTEM_SETTINGS;

const getSystemSettingValue = (systemSettingCode) => {
  return apiClient.get(endpoint + "/" + systemSettingCode);
};

const systemSettingApi = {
  getSystemSettingValue,
};

export default systemSettingApi;
