import { useState, useEffect, useContext } from "react";
import NotificationModel from "../common/interfaces/notifications/NotificationModel";
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { StringDictionary } from "../common/interfaces/StringDictionary";
import { AuthenticationContext } from "../common/context/authenticationContext";
import CryptoJS from "crypto-js";
import cryptoNotificationKeys from "../enums/notifications/cryptoNotificationKeys";

export default function useNotifications() {
  const [connection, setConnection] = useState<HubConnection>();
  const [notificationHandlers, setNotificationHandlers] = useState<
    StringDictionary<
      (
        notificationContent: NotificationModel,
        notificationParameters: { [key: string]: any }
      ) => void
    >
  >({});
  const { user, password } = useContext(AuthenticationContext);

  useEffect(() => {
    const encryptedUsername = CryptoJS.AES.encrypt(
      user.Username,
      CryptoJS.enc.Base64.parse(cryptoNotificationKeys.KEY_SIGNALR),
      {
        iv: CryptoJS.enc.Base64.parse(cryptoNotificationKeys.IV_SIGNALR),
      }
    ).toString();

    const encryptedPassword = CryptoJS.AES.encrypt(
      password,
      CryptoJS.enc.Base64.parse(cryptoNotificationKeys.KEY_SIGNALR),
      {
        iv: CryptoJS.enc.Base64.parse(cryptoNotificationKeys.IV_SIGNALR),
      }
    ).toString();

    const myConnection = new HubConnectionBuilder()
      .withUrl(
        process.env.REACT_APP_SIGNALR_HUB_URL +
          "?USER_NAME=" +
          encodeURIComponent(encryptedUsername) +
          "&PASSWORD=" +
          encodeURIComponent(encryptedPassword),
        {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        }
      )
      .withAutomaticReconnect()
      .build();
    setConnection(myConnection);

    //testing push notification
    //myConnection.start().then(() => {
    //   if (myConnection?.state === HubConnectionState.Connected) {
    // connection.invoke("NewMessage", 45225, "hello").catch((err: Error) => {
    //   return console.error(err.toString());
    // });
    /* myConnection
          .invoke(
            "pushNotificationToUser",
            10,
            JSON.stringify({
              Data: "my data",
              WorkflowObjectCode: "CApproval",
              WorkflowObjectConfiguration: JSON.stringify({
                WorkflowObjectClassID: "CApproval",
                WorkflowObjectFriendlyName: "Approval",
                ManageCommandKey: "MANAGE_APPROVAL_REQUEST_COMMAND",
              }).toString(),
              ActionName: "SUBMIT",
              Title: "My Title",
              Body: "Body of Noti",
              RefrenceNumber: "reference",
              NotificationTypeModel: {
                ID: 3,
                LocalName: "Approval Accepted",
                ForeignName: "Approval Accepted",
                Code: "APPROVAL_ACCEPTED",
              },
            }).toString(),
            JSON.stringify({ Params: "params" }).toString()
          )
          .catch((err: Error) => {
            return console.error(err.toString());
          }); */
    //  }
    //});

    /* myConnection.on(
      "ReceiveNotification",
      (serializedNotification: string, notificationParameters: string) => {
        handleReceivedNotification(
          serializedNotification,
          notificationParameters
        );
      }
    ); */

    /* return () => {
      if (connection) {
        disconnect();
      }
    }; */
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          if (connection?.state === HubConnectionState.Connected)
            connection.on(
              "ReceiveNotification",
              (
                serializedNotification: string,
                notificationParameters: string
              ) => {
                handleReceivedNotification(
                  serializedNotification,
                  notificationParameters
                );
              }
            );
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  const isConnected: () => boolean = () => {
    return !!(connection && connection.state === HubConnectionState.Connected);
  };

  const registerNotificationHandler = (
    notificationType: string,
    notificationHandler: (
      notificationContent: NotificationModel,
      notificationParameters: { [key: string]: any }
    ) => void
  ) => {
    notificationHandlers[notificationType] = notificationHandler;
  };
  const unregisterNotificationHandler = (notificationType: string) => {
    delete notificationHandlers[notificationType];
  };
  const connect = () => {
    return connection?.start;
  };
  const disconnect = () => {
    return connection?.stop;
  };

  const handleReceivedNotification = (
    serializedNotification: string,
    notificationParameters: string
  ) => {
    const notification = JSON.parse(
      serializedNotification
    ) as NotificationModel;
    const parameters = JSON.parse(notificationParameters);

    // For all notification types
    if (notification && notification.NotificationTypeModel.ShowToUser) {
      notificationHandlers["NOTIFICATION_RECEIPT"](notification, parameters);
    }

    // Notifications that are not visible to the user (might be only for mailbox) (CHANGE_ASSIGNMENT AND REMOVE_ASSIGNMENT)
    if (
      notification &&
      notification.NotificationTypeModel.Code &&
      notificationHandlers[notification.NotificationTypeModel.Code]
    ) {
      notificationHandlers[notification.NotificationTypeModel.Code](
        notification,
        parameters
      );
    }
  };
  return {
    connection,
    isConnected,
    connect,
    disconnect,
    registerNotificationHandler,
    unregisterNotificationHandler,
  };
}
