import { Dispatch, SetStateAction, useContext, useState } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownSearchField from "../../common/components/search/AppDropDownSearchField";
import OrganizationAutoComplete from "../../controls/organizations/base/OrganizationAutoComplete";
import PersonAutoComplete from "../../controls/persons/base/PersonAutoComplete";
import InsuranceCompanyAutoComplete from "../../controls/insuranceCompanies/base/InsuranceCompanyAutoComplete";
import InsuranceCompanyBranchAutoComplete from "../../controls/insuranceCompanies/base/InsuranceCompanyBranchAutoComplete";
import PremiumFunderAutoComplete from "../../controls/parties/base/PremiumFunderAutoComplete";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppCustomerEntitySearchFieldKeys = {
  EntityType: "EntityType",
  EntityID: "EntityID",
};
export default function AppCustomerEntitySearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "Code",
  setErrorMessage,
}: Props) {
  const { translate, translateIn } = useContext(LocalizationContext);

  const [entityType, setEntityType] = useState<string>();

  const EEntities = {
    ORGANIZATION: translate(strings.ORGANIZATION),
    PERSON: translate(strings.PERSON),
    MAIN_ORGANIZATION: translate(strings.MAIN_ORGANIZATION),
    PREMIUM_FUNDER: translate(strings.PREMIUM_FUNDER),
    INSURANCE_COMPANY: translate(strings.INSURANCE_COMPANY),
    INSURANCE_COMPANY_BRANCH: translate(strings.INSURANCE_COMPANY_BRANCH),
  };

  const getEntityTypes = async () => {
    const entityTypes = [
      {
        ID: 1,
        LocalName: translateIn(strings.ORGANIZATION, "ar"),
        ForeignName: translateIn(strings.ORGANIZATION, "en"),
        Code: "ORGANIZATION",
      },
      {
        ID: 2,
        LocalName: translateIn(strings.PERSON, "ar"),
        ForeignName: translateIn(strings.PERSON, "en"),
        Code: "PERSON",
      },
      {
        ID: 3,
        LocalName: translateIn(strings.MAIN_ORGANIZATION, "ar"),
        ForeignName: translateIn(strings.MAIN_ORGANIZATION, "en"),
        Code: "MAIN_ORGANIZATION",
      },
      {
        ID: 4,
        LocalName: translateIn(strings.PREMIUM_FUNDER, "ar"),
        ForeignName: translateIn(strings.PREMIUM_FUNDER, "en"),
        Code: "PREMIUM_FUNDER",
      },
      {
        ID: 5,
        LocalName: translateIn(strings.INSURANCE_COMPANY, "ar"),
        ForeignName: translateIn(strings.INSURANCE_COMPANY, "en"),
        Code: "INSURANCE_COMPANY",
      },
      {
        ID: 6,
        LocalName: translateIn(strings.INSURANCE_COMPANY_BRANCH, "ar"),
        ForeignName: translateIn(strings.INSURANCE_COMPANY_BRANCH, "en"),
        Code: "INSURANCE_COMPANY_BRANCH",
      },
    ];

    return entityTypes;
  };

  const handleEntityTypeChange = async (
    event: DropDownButtonItemClickEvent,
    searchValue: string
  ) => {
    removeSearchFieldValue(AppCustomerEntitySearchFieldKeys.EntityID);
    if (event.itemIndex !== 0) {
      setEntityType(event.item);
    } else {
      setEntityType("");
    }
  };

  const renderAutoComplete = (entityType?: string) => {
    switch (entityType) {
      case EEntities.ORGANIZATION:
        return (
          <OrganizationAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.ORGANIZATION)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppCustomerEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppCustomerEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.PERSON:
        return (
          <PersonAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PERSON)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppCustomerEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppCustomerEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.MAIN_ORGANIZATION:
        return (
          <OrganizationAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.MAIN_ORGANIZATION)}
            className="search-component"
            searchCriteria={{ IsBranch: false }}
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppCustomerEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppCustomerEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.PREMIUM_FUNDER:
        return (
          <PremiumFunderAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PREMIUM_FUNDER)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppCustomerEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppCustomerEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.INSURANCE_COMPANY:
        return (
          <InsuranceCompanyAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.INSURANCE_COMPANY)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppCustomerEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppCustomerEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.INSURANCE_COMPANY_BRANCH:
        return (
          <InsuranceCompanyBranchAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.INSURANCE_COMPANY_BRANCH)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppCustomerEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppCustomerEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      default:
        break;
    }
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AppDropDownSearchField
        definitionType={""}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={AppCustomerEntitySearchFieldKeys.EntityType}
        defaultShow={false}
        title={translate(strings.ENTITY)}
        searchFieldName={searchFieldName}
        onChange={handleEntityTypeChange}
        useCustomBinding
        getCustomList={getEntityTypes}
      />
      {renderAutoComplete(entityType)}
    </div>
  );
}
