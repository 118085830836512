import { Dispatch, SetStateAction, useContext, useState } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownSearchField from "../../common/components/search/AppDropDownSearchField";
import ProviderPaymentVoucherAutoComplete from "../../controls/pvps/base/ProviderPaymentVoucherAutoComplete";
import AdvancePaymentAutocomplete from "../../controls/advancePayments/base/AdvancePaymentAutoComplete";
import ClientPaymentVoucherAutoComplete from "../../controls/pvcs/base/ClientPaymentVoucherAutoComplete";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppBillEntitySearchFieldKeys = {
  EntityType: "EntityType",
  EntityID: "EntityID",
};
export default function AppBillEntitySearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "Code",
  setErrorMessage,
}: Props) {
  const { translate, translateIn } = useContext(LocalizationContext);

  const [entityType, setEntityType] = useState<string>();

  const EEntities = {
    PVP: translate(strings.PVP),
    ADVANCE_PAYMENT: translate(strings.ADVANCE_PAYMENT),
    CLIENT_PAYMENT_VOUCHER: translate(strings.PVC),
  };

  const getEntityTypes = async () => {
    const entityTypes = [
      {
        ID: 1,
        LocalName: translateIn(strings.PVP, "ar"),
        ForeignName: translateIn(strings.PVP, "en"),
        Code: "PVP",
      },
      {
        ID: 2,
        LocalName: translateIn(strings.ADVANCE_PAYMENT, "ar"),
        ForeignName: translateIn(strings.ADVANCE_PAYMENT, "en"),
        Code: "ADVANCE_PAYMENT",
      },
      {
        ID: 3,
        LocalName: translateIn(strings.PVC, "ar"),
        ForeignName: translateIn(strings.PVC, "en"),
        Code: "CLIENT_PAYMENT_VOUCHER",
      },
    ];

    return entityTypes;
  };

  const handleEntityTypeChange = async (
    event: DropDownButtonItemClickEvent,
    searchValue: string
  ) => {
    removeSearchFieldValue(AppBillEntitySearchFieldKeys.EntityID);
    if (event.itemIndex !== 0) {
      setEntityType(event.item);
    } else {
      setEntityType("");
    }
  };

  const renderAutoComplete = (entityType?: string) => {
    switch (entityType) {
      case EEntities.PVP:
        return (
          <ProviderPaymentVoucherAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PVP)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppBillEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(AppBillEntitySearchFieldKeys.EntityID);
            }}
          />
        );
      case EEntities.ADVANCE_PAYMENT:
        return (
          <AdvancePaymentAutocomplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.ADVANCE_PAYMENT)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppBillEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(AppBillEntitySearchFieldKeys.EntityID);
            }}
          />
        );
      case EEntities.CLIENT_PAYMENT_VOUCHER:
        return (
          <ClientPaymentVoucherAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PVC)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppBillEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(AppBillEntitySearchFieldKeys.EntityID);
            }}
          />
        );
      default:
        break;
    }
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AppDropDownSearchField
        definitionType={""}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={AppBillEntitySearchFieldKeys.EntityType}
        defaultShow={false}
        title={translate(strings.ENTITY)}
        searchFieldName={searchFieldName}
        onChange={handleEntityTypeChange}
        useCustomBinding
        getCustomList={getEntityTypes}
      />
      {renderAutoComplete(entityType)}
    </div>
  );
}
