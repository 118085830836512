import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.VENDORS;

const route_vendor = "/vendor";
const route_vendor_attachment = "/vendor-attachment";
const route_vendor_bank_account = "/vendor-bank-account";
const route_vendor_direct_payment_recipient =
  "/vendor-direct-payment-recipient";
const route_vendor_tax = "/vendor-tax";
const act_create_new_vendor = "/create-new-vendor";
const act_update_vendor_basic_info = "/update-vendor-basic-info";
const act_delete = "/delete";
const act_update_vendor_legal_info = "/update-vendor-legal-info";
const act_set_vendor_payment_method = "/set-vendor-payment-method";
const act_set_vendor_opening_balance = "/set-vendor-opening-balance";
const act_remove_vendor_opening_balance = "/remove-vendor-opening-balance";
const act_payment_details = "/payment-details";
const act_vendor_attachments = "/vendor-attachments";
const act_vendor_bank_accounts = "/vendor-bank-accounts";
const act_vendor_payment_recipients = "/vendor-payment-recipients";
const act_add_vendor_bank_account = "/add-vendor-bank-account";
const act_activate = "/activate";
const act_de_activate = "/de-activate";
const act_add_vendor_direct_payment_recipient =
  "/add-vendor-direct-payment-recipient";
const act_update_vendor_direct_payment_recipient =
  "/update-vendor-direct-payment-recipient";
const act_vendor_taxes = "/vendor-taxes";
const act_add_vendor_tax = "/add-vendor-tax";
const act_update_vendor_tax = "/update-vendor-tax";
const act_cancel = "/cancel";
const act_reinstate = "/reinstate";
const act_vendor_opening_balance_transaction =
  "/vendor-opening-balance-transaction";

const getVendors = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const createNewVendor = (vendor) => {
  return apiClient.post(
    endpoint + act_create_new_vendor,
    JSON.stringify({ ...vendor })
  );
};

const updateVendorBasicInfo = (vendorID, vendor) => {
  return apiClient.put(
    endpoint + route_vendor + "/" + vendorID + act_update_vendor_basic_info,
    JSON.stringify({ ...vendor })
  );
};

const deleteVendor = (vendorID) => {
  return apiClient.delete(
    endpoint + route_vendor + "/" + vendorID + act_delete
  );
};

const getVendor = (vendorID) => apiClient.get(endpoint + "/" + vendorID);

const updateVendorLegalInfo = (vendorID, criteria) => {
  return apiClient.put(
    endpoint + route_vendor + "/" + vendorID + act_update_vendor_legal_info,
    JSON.stringify({ ...criteria })
  );
};

const setVendorPaymentMethod = (vendorID, paymentMethoID) => {
  return apiClient.put(
    endpoint + route_vendor + "/" + vendorID + act_set_vendor_payment_method,
    JSON.stringify({ PaymentMethodID: paymentMethoID })
  );
};

const setVendorOpeningBalance = (vendorID, criteria) => {
  return apiClient.put(
    endpoint + route_vendor + "/" + vendorID + act_set_vendor_opening_balance,
    JSON.stringify({ ...criteria })
  );
};

const removeVendorOpeningBalance = (vendorID) => {
  return apiClient.put(
    endpoint + route_vendor + "/" + vendorID + act_remove_vendor_opening_balance
  );
};

const getVendorPaymentDetails = (vendorID) =>
  apiClient.get(endpoint + route_vendor + "/" + vendorID + act_payment_details);

const getVendorAttachments = (vendorID) =>
  apiClient.get(
    endpoint + route_vendor + "/" + vendorID + act_vendor_attachments
  );

const deleteVendorAttachment = (vendorID, vendorAttachmentID) => {
  return apiClient.delete(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_attachment +
      "/" +
      vendorAttachmentID +
      act_delete
  );
};

const addVendorAttachment = (vendorID, attachmentID) => {
  return apiClient.post(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_attachment +
      "/" +
      attachmentID
  );
};

const getVendorBankAccounts = (vendorID) =>
  apiClient.get(
    endpoint + route_vendor + "/" + vendorID + act_vendor_bank_accounts
  );

const getVendorPaymentRecipients = (vendorID) =>
  apiClient.get(
    endpoint + route_vendor + "/" + vendorID + act_vendor_payment_recipients
  );

const addVendorBankAccount = (vendorID, bankAccount) => {
  return apiClient.post(
    endpoint + route_vendor + "/" + vendorID + act_add_vendor_bank_account,
    JSON.stringify({ ...bankAccount })
  );
};

const deleteVendorBankAccount = (vendorID, vendorBankAccountID) => {
  return apiClient.put(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_bank_account +
      "/" +
      vendorBankAccountID +
      act_delete
  );
};

const activateVendorBankAccount = (vendorID, vendorBankAccountID, criteria) => {
  return apiClient.put(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_bank_account +
      "/" +
      vendorBankAccountID +
      act_activate,
    JSON.stringify({ ...criteria })
  );
};

const deActivateVendorBankAccount = (
  vendorID,
  vendorBankAccountID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_bank_account +
      "/" +
      vendorBankAccountID +
      act_de_activate,
    JSON.stringify({ ...criteria })
  );
};

const addVendorDirectPaymentRecipient = (vendorID, directPaymentRecipient) => {
  return apiClient.post(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      act_add_vendor_direct_payment_recipient,
    JSON.stringify({ ...directPaymentRecipient })
  );
};

const updateVendorDirectPaymentRecipient = (
  vendorID,
  vendorDirectPaymentRecipientID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_direct_payment_recipient +
      "/" +
      vendorDirectPaymentRecipientID +
      act_update_vendor_direct_payment_recipient,
    JSON.stringify({ ...criteria })
  );
};

const deleteVendorDirectPaymentRecipient = (
  vendorID,
  vendorDirectPaymentRecipientID
) => {
  return apiClient.put(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_direct_payment_recipient +
      "/" +
      vendorDirectPaymentRecipientID +
      act_delete
  );
};

const activateVendorDirectPaymentRecipient = (
  vendorID,
  vendorDirectPaymentRecipientID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_direct_payment_recipient +
      "/" +
      vendorDirectPaymentRecipientID +
      act_activate,
    JSON.stringify({ ...criteria })
  );
};

const deActivateVendorDirectPaymentRecipient = (
  vendorID,
  vendorDirectPaymentRecipientID
) => {
  return apiClient.put(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_direct_payment_recipient +
      "/" +
      vendorDirectPaymentRecipientID +
      act_de_activate
  );
};

const getVendorTaxes = (vendorID) =>
  apiClient.get(endpoint + route_vendor + "/" + vendorID + act_vendor_taxes);

const addVendorTax = (vendorID, tax) => {
  return apiClient.post(
    endpoint + route_vendor + "/" + vendorID + act_add_vendor_tax,
    JSON.stringify({ ...tax })
  );
};

const updateVendorTax = (vendorID, vendorTaxID, criteria) => {
  return apiClient.put(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_tax +
      "/" +
      vendorTaxID +
      act_update_vendor_tax,
    JSON.stringify({ ...criteria })
  );
};

const deleteVendorTax = (vendorID, vendorTaxID) => {
  return apiClient.delete(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_tax +
      "/" +
      vendorTaxID +
      act_delete
  );
};

const cancelVendorTax = (vendorID, vendorTaxID, criteria) => {
  return apiClient.put(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_tax +
      "/" +
      vendorTaxID +
      act_cancel,
    JSON.stringify({ ...criteria })
  );
};

const reinstateVendorTax = (vendorID, vendorTaxID) => {
  return apiClient.put(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      route_vendor_tax +
      "/" +
      vendorTaxID +
      act_reinstate
  );
};

const getVendorOpeningBalanceTransaction = (vendorID) =>
  apiClient.get(
    endpoint +
      route_vendor +
      "/" +
      vendorID +
      act_vendor_opening_balance_transaction
  );

const vendorsApi = {
  getVendors,
  createNewVendor,
  updateVendorBasicInfo,
  deleteVendor,
  getVendor,
  updateVendorLegalInfo,
  setVendorPaymentMethod,
  setVendorOpeningBalance,
  removeVendorOpeningBalance,
  getVendorPaymentDetails,
  getVendorAttachments,
  deleteVendorAttachment,
  addVendorAttachment,
  getVendorBankAccounts,
  getVendorPaymentRecipients,
  addVendorBankAccount,
  deleteVendorBankAccount,
  activateVendorBankAccount,
  deActivateVendorBankAccount,
  addVendorDirectPaymentRecipient,
  updateVendorDirectPaymentRecipient,
  deleteVendorDirectPaymentRecipient,
  activateVendorDirectPaymentRecipient,
  deActivateVendorDirectPaymentRecipient,
  getVendorTaxes,
  addVendorTax,
  updateVendorTax,
  deleteVendorTax,
  cancelVendorTax,
  reinstateVendorTax,
  getVendorOpeningBalanceTransaction,
};

export default vendorsApi;
