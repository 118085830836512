import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownButton from "../../common/components/buttons/AppDropDownButton";
import definitions from "../../api/definitions";
import { getDefinitionList } from "../../common/api/searchDefinitionHelper";
import countriesAPI from "../../api/countries";
import errorHandler from "../../common/utility/errorHandler";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import BusinessObjectModel from "../../common/interfaces/BusinessObjectModel";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import AppDropDownFilterSearchField from "../../common/components/search/AppDropDownFilterSearchField";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppCountryGovernorateSearchFieldKeys = {
  CountryID: "CountryID",
  GovernorateID: "GovernorateID",
};
export default function AppCountryGovernorateSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { getNameField, translate, getName } = useContext(LocalizationContext);

  const resetObject = {
    ID: 0,
    LocalName: translate(strings.UNSET_THIS_VALUE),
    ForeignName: translate(strings.UNSET_THIS_VALUE),
  };

  const [countryFieldName, setCountryFieldName] = useState(
    translate(strings.COUNTRY)
  );

  const [showGovernorate, setShowGovernorate] = useState(false);

  const [countries, setCountries] = useState<BusinessObjectModel[]>([]);
  const [SelectedCountry, setSelectedCountry] = useState<BusinessObjectModel>();

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  const getGovernorates = async () => {
    const response = await countriesAPI.getGovernorates({
      CountryID: SelectedCountry?.ID,
    });

    if (response.ok) {
      return [...(response.data ?? [])];
    } else {
      setErrorMessage(errorHandler.getErrorMessage(response));
      return [];
    }
  };

  const getCountries = async () => {
    const countryObjectType = definitions.CountryDefinition.objectType;
    const countryList: BusinessObjectModel[] = await getDefinitionList<
      typeof countryObjectType
    >(
      definitions.CountryDefinition.endpoint,
      definitions.CountryDefinition.callType,
      setErrorMessage
    );
    setCountries([resetObject, ...countryList]);
  };

  const getObjectSearchValue = (dropDownObjectList: any[], value: string) => {
    let returnedValue: string = "";
    dropDownObjectList.filter((object) => {
      if (getName(object) === value) returnedValue = object[searchFieldName];
      return returnedValue;
    });
    return returnedValue;
  };

  const handleCountryCLick = async (event: DropDownButtonItemClickEvent) => {
    const value = event.item;
    setSelectedCountry(value);
    await setShowGovernorate(false);
    if (!value) setShowGovernorate(false);
    if (getName(event.item) === getName(resetObject)) {
      removeSearchFieldValue(AppCountryGovernorateSearchFieldKeys.CountryID);
      setCountryFieldName(translate(strings.COUNTRY));
      onGovernorateChanged(resetObject);
    } else {
      setCountryFieldName(
        translate(strings.COUNTRY) + ": " + getName(event.item)
      );
      setShowGovernorate(true);
      const searchValue: string = getObjectSearchValue(
        countries,
        getName(value)
      );
      onGovernorateChanged(resetObject);
      setSearchFieldValues(
        AppCountryGovernorateSearchFieldKeys.CountryID,
        searchValue
      );
    }
  };

  const onGovernorateChanged = (governorate: BusinessObjectModel) => {
    if (!governorate) setShowGovernorate(false);
    if (governorate?.ID <= 0) {
      removeSearchFieldValue(
        AppCountryGovernorateSearchFieldKeys.GovernorateID
      );
    } else {
      setSearchFieldValues(
        AppCountryGovernorateSearchFieldKeys.GovernorateID,
        governorate?.ID
      );
    }
  };
  const handleGovernorateClick = async (event: DropDownListChangeEvent) => {
    const value = event.value;
    onGovernorateChanged(value);
  };

  return (
    <>
      <AppDropDownButton
        className="search-component"
        text={countryFieldName}
        icon="k-icon k-font-icon k-i-arrow-chevron-down"
        items={countries}
        textField={getNameField()}
        popupSettings={{ popupClass: "popup" }}
        themeColor={"primary"}
        onItemClick={handleCountryCLick}
      />
      {showGovernorate && (
        <span
          style={{
            display: "inline-block",
            minWidth: "200px",
            margin: "6px 6px",
          }}
        >
          <AppDropDownFilterSearchField
            definitionType={""}
            setErrorMessage={setErrorMessage}
            removeSearchFieldValue={removeSearchFieldValue}
            setSearchFieldValues={setSearchFieldValues}
            criteriaTitle={AppCountryGovernorateSearchFieldKeys.GovernorateID}
            defaultShow={false}
            title={translate(strings.GOVERNORATE)}
            searchFieldName={searchFieldName}
            onChange={handleGovernorateClick}
            useCustomBinding
            getCustomList={getGovernorates}
          />
        </span>
      )}
    </>
  );
}
