import { useContext } from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { LocalizationContext } from "../../context/localizationContext";
import AppTextArea from "../inputs/AppTextArea";

interface Props extends FieldRenderProps {
  validationMessage: string | null;
  visited: boolean;
  hintMessage?: string;
  label?: string;
  id?: string;
  valid: boolean;
  rows?: number;
}

export default function FormLabelTextArea({
  validationMessage,
  visited,
  hintMessage,
  label,
  id,
  valid,
  rows = 4,
  ...others
}: Props) {
  const { translate } = useContext(LocalizationContext);
  const showValidationMessage: string | false | null =
    visited && validationMessage;

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {translate(label)}
      </Label>
      <AppTextArea valid={valid} id={id} rows={rows} {...others} />
      {!showValidationMessage && hintMessage && <Hint>{hintMessage}</Hint>}
      {showValidationMessage && <Error>{translate(validationMessage)}</Error>}
    </FieldWrapper>
  );
}
