import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownButton from "../../common/components/buttons/AppDropDownButton";
import definitions from "../../api/definitions";
import { getDefinitionList } from "../../common/api/searchDefinitionHelper";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import BusinessObjectModel from "../../common/interfaces/BusinessObjectModel";
import CityAutoComplete from "../../controls/countries/base/CityAutoComplete";
import CityModel from "../../interfaces/geography/CityModel";
import LocationAutoComplete from "../../controls/countries/base/LocationAutoComplete";
import LocationModel from "../../interfaces/geography/Location";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppLocationSearchFieldKeys = {
  ProviderCountryID: "ProviderCountryID",
  ProviderCityID: "ProviderCityID",
  ProviderLocationID: "ProviderLocationID",
};
export default function AppLocationSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { getNameField, translate, getName } = useContext(LocalizationContext);

  const resetObject = {
    ID: 0,
    LocalName: translate(strings.UNSET_THIS_VALUE),
    ForeignName: translate(strings.UNSET_THIS_VALUE),
  };

  const [countryFieldName, setCountryFieldName] = useState(
    translate(strings.COUNTRY)
  );

  const [showCity, setShowCity] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const [countries, setCountries] = useState<BusinessObjectModel[]>([]);

  const [selectedCountry, setSelectedCountry] = useState<BusinessObjectModel>();
  const [selectedCity, setSelectedCity] = useState<BusinessObjectModel>();

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  const getCountries = async () => {
    const countryObjectType = definitions.CountryDefinition.objectType;
    const countryList: BusinessObjectModel[] = await getDefinitionList<
      typeof countryObjectType
    >(
      definitions.CountryDefinition.endpoint,
      definitions.CountryDefinition.callType,
      setErrorMessage
    );
    setCountries([resetObject, ...countryList]);
  };

  const getObjectSearchValue = (dropDownObjectList: any[], value: string) => {
    let returnedValue: string = "";
    dropDownObjectList.filter((object) => {
      if (getName(object) === value) returnedValue = object[searchFieldName];
      return returnedValue;
    });
    return returnedValue;
  };

  const handleCountryCLick = async (event: DropDownButtonItemClickEvent) => {
    const value = event.item;
    await setShowCity(false);
    await setShowLocation(false);
    removeSearchFieldValue(AppLocationSearchFieldKeys.ProviderCityID);
    removeSearchFieldValue(AppLocationSearchFieldKeys.ProviderLocationID);

    if (getName(event.item) === getName(resetObject)) {
      removeSearchFieldValue(AppLocationSearchFieldKeys.ProviderCountryID);
      setCountryFieldName(translate(strings.COUNTRY));
      setShowCity(false);
    } else {
      setSelectedCountry(event.item);
      setCountryFieldName(
        translate(strings.COUNTRY) + ": " + getName(event.item)
      );
      await setShowCity(true);
      const searchValue: string = getObjectSearchValue(
        countries,
        getName(value)
      );
      setSearchFieldValues(
        AppLocationSearchFieldKeys.ProviderCountryID,
        searchValue
      );
    }
  };

  const onCityChanged = async (city: CityModel) => {
    setSelectedCity(undefined);
    removeSearchFieldValue(AppLocationSearchFieldKeys.ProviderCityID);
    removeSearchFieldValue(AppLocationSearchFieldKeys.ProviderLocationID);
    await setShowLocation(false);

    if (city) {
      setSelectedCity(city);
      await setShowLocation(true);
      setSearchFieldValues(AppLocationSearchFieldKeys.ProviderCityID, city.ID);
    }
  };

  const onLocationChanged = (location: LocationModel) => {
    if (!location) {
      setSelectedCity(undefined);
      removeSearchFieldValue(AppLocationSearchFieldKeys.ProviderLocationID);
      setShowLocation(false);
    } else {
      setSelectedCity(location);
      setShowLocation(true);
      setSearchFieldValues(
        AppLocationSearchFieldKeys.ProviderLocationID,
        location.ID
      );
    }
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AppDropDownButton
        className="search-component"
        text={countryFieldName}
        icon="k-icon k-font-icon k-i-arrow-chevron-down"
        items={countries}
        textField={getNameField()}
        popupSettings={{ popupClass: "popup" }}
        themeColor={"primary"}
        onItemClick={handleCountryCLick}
      />
      {showCity && (
        <CityAutoComplete
          columns={[]}
          textField={getNameField()}
          loadDataOnOpen
          charNumber={3}
          placeholder={translate(strings.CITY)}
          searchCriteria={{
            CountryID: selectedCountry?.ID,
          }}
          className="search-component"
          onChange={(event) => onCityChanged(event.value)}
        />
      )}
      {showLocation && (
        <LocationAutoComplete
          columns={[]}
          textField={getNameField()}
          loadDataOnOpen
          charNumber={3}
          placeholder={translate(strings.LOCATION)}
          searchCriteria={{
            CityID: selectedCity?.ID,
          }}
          className="search-component"
          onChange={(event) => onLocationChanged(event.value)}
        />
      )}
    </div>
  );
}
