import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const providerSpecialties = {
  columns: [
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "150px",
    },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "150px",
    },
  ],
  autoCompleteEndpoint: endpoints.PROVIDERS + "/provider-specialty-list",
  autoCompleteSearchKey: "Name",
};

export default providerSpecialties;
