import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.BANKS;
const route_banks = "/banks";
const route_bank_branches = "/bank-branches";
const route_bank_accounts = "/bank-accounts";
const route_bank_account_types = "/bank-account-types";
const route_bank_cheque_books = "/bank-cheque-books";
const route_entity_bank_account_activation_history =
  "/entity-bank-account-activation-history";
const route_bank_account_history = "/bank-account-history";
const route_bank_account_actions_history = "/bank-account-actions-history";
const route_banking_account_type = "/banking-account-type";
const route_bank = "/bank";
const route_bank_account_type = "/bank-account-type";
const route_bank_branch = "/bank-branch";
const route_bank_cheque_book = "/bank-cheque-book";
const route_bank_cheque_book_serial_adjustment =
  "/bank-cheque-book-serial-adjustment";
const act_update = "/update";
const act_verify = "/verify";
const act_unverify = "/unverify";
const act_freeze = "/freeze";
const act_unfreeze = "/unfreeze";
const act_create_new_banking_account_type = "/create-new-banking-account-type";
const act_update_banking_account_type = "/update-banking-account-type";
const act_delete = "/delete";
const act_banking_account_types = "/banking-account-types";
const act_create_new_bank = "/create-new-bank";
const act_create_new_bank_account_type = "/create-new-bank-account-type";
const act_update_bank_account_type = "/update-bank-account-type";
const act_create_new_bank_branch = "/create-new-bank-branch";
const act_update_bank_branch = "/update-bank-branch";
const act_create_new_bank_cheque_book = "/create-new-bank-cheque-book";
const act_update_bank_cheque_book = "/update-bank-cheque-book";
const act_bank_cheque_book_serial_adjustments =
  "/bank-cheque-book-serial-adjustments";
const act_create_new_bank_cheque_book_serial_adjustment =
  "/create-new-bank-cheque-book-serial-adjustment";
const act_bank_payment_details_columns = "/bank-payment-details-columns";
const act_update_bank = "/update-bank";
const route_bank_account = "/bank-account";

const getBanks = (paging) =>
  apiClient.post(
    endpoint + route_banks,
    paging && JSON.stringify({ ...paging }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );

const getBankBranches = (bankID) =>
  apiClient.get(endpoint + "/" + bankID + route_bank_branches);

const getBankAccounts = (data) =>
  apiClient.post(endpoint + route_bank_accounts, data, {
    headers: { "X-HTTP-Method-Override": "GET" },
  });

const getBankAccountTypes = (bankID) =>
  apiClient.get(endpoint + "/" + bankID + route_bank_account_types);

const getBankChequeBooks = (criteria) => {
  return apiClient.post(
    endpoint + route_bank_cheque_books,
    criteria && JSON.stringify({ ...criteria }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getEntityBankAccountActivationHistory = (data) => {
  return apiClient.post(
    endpoint + route_entity_bank_account_activation_history,
    data,
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const updateBankAccount = (bankAccountID, data) => {
  return apiClient.put(endpoint + "/" + bankAccountID + act_update, data);
};

const verifyBankAccount = (bankAccountID) => {
  return apiClient.put(endpoint + "/" + bankAccountID + act_verify);
};

const unverifyBankAccount = (bankAccountID) => {
  return apiClient.put(endpoint + "/" + bankAccountID + act_unverify);
};

const freezeBankAccount = (bankAccountID, data) => {
  return apiClient.put(endpoint + "/" + bankAccountID + act_freeze, data);
};

const unfreezeBankAccount = (bankAccountID, data) => {
  return apiClient.put(endpoint + "/" + bankAccountID + act_unfreeze, data);
};

const getBankAccountHistory = (bankAccountID) => {
  return apiClient.get(
    endpoint + "/" + bankAccountID + route_bank_account_history
  );
};

const getBankAccountActionsHistory = (bankAccountID) => {
  return apiClient.get(
    endpoint + "/" + bankAccountID + route_bank_account_actions_history
  );
};

const getBankingAccountTypes = () =>
  apiClient.get(endpoint + act_banking_account_types);

const createNewBankingAccountType = (bankingAccountType) => {
  return apiClient.post(
    endpoint + act_create_new_banking_account_type,
    JSON.stringify({ ...bankingAccountType })
  );
};

const updateBankingAccountType = (bankingAccountTypeID, criteria) => {
  return apiClient.put(
    endpoint +
      route_banking_account_type +
      "/" +
      bankingAccountTypeID +
      act_update_banking_account_type,
    JSON.stringify({ ...criteria })
  );
};

const deleteBankingAccountType = (bankingAccountTypeID) => {
  return apiClient.delete(
    endpoint +
      route_banking_account_type +
      "/" +
      bankingAccountTypeID +
      act_delete
  );
};

const createNewBank = (bank) => {
  return apiClient.post(
    endpoint + act_create_new_bank,
    JSON.stringify({ ...bank })
  );
};

const getBank = (bankID) => {
  return apiClient.get(endpoint + route_bank + "/" + bankID);
};

const createNewBankAccountType = (bankID, bankAccountType) => {
  return apiClient.post(
    endpoint + route_bank + "/" + bankID + act_create_new_bank_account_type,
    JSON.stringify({ ...bankAccountType })
  );
};

const updateBankAccountType = (bankID, bankAccountTypeID, bankAccountType) => {
  return apiClient.put(
    endpoint +
      route_bank +
      "/" +
      bankID +
      route_bank_account_type +
      "/" +
      bankAccountTypeID +
      act_update_bank_account_type,
    JSON.stringify({ ...bankAccountType })
  );
};

const deleteBankAccountType = (bankID, bankAccountTypeID) => {
  return apiClient.put(
    endpoint +
      route_bank +
      "/" +
      bankID +
      route_bank_account_type +
      "/" +
      bankAccountTypeID +
      act_delete
  );
};

const createNewBankBranch = (bankID, bankBranch) => {
  return apiClient.post(
    endpoint + route_bank + "/" + bankID + act_create_new_bank_branch,
    JSON.stringify({ ...bankBranch })
  );
};

const updateBankBranch = (bankID, bankBranchID, bankBranch) => {
  return apiClient.put(
    endpoint +
      route_bank +
      "/" +
      bankID +
      route_bank_branch +
      "/" +
      bankBranchID +
      act_update_bank_branch,
    JSON.stringify({ ...bankBranch })
  );
};

const deleteBankBranch = (bankID, bankBranchID) => {
  return apiClient.put(
    endpoint +
      route_bank +
      "/" +
      bankID +
      route_bank_branch +
      "/" +
      bankBranchID +
      act_delete
  );
};

const createNewBankChequeBook = (bankID, bankChequeBook) => {
  return apiClient.post(
    endpoint + route_bank + "/" + bankID + act_create_new_bank_cheque_book,
    JSON.stringify({ ...bankChequeBook })
  );
};

const updateBankChequeBook = (bankID, bankChequeBookID, bankChequeBook) => {
  return apiClient.put(
    endpoint +
      route_bank +
      "/" +
      bankID +
      route_bank_cheque_book +
      "/" +
      bankChequeBookID +
      act_update_bank_cheque_book,
    JSON.stringify({ ...bankChequeBook })
  );
};

const deleteBankChequeBook = (bankID, bankChequeBookID) => {
  return apiClient.delete(
    endpoint +
      route_bank +
      "/" +
      bankID +
      route_bank_cheque_book +
      "/" +
      bankChequeBookID +
      act_delete
  );
};

const getBankChequeBookSerialAdjustments = (bankChequeBookID) =>
  apiClient.get(
    endpoint +
      route_bank_cheque_book +
      "/" +
      bankChequeBookID +
      act_bank_cheque_book_serial_adjustments
  );

const deleteBankChequeBookSerialAdjustment = (
  bankID,
  bankChequeBookID,
  bankChequeBookSerialAdjustmentID
) => {
  return apiClient.put(
    endpoint +
      route_bank +
      "/" +
      bankID +
      route_bank_cheque_book +
      "/" +
      bankChequeBookID +
      route_bank_cheque_book_serial_adjustment +
      "/" +
      bankChequeBookSerialAdjustmentID +
      act_delete
  );
};

const createNewBankChequeBookSerialAdjustment = (
  bankID,
  bankChequeBookID,
  bankChequeBookSerialAdjustment
) => {
  return apiClient.post(
    endpoint +
      route_bank +
      "/" +
      bankID +
      route_bank_cheque_book +
      "/" +
      bankChequeBookID +
      act_create_new_bank_cheque_book_serial_adjustment,
    JSON.stringify({ ...bankChequeBookSerialAdjustment })
  );
};

const getBankPaymentDetailsColumns = (bankID) =>
  apiClient.get(
    endpoint + route_bank + "/" + bankID + act_bank_payment_details_columns
  );

const updateBank = (bankID, bank) => {
  return apiClient.put(
    endpoint + route_bank + "/" + bankID + act_update_bank,
    JSON.stringify({ ...bank })
  );
};

const getBankAccount = (bankAccountID) => {
  return apiClient.get(endpoint + route_bank_account + "/" + bankAccountID);
};

const banksApi = {
  getBanks,
  getBankBranches,
  getBankAccounts,
  getBankAccountTypes,
  getBankChequeBooks,
  getEntityBankAccountActivationHistory,
  updateBankAccount,
  verifyBankAccount,
  unverifyBankAccount,
  freezeBankAccount,
  unfreezeBankAccount,
  getBankAccountHistory,
  getBankAccountActionsHistory,
  getBankingAccountTypes,
  createNewBankingAccountType,
  updateBankingAccountType,
  deleteBankingAccountType,
  createNewBank,
  getBank,
  createNewBankAccountType,
  updateBankAccountType,
  deleteBankAccountType,
  createNewBankBranch,
  updateBankBranch,
  deleteBankBranch,
  createNewBankChequeBook,
  updateBankChequeBook,
  deleteBankChequeBook,
  getBankChequeBookSerialAdjustments,
  deleteBankChequeBookSerialAdjustment,
  createNewBankChequeBookSerialAdjustment,
  getBankPaymentDetailsColumns,
  updateBank,
  getBankAccount,
};

export default banksApi;
