import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.ORGANIZATIONS;

const route_organization_bank_accounts = "/organization-bank-accounts";
const route_organization_contacts = "/organization-contacts";
const route_organization_direct_payment_recipients =
  "/organization-direct-payment-recipients";
const route_organization_branches = "/organization-branches";
const route_organization_history = "/organization-history";

const act_update = "/update";
const act_activate = "/activate";
const act_de_activate = "/de-activate";
const act_set_payment_method_type = "/set-payment-method-type";

const getOrganizations = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const createOrganization = (data) => {
  return apiClient.post(endpoint, data);
};

const updateOrganization = (organizationID, data) => {
  return apiClient.put(endpoint + "/" + organizationID, data);
};

const deleteOrganization = (organizationID) => {
  return apiClient.delete(endpoint + "/" + organizationID);
};

const getOrganizationBankAccounts = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_organization_bank_accounts,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getOrganizationContacts = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_organization_contacts,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getOrganizationDirectPaymentRecipients = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_organization_direct_payment_recipients,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addOrganizationBankAccount = (organizationID, data) => {
  return apiClient.post(
    endpoint + "/" + organizationID + route_organization_bank_accounts,
    data
  );
};

const removeOrganizationBankAccount = (
  organizationID,
  organizationBankAccountID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      organizationID +
      route_organization_bank_accounts +
      "/" +
      organizationBankAccountID
  );
};

const activateOrganizationBankAccount = (
  organizationID,
  organizationBankAccountID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      organizationID +
      route_organization_bank_accounts +
      "/" +
      organizationBankAccountID +
      act_activate,
    data
  );
};

const deActivateOrganizationBankAccount = (
  organizationID,
  organizationBankAccountID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      organizationID +
      route_organization_bank_accounts +
      "/" +
      organizationBankAccountID +
      act_de_activate,
    data
  );
};

const addOrganizationBranch = (organizationID, data) => {
  return apiClient.post(
    endpoint + "/" + organizationID + route_organization_branches,
    data
  );
};

const updateOrganizationBranch = (organizationBranchID, data) => {
  return apiClient.put(
    endpoint + route_organization_branches + "/" + organizationBranchID,
    data
  );
};

const deleteOrganizationBranch = (organizationBranchID) => {
  return apiClient.delete(
    endpoint + route_organization_branches + "/" + organizationBranchID
  );
};

const addOrganizationContact = (data) => {
  return apiClient.post(endpoint + route_organization_contacts, data);
};

const updateOrganizationContact = (organizationContactID, data) => {
  return apiClient.put(
    endpoint + route_organization_contacts + "/" + organizationContactID,
    data
  );
};

const deleteOrganizationContact = (organizationID, organizationContactID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      organizationID +
      route_organization_contacts +
      "/" +
      organizationContactID
  );
};

const addOrganizationDirectPaymentRecipient = (data) => {
  return apiClient.post(
    endpoint + route_organization_direct_payment_recipients,
    data
  );
};

const updateOrganizationDirectPaymentRecipient = (
  organizationDirectPaymentRecipientID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_organization_direct_payment_recipients +
      "/" +
      organizationDirectPaymentRecipientID +
      act_update,
    data
  );
};

const deleteOrganizationDirectPaymentRecipient = (
  organizationDirectPaymentRecipientID
) => {
  return apiClient.delete(
    endpoint +
      route_organization_direct_payment_recipients +
      "/" +
      organizationDirectPaymentRecipientID
  );
};

const activateOrganizationDirectPaymentRecipient = (
  organizationDirectPaymentRecipientID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_organization_direct_payment_recipients +
      "/" +
      organizationDirectPaymentRecipientID +
      act_activate,
    data
  );
};

const deActivateOrganizationDirectPaymentRecipient = (
  organizationDirectPaymentRecipientID
) => {
  return apiClient.put(
    endpoint +
      route_organization_direct_payment_recipients +
      "/" +
      organizationDirectPaymentRecipientID +
      act_de_activate
  );
};

const setOrganizationPaymentMethodType = (organizationID, data) => {
  return apiClient.put(
    endpoint + "/" + organizationID + act_set_payment_method_type,
    data
  );
};

const getOrganizationHistory = (organizationID) => {
  return apiClient.get(
    endpoint + "/" + organizationID + route_organization_history
  );
};

const organizationsApi = {
  getOrganizations,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  getOrganizationBankAccounts,
  getOrganizationContacts,
  getOrganizationDirectPaymentRecipients,
  addOrganizationBankAccount,
  removeOrganizationBankAccount,
  activateOrganizationBankAccount,
  deActivateOrganizationBankAccount,
  addOrganizationBranch,
  updateOrganizationBranch,
  deleteOrganizationBranch,
  addOrganizationContact,
  updateOrganizationContact,
  deleteOrganizationContact,
  addOrganizationDirectPaymentRecipient,
  updateOrganizationDirectPaymentRecipient,
  deleteOrganizationDirectPaymentRecipient,
  activateOrganizationDirectPaymentRecipient,
  deActivateOrganizationDirectPaymentRecipient,
  setOrganizationPaymentMethodType,
  getOrganizationHistory,
};

export default organizationsApi;
