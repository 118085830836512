import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const clientCompanies = {
  columns: [
    {
      field: "ID",
      header: strings.ID,
      width: "100px",
    },
    {
      field: "Contract.LocalName",
      header: strings.LOCAL_NAME,
      width: "380px",
    },
    {
      field: "Contract.ForeignName",
      header: strings.FOREIGN_NAME,
      width: "400px",
    },
  ],
  autoCompleteEndpoint: endpoints.CLIENTS + "/client-companies",
  autoCompleteSearchKey: "ClientName",
  customObjectDisplay: "Contract",
};

export default clientCompanies;
