import { createContext, ReactNode } from "react";
import BaseUserModel from "../interfaces/BaseUserModel";
import UserCurrentStatusModel from "../interfaces/UserCurrentStatusModel";

interface Props {
  user: any;
  setUser: (user: BaseUserModel | null) => void;
  token: string;
  setToken: (token: string) => void;
  password: string;
  setPassword: (password: string) => void;
  logOut: () => void;
  defaultCountryID: number | undefined;
  setDefaultCountryID: (countryID: number) => void;
  children: ReactNode;
  userCurrentStatus: UserCurrentStatusModel | null | undefined;
  setUserCurrentStatus: (userCurrentStatus: UserCurrentStatusModel) => void;
}

interface ContextProps {
  user: any;
  setUser: (user: BaseUserModel) => void;
  token: string;
  setToken: (token: string) => void;
  password: string;
  setPassword: (password: string) => void;
  logOut: () => void;
  defaultCountryID: number | undefined;
  setDefaultCountryID: (countryID: number) => void;
  isAuthenticated: () => boolean;
  userCurrentStatus: UserCurrentStatusModel | null | undefined;
  setUserCurrentStatus: (userCurrentStatus: UserCurrentStatusModel) => void;
}
export const AuthenticationContext = createContext<ContextProps>({
  user: null,
  // user:
  // {
  //   ...defaultUserModel,
  //   FirstName: "",
  //   MiddleName: "",
  //   LastName: "",
  //   AuthenticationPassword: "",
  //   ID: 0,
  //   LocalName: "",
  //   ForeignName: "",
  //   ProviderModel: defaultProvider,
  //   InsuredPersonInformationModel: defaultInsured,
  // }
  setUser: (user: BaseUserModel | null) => {},
  token: "",
  setToken: (token: string) => {},
  password: "",
  setPassword: (password: string) => {},
  logOut: () => {},
  defaultCountryID: 1,
  setDefaultCountryID: (countryID: number) => {},
  isAuthenticated: () => {
    return false;
  },
  userCurrentStatus: null,
  setUserCurrentStatus: (userCurrentStatus: UserCurrentStatusModel) => {},
});

export default function AuthenticationProvider({
  user,
  setUser,
  token,
  setToken,
  password,
  setPassword,
  logOut,
  children,
  defaultCountryID,
  setDefaultCountryID,
  userCurrentStatus,
  setUserCurrentStatus,
}: Props) {
  const isAuthenticated = () => {
    return user !== null && token !== null;
  };
  return (
    <AuthenticationContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        password,
        setPassword,
        isAuthenticated,
        logOut,
        defaultCountryID,
        setDefaultCountryID,
        userCurrentStatus,
        setUserCurrentStatus,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
