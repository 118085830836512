import { createContext, ReactNode } from "react";

interface ContextProps {
  drawerExpanded: boolean;
  setDrawerExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}
interface Props extends ContextProps {
  children: ReactNode;
}
export const DrawerContext = createContext<ContextProps>({
  drawerExpanded: false,
  setDrawerExpanded: () => {},
});

export default function DrawerContextProvider({
  drawerExpanded,
  setDrawerExpanded,
  children,
}: Props) {
  return (
    <DrawerContext.Provider
      value={{
        drawerExpanded,
        setDrawerExpanded,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}
