import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import ClaimInvoiceModel from "../../../interfaces/claims/ClaimInvoiceModel";
import claimsApi from "../../../api/claims";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<ClaimInvoiceModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
}
export default function ClaimInvoiceAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: "ClaimFormModel.ID",
      header: translate(strings.CLAIM_FORM_ID),
      width: "120px",
    },
    {
      field: "ClaimInvoiceStatus",
      header: translate(strings.STATUS),
      width: "120px",
    },
    {
      field: "ProviderModel",
      header: translate(strings.PROVIDER),
      width: "120px",
    },
    {
      field: "AccidentDate",
      header: translate(strings.ACCIDENT_DATE),
      width: "120px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return claimsApi.getClaimInvoices(
      {
        TpaRefNumber: inputString,
        ...searchCriteria,
      },
      { Page: 0, PageSize: pageDefaultSize }
    );
  };

  return (
    <AppAutoComplete<ClaimInvoiceModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay="ClaimFormModel.TpaReferenceNumber"
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
