import { createContext, ReactNode } from "react";

interface ContextProps {
  useNotifications: () => {
    connection: any;
    isConnected: () => boolean;
    connect: () => (() => Promise<void>) | undefined;
    disconnect: () => (() => Promise<void>) | undefined;
    registerNotificationHandler: (
      notificationType: string,
      notificationHandler: (
        notificationContent: any,
        notificationParameters: {
          [key: string]: any;
        }
      ) => void
    ) => void;
    unregisterNotificationHandler: (notificationType: string) => void;
  };
  getNotificationType: (
    notificationTypeCode: string
  ) => "success" | "error" | "warning" | "info" | "none" | undefined;
  isNotificationTypeCountable: (notificationTypeCode: string) => boolean;
}
interface Props extends ContextProps {
  children: ReactNode;
}
export const UseNotificationContext = createContext<ContextProps>({
  useNotifications: () => {
    return {
      connection: undefined,
      isConnected: () => false,
      connect: () => undefined,
      disconnect: () => undefined,
      registerNotificationHandler: () => {},
      unregisterNotificationHandler: () => {},
    };
  },
  getNotificationType: (notificationTypeCode: string) => undefined,
  isNotificationTypeCountable: (notificationTypeCode: string) => false,
});

export default function UseNotificationContextProvider({
  useNotifications,
  getNotificationType,
  isNotificationTypeCountable,
  children,
}: Props) {
  return (
    <UseNotificationContext.Provider
      value={{
        useNotifications,
        getNotificationType,
        isNotificationTypeCountable,
      }}
    >
      {children}
    </UseNotificationContext.Provider>
  );
}
