import { GridCellProps } from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import uiHelper from "../../helpers/uiHelper";

interface CustomCellProps extends GridCellProps {
  editable: boolean;
  editableInEditMode: boolean;
  editableInNewMode: boolean;
  onCheckStateChanged?: (event: any) => Promise<boolean>;
  onCheckStateChanging?: (event: any) => Promise<boolean>;
  field: string;
  handleOnRowClick?: (e: any) => void;
}

export default function AppBooleanCell({
  editable,
  editableInEditMode,
  editableInNewMode,
  field,
  onCheckStateChanged,
  onCheckStateChanging,
  dataItem,
  onChange,
  rowType,
  handleOnRowClick,
  ...rest
}: CustomCellProps) {
  // const [value, setValue] = useState<boolean>(dataItem[field]);
  const handleOnChange = async (e: CheckboxChangeEvent) => {
    if (!dataItem.isNewItem) {
      if (onCheckStateChanging) {
        // const succeed = await onCheckStateChanging({
        await onCheckStateChanging({
          dataIndex: 0,
          dataItem,
          field,
          syntheticEvent: e,
          value: e.value,
        });
        // if (succeed === true) {
        //   setValue(e.value);
        // } else setValue(!e.value);
      }
      if (onCheckStateChanged) {
        const succeed = await onCheckStateChanged({
          dataIndex: 0,
          dataItem,
          field,
          syntheticEvent: e,
          value: e.value,
        });
        // if (succeed === true) {
        //   setValue(e.value);
        // } else setValue(!e.value);
      }
    }
    // else setValue(e.value);
    //setValue(e.value);
  };

  // const handleCLick = async (e: any) => {
  //   if (onChange) {
  //     onChange({
  //       dataIndex: 0,
  //       dataItem: dataItem,
  //       field: field,
  //       // syntheticEvent: e.syntheticEvent,
  //       syntheticEvent: e,
  //       value: e.target.checked,
  //     });
  //   }
  // };

  if (rowType === "groupHeader") {
    return null;
  }
  const canEdit = () => {
    return (
      (dataItem.inEdit && editable && !dataItem.isNewItem) ||
      (editable && !dataItem.isNewItem && !dataItem.inEdit) ||
      (dataItem.isNewItem && editableInNewMode)
    );
  };
  return (
    <td
      tabIndex={!dataItem[field] ? -1 : 0}
      onClick={(e) =>
        handleOnRowClick
          ? handleOnRowClick({
              dataItem,
            })
          : null
      }
    >
      <Checkbox
        disabled={!canEdit()}
        //value={value}
        //onClick={handleCLick}
        defaultChecked={false}
        onChange={handleOnChange}
        checked={uiHelper.getFieldValue(dataItem, field)}
      />
    </td>
  );
}
