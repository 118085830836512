import { useContext } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import services from "../../../api/services";
import strings from "../../../base/strings";
import ServiceModel from "../../../interfaces/services/ServiceModel";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import { pageDefaultSize } from "../../../common/constants/appConstants";

interface ServiceAutoCompleteProps extends MultiColumnComboBoxProps {
  dataList: Array<ServiceModel>;
  searchKey?: string;
  searchCriteria?: Object;
  charNumber?: number;
  loadDataOnOpen?: boolean;
  isFormComponent?: boolean;
}

export default function ServiceAutoComplete({
  dataList,
  searchKey = "ServiceName",
  columns,
  charNumber = 3,
  loadDataOnOpen,
  isFormComponent = false,
  searchCriteria,
  ...rest
}: ServiceAutoCompleteProps) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    {
      field: "SpecializedCode",
      header: translate(strings.CODE),
      width: "100px",
    },
    {
      field: "LocalName",
      header: translate(strings.LOCAL_NAME),
      width: "120px",
    },
    {
      field: "ForeignName",
      header: translate(strings.FOREIGN_NAME),
      width: "120px",
    },
    {
      field: "DefaultPrice",
      header: translate(strings.DEFAULT_PRICE),
      width: "100px",
    },
    {
      field: "Barcode",
      header: translate(strings.BARCODE),
      width: "100px",
    },
    {
      field: "IsMedicine",
      header: translate(strings.IS_MEDICINE),
      width: "75",
    },
    {
      field: "PackSize",
      header: translate(strings.PACK_SIZE),
      width: "100px",
    },
    {
      field: "Potency",
      header: translate(strings.POTENCY),
      width: "100px",
    },
    {
      field: "DoseUnitModel.UnitModel",
      header: translate(strings.DOSE_UNIT),
      width: "100px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return services.getServices({
      [searchKey]: inputString,
      PageSize: pageDefaultSize,
      Page: 0,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<ServiceModel>
      dataList={dataList}
      columns={objectColumns}
      isFormComponent={isFormComponent}
      textFieldDisplay={getNameField()}
      getData={getData}
      loadDataOnOpen={loadDataOnOpen}
      searchCriteria={searchCriteria}
      {...rest}
    />
  );
}
