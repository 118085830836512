import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownButton from "../../common/components/buttons/AppDropDownButton";
import definitions from "../../api/definitions";
import { getDefinitionList } from "../../common/api/searchDefinitionHelper";
import errorHandler from "../../common/utility/errorHandler";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import BusinessObjectModel from "../../common/interfaces/BusinessObjectModel";
import claimsApi from "../../api/claims";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppClaimFormTypeClaimTypeSearchFieldKeys = {
  ClaimFormTypeID: "ClaimFormTypeID",
  ClaimTypeID: "ClaimTypeID",
};

export default function AppClaimFormTypeClaimTypeSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { getNameField, translate, getName } = useContext(LocalizationContext);

  const resetObject = {
    ID: -1,
    LocalName: translate(strings.UNSET_THIS_VALUE),
    ForeignName: translate(strings.UNSET_THIS_VALUE),
  };

  const [claimFormTypeFieldName, setClaimFormTypeFieldName] = useState(
    translate(strings.CLAIM_FORM_TYPE)
  );
  const [claimTypeFieldName, setClaimTypeFieldName] = useState(
    translate(strings.CLAIM_TYPE)
  );

  const [showClaimType, setShowClaimType] = useState(false);

  const [claimFormTypes, setClaimFormTypes] = useState<BusinessObjectModel[]>(
    []
  );
  const [claimTypes, setClaimTypes] = useState<BusinessObjectModel[]>([]);

  const getClaimFormTypes = async () => {
    const claimFormTypeObjectType =
      definitions.CClaimFormTypeDefinition.objectType;
    const claimFormTypeList: BusinessObjectModel[] = await getDefinitionList<
      typeof claimFormTypeObjectType
    >(
      definitions.CClaimFormTypeDefinition.endpoint,
      definitions.CClaimFormTypeDefinition.callType,
      setErrorMessage
    );
    setClaimFormTypes([resetObject, ...claimFormTypeList]);
  };

  useEffect(() => {
    getClaimFormTypes();
    // eslint-disable-next-line
  }, []);

  const getClaimTypes = async (claimFormTypeID: number) => {
    const response = await claimsApi.getClaimTypes({
      ClaimFormTypeID: claimFormTypeID,
    });

    if (response.ok) {
      if (response.data) setClaimTypes([resetObject, ...response.data]);
      else setClaimTypes([resetObject]);
    } else setErrorMessage(errorHandler.getErrorMessage(response));
  };

  const getObjectSearchValue = (dropDownObjectList: any[], value: string) => {
    let returnedValue: string = "";
    dropDownObjectList.filter((object) => {
      if (getName(object) === value) returnedValue = object[searchFieldName];
      return returnedValue;
    });
    return returnedValue;
  };

  const handleClaimFormTypeCLick = async (
    event: DropDownButtonItemClickEvent
  ) => {
    const value = event.item;
    if (!value) setShowClaimType(false);
    setClaimTypeFieldName(translate(strings.CLAIM_TYPE));
    if (getName(event.item) === getName(resetObject)) {
      removeSearchFieldValue(
        AppClaimFormTypeClaimTypeSearchFieldKeys.ClaimFormTypeID
      );
      setClaimFormTypeFieldName(translate(strings.CLAIM_FORM_TYPE));
      setShowClaimType(false);
      onClaimTypeChanged(resetObject);
    } else {
      getClaimTypes(event.item.ID);
      setClaimFormTypeFieldName(
        translate(strings.CLAIM_FORM_TYPE) + ": " + getName(event.item)
      );
      setShowClaimType(true);
      const searchValue: string = getObjectSearchValue(
        claimFormTypes,
        getName(value)
      );
      onClaimTypeChanged(resetObject);
      setSearchFieldValues(
        AppClaimFormTypeClaimTypeSearchFieldKeys.ClaimFormTypeID,
        searchValue
      );
    }
  };

  const onClaimTypeChanged = (claimType: BusinessObjectModel) => {
    if (!claimType) setShowClaimType(false);
    if (getName(claimType) === getName(resetObject)) {
      removeSearchFieldValue(
        AppClaimFormTypeClaimTypeSearchFieldKeys.ClaimTypeID
      );
      setClaimTypeFieldName(translate(strings.CLAIM_TYPE));
    } else {
      setClaimTypeFieldName(
        translate(strings.CLAIM_TYPE) + ": " + getName(claimType)
      );
      const searchValue: string = getObjectSearchValue(
        claimTypes,
        getName(claimType)
      );
      setSearchFieldValues(
        AppClaimFormTypeClaimTypeSearchFieldKeys.ClaimTypeID,
        searchValue
      );
    }
  };
  const handleClaimTypeClick = async (event: DropDownButtonItemClickEvent) => {
    const value = event.item;
    onClaimTypeChanged(value);
  };

  return (
    <>
      <AppDropDownButton
        className="search-component"
        text={claimFormTypeFieldName}
        icon="k-icon k-font-icon k-i-arrow-chevron-down"
        items={claimFormTypes}
        textField={getNameField()}
        popupSettings={{ popupClass: "popup" }}
        themeColor={"primary"}
        onItemClick={handleClaimFormTypeCLick}
      />
      {showClaimType && (
        <AppDropDownButton
          className="search-component"
          text={claimTypeFieldName}
          icon="k-icon k-font-icon k-i-arrow-chevron-down"
          items={claimTypes}
          textField={getNameField()}
          popupSettings={{ popupClass: "popup" }}
          themeColor={"primary"}
          onItemClick={handleClaimTypeClick}
        />
      )}
    </>
  );
}
