import { useContext } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import InsuranceCompanyModel from "../../../interfaces/insuranceCompany/InsuranceCompanyModel";
import insuranceCompanies from "../../../api/insuranceCompanies";

interface InsuranceCompanyAutoCompleteProps extends MultiColumnComboBoxProps {
  dataList?: Array<InsuranceCompanyModel>;
  searchCriteria?: Object;
  charNumber?: number;
  loadDataOnOpen?: boolean;
  isFormComponent?: boolean;
}

export default function InsuranceCompanyAutoComplete({
  dataList,
  columns,
  charNumber = 3,
  loadDataOnOpen,
  isFormComponent,
  ...rest
}: InsuranceCompanyAutoCompleteProps) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    { field: getNameField(), header: translate(strings.Name), width: "300px" },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return insuranceCompanies.getInsuranceCompanies({
      InsuranceCompanyName: inputString,
      //PageSize: 10,
      //Page: 0,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<InsuranceCompanyModel>
      dataList={dataList || []}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      loadDataOnOpen={loadDataOnOpen}
      charNumber={charNumber}
      isFormComponent={isFormComponent}
      {...rest}
    />
  );
}
