import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.OFFICIAL_LETTERS;
const route_authorized_signatory_classes = "/authorized-signatory-classes";
const route_authorized_signatory_class_signatories =
  "/authorized-signatory-class-signatories";

const getAuthorizedSignatoryClasses = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_authorized_signatory_classes,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getAuthorizedSignatoryClassSignatories = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_authorized_signatory_class_signatories,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const officialLettersApi = {
  getAuthorizedSignatoryClasses,
  getAuthorizedSignatoryClassSignatories,
};

export default officialLettersApi;
