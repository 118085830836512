import { useState, useEffect, useRef, useContext } from "react";
import AppButton from "../buttons/AppButton";
import { LocalizationContext } from "../../context/localizationContext";
import AppStackLayout from "../stack/AppStackLayout";
import AppInput from "./AppInput";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import baseStrings from "../../base/baseStrings";
import styleConstants from "../../constants/styleConstants";

interface Props {
  captchaValue: { captchaText: string; userInput: string };
  onChange?: (
    e: InputChangeEvent,
    captchaValue: { captchaText: string; userInput: string }
  ) => void;
}

export default function AppCaptcha({ captchaValue, onChange, ...rest }: Props) {
  const { translate } = useContext(LocalizationContext);

  const [captchaInputValue, setCaptchaInputValue] = useState<{
    captchaText: string;
    userInput: string;
  }>(captchaValue);

  const canvasRef = useRef(null);

  const initializeCaptcha = (ctx: CanvasRenderingContext2D) => {
    const newCaptcha = generateCaptchaText();
    setCaptchaInputValue({ captchaText: newCaptcha, userInput: "" });
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  useEffect(() => {
    const canvas: any = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    initializeCaptcha(ctx);
  }, []);

  const generateRandomChar = (min: number, max: number) =>
    String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min));

  const generateCaptchaText = () => {
    let captcha = "";
    for (let i = 0; i < 3; i++) {
      captcha += generateRandomChar(65, 90);
      captcha += generateRandomChar(97, 122);
      captcha += generateRandomChar(48, 57);
    }
    return captcha
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
  };

  const drawCaptchaOnCanvas = (
    ctx: CanvasRenderingContext2D,
    captcha: string
  ) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ["rgb(0,0,0)", "rgb(130,130,130)"];
    const letterSpace = 150 / captcha.length;
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 25;
      ctx.font = "20px Roboto Mono";
      ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,

        // Randomize Y position slightly
        Math.floor(Math.random() * 16 + 25),
        100
      );
    }
  };

  const handleUserInputChange = (e: InputChangeEvent) => {
    setCaptchaInputValue({ ...captchaInputValue, userInput: e.value });

    if (onChange) onChange(e, { ...captchaInputValue, userInput: e.value });
  };

  return (
    <div className="captcha">
      <AppStackLayout
        orientation="vertical"
        className="wrapper"
        gap={styleConstants.GAP_LARGER}
      >
        <AppStackLayout
          gap={styleConstants.GAP_MEDIUM}
          align={{ horizontal: "center", vertical: "middle" }}
        >
          <canvas ref={canvasRef} width="200" height="50"></canvas>
          <div className="k-form-buttons" style={{ marginTop: 0 }}>
            <AppButton
              type="button"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base primary-button"
              onClick={() => {
                const canvas: any = canvasRef.current;
                const ctx = canvas?.getContext("2d");
                initializeCaptcha(ctx);
              }}
            >
              {translate(baseStrings.RESET)}
            </AppButton>
          </div>
        </AppStackLayout>
        <AppInput
          placeholder={translate(baseStrings.ENTER_THE_TEXT_IN_THE_IMAGE)}
          value={captchaInputValue.userInput}
          onChange={handleUserInputChange}
          {...rest}
        />
      </AppStackLayout>
    </div>
  );
}
