import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const users = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    {
      field: "Fullname",
      header: strings.NAME,
      width: "200px",
    },
  ],
  autoCompleteEndpoint: endpoints.USERS + "/users",
  autoCompleteSearchKey: "FirstName",
};

export default users;
