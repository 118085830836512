import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import EmployeeModel from "../../../interfaces/employee/EmployeeModel";
import employeesApi from "../../../api/employees";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<EmployeeModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
  searchCriteria?: Object;
}
export default function EmployeeAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  searchCriteria,
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "70px" },
    {
      field: getNameField(),
      header: translate(strings.NAME),
      width: "100px",
    },
    {
      field: "PhoneNumber",
      header: translate(strings.PHONE_NUMBER),
      width: "70px",
    },
    {
      field: "MobileNumber",
      header: translate(strings.MOBILE),
      width: "70px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return employeesApi.getEmployees({
      EmployeeName: inputString,
      Page: 0,
      PageSize: pageDefaultSize,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<EmployeeModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      searchCriteria={searchCriteria}
      {...rest}
    />
  );
}
