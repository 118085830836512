import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.CHECKUP;
const act_checkup_agreements = "/checkup-agreements";
const act_checkup_invoices = "/checkup-invoices";

const getCheckupAgreements = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_checkup_agreements,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getCheckupInvoices = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_checkup_invoices,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const checkupApi = {
  getCheckupAgreements,
  getCheckupInvoices,
};

export default checkupApi;
