import { Dispatch, SetStateAction, useContext, useState } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownSearchField from "../../common/components/search/AppDropDownSearchField";
import InsuredPersonInformationAutoComplete from "../../controls/insuredPersonInformations/InsuredPersonInformationAutoComplete";
import ProviderAutoComplete from "../../controls/providers/base/ProviderAutoComplete";
import ClientAutoComplete from "../../controls/clients/base/ClientAutoComplete";
import InsuranceCompanyAutoComplete from "../../controls/insuranceCompanies/base/InsuranceCompanyAutoComplete";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppStackholderSearchFieldKeys = {
  StakeholderTypeID: "StakeholderTypeID",
  StakeholderID: "StakeholderID",
};
export default function AppStackholderSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const [stakeHolderType, setStakeHolderType] = useState<number>();

  const EStackholderTypes = {
    INSURED: 1,
    PROVIDER: 2,
    CLIENT: 3,
    INSURANCE_COMPANY: 4,
    TPA_COMPANY: 5,
  };

  const handleStackholderTypeChange = async (event: any, searchValue: any) => {
    removeSearchFieldValue(AppStackholderSearchFieldKeys.StakeholderID);
    if (event.itemIndex !== 0) {
      setStakeHolderType(event.itemIndex);
    } else {
      setStakeHolderType(0);
    }
  };

  const renderAutoComplete = (stakeHolderType?: number) => {
    switch (stakeHolderType) {
      case EStackholderTypes.INSURED:
        return (
          <InsuredPersonInformationAutoComplete
            columns={[]}
            textField={getNameField()}
            loadDataOnOpen
            searchKey="Name"
            charNumber={3}
            placeholder={translate(strings.INSURED)}
            className="search-component"
            onChange={(event) =>
              setSearchFieldValues(
                AppStackholderSearchFieldKeys.StakeholderID,
                event.value.ID
              )
            }
          />
        );
      case EStackholderTypes.PROVIDER:
        return (
          <ProviderAutoComplete
            columns={[]}
            textField={getNameField()}
            loadDataOnOpen
            charNumber={3}
            placeholder={translate(strings.PROVIDER)}
            className="search-component"
            onChange={(event) =>
              setSearchFieldValues(
                AppStackholderSearchFieldKeys.StakeholderID,
                event.value.ID
              )
            }
          />
        );
      case EStackholderTypes.CLIENT:
        return (
          <ClientAutoComplete
            columns={[]}
            textField={getNameField()}
            loadDataOnOpen
            placeholder={translate(strings.CLIENT)}
            dataList={[]}
            className="search-component"
            onChange={(event) =>
              setSearchFieldValues(
                AppStackholderSearchFieldKeys.StakeholderID,
                event.value.ID
              )
            }
          />
        );
      case EStackholderTypes.INSURANCE_COMPANY:
        return (
          <InsuranceCompanyAutoComplete
            columns={[]}
            textField={getNameField()}
            loadDataOnOpen
            placeholder={translate(strings.INSURANCE_COMPANY)}
            dataList={[]}
            onChange={(event) =>
              setSearchFieldValues(
                AppStackholderSearchFieldKeys.StakeholderID,
                event.value.ID
              )
            }
          />
        );
      case EStackholderTypes.TPA_COMPANY:
        return (
          <AppDropDownSearchField
            definitionType={"CTPACompanyDefinition"}
            setErrorMessage={setErrorMessage}
            removeSearchFieldValue={removeSearchFieldValue}
            setSearchFieldValues={setSearchFieldValues}
            criteriaTitle={AppStackholderSearchFieldKeys.StakeholderID}
            defaultShow={true}
            title={translate(strings.TPA_COMPANY)}
            searchFieldName={searchFieldName}
          />
        );
      default:
        break;
    }
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AppDropDownSearchField
        definitionType={"CStakeholderTypeDefinition"}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={AppStackholderSearchFieldKeys.StakeholderTypeID}
        defaultShow={true}
        title={translate(strings.STAKEHOLDER_TYPE)}
        searchFieldName={searchFieldName}
        onChange={handleStackholderTypeChange}
      />
      {renderAutoComplete(stakeHolderType)}
    </div>
  );
}
