import { useContext } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import AppButton from "../buttons/AppButton";
import { LocalizationContext } from "../../context/localizationContext";
import uiHelper from "../../helpers/uiHelper";
import AppDropDownButton from "../buttons/AppDropDownButton";
import icons from "../../constants/icons";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";

interface AppDropDownMenuCellProps extends GridCellProps {
  handleOnRowClick?: (e: any) => void;
  onClick?: (e: any) => void;
  tooltipTitle?: string;
  tooltipField?: string;
  menu?: {
    getMenuItems: (dataItem: any) => any[];
    handleMenuItemClick: (event: DropDownButtonItemClickEvent) => void;
  };
}
export default function AppDropDownMenuCell({
  field,
  dataItem,
  handleOnRowClick,
  onClick,
  tooltipTitle,
  style,
  className,
  tooltipField,
  menu,
  ...rest
}: AppDropDownMenuCellProps) {
  const { translate } = useContext(LocalizationContext);
  const handleClick = (e: any) => {
    if (handleOnRowClick) handleOnRowClick({ dataItem });
  };

  const renderTooltip = () => {
    let returnValue = "";
    if (tooltipTitle) returnValue = translate(tooltipTitle);
    if (tooltipField)
      // returnValue = dataItem[tooltipField];
      returnValue = uiHelper.getFieldValue(dataItem, tooltipField);
    return returnValue;
  };
  return (
    <td
      role={"gridcell"}
      // tabIndex={0}
      onClick={handleClick}
      title={renderTooltip()}
      style={style}
      className={className}
    >
      <AppDropDownButton
        className="drop-down-icon"
        key="toolbarMenu"
        icon={icons.MORE}
        onItemClick={menu?.handleMenuItemClick}
        items={menu?.getMenuItems(dataItem)}
        textField={"key"}
      />
    </td>
  );
}
