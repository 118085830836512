import { useContext, ReactElement, cloneElement } from "react";
import { DropDownListProps } from "@progress/kendo-react-dropdowns";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Label } from "@progress/kendo-react-labels";
import { LocalizationContext } from "../../context/localizationContext";
import AppDropDownList from "../dropDown/AppDropDownList";
import baseMessages from "../../base/baseMessages";
import AppStackLayout from "../stack/AppStackLayout";

// interface Props {
//   children: any;
//   data: any[];
//   label: string;
// }

type Props = DropDownListProps & FieldRenderProps;

export default function FormDropDownList({
  // The meta props of the Field.
  validationMessage,
  valid,
  visited,
  label,
  // // The input props of the Field.
  // value,
  // onChange,
  // The custom props that you passed to the Field.
  data = [],
  noDataMessage = baseMessages.NO_DATA,
  addDummyItem,
  useObjectBinding,
  defaultItem,
  horizontalLabel = false,
  ...other
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const showValidationMessage: string | false | null =
    visited && validationMessage;

  const listNoDataRender = (element: ReactElement<HTMLDivElement>) => {
    const noData = (
      <h5>
        <span className="noDataMessage">{translate(noDataMessage)}</span>
      </h5>
    );
    return cloneElement(element, { ...element.props }, noData);
  };

  return (
    <FieldWrapper>
      {horizontalLabel ? (
        <>
          <AppStackLayout
            orientation="horizontal"
            style={{ alignItems: "baseline" }}
            switchOnMobile
          >
            {label ? <Label>{translate(label)}</Label> : null}
            {
              <AppDropDownList
                // label={translate(label)}
                data={data}
                addDummyItem={addDummyItem}
                listNoDataRender={listNoDataRender}
                defaultItem={defaultItem}
                objectBinding={useObjectBinding}
                transparentMode={true}
                {...other}
              />
            }
          </AppStackLayout>
        </>
      ) : (
        <>
          {label ? <Label>{translate(label)}</Label> : null}
          {
            <AppDropDownList
              // label={translate(label)}
              data={data}
              addDummyItem={addDummyItem}
              listNoDataRender={listNoDataRender}
              defaultItem={defaultItem}
              objectBinding={useObjectBinding}
              transparentMode={true}
              {...other}
            />
          }
        </>
      )}

      {showValidationMessage && <Error>{translate(validationMessage)}</Error>}
    </FieldWrapper>
  );
}
