import {
  JSXElementConstructor,
  cloneElement,
  useContext,
  createRef,
  useState,
  useEffect,
  ReactElement,
} from "react";
import {
  ComboBoxChangeEvent,
  ListItemProps,
  MultiColumnComboBox,
  MultiColumnComboBoxHandle,
  MultiColumnComboBoxProps,
} from "@progress/kendo-react-dropdowns";
import useBreakpoint from "use-breakpoint";
import { Breakpoints } from "../../constants/breakpoints";
import { LocalizationContext } from "../../context/localizationContext";
import LoadingPanel from "../general/LoadingPanel";
import baseMessages from "../../base/baseMessages";
import breakpointKeys from "../../constants/breakpointKeys";
import uiHelper from "../../helpers/uiHelper";

interface Props extends MultiColumnComboBoxProps {
  dataList: any[];
  columns: any[];
  noDataMessage?: string;
  loadingData?: boolean;
  setByDefaultIfOnlyOneItem?: boolean;
}
export default function AppMultiColumnComboBox({
  dataList,
  columns,
  textField,
  placeholder = "",
  // data,
  value,
  onChange,
  loadingData,
  noDataMessage = baseMessages.NO_DATA,
  setByDefaultIfOnlyOneItem = true,
  ...rest
}: Props) {
  const ref = createRef<MultiColumnComboBoxHandle>();
  const { getName, translate, isRTL } = useContext(LocalizationContext);
  const { breakpoint } = useBreakpoint(Breakpoints, breakpointKeys.DESKTOP);
  const [autoCompleteValue, setAutoCompleteValue] = useState<any>(value);

  const itemRender = (
    li: React.ReactElement<HTMLLIElement, string | JSXElementConstructor<any>>,
    itemProps: ListItemProps
  ) => {
    const children = columns.map((col, i) => {
      return (
        <span
          className="k-table-td"
          style={{
            width: col.width,
          }}
          key={col.width + i}
        >
          {/* {itemProps.dataItem[col.field] instanceof Object
            ? getName(itemProps.dataItem[col.field])
              ? getName(itemProps.dataItem[col.field])
              : itemProps.dataItem[col.field].ID
            : itemProps.dataItem[col.field]} */}
          {uiHelper.getFieldValue(itemProps.dataItem, col.field) instanceof
          Object
            ? getName(uiHelper.getFieldValue(itemProps.dataItem, col.field))
              ? getName(uiHelper.getFieldValue(itemProps.dataItem, col.field))
              : uiHelper.getFieldValue(itemProps.dataItem, col.field).ID
            : uiHelper.getFieldValue(itemProps.dataItem, col.field)}
        </span>
      );
    });
    return cloneElement(li, { ...li.props }, children);
  };

  const listNoDataRender = (element: ReactElement<HTMLDivElement>) => {
    const noData = loadingData ? (
      <div>
        <LoadingPanel />
      </div>
    ) : (
      <span className="noDataMessage">{translate(noDataMessage)}</span>
    );
    return cloneElement(element, { ...element.props }, noData);
  };

  const handleChange = (e: ComboBoxChangeEvent) => {
    if (onChange) onChange(e);
  };

  useEffect(() => {
    let event: any;
    // let event: ComboBoxChangeEvent;
    if (ref.current && onChange && dataList && setByDefaultIfOnlyOneItem) {
      event = { value: dataList[0], target: ref.current };

      if (dataList.length === 1) {
        onChange(event);
        // setAutoCompleteValue(dataList[0]);
      }
    }
    // eslint-disable-next-line
  }, [dataList]);

  useEffect(() => {
    setAutoCompleteValue(value);
  }, [value]);

  return (
    <MultiColumnComboBox
      ref={ref}
      popupSettings={{
        width: breakpoint === breakpointKeys.MOBILE ? "300px" : "700px",
        className: " app-multiColumnComboBox",
      }}
      style={isRTL ? { direction: "rtl" } : { direction: "ltr" }}
      filterable={true}
      data={dataList || []}
      // defaultValue={autoCompleteValue}
      value={autoCompleteValue}
      onChange={handleChange}
      textField={textField}
      placeholder={placeholder}
      columns={columns}
      itemRender={itemRender}
      size="small"
      rounded="full"
      fillMode="outline"
      listNoDataRender={listNoDataRender}
      {...rest}
    />
  );
}
