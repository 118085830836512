import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const packs = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    { field: "PackName", header: strings.NAME, width: "120px" },
    {
      field: "PackStatus",
      header: strings.STATUS,
      width: "120px",
    },
  ],
  autoCompleteEndpoint: endpoints.PACKS + "/packs",
  autoCompleteSearchKey: "PackName",
};

export default packs;
