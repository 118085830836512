import workflowStorageKeys from "../enums/workflow/workflowStorageKeys";

const storeMailboxItemsCount = (newCount: string) => {
  localStorage.setItem(
    workflowStorageKeys.PREFIX + workflowStorageKeys.MAILBOX_ITEMS_COUNT_KEY,
    newCount
  );
};

const getMailboxItemsCount = () => {
  const newCount = localStorage.getItem(
    workflowStorageKeys.PREFIX + workflowStorageKeys.MAILBOX_ITEMS_COUNT_KEY
  );
  return newCount || "0";
};

const removeMailboxItemsCount = () => {
  localStorage.removeItem(
    workflowStorageKeys.PREFIX + workflowStorageKeys.MAILBOX_ITEMS_COUNT_KEY
  );
};

const storeNotificationsCount = (newCount: string) => {
  localStorage.setItem(
    workflowStorageKeys.PREFIX + workflowStorageKeys.NOTIFICATIONS_COUNT_KEY,
    newCount
  );
};

const getNotificationsCount = () => {
  const newCount = localStorage.getItem(
    workflowStorageKeys.PREFIX + workflowStorageKeys.NOTIFICATIONS_COUNT_KEY
  );
  return newCount || "0";
};

const removeNotificationsCount = () => {
  localStorage.removeItem(
    workflowStorageKeys.PREFIX + workflowStorageKeys.NOTIFICATIONS_COUNT_KEY
  );
};

const workflowStorage = {
  storeMailboxItemsCount,
  getMailboxItemsCount,
  removeMailboxItemsCount,
  storeNotificationsCount,
  getNotificationsCount,
  removeNotificationsCount,
};

export default workflowStorage;
