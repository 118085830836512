import { Dispatch, SetStateAction, useContext, useState } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownSearchField from "../../common/components/search/AppDropDownSearchField";
import ProviderAutoComplete from "../../controls/providers/base/ProviderAutoComplete";
import BrokerAutocomplete from "../../controls/brokers/base/BrokerAutocomplete";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppVendorEntitySearchFieldKeys = {
  EntityType: "EntityType",
  EntityID: "EntityID",
};
export default function AppVendorEntitySearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "Code",
  setErrorMessage,
}: Props) {
  const { translate, translateIn } = useContext(LocalizationContext);

  const [entityType, setEntityType] = useState<string>();

  const EEntities = {
    PROVIDER: translate(strings.PROVIDER),
    BROKER: translate(strings.BROKER),
    TPA_COMPANY: translate(strings.TPA_COMPANY),
  };

  const getEntityTypes = async () => {
    const entityTypes = [
      {
        ID: 1,
        LocalName: translateIn(strings.PROVIDER, "ar"),
        ForeignName: translateIn(strings.PROVIDER, "en"),
        Code: "PROVIDER",
      },
      {
        ID: 2,
        LocalName: translateIn(strings.BROKER, "ar"),
        ForeignName: translateIn(strings.BROKER, "en"),
        Code: "BROKER",
      },
      {
        ID: 3,
        LocalName: translateIn(strings.TPA_COMPANY, "ar"),
        ForeignName: translateIn(strings.TPA_COMPANY, "en"),
        Code: "TPA_COMPANY",
      },
    ];

    return entityTypes;
  };

  const handleEntityTypeChange = async (
    event: DropDownButtonItemClickEvent,
    searchValue: string
  ) => {
    removeSearchFieldValue(AppVendorEntitySearchFieldKeys.EntityID);
    if (event.itemIndex !== 0) {
      setEntityType(event.item);
    } else {
      setEntityType("");
    }
  };

  const renderAutoComplete = (entityType?: string) => {
    switch (entityType) {
      case EEntities.PROVIDER:
        return (
          <ProviderAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PROVIDER)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppVendorEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(AppVendorEntitySearchFieldKeys.EntityID);
            }}
          />
        );
      case EEntities.BROKER:
        return (
          <BrokerAutocomplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.BROKER)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppVendorEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(AppVendorEntitySearchFieldKeys.EntityID);
            }}
          />
        );
      case EEntities.TPA_COMPANY:
        return (
          <AppDropDownSearchField
            definitionType="CTPACompanyDefinition"
            setErrorMessage={setErrorMessage}
            removeSearchFieldValue={removeSearchFieldValue}
            setSearchFieldValues={setSearchFieldValues}
            criteriaTitle={AppVendorEntitySearchFieldKeys.EntityID}
            defaultShow={true}
            title={translate(strings.TPA_COMPANY)}
            searchFieldName={"ID"}
          />
        );
      default:
        break;
    }
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AppDropDownSearchField
        definitionType={""}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={AppVendorEntitySearchFieldKeys.EntityType}
        defaultShow={false}
        title={translate(strings.ENTITY)}
        searchFieldName={searchFieldName}
        onChange={handleEntityTypeChange}
        useCustomBinding
        getCustomList={getEntityTypes}
      />
      {renderAutoComplete(entityType)}
    </div>
  );
}
