import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.POLICIES;
const route_policy_insured_person = "/policy-insured-persons";
const route_policy_insured_benefits = "/policy-insured-benefits";
const route_policy_insured_card = "/policy-insured-cards";
//const route_download_insured_addition_report = "/download-insured-addition-report";
const route_download_production_report = "/download-production-report";
const route_download_active_insured_report = "/download-active-insured-report";
const route_download_policy_active_insured_system_report =
  "/download-policy-active-insured-system-report";
const route_download_affected_insured_report =
  "/download-affected-insured-report";
const route_download_policy_schedule_accounting_report =
  "/download-policy-schedule-accounting-report";
const route_insured_benefit_limits = "/insured-benefit-limits";

// const route_download_policy_schedule_client_report =
//   "/download-policy-schedule-client-report";

const route_download_active_insured_client_report =
  "/download-active-insured-client-report";
const route_policy_amount_distribution_figures =
  "/policy-amount-distribution-figures";
const route_premium_and_claims_distribution =
  "/premium-and-claims-distribution";
const route_download_portfolio_loss_ratio_report =
  "/download-portfolio-loss-ratio-report";
const route_download_policy_loss_ratio_report =
  "/download-policy-loss-ratio-report";

const route_download_policy_loss_ratio_insured_level_report =
  "/download-policy-loss-ratio-insured-level-report";

const route_download_upcoming_renewal_policies_report =
  "/download-upcoming-renewal-policies-report";

const route_download_Insured_Aggregate_Premium_report =
  "/download-Insured-Aggregate-Premium-report";
const route_download_revision_details_report =
  "/download-revision-details-report";

const route_policy_brokers = "/policy-brokers";
const route_policy_premium_funders = "/policy-premium-funders";
const route_endorsement_types = "/endorsement-types";
const route_insured_policy_coverages = "/insured-policy-coverages";
const route_exclusions = "/exclusions";
const route_policy_administration_cost_installment_rules =
  "/policy-administration-cost-installment-rules";
const route_policy_administration_cost_installments =
  "/policy-administration-cost-installments";
const route_policy_broker_commission_installments =
  "/policy-broker-commission-installments";
const route_policy_premium_funder_installments =
  "/policy-premium-funder-installments";

const route_policy_installment_rules = "/policy-installment-rules";
const route_policy_installments = "/policy-installments";
const route_policy_coverages = "/policy-coverages";
const route_policy_product_coverages = "/policy-product-coverages";
const route_policy_benefit_coverages = "/policy-benefit-coverages";
const route_policy_exclusions = "/policy-exclusions";
const route_policy_headers = "/policy-headers";

const route_policy_charges = "/policy-charges";
const route_policy_taxes = "/policy-taxes";
const route_policy_tax_details = "/policy-tax-details";
const route_policy_tax_endorsement_types = "/policy-tax-endorsement-types";
const route_policy_features = "/policy-features";
const route_policy_feature_details = "/policy-feature-details";
const route_policy_tpa_rules = "/policy-tpa-rules";
const route_policy_tpa_premium_shares = "/policy-tpa-premium-shares";
const route_insured_cancellation_workspaces =
  "/insured-cancellation-workspaces";
const route_workspace_summary = "/workspace-summary";
const route_insured_to_cancel_field_values = "/insured-to-cancel-field-values";
const route_insured_transfer_workspaces = "/insured-transfer-workspaces";
const route_insured_to_transfer_field_values =
  "/insured-to-transfer-field-values";
const route_policy_renewals = "/policy-renewals";
const route_policy_insured_benefit_features =
  "/policy-insured-benefit-features";

const route_policy_insured_exclusions = "/policy-insured-exclusions";
const route_card_issuance_workspaces = "/card-issuance-workspaces";

const route_compact_policy_insured_person_dependents =
  "/compact-policy-insured-person-dependents";

const route_card_types = "/card-types";
const route_card_type_prices = "/card-type-prices";
const route_policy_classifications = "/policy-classifications";

const act_generate_recalculate_policy_task =
  "/generate-recalculate-policy-task";

const act_detach_task = "/detach-task";

const act_create_policy = "/create-policy";

const act_update_policy_insured_age_configuration =
  "/update-policy-insured-age-configuration";
const act_update_alias_policy_number = "/alias-policy-number";
const act_set_approval_alert = "/set-approval-alert-level";
const act_remove_approval_alert = "/remove-approval-alert-level";
const act_set_policy_classification = "/set-policy-classification";
const act_remove_policy_classification = "/remove-policy-classification";
const act_update_endorsement_info = "/update-endorsement-info";
const act_change_policy_dates = "/change-policy-dates";
const act_change_policy_plan = "/change-policy-plan";
const act_create_branch_transfer_endorsement =
  "/create-branch-transfer-endorsement";
const act_create_client_share_update_endorsement =
  "/create-client-share-update-endorsement";
const act_deactivate_policy = "/deactivate-policy";
const act_checkin = "/checkin";
const act_checkout = "/checkout";
const act_set_suspend_approvals = "/set-suspend-approvals";
const act_set_dispense_chronic_form_to_policy_end_date_only =
  "/set-dispense-chronic-form-to-policy-end-date-only";
const act_set_deny_automatic_approvals = "/set-deny-automatic-approvals";
const act_subtract_admin_cost_taxes = "/subtract-admin-cost-taxes";
const act_set_requires_approval_rejection_notification =
  "/set-requires-approval-rejection-notification";
const act_create_policy_activation_task = "/create-policy-activation-task";
const act_create_recalculate_policy_task = "/create-recalculate-policy-task";
const act_create_extend_policy_task = "/create-extend-policy-task";
const act_create_cancel_policy_task = "/create-cancel-policy-task";

const route_download_portfolio_loss_ratio_summary_report =
  "/download-portfolio-loss-ratio-summary-report";

const act_create_endorsement = "/create-endorsement";
const act_create_delete_policy_task = "/create-delete-policy-task";
const act_update_policy_charge = "/update-policy-charge";
const act_reinstate_policy_charge = "/reinstate-policy-charge";
const act_cancel_policy_charge = "/cancel-policy-charge";

const act_update_policy_tax = "/update-policy-tax";
const act_cancel_policy_tax = "/cancel-policy-tax";
const act_reinstate_policy_tax = "/reinstate-policy-tax";

const act_update_policy_feature = "/update-policy-feature";
const act_reinstate_policy_feature = "/reinstate-policy-feature";
const act_cancel_policy_feature = "/cancel-policy-feature";

const act_update_policy_tpa_rule = "/update-policy-tpa-rule";
const act_reinstate_policy_tpa_rule = "/reinstate-policy-tpa-rule";
const act_cancel_policy_tpa_rule = "/cancel-policy-tpa-rule";

const act_reinstate_policy_premium_funder = "/reinstate-policy-premium-funder";
const act_cancel_policy_premium_funder = "/cancel-policy-premium-funder";
const act_revise_policy_coverage = "/revise-policy-coverage";
const act_revise_policy_product_coverage = "/revise-policy-product-coverage";
const act_revise_policy_benefit_coverage = "/revise-policy-benefit-coverage";
const act_cancel_policy_benefit_coverage = "/cancel-policy-benefit-coverage";
const act_update_policy_benefit_coverage = "/update-policy-benefit-coverage";

const act_update_policy_exclusion = "/update-policy-exclusion";
const act_cancel_policy_exclusion = "/cancel-policy-exclusion";
const act_add_to_latest_revision = "/add-to-latest-revision";
const act_remove_from_revision = "/remove-from-revision";
const act_create_print_insured_cards_task = "/create-print-insured-cards-task";
const act_print_cards = "/print-cards";
const act_update_card_holder_name = "/update-card-holder-name";
const act_rollback_print_card = "/rollback-print-card";

const act_add_to_revision = "/add-to-revision";
const act_print_card = "/print-card";
const act_create_add_insured_to_revision_task =
  "/create-add-insured-to-revision-task";

const act_create_copy_and_apply_provision_modifications_task =
  "/create-copy-and-apply-provision-modifications-task";

const act_create_copy_and_sync_benefit_coverage_task =
  "/create-copy-and-sync-benefit-coverage-task";

const act_download_insured_cancellation_workspace_report =
  "/download-insured-cancellation-workspace-report";

const act_import_insured_cancellation_workspace_drafts =
  "/import-insured-cancellation-workspace-drafts";

const act_map_values = "/map-values";

const act_updat_insured_info = "/update-insured-info";

const act_update_insured_premium_related_info =
  "/update-insured-premium-related-info";
const act_update_original_max_client_share =
  "/update-original-max-client-share";
const act_inherit_insured_coverage_from_default =
  "/inherit-insured-coverage-from-default";
const act_set_as_primary = "/set-as-primary";
const act_set_primary_insured_person = "/set-primary-insured-person";
const act_recalculate_insured = "/recalculate-insured";
const act_set_insured_not_covered = "/set-insured-not-covered";
const act_set_insured_follows_default_benefit =
  "/set-insured-follows-default-benefit";
const act_update_insured_coverage_plan = "/update-insured-coverage-plan";
const act_update_insured_coverage_info = "/update-insured-coverage-info";
const act_update_policy_insured_benefit = "/update-policy-insured-benefit";
const act_set_wave_waiting_eriod = "/set-wave-waiting-period";
const act_generate_new_card = "/generate-new-card";
const act_check_duplicates = "/check-duplicates";
const act_resolve_duplicate = "/resolve-duplicate";
const act_validate_insured_to_cancel = "/validate-insureds-to-cancel";
const act_commit = "/commit";

const act_download_insured_transfer_workspace_report =
  "/download-insured-transfer-workspace-report";

const act_import_insured_transfer_workspace_drafts =
  "/import-insured-transfer-workspace-drafts";

const act_validate_insured_to_transfer = "/validate-insureds-to-transfer";

const act_check_claims_exceeding_date = "/claims-exceeding-date";
const act_force_checkin = "/force-checkin";
const act_import_card_issuance_workspace_drafts =
  "/import-card-issuance-workspace-drafts";
const act_download_card_issuance_workspace_report =
  "/download-card-issuance-workspace-report";
const act_validate_cards_to_issue = "/validate-cards-to-issue";

const act_issue_policy_accounting = "/issue-policy-accounting";
const act_unissue_policy_accounting = "/unissue-policy-accounting";

const act_resync_installemts = "/resync-installemts";
const act_resync_administration_cost_installemts =
  "/resync-administration-cost-installemts";
const act_resync_broker_installemts = "/resync-broker-installemts";
const act_create_renew_policy_task = "/create-renew-policy-task";

const act_download_policy_added_insured_system_report =
  "/download-policy-added-insured-system-report";
const act_download_policy_removed_insured_report =
  "/download-policy-removed-insured-report";
const act_download_policy_active_insured_contextual_report =
  "/download-policy-active-insured-contextual-report";
const act_download_policy_schedule_policyholder_report =
  "/download-policy-schedule-policy-holder-report";
const act_download_policy_schedule_broker_report =
  "/download-policy-schedule-broker-report";
const act_download_schedule_of_benefits_report =
  "/download-schedule-of-benefits-report";
const act_download_payment_schedule_report =
  "/download-payment-schedule-report";
const act_download_policy_administration_cost_installments_report =
  "/download-policy-administration-cost-installments-report";
const act_download_policy_broker_commission_installments_report =
  "/download-policy-broker-commission-installments-report";
const act_download_policy_installmenst_report =
  "/download-policy-installmenst-report";
const act_download_policy_modified_insured_report =
  "/download-policy-modified-insured-report";
const act_download_policy_revision_affected_insured_report =
  "/download-policy-revision-affected-insured-report";
const act_download_policy_affected_insured_system_report =
  "/download-policy-affected-insured-system-report";
const act_download_policy_endorsement_list_report =
  "/download-policy-endorsement-list-report";
const act_download_policy_holders_report = "/download-policy-holders-report";
const act_download_policy_holder_contacts_report =
  "/download-policy-holder-contacts-report";
const act_download_policy_list_report = "/download-policy-list-report";
const act_download_insured_missing_pictures_report =
  "/download-insured-missing-pictures-report";
const act_download_insured_bank_accounts_report =
  "/download-insured-bank-accounts-report";
const act_download_benefit_summary_report = "/download-benefit-summary-report";
const act_download_vezeeta_insured_report = "/download-vezeeta-insured-report";
const act_download_upcoming_renewal_policies_report =
  "/download-upcoming-renewal-policies-report";
const act_download_customized_policy_affected_insured_report =
  "/download-customized-policy-affected-insured-report";
const act_download_policy_administration_cost_report =
  "/download-policy-administration-cost-report";

const act_download_insured_gender_distribution_report =
  "/download-insured-gender-distribution-report";

const act_cancel = "/cancel";
const act_update = "/update";
const act_reinstate = "/reinstate";

const act_create_recalculate_card_amounts_task =
  "/create-recalculate-card-amounts-task";
const act_create_update_card_type_task = "/create-update-card-type-task";
const act_update_policy_reinsurer = "/update-policy-reinsurer";

const act_check_all_values_mapped = "/check-all-values-mapped";

const act_download_issuance_items_details_report =
  "/download-issuance-items-details-report";

const getPolicies = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPolicyHeaders = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_policy_headers,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPolicyInsuredPersons = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_policy_insured_person,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPolicyInsuredPerson = (policyInsuredPersonID) => {
  return apiClient.get(
    endpoint + route_policy_insured_person + "/" + policyInsuredPersonID
  );
};

const getPolicy = (policyID, includePolicyHeader) => {
  return apiClient.get(
    endpoint + "/" + policyID + (includePolicyHeader ? "/true" : "")
  );
};

const getPolicyHeader = (policyHeaderID) => {
  return apiClient.get(endpoint + route_policy_headers + "/" + policyHeaderID);
};

const getPolicyInsuredCards = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_policy_insured_card,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadInsuredAdditionReport = (criteria) => {
  return apiClient.post(
    endpoint +
      //  route_download_insured_addition_report,
      act_download_policy_added_insured_system_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadProductionReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_production_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadActiveInsuredReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_active_insured_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadAffectedInsuredReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_affected_insured_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyScheduleAccountingReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_policy_schedule_accounting_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyScheduleClientReport = (criteria, onDownloadProgress) => {
  return apiClient.post(
    endpoint + act_download_policy_schedule_policyholder_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
      onDownloadProgress: (progress) =>
        onDownloadProgress(
          Math.round((100 * progress.loaded) / progress.total)
        ),
    }
  );
};

const downloadActiveInsuredClientReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_active_insured_client_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const checkPolicyInsuredPersonBenefitLimits = (data) => {
  return apiClient.post(
    endpoint + route_insured_benefit_limits,
    JSON.stringify({
      ...data,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPolicyAmountDistributionFigures = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_policy_amount_distribution_figures,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPremiumAndClaimsDistribution = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_premium_and_claims_distribution,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPortfolioLossRatioReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_portfolio_loss_ratio_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyLossRatioReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_policy_loss_ratio_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyLossRatioInsuredLevelReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_policy_loss_ratio_insured_level_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadUpcomingRenewalPoliciesReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_upcoming_renewal_policies_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadInsuredAggregatePremiumReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_Insured_Aggregate_Premium_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const generateRecalculatePolicyTask = (policyID) => {
  return apiClient.post(
    endpoint + "/" + policyID + act_generate_recalculate_policy_task
  );
};

const detachPolicyTask = (policyHeaderID) => {
  return apiClient.put(endpoint + "/" + policyHeaderID + act_detach_task);
};

const getInsuredCoverages = (criteria) => {
  return apiClient.post(
    endpoint + route_insured_policy_coverages,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getInsuredExclusions = (policyInsuredPersonID) => {
  return apiClient.get(
    endpoint + "/" + policyInsuredPersonID + route_exclusions
  );
};

const downloadPortfolioLossRatioSummaryReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_portfolio_loss_ratio_summary_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPolicyBrokers = (criteria) => {
  return apiClient.post(
    endpoint + route_policy_brokers,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getEndorsementTypes = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_endorsement_types,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const createPolicy = (data) => {
  return apiClient.post(endpoint + act_create_policy, data);
};

const getPolicyAdministrationCostInstallmentRules = (policyID) => {
  return apiClient.get(
    endpoint +
      "/" +
      policyID +
      route_policy_administration_cost_installment_rules
  );
};

const getPolicyAdministrationCostInstallments = (criteria) => {
  return apiClient.post(
    endpoint + route_policy_administration_cost_installments,
    criteria,
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPolicyBrokerCommissionInstallments = (criteria) => {
  return apiClient.post(
    endpoint + route_policy_broker_commission_installments,
    criteria,
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPolicyPremiumFunders = (policyID) => {
  return apiClient.get(
    endpoint + "/" + policyID + route_policy_premium_funders
  );
};

const getPolicyPremiumFunderInstallments = (criteria) => {
  return apiClient.post(
    endpoint + route_policy_premium_funder_installments,
    criteria,
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPolicyInstallmentRules = (policyID) => {
  return apiClient.get(
    endpoint + "/" + policyID + route_policy_installment_rules
  );
};

const getPolicyInstallments = (criteria) => {
  return apiClient.post(endpoint + route_policy_installments, criteria, {
    headers: {
      "X-HTTP-Method-Override": "GET",
    },
  });
};

const getPolicyCoverages = (policyID) => {
  return apiClient.get(endpoint + "/" + policyID + route_policy_coverages);
};

const getPolicyProductCoverages = (policyID) => {
  return apiClient.get(
    endpoint + "/" + policyID + route_policy_product_coverages
  );
};

const getPolicyBenefitCoverages = (policyID) => {
  return apiClient.get(
    endpoint + "/" + policyID + route_policy_benefit_coverages
  );
};

const getPolicyExclusions = (criteria, paging) => {
  return apiClient.post(
    endpoint + route_policy_exclusions,
    JSON.stringify({
      ...criteria,
      ...paging,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const updatePolicyInsuredAgeConfiguration = (policyID, data) => {
  return apiClient.put(
    endpoint + "/" + policyID + act_update_policy_insured_age_configuration,
    data
  );
};

const updateAliasPolicyNumber = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_update_alias_policy_number,
    data
  );
};

const setApprovalAlertLevel = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_set_approval_alert,
    data
  );
};

const removeApprovalAlertLevel = (policyHeaderID) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_remove_approval_alert
  );
};

const setPolicyClassification = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_set_policy_classification,
    data
  );
};

const removePolicyClassification = (policyHeaderID) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_remove_policy_classification
  );
};

const updateEndorsementInfo = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_update_endorsement_info,
    data
  );
};

const changePolicyDates = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_change_policy_dates,
    data
  );
};

const changePolicyPlan = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_change_policy_plan,
    data
  );
};

const createBranchTransferEndorsment = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_create_branch_transfer_endorsement,
    data
  );
};

const createClientShareUpdateEndorsment = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyHeaderID +
      act_create_client_share_update_endorsement,
    data
  );
};

const deactivatePolicy = (policyHeaderID) => {
  return apiClient.put(endpoint + "/" + policyHeaderID + act_deactivate_policy);
};

const checkinPolicy = (policyHeaderID) => {
  return apiClient.put(endpoint + "/" + policyHeaderID + act_checkin);
};

const checkoutPolicy = (policyHeaderID) => {
  return apiClient.put(endpoint + "/" + policyHeaderID + act_checkout);
};

const setSuspendApprovals = (policyHeaderID, suspendApprovals) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyHeaderID +
      act_set_suspend_approvals +
      (suspendApprovals ? "/true" : "")
  );
};

const setPolicyDenyAutomaticApprovals = (
  policyHeaderID,
  denyAutomaticApprovals
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyHeaderID +
      act_set_deny_automatic_approvals +
      (denyAutomaticApprovals ? "/true" : "")
  );
};

const setDispenseChronicFormToPolicyEndDateOnly = (
  policyHeaderID,
  dispenseTillPolicyEndDateOnly
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyHeaderID +
      act_set_dispense_chronic_form_to_policy_end_date_only +
      (dispenseTillPolicyEndDateOnly ? "/true" : "")
  );
};

const subtractAdminCostTaxes = (policyID, subtractTaxes) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      act_subtract_admin_cost_taxes +
      "/" +
      subtractTaxes
  );
};

const createEndorsement = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_create_endorsement,
    data
  );
};

const setRequiresApprovalRejectionNotification = (
  policyID,
  requiresNotification
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      act_set_requires_approval_rejection_notification +
      "/" +
      requiresNotification
  );
};

const createPolicyActivationTask = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_create_policy_activation_task,
    data
  );
};

const createDeletePolicyTask = (policyHeaderID) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_create_delete_policy_task
  );
};

const addPolicyCharges = (policyID, data) => {
  return apiClient.post(endpoint + "/" + policyID + route_policy_charges, data);
};

const removePolicyCharge = (policyID, policyChargeID) => {
  return apiClient.delete(
    endpoint + "/" + policyID + route_policy_charges + "/" + policyChargeID
  );
};

const updatePolicyCharge = (policyID, policyChargeID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_charges +
      "/" +
      policyChargeID +
      act_update_policy_charge,
    data
  );
};

const reinstatePolicyCharge = (policyID, policyChargeID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_charges +
      "/" +
      policyChargeID +
      act_reinstate_policy_charge
  );
};

const cancelPolicyCharge = (policyID, policyChargeID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_charges +
      "/" +
      policyChargeID +
      act_cancel_policy_charge,
    data
  );
};

const addPolicyTax = (policyID, data) => {
  return apiClient.post(endpoint + "/" + policyID + route_policy_taxes, data);
};

const removePolicyTax = (policyID, policyTaxID) => {
  return apiClient.delete(
    endpoint + "/" + policyID + route_policy_taxes + "/" + policyTaxID
  );
};

const updatePolicyTax = (policyID, policyTaxID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_taxes +
      "/" +
      policyTaxID +
      act_update_policy_tax,
    data
  );
};

const cancelPolicyTax = (policyID, policyTaxID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_taxes +
      "/" +
      policyTaxID +
      act_cancel_policy_tax,
    data
  );
};

const reinstatePolicyTax = (policyID, policyTaxID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_taxes +
      "/" +
      policyTaxID +
      act_reinstate_policy_tax
  );
};

const addPolicyTaxDetail = (policyID, policyTaxID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_taxes +
      "/" +
      policyTaxID +
      route_policy_tax_details,
    data
  );
};

const removePolicyTaxDetail = (policyID, policyTaxID, policyTaxDetailID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      policyID +
      route_policy_taxes +
      "/" +
      policyTaxID +
      route_policy_tax_details +
      "/" +
      policyTaxDetailID
  );
};

const updatePolicyTaxDetail = (
  policyID,
  policyTaxID,
  policyTaxDetailID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_taxes +
      "/" +
      policyTaxID +
      route_policy_tax_details +
      "/" +
      policyTaxDetailID,
    data
  );
};

const addPolicyTaxEndorsementTypes = (policyID, policyTaxID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_taxes +
      "/" +
      policyTaxID +
      route_policy_tax_endorsement_types,
    data
  );
};

const removePolicyTaxEndorsementType = (
  policyID,
  policyTaxID,
  policyTaxEndorsementTypeID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      policyID +
      route_policy_taxes +
      "/" +
      policyTaxID +
      route_policy_tax_endorsement_types +
      "/" +
      policyTaxEndorsementTypeID
  );
};

const addPolicyFeatures = (policyID, data) => {
  return apiClient.post(
    endpoint + "/" + policyID + route_policy_features,
    data
  );
};

const removePolicyFeature = (policyID, policyFeatureID) => {
  return apiClient.delete(
    endpoint + "/" + policyID + route_policy_features + "/" + policyFeatureID
  );
};

const updatePolicyFeature = (policyID, policyFeatureID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_features +
      "/" +
      policyFeatureID +
      act_update_policy_feature,
    data
  );
};

const reinstatePolicyFeature = (policyID, policyFeatureID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_features +
      "/" +
      policyFeatureID +
      act_reinstate_policy_feature
  );
};

const cancelPolicyFeature = (policyID, policyFeatureID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_features +
      "/" +
      policyFeatureID +
      act_cancel_policy_feature,
    data
  );
};

const addPolicyFeatureDetail = (policyID, policyFeatureID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_features +
      "/" +
      policyFeatureID +
      route_policy_feature_details,
    data
  );
};

const removePolicyFeatureDetail = (
  policyID,
  policyFeatureID,
  policyFeatureDetailID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      policyID +
      route_policy_features +
      "/" +
      policyFeatureID +
      route_policy_feature_details +
      "/" +
      policyFeatureDetailID
  );
};

const updatePolicyFeatureDetail = (
  policyID,
  policyFeatureID,
  policyFeatureDetailID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_features +
      "/" +
      policyFeatureID +
      route_policy_feature_details +
      "/" +
      policyFeatureDetailID,
    data
  );
};

const addPolicyTPARule = (policyID, data) => {
  return apiClient.post(
    endpoint + "/" + policyID + route_policy_tpa_rules,
    data
  );
};

const removePolicyTPARule = (policyID, policyTPARuleID) => {
  return apiClient.delete(
    endpoint + "/" + policyID + route_policy_tpa_rules + "/" + policyTPARuleID
  );
};

const updatePolicyTPARule = (policyID, policyTPARuleID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_tpa_rules +
      "/" +
      policyTPARuleID +
      act_update_policy_tpa_rule,
    data
  );
};

const reinstatePolicyTPARule = (policyID, policyTPARuleID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_tpa_rules +
      "/" +
      policyTPARuleID +
      act_reinstate_policy_tpa_rule
  );
};

const cancelPolicyTPARule = (policyID, policyTPARuleID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_tpa_rules +
      "/" +
      policyTPARuleID +
      act_cancel_policy_tpa_rule,
    data
  );
};

const addPolicyTPAPremiumShares = (policyID, policyTPARuleID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_tpa_rules +
      "/" +
      policyTPARuleID +
      route_policy_tpa_premium_shares,
    data
  );
};

const removePolicyTPAPremiumShare = (
  policyID,
  policyTPARuleID,
  policyTPAPremiumShareID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      policyID +
      route_policy_tpa_rules +
      "/" +
      policyTPARuleID +
      route_policy_tpa_premium_shares +
      "/" +
      policyTPAPremiumShareID
  );
};

const updatePolicyTPAPremiumShare = (
  policyID,
  policyTPARuleID,
  policyTPAPremiumShareID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_tpa_rules +
      "/" +
      policyTPARuleID +
      route_policy_tpa_premium_shares +
      "/" +
      policyTPAPremiumShareID,
    data
  );
};

const addPolicyAdministrationCostInstallmentRule = (data) => {
  return apiClient.post(
    endpoint + route_policy_administration_cost_installment_rules,
    data
  );
};

const removePolicyAdministrationCostInstallmentRule = (
  policyAdministrationCostInstallmentRuleID
) => {
  return apiClient.delete(
    endpoint +
      route_policy_administration_cost_installment_rules +
      "/" +
      policyAdministrationCostInstallmentRuleID
  );
};

const updatePolicyAdministrationCostInstallmentRule = (
  policyAdministrationCostInstallmentRuleID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_policy_administration_cost_installment_rules +
      "/" +
      policyAdministrationCostInstallmentRuleID,
    data
  );
};

const addPolicyBrokerCommissionInstallment = (policyID, data) => {
  return apiClient.post(
    endpoint + "/" + policyID + route_policy_broker_commission_installments,
    data
  );
};

const removePolicyBrokerCommissionInstallment = (
  policyID,
  policyBrokerCommissionInstallmentID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      policyID +
      route_policy_broker_commission_installments +
      "/" +
      policyBrokerCommissionInstallmentID
  );
};

const updatePolicyBrokerCommissionInstallment = (
  policyID,
  policyBrokerCommissionInstallmentID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_broker_commission_installments +
      "/" +
      policyBrokerCommissionInstallmentID,
    data
  );
};

const addPolicyPremiumFunderInstallment = (policyID, data) => {
  return apiClient.post(
    endpoint + "/" + policyID + route_policy_premium_funder_installments,
    data
  );
};

const deletePolicyPremiumFunderInstallment = (
  policyID,
  policyPremiumFunderInstallmentID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      policyID +
      route_policy_premium_funder_installments +
      "/" +
      policyPremiumFunderInstallmentID
  );
};

const updatePolicyPremiumFunderInstallment = (
  policyID,
  policyPremiumFunderInstallmentID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_premium_funder_installments +
      "/" +
      policyPremiumFunderInstallmentID,
    data
  );
};

const reinstatePolicyPremiumFunder = (policyID, policyPremiumFunderID) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_premium_funders +
      "/" +
      policyPremiumFunderID +
      act_reinstate_policy_premium_funder
  );
};

const cancelPolicyPremiumFunder = (policyID, policyPremiumFunderID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_premium_funders +
      "/" +
      policyPremiumFunderID +
      act_cancel_policy_premium_funder,
    data
  );
};

const addPolicyInstallmentRule = (policyID, data) => {
  return apiClient.post(
    endpoint + "/" + policyID + route_policy_installment_rules,
    data
  );
};

const removePolicyInstallmentRule = (policyID, policyInstallmentRuleID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      policyID +
      route_policy_installment_rules +
      "/" +
      policyInstallmentRuleID
  );
};

const updatePolicyInstallmentRule = (
  policyID,
  policyInstallmentRuleID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_installment_rules +
      "/" +
      policyInstallmentRuleID,
    data
  );
};

const addPolicyCoverage = (policyID, policyHeaderID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_coverages,
    data
  );
};

const deletePolicyCoverage = (policyID, policyHeaderID, policyCoverageID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_coverages +
      "/" +
      policyCoverageID
  );
};

const updatePolicyCoverage = (
  policyID,
  policyHeaderID,
  policyCoverageID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_coverages +
      "/" +
      policyCoverageID,
    data
  );
};

const revisePolicyCoverage = (
  policyID,
  policyHeaderID,
  cancelledPolicyCoverageID,
  data
) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_coverages +
      "/" +
      cancelledPolicyCoverageID +
      act_revise_policy_coverage,
    data
  );
};

const addPolicyProductCoverage = (policyID, policyHeaderID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_product_coverages,
    data
  );
};

const deletePolicyProductCoverage = (
  policyID,
  policyHeaderID,
  policyProductCoverageID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_product_coverages +
      "/" +
      policyProductCoverageID
  );
};

const updatePolicyProductCoverage = (
  policyID,
  policyHeaderID,
  policyProductCoverageID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_product_coverages +
      "/" +
      policyProductCoverageID,
    data
  );
};

const revisePolicyProductCoverage = (
  policyID,
  policyHeaderID,
  cancelledPolicyProductCoverageID,
  data
) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_product_coverages +
      "/" +
      cancelledPolicyProductCoverageID +
      act_revise_policy_product_coverage,
    data
  );
};

const addPolicyBenefitCoverage = (policyID, policyHeaderID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_benefit_coverages,
    data
  );
};

const deletePolicyBenefitCoverage = (
  policyID,
  policyHeaderID,
  policyBenefitCoverageID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_benefit_coverages +
      "/" +
      policyBenefitCoverageID
  );
};

const updatePolicyBenefitCoverage = (
  policyID,
  policyHeaderID,
  policyBenefitCoverageID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_benefit_coverages +
      "/" +
      policyBenefitCoverageID +
      act_update_policy_benefit_coverage,
    data
  );
};

const cancelPolicyBenefitCoverage = (
  policyID,
  policyHeaderID,
  policyBenefitCoverageID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_benefit_coverages +
      "/" +
      policyBenefitCoverageID +
      act_cancel_policy_benefit_coverage
  );
};

const revisePolicyBenefitCoverage = (
  policyID,
  policyHeaderID,
  cancelledPolicyBenefitCoverageID,
  data
) => {
  return apiClient.post(
    endpoint +
      "/" +
      policyID +
      route_policy_headers +
      "/" +
      policyHeaderID +
      route_policy_benefit_coverages +
      "/" +
      cancelledPolicyBenefitCoverageID +
      act_revise_policy_benefit_coverage,
    data
  );
};

const addPolicyExclusion = (policyID, data) => {
  return apiClient.post(
    endpoint + "/" + policyID + route_policy_exclusions,
    data
  );
};

const updatePolicyExclusion = (policyID, policyExclusionID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_exclusions +
      "/" +
      policyExclusionID +
      act_update_policy_exclusion,
    data
  );
};

const cancelPolicyExclusion = (policyID, policyExclusionID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_exclusions +
      "/" +
      policyExclusionID +
      act_cancel_policy_exclusion,
    data
  );
};

const addPolicyExclusionToLatestRevision = (policyID, policyExclusionID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_exclusions +
      "/" +
      policyExclusionID +
      act_add_to_latest_revision
  );
};

const removePolicyExclusionFromRevision = (policyID, policyExclusionID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_exclusions +
      "/" +
      policyExclusionID +
      act_remove_from_revision
  );
};

const createPrintInsuredCardsTask = (policyHeaderID) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_create_print_insured_cards_task
  );
};

const printCards = (policyID, data) => {
  return apiClient.put(
    endpoint + "/" + policyID + route_policy_insured_card + act_print_cards,
    data
  );
};

const updateInsuredCardHolderName = (policyID, insuredCardID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_insured_card +
      "/" +
      insuredCardID +
      act_update_card_holder_name,
    data
  );
};

const rollbackPrintCard = (policyID, insuredCardID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_insured_card +
      "/" +
      insuredCardID +
      act_rollback_print_card
  );
};

const addInsuredCardToRevision = (policyID, insuredCardID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_insured_card +
      "/" +
      insuredCardID +
      act_add_to_revision
  );
};

const removeInsuredCardFromRevision = (policyID, insuredCardID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_insured_card +
      "/" +
      insuredCardID +
      act_remove_from_revision
  );
};

const printCard = (policyID, insuredCardID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_insured_card +
      "/" +
      insuredCardID +
      act_print_card
  );
};

const getPolicyInsuredCard = (insuredCardID) => {
  return apiClient.get(
    endpoint + route_policy_insured_card + "/" + insuredCardID
  );
};

const createAddInsuredToRevisionTask = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_create_add_insured_to_revision_task,
    data
  );
};

const createCopyAndApplyProvisionModificationsTask = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyHeaderID +
      act_create_copy_and_apply_provision_modifications_task,
    data
  );
};

const createCopyAndSyncBenefitCoverageTask = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyHeaderID +
      act_create_copy_and_sync_benefit_coverage_task,
    data
  );
};

const addInsuredToLatestRevision = (pipID, data) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      pipID +
      act_add_to_latest_revision,
    data
  );
};

const removeInsuredFromRevision = (pipID) => {
  return apiClient.put(
    endpoint +
      "/" +
      route_policy_insured_person +
      "/" +
      pipID +
      act_remove_from_revision
  );
};

const getInsuredCancellationWorkspaces = (data) => {
  return apiClient.post(
    endpoint + route_insured_cancellation_workspaces,
    data,
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createInsuredCancellationWorkspace = (policyID, data) => {
  return apiClient.post(
    endpoint + route_insured_cancellation_workspaces,
    JSON.stringify({ PolicyID: policyID, ...data })
  );
};

const getInsuredCancellationWorkspaceSummary = (
  insuredCancellationWorkspaceID
) => {
  return apiClient.get(
    endpoint +
      route_insured_cancellation_workspaces +
      "/" +
      insuredCancellationWorkspaceID +
      route_workspace_summary
  );
};

const deleteInsuredCancellationWorkspace = (insuredCancellationWorkspaceID) => {
  return apiClient.delete(
    endpoint +
      route_insured_cancellation_workspaces +
      "/" +
      insuredCancellationWorkspaceID
  );
};

const downloadInsuredCancellationWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint +
      route_insured_cancellation_workspaces +
      act_download_insured_cancellation_workspace_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importInsuredCancellationWorkspaceDrafts = (
  // policyID,
  insuredCancellationWorkspaceID,
  formData,
  onUploadProgress
  // defaultValues
) => {
  return apiClient.post(
    endpoint +
      route_insured_cancellation_workspaces +
      act_import_insured_cancellation_workspace_drafts,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        // PolicyID: policyID,
        InsuredCancellationWorkspaceID: insuredCancellationWorkspaceID,
        // ...(defaultValues ? defaultValues : {}),
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const getInsuredToCancelFieldValues = (
  insuredCancellationWorkspaceID,
  fieldCode
) => {
  return apiClient.get(
    endpoint +
      route_insured_cancellation_workspaces +
      "/" +
      insuredCancellationWorkspaceID +
      route_insured_to_cancel_field_values +
      "/" +
      fieldCode
  );
};

const updateInsuredToCancelFieldValues = (
  insuredCancellationWorkspaceID,
  fieldCode,
  data
) => {
  return apiClient.put(
    endpoint +
      route_insured_cancellation_workspaces +
      "/" +
      insuredCancellationWorkspaceID +
      route_insured_to_cancel_field_values +
      "/" +
      fieldCode +
      act_map_values,
    data
  );
};

const updatePolicyInsuredPerson_InsuredInfo = (
  policyID,
  policyInsuredPersonID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_updat_insured_info,
    data
  );
};

const updateInsuredPremiumRelatedInfo = (policyInsuredPersonID, data) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_update_insured_premium_related_info,
    data
  );
};

const updateInsuredOriginalMaximumClientShareAmount = (
  policyInsuredPersonID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_update_original_max_client_share,
    data
  );
};

const inheritPolicyInsuredPersonCoverageFromDefault = (
  policyInsuredPersonID
) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_inherit_insured_coverage_from_default
  );
};

const setInsuredPersonAsPrimary = (policyInsuredPersonID) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_set_as_primary
  );
};

const setPrimaryInsuredPerson = (policyInsuredPersonID, data) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_set_primary_insured_person,
    data
  );
};

const recalculatePolicyInsuredPerson = (policyInsuredPersonID) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_recalculate_insured
  );
};

const setPolicyInsuredPersonNotCovered = (policyInsuredPersonID, data) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_set_insured_not_covered,
    data
  );
};

const setInsuredFollowsDefaultBenefit = (
  policyID,
  policyInsuredPersonID,
  followsDefaultBenefit
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_set_insured_follows_default_benefit +
      (followsDefaultBenefit ? "/true" : "")
  );
};

const updatePolicyInsuredPersonCoveragePlan = (
  policyID,
  policyInsuredPersonID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_update_insured_coverage_plan,
    data
  );
};

const updateInsuredCoverageInfo = (policyInsuredPersonID, data) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      act_update_insured_coverage_info,
    data
  );
};

const updatePolicyInsuredBenefit = (
  policyInsuredPersonID,
  policyInsuredBenefitID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      route_policy_insured_benefits +
      "/" +
      policyInsuredBenefitID +
      act_update_policy_insured_benefit,
    data
  );
};

const setPolicyInsuredBenefitWaveWaitingPeriod = (
  policyInsuredPersonID,
  policyInsuredBenefitID,
  waveWaitingPeriod
) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      route_policy_insured_benefits +
      "/" +
      policyInsuredBenefitID +
      act_set_wave_waiting_eriod +
      (waveWaitingPeriod ? "/true" : "")
  );
};

const generateNewCard = (policyID, policyInsuredPersonID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyID +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      route_policy_insured_card +
      act_generate_new_card,
    data
  );
};

const resolveDuplicateInsuredToCancel = (
  insuredCancellationWorkspaceID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_insured_cancellation_workspaces +
      "/" +
      insuredCancellationWorkspaceID +
      act_resolve_duplicate,
    data
  );
};

const checkDuplicateInsuredsToCancel = (
  insuredCancellationWorkspaceID,
  statusCode
) => {
  return apiClient.get(
    endpoint +
      route_insured_cancellation_workspaces +
      "/" +
      insuredCancellationWorkspaceID +
      act_check_duplicates +
      "/" +
      statusCode
  );
};

const validateInsuredsToCancel = (insuredCancellationWorkspaceID, data) => {
  return apiClient.put(
    endpoint +
      route_insured_cancellation_workspaces +
      "/" +
      insuredCancellationWorkspaceID +
      act_validate_insured_to_cancel,
    data
  );
};

const commitInsuredCancellationWorkspace = (insuredCancellationWorkspaceID) => {
  return apiClient.put(
    endpoint +
      route_insured_cancellation_workspaces +
      "/" +
      insuredCancellationWorkspaceID +
      act_commit
  );
};

const getInsuredTransferWorkspaces = (data) => {
  return apiClient.post(endpoint + route_insured_transfer_workspaces, data, {
    headers: { "X-HTTP-Method-Override": "GET" },
  });
};

const createInsuredTransferWorkspace = (policyID, data) => {
  return apiClient.post(
    endpoint + route_insured_transfer_workspaces,
    JSON.stringify({ PolicyID: policyID, ...data })
  );
};

const getInsuredTransferWorkspaceSummary = (insuredTransferWorkspaceID) => {
  return apiClient.get(
    endpoint +
      route_insured_transfer_workspaces +
      "/" +
      insuredTransferWorkspaceID +
      route_workspace_summary
  );
};

const deleteInsuredTransferWorkspace = (insuredTransferWorkspaceID) => {
  return apiClient.delete(
    endpoint +
      route_insured_transfer_workspaces +
      "/" +
      insuredTransferWorkspaceID
  );
};

const downloadInsuredTransferWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint +
      route_insured_transfer_workspaces +
      act_download_insured_transfer_workspace_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importInsuredTransferWorkspaceDrafts = (
  // policyID,
  insuredTransferWorkspaceID,
  formData,
  onUploadProgress
  // defaultValues
) => {
  return apiClient.post(
    endpoint +
      route_insured_transfer_workspaces +
      act_import_insured_transfer_workspace_drafts,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        // PolicyID: policyID,
        InsuredTransferWorkspaceID: insuredTransferWorkspaceID,
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const getInsuredToTransferFieldValues = (
  insuredTransferWorkspaceID,
  fieldCode
) => {
  return apiClient.get(
    endpoint +
      route_insured_transfer_workspaces +
      "/" +
      insuredTransferWorkspaceID +
      route_insured_to_transfer_field_values +
      "/" +
      fieldCode
  );
};

const updateInsuredToTransferFieldValues = (
  insuredTransferWorkspaceID,
  fieldCode,
  data
) => {
  return apiClient.put(
    endpoint +
      route_insured_transfer_workspaces +
      "/" +
      insuredTransferWorkspaceID +
      route_insured_to_transfer_field_values +
      "/" +
      fieldCode +
      act_map_values,
    data
  );
};

const checkAllValuesAreMapped_InsuredTransferWorkspace = (
  insuredTransferWorkspaceID,
  fieldCode
) => {
  return apiClient.put(
    endpoint +
      route_insured_transfer_workspaces +
      "/" +
      insuredTransferWorkspaceID +
      route_insured_to_transfer_field_values +
      "/" +
      fieldCode +
      act_check_all_values_mapped
  );
};

const validateInsuredsToTransfer = (insuredTransferWorkspaceID, data) => {
  return apiClient.put(
    endpoint +
      route_insured_transfer_workspaces +
      "/" +
      insuredTransferWorkspaceID +
      act_validate_insured_to_transfer,
    data
  );
};

const resolveDuplicateInsuredToTransfer = (
  insuredTransferWorkspaceID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_insured_transfer_workspaces +
      "/" +
      insuredTransferWorkspaceID +
      act_resolve_duplicate,
    data
  );
};

const checkDuplicateInsuredsToTransfer = (
  insuredTransferWorkspaceID,
  statusCode
) => {
  return apiClient.get(
    endpoint +
      route_insured_transfer_workspaces +
      "/" +
      insuredTransferWorkspaceID +
      act_check_duplicates +
      "/" +
      statusCode
  );
};

const commitInsuredTransferWorkspace = (insuredTransferWorkspaceID) => {
  return apiClient.put(
    endpoint +
      route_insured_transfer_workspaces +
      "/" +
      insuredTransferWorkspaceID +
      act_commit
  );
};

const getPolicyRenewals = (policyHeaderID) => {
  return apiClient.get(endpoint + "/" + policyHeaderID + route_policy_renewals);
};

const createRecalculatePolicyTask = (policyHeaderID, startRevisionID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyHeaderID +
      act_create_recalculate_policy_task +
      "/" +
      startRevisionID
  );
};

const createExtendPolicyTask = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_create_extend_policy_task,
    data
  );
};

const createCancelPolicyTask = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_create_cancel_policy_task,
    data
  );
};

const getClaimsCountExceedingDate = (data) => {
  return apiClient.post(endpoint + act_check_claims_exceeding_date, data, {
    headers: {
      "X-HTTP-Method-Override": "GET",
    },
  });
};

const addPolicyInsuredBenefitFeature = (
  policyInsuredPersonID,
  policyInsuredBenefitID,
  data
) => {
  return apiClient.post(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      route_policy_insured_benefits +
      "/" +
      policyInsuredBenefitID +
      route_policy_insured_benefit_features,
    data
  );
};

const removePolicyInsuredBenefitFeature = (
  policyInsuredPersonID,
  policyInsuredBenefitID,
  policyInsuredBenefitFeatureID
) => {
  return apiClient.delete(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      route_policy_insured_benefits +
      "/" +
      policyInsuredBenefitID +
      route_policy_insured_benefit_features +
      "/" +
      policyInsuredBenefitFeatureID
  );
};

const updatePolicyInsuredBenefitFeature = (
  policyInsuredPersonID,
  policyInsuredBenefitID,
  policyInsuredBenefitFeatureID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      route_policy_insured_benefits +
      "/" +
      policyInsuredBenefitID +
      route_policy_insured_benefit_features +
      "/" +
      policyInsuredBenefitFeatureID,
    data
  );
};

const addPolicyInsuredExclusion = (policyInsuredPersonID, data) => {
  return apiClient.post(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      route_policy_insured_exclusions,
    data
  );
};

const updatePolicyInsuredExclusion = (
  policyInsuredPersonID,
  policyInsuredExclusionID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      route_policy_insured_exclusions +
      "/" +
      policyInsuredExclusionID,
    data
  );
};

const removePolicyInsuredExclusion = (
  policyInsuredPersonID,
  policyInsuredExclusionID
) => {
  return apiClient.delete(
    endpoint +
      route_policy_insured_person +
      "/" +
      policyInsuredPersonID +
      route_policy_insured_exclusions +
      "/" +
      policyInsuredExclusionID
  );
};

const forceCheckinPolicy = (policyHeaderID) => {
  return apiClient.put(endpoint + "/" + policyHeaderID + act_force_checkin);
};

const getCardIssuanceWorkspaces = (data) => {
  return apiClient.post(endpoint + route_card_issuance_workspaces, data, {
    headers: { "X-HTTP-Method-Override": "GET" },
  });
};

const createCardIssuanceWorkspace = (policyID, data) => {
  return apiClient.post(
    endpoint + route_card_issuance_workspaces,
    JSON.stringify({ PolicyID: policyID, ...data })
  );
};

const getCardIssuanceWorkspaceSummary = (cardIssuanceWorkspaceID) => {
  return apiClient.get(
    endpoint +
      route_card_issuance_workspaces +
      "/" +
      cardIssuanceWorkspaceID +
      route_workspace_summary
  );
};

const deleteCardIssuanceWorkspace = (cardIssuanceWorkspaceID) => {
  return apiClient.delete(
    endpoint + route_card_issuance_workspaces + "/" + cardIssuanceWorkspaceID
  );
};

const downloadCardIssuanceWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint +
      route_card_issuance_workspaces +
      act_download_card_issuance_workspace_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importCardIssuanceWorkspaceDrafts = (
  // policyID,
  cardIssuanceWorkspaceID,
  formData,
  onUploadProgress
  // defaultValues
) => {
  return apiClient.post(
    endpoint +
      route_card_issuance_workspaces +
      act_import_card_issuance_workspace_drafts,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        // PolicyID: policyID,
        CardIssuanceWorkspaceID: cardIssuanceWorkspaceID,
        // ...(defaultValues ? defaultValues : {}),
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const getCardToIssueFieldValues = (cardIssuanceWorkspaceID, fieldCode) => {
  return apiClient.get(
    endpoint +
      route_card_issuance_workspaces +
      "/" +
      cardIssuanceWorkspaceID +
      route_insured_to_cancel_field_values +
      "/" +
      fieldCode
  );
};

const updateCardToIssueFieldValues = (
  cardIssuanceWorkspaceID,
  fieldCode,
  data
) => {
  return apiClient.put(
    endpoint +
      route_card_issuance_workspaces +
      "/" +
      cardIssuanceWorkspaceID +
      route_insured_to_cancel_field_values +
      "/" +
      fieldCode +
      act_map_values,
    data
  );
};

const resolveDuplicateCardToIssue = (cardIssuanceWorkspaceID, data) => {
  return apiClient.put(
    endpoint +
      route_card_issuance_workspaces +
      "/" +
      cardIssuanceWorkspaceID +
      act_resolve_duplicate,
    data
  );
};

const checkDuplicateCardsToIssue = (cardIssuanceWorkspaceID, statusCode) => {
  return apiClient.get(
    endpoint +
      route_card_issuance_workspaces +
      "/" +
      cardIssuanceWorkspaceID +
      act_check_duplicates +
      "/" +
      statusCode
  );
};

const validateCardsToIssue = (cardIssuanceWorkspaceID, data) => {
  return apiClient.put(
    endpoint +
      route_card_issuance_workspaces +
      "/" +
      cardIssuanceWorkspaceID +
      act_validate_cards_to_issue,
    data
  );
};

const commitCardIssuanceWorkspace = (cardIssuanceWorkspaceID) => {
  return apiClient.put(
    endpoint +
      route_card_issuance_workspaces +
      "/" +
      cardIssuanceWorkspaceID +
      act_commit
  );
};

const issuePolicyAccounting = (policyID) => {
  return apiClient.put(endpoint + "/" + policyID + act_issue_policy_accounting);
};

const unIssuePolicyAccounting = (policyID) => {
  return apiClient.put(
    endpoint + "/" + policyID + act_unissue_policy_accounting
  );
};

const resyncInstallments = (policyID) => {
  return apiClient.put(endpoint + "/" + policyID + act_resync_installemts);
};

const resyncAdministrationCostInstallments = (policyID) => {
  return apiClient.put(
    endpoint + "/" + policyID + act_resync_administration_cost_installemts
  );
};

const resyncBrokerInstallments = (policyID) => {
  return apiClient.put(
    endpoint + "/" + policyID + act_resync_broker_installemts
  );
};

const createRenewPolicyTask = (policyHeaderID, data) => {
  return apiClient.put(
    endpoint + "/" + policyHeaderID + act_create_renew_policy_task,
    data
  );
};

const downloadEndorsementDetailsReport = (data) => {
  return apiClient.post(
    endpoint + route_download_revision_details_report,
    data,
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getCompactPolicyInsuredPersonDependents = (pipID) => {
  return apiClient.get(
    endpoint + "/" + pipID + route_compact_policy_insured_person_dependents
  );
};

const downloadRemovedInsuredReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_removed_insured_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyActiveInsuredContextualReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_active_insured_contextual_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyScheduleBrokerReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_schedule_broker_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadScheduleOfBenefitsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_schedule_of_benefits_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPaymentScheduleReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_payment_schedule_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyAdministrationCostInstallmentsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_administration_cost_installments_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyBrokerCommissionInstallmentsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_broker_commission_installments_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyInstallmentsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_installmenst_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyModifiedInsuredReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_modified_insured_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyRevisionAffectedInsuredReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_revision_affected_insured_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyActiveInsuredSystemReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_policy_active_insured_system_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyAffectedInsuredSystemReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_affected_insured_system_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyEndorsementListReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_endorsement_list_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyholdersReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_holders_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyholderContactsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_holder_contacts_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyListReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_list_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addCardType = (data) => {
  return apiClient.post(endpoint + route_card_types, data);
};

const deleteCardType = (cardTypeID) => {
  return apiClient.delete(endpoint + route_card_types + "/" + cardTypeID);
};

const updateCardType = (cardTypeID, data) => {
  return apiClient.put(endpoint + route_card_types + "/" + cardTypeID, data);
};

const addCardTypePrice = (cardTypeID, data) => {
  return apiClient.post(
    endpoint + route_card_types + "/" + cardTypeID + route_card_type_prices,
    data
  );
};

const deleteCardTypePrice = (cardTypeID, cardTypePriceID) => {
  return apiClient.delete(
    endpoint +
      route_card_types +
      "/" +
      cardTypeID +
      route_card_type_prices +
      "/" +
      cardTypePriceID
  );
};

const updateCardTypePrice = (cardTypeID, cardTypePriceID, data) => {
  return apiClient.put(
    endpoint +
      route_card_types +
      "/" +
      cardTypeID +
      route_card_type_prices +
      "/" +
      cardTypePriceID +
      act_update,
    data
  );
};

const cancelCardTypePrice = (cardTypeID, cardTypePriceID, data) => {
  return apiClient.put(
    endpoint +
      route_card_types +
      "/" +
      cardTypeID +
      route_card_type_prices +
      "/" +
      cardTypePriceID +
      act_cancel,
    data
  );
};

const reinstateCardTypePrice = (cardTypeID, cardTypePriceID) => {
  return apiClient.put(
    endpoint +
      route_card_types +
      "/" +
      cardTypeID +
      route_card_type_prices +
      "/" +
      cardTypePriceID +
      act_reinstate
  );
};

const getCardTypes = (data) => {
  return apiClient.post(endpoint + route_card_types, data, {
    headers: {
      "X-HTTP-Method-Override": "GET",
    },
  });
};

const getCardType = (cardTypeID) => {
  return apiClient.get(endpoint + route_card_types + "/" + cardTypeID);
};

const downloadInsuredMissingPicturesReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_insured_missing_pictures_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadInsuredBankAccountsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_insured_bank_accounts_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadActiveInsuredSystemReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_policy_active_insured_system_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadBenefitSummaryReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_benefit_summary_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadVezeetaInsuredReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_vezeeta_insured_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadUpcomingRenewalsPoliciesReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_upcoming_renewal_policies_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadCustomizedPolicyAffectedInsuredReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_customized_policy_affected_insured_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addPolicyClassification = (data) => {
  return apiClient.post(endpoint + route_policy_classifications, data);
};

const deletePolicyClassification = (policyClassificationID) => {
  return apiClient.delete(
    endpoint + route_policy_classifications + "/" + policyClassificationID
  );
};

const updatePolicyClassification = (policyClassificationID, data) => {
  return apiClient.put(
    endpoint + route_policy_classifications + "/" + policyClassificationID,
    data
  );
};

const getPolicyClassifications = () => {
  return apiClient.get(endpoint + route_policy_classifications);
};

const downloadPolicyAdministrationCostReport = (
  criteria,
  onDownloadProgress
) => {
  return apiClient.post(
    endpoint + act_download_policy_administration_cost_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
      onDownloadProgress: (progress) =>
        onDownloadProgress(
          Math.round((100 * progress.loaded) / progress.total)
        ),
    }
  );
};

const downloadInsuredGenderDistributionReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_insured_gender_distribution_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const createRecalculateCardAmountsTask = (policyHeaderID, startRevisionID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyHeaderID +
      act_create_recalculate_card_amounts_task +
      "/" +
      startRevisionID
  );
};

const createUpdateCardTypeTask = (policyHeaderID, cardTypeID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyHeaderID +
      act_create_update_card_type_task +
      "/" +
      cardTypeID
  );
};

const updatePolicyReinsurer = (policyHeaderID, cardTypeID) => {
  return apiClient.put(
    endpoint +
      "/" +
      policyHeaderID +
      act_update_policy_reinsurer +
      (cardTypeID ? "/" + cardTypeID : "")
  );
};

const checkAllValuesAreMapped_InsuredCancellationWorkspace = (
  insuredCancellationWorkspaceID,
  fieldCode
) => {
  return apiClient.put(
    endpoint +
      route_insured_cancellation_workspaces +
      "/" +
      insuredCancellationWorkspaceID +
      route_insured_to_cancel_field_values +
      "/" +
      fieldCode +
      act_check_all_values_mapped
  );
};

const downloadIssuanceItemDetailsReport = (data) => {
  return apiClient.post(
    endpoint + act_download_issuance_items_details_report,
    data,
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};
const policiesApi = {
  getPolicyInsuredPerson,
  getPolicies,
  getPolicy,
  getPolicyHeader,
  getPolicyInsuredCards,
  getPolicyInsuredPersons,
  downloadInsuredAdditionReport,
  downloadProductionReport,
  downloadActiveInsuredReport,
  downloadActiveInsuredSystemReport,
  downloadAffectedInsuredReport,
  downloadPolicyScheduleAccountingReport,
  downloadPolicyScheduleClientReport,
  downloadActiveInsuredClientReport,
  checkPolicyInsuredPersonBenefitLimits,
  getPolicyAmountDistributionFigures,
  getPremiumAndClaimsDistribution,
  downloadPortfolioLossRatioReport,
  downloadPolicyLossRatioReport,
  downloadPolicyLossRatioInsuredLevelReport,
  downloadUpcomingRenewalPoliciesReport,
  downloadInsuredAggregatePremiumReport,
  generateRecalculatePolicyTask,
  detachPolicyTask,
  getInsuredCoverages,
  getInsuredExclusions,
  downloadPortfolioLossRatioSummaryReport,
  getPolicyBrokers,
  getEndorsementTypes,
  createPolicy,
  getPolicyAdministrationCostInstallmentRules,
  getPolicyAdministrationCostInstallments,
  getPolicyBrokerCommissionInstallments,
  getPolicyPremiumFunders,
  getPolicyPremiumFunderInstallments,
  getPolicyInstallmentRules,
  getPolicyInstallments,
  getPolicyCoverages,
  getPolicyProductCoverages,
  getPolicyBenefitCoverages,
  getPolicyExclusions,
  updatePolicyInsuredAgeConfiguration,
  updateAliasPolicyNumber,
  setApprovalAlertLevel,
  removeApprovalAlertLevel,
  setPolicyClassification,
  removePolicyClassification,
  updateEndorsementInfo,
  changePolicyDates,
  changePolicyPlan,
  createBranchTransferEndorsment,
  createClientShareUpdateEndorsment,
  deactivatePolicy,
  checkinPolicy,
  checkoutPolicy,
  forceCheckinPolicy,
  setSuspendApprovals,
  setDispenseChronicFormToPolicyEndDateOnly,
  setPolicyDenyAutomaticApprovals,
  subtractAdminCostTaxes,
  createEndorsement,
  setRequiresApprovalRejectionNotification,
  createPolicyActivationTask,
  createDeletePolicyTask,
  addPolicyCharges,
  removePolicyCharge,
  cancelPolicyCharge,
  reinstatePolicyCharge,
  updatePolicyCharge,
  addPolicyTax,
  updatePolicyTax,
  removePolicyTax,
  cancelPolicyTax,
  reinstatePolicyTax,
  addPolicyTaxDetail,
  removePolicyTaxDetail,
  updatePolicyTaxDetail,
  addPolicyTaxEndorsementTypes,
  removePolicyTaxEndorsementType,
  addPolicyFeatures,
  removePolicyFeature,
  updatePolicyFeature,
  reinstatePolicyFeature,
  cancelPolicyFeature,
  addPolicyFeatureDetail,
  updatePolicyFeatureDetail,
  removePolicyFeatureDetail,
  addPolicyTPARule,
  removePolicyTPARule,
  updatePolicyTPARule,
  reinstatePolicyTPARule,
  cancelPolicyTPARule,
  addPolicyTPAPremiumShares,
  updatePolicyTPAPremiumShare,
  removePolicyTPAPremiumShare,
  addPolicyAdministrationCostInstallmentRule,
  removePolicyAdministrationCostInstallmentRule,
  updatePolicyAdministrationCostInstallmentRule,
  addPolicyBrokerCommissionInstallment,
  updatePolicyBrokerCommissionInstallment,
  removePolicyBrokerCommissionInstallment,
  addPolicyPremiumFunderInstallment,
  updatePolicyPremiumFunderInstallment,
  deletePolicyPremiumFunderInstallment,
  reinstatePolicyPremiumFunder,
  cancelPolicyPremiumFunder,
  addPolicyInstallmentRule,
  removePolicyInstallmentRule,
  updatePolicyInstallmentRule,
  addPolicyCoverage,
  updatePolicyCoverage,
  deletePolicyCoverage,
  revisePolicyCoverage,
  addPolicyProductCoverage,
  updatePolicyProductCoverage,
  deletePolicyProductCoverage,
  revisePolicyProductCoverage,
  addPolicyBenefitCoverage,
  updatePolicyBenefitCoverage,
  deletePolicyBenefitCoverage,
  revisePolicyBenefitCoverage,
  cancelPolicyBenefitCoverage,
  getPolicyHeaders,
  addPolicyExclusion,
  updatePolicyExclusion,
  cancelPolicyExclusion,
  addPolicyExclusionToLatestRevision,
  removePolicyExclusionFromRevision,
  createPrintInsuredCardsTask,
  printCards,
  updateInsuredCardHolderName,
  rollbackPrintCard,
  addInsuredCardToRevision,
  removeInsuredCardFromRevision,
  printCard,
  getPolicyInsuredCard,
  createAddInsuredToRevisionTask,
  createCopyAndApplyProvisionModificationsTask,
  createCopyAndSyncBenefitCoverageTask,
  removeInsuredFromRevision,
  addInsuredToLatestRevision,
  getInsuredCancellationWorkspaces,
  createInsuredCancellationWorkspace,
  deleteInsuredCancellationWorkspace,
  getInsuredCancellationWorkspaceSummary,
  downloadInsuredCancellationWorkspaceReport,
  importInsuredCancellationWorkspaceDrafts,
  getInsuredToCancelFieldValues,
  updateInsuredToCancelFieldValues,
  updatePolicyInsuredPerson_InsuredInfo,
  updateInsuredPremiumRelatedInfo,
  updateInsuredOriginalMaximumClientShareAmount,
  inheritPolicyInsuredPersonCoverageFromDefault,
  setPrimaryInsuredPerson,
  setInsuredPersonAsPrimary,
  recalculatePolicyInsuredPerson,
  setPolicyInsuredPersonNotCovered,
  setInsuredFollowsDefaultBenefit,
  updatePolicyInsuredPersonCoveragePlan,
  updateInsuredCoverageInfo,
  updatePolicyInsuredBenefit,
  setPolicyInsuredBenefitWaveWaitingPeriod,
  generateNewCard,
  checkDuplicateInsuredsToCancel,
  resolveDuplicateInsuredToCancel,
  validateInsuredsToCancel,
  commitInsuredCancellationWorkspace,
  getInsuredTransferWorkspaces,
  createInsuredTransferWorkspace,
  getInsuredTransferWorkspaceSummary,
  deleteInsuredTransferWorkspace,
  downloadInsuredTransferWorkspaceReport,
  importInsuredTransferWorkspaceDrafts,
  getInsuredToTransferFieldValues,
  updateInsuredToTransferFieldValues,
  validateInsuredsToTransfer,
  checkDuplicateInsuredsToTransfer,
  resolveDuplicateInsuredToTransfer,
  commitInsuredTransferWorkspace,
  getPolicyRenewals,
  createRecalculatePolicyTask,
  createExtendPolicyTask,
  createCancelPolicyTask,
  getClaimsCountExceedingDate,
  addPolicyInsuredBenefitFeature,
  updatePolicyInsuredBenefitFeature,
  removePolicyInsuredBenefitFeature,
  addPolicyInsuredExclusion,
  updatePolicyInsuredExclusion,
  removePolicyInsuredExclusion,
  getCardIssuanceWorkspaces,
  createCardIssuanceWorkspace,
  deleteCardIssuanceWorkspace,
  getCardIssuanceWorkspaceSummary,
  downloadCardIssuanceWorkspaceReport,
  importCardIssuanceWorkspaceDrafts,
  getCardToIssueFieldValues,
  updateCardToIssueFieldValues,
  checkDuplicateCardsToIssue,
  resolveDuplicateCardToIssue,
  validateCardsToIssue,
  commitCardIssuanceWorkspace,
  issuePolicyAccounting,
  unIssuePolicyAccounting,
  resyncInstallments,
  resyncAdministrationCostInstallments,
  resyncBrokerInstallments,
  createRenewPolicyTask,
  downloadEndorsementDetailsReport,
  getCompactPolicyInsuredPersonDependents,
  downloadRemovedInsuredReport,
  downloadPolicyActiveInsuredContextualReport,
  downloadPolicyScheduleBrokerReport,
  downloadScheduleOfBenefitsReport,
  downloadPaymentScheduleReport,
  downloadPolicyAdministrationCostInstallmentsReport,
  downloadPolicyBrokerCommissionInstallmentsReport,
  downloadPolicyInstallmentsReport,
  downloadPolicyModifiedInsuredReport,
  downloadPolicyRevisionAffectedInsuredReport,
  downloadPolicyActiveInsuredSystemReport,
  downloadPolicyAffectedInsuredSystemReport,
  downloadPolicyEndorsementListReport,
  downloadPolicyholdersReport,
  downloadPolicyholderContactsReport,
  downloadPolicyListReport,
  addCardType,
  updateCardType,
  deleteCardType,
  addCardTypePrice,
  updateCardTypePrice,
  deleteCardTypePrice,
  cancelCardTypePrice,
  reinstateCardTypePrice,
  getCardTypes,
  getCardType,
  downloadInsuredMissingPicturesReport,
  downloadInsuredBankAccountsReport,
  downloadBenefitSummaryReport,
  downloadVezeetaInsuredReport,
  downloadUpcomingRenewalsPoliciesReport,
  downloadCustomizedPolicyAffectedInsuredReport,
  addPolicyClassification,
  updatePolicyClassification,
  deletePolicyClassification,
  getPolicyClassifications,
  downloadPolicyAdministrationCostReport,
  downloadInsuredGenderDistributionReport,
  createRecalculateCardAmountsTask,
  createUpdateCardTypeTask,
  updatePolicyReinsurer,
  checkAllValuesAreMapped_InsuredCancellationWorkspace,
  checkAllValuesAreMapped_InsuredTransferWorkspace,
  downloadIssuanceItemDetailsReport,
};

export default policiesApi;
