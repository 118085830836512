import { useEffect, useState, useContext } from "react";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import ActionWrapper from "../formComponents/ActionWrapper";
import { LocalizationContext } from "../../context/localizationContext";
import AppDialog from "../dialog/AppDialog";
import usePermissions from "../../hooks/usePermissions";
import AppSlidingPanel from "../slidingPanel/AppSlidingPanel";
import { AppFormProps } from "../../ui/AppFormProps";
import PageTitle from "./PageTitle";
import PermissionDeniedPage from "../../pages/PermissionDeniedPage";
import baseStrings from "../../base/baseStrings";

export interface stepsInterface {
  icon: string;
  isValid: boolean | undefined;
  label: string;
}

const AppForm = ({
  isDialog = false,
  popupTitle = "",
  popupTitleSuffix = "",
  isFormVisible,
  setIsFormVisible,
  // numValidationRows = 1,
  // refreshFormSize,
  submitButtonTitle,
  cancelButtonTitle,
  addActionWrapper,
  render,
  onClose,
  // minFieldWidth,
  // maxNumColumns,
  formWidth,
  loading,
  setLoading,
  commandName = "",
  alignActionsTop = false,
  renderCustomActionWrapper,
  isInlineForm,
  showCancelButton = true,
  height,
  isSlidingPanel = false,
  slidingPanelSize = "medium",
  slidingPanelHighResSize = slidingPanelSize,
  onAfterClose,
  forceAllowFormSubmission = false,
  forceOpen,
  showCloseIcon = true,
  ...rest
}: AppFormProps) => {
  const { isRTL, translate } = useContext(LocalizationContext);
  const { hasPermission, renderPermissionAlert } = usePermissions(
    commandName,
    true
  );
  const [hasAccess, setHasAccess] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const checkPermission = async () => {
    if (!commandName || commandName === "") setHasAccess(true);
    else {
      const canAccess = await hasPermission();
      if (canAccess === true) setHasAccess(true);
      else setHasAccess(false);
    }
    setInitialized(true);
  };

  useEffect(() => {
    if (!initialized) {
      checkPermission();
    }
  }, [initialized]);

  const resetForm = (formRenderProps: FormRenderProps) => {
    formRenderProps.onFormReset();
    if (setIsFormVisible) setIsFormVisible(false);
    if (onClose) onClose();
  };

  const renderActionWrapper = (formRenderProps: FormRenderProps) => {
    return renderCustomActionWrapper ? (
      renderCustomActionWrapper(formRenderProps, resetForm)
    ) : (
      <ActionWrapper
        submit={true}
        reset={showCancelButton}
        submitTitle={submitButtonTitle || baseStrings.SUBMIT}
        resetTitle={cancelButtonTitle || baseStrings.CANCEL}
        submitDisabled={
          forceAllowFormSubmission && formRenderProps.valid
            ? false
            : !formRenderProps.allowSubmit
        }
        handleReset={() => resetForm(formRenderProps)}
      />
    );
  };
  const renderForm = () => {
    return (
      <Form
        render={(formRenderProps: FormRenderProps) => (
          <FormElement className={isRTL ? "rtlDirection" : "ltrDirection"}>
            <fieldset className="k-form-fieldset">
              {addActionWrapper &&
                alignActionsTop &&
                renderActionWrapper(formRenderProps)}
              {render(formRenderProps)}
              {addActionWrapper &&
                !alignActionsTop &&
                renderActionWrapper(formRenderProps)}
            </fieldset>
          </FormElement>
        )}
        {...rest}
      />
    );
  };
  return initialized ? (
    isDialog && isFormVisible ? (
      hasAccess ? (
        <AppDialog
          isForm={true}
          dialogWidth={formWidth}
          title={translate(popupTitle) + popupTitleSuffix}
          closeIcon={showCloseIcon}
          onClose={() => {
            if (setIsFormVisible) setIsFormVisible(!isFormVisible);
            if (onClose) onClose();
          }}
          loading={loading}
          setLoading={setLoading}
          height={height}
        >
          <span className="app-form form-container">{renderForm()}</span>
        </AppDialog>
      ) : (
        renderPermissionAlert(() => {
          if (setIsFormVisible) setIsFormVisible(false);
        }, commandName)
      )
    ) : hasAccess ? (
      isFormVisible && setIsFormVisible && isSlidingPanel ? (
        <AppSlidingPanel
          size={slidingPanelSize}
          tabletSize="large"
          highresSize={slidingPanelHighResSize}
          isVisible={isFormVisible}
          setVisible={setIsFormVisible}
          loading={loading}
          onAfterClose={onAfterClose}
          forceOpen={forceOpen}
        >
          <PageTitle title={popupTitle} subTitle={popupTitleSuffix || ""} />
          {renderForm()}
        </AppSlidingPanel>
      ) : (
        renderForm()
      )
    ) : isInlineForm || isSlidingPanel ? (
      renderPermissionAlert(() => {
        if (setIsFormVisible) setIsFormVisible(false);
      }, commandName)
    ) : (
      <PermissionDeniedPage commandName={commandName} />
    )
  ) : (
    <></>
  );
};

export default AppForm;
