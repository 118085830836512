import Cookies from "universal-cookie";

const prefix = "api";
const key_locale = "lreqt";
const cookies = new Cookies();

const storeLastRequestTime = (lastRequestTime: Date) => {
  cookies.set(prefix + key_locale, lastRequestTime, {
    path: "/",
  });
};

const getLastRequestTime = () => {
  const lastRequestTime = cookies.get(prefix + key_locale);
  return lastRequestTime?.toString();
};

const apiActionStorage = {
  getLastRequestTime,
  storeLastRequestTime,
};
export default apiActionStorage;
