import { GridCellProps } from "@progress/kendo-react-grid";
import AppIndicator from "../indicators/AppIndicator";
import AppIndicatorCellProps from "../../ui/AppIndicatorCellProps";
interface Props extends AppIndicatorCellProps, GridCellProps {
  handleOnRowClick?: (e: any) => void;
}
export default function AppIndicatorCell({
  indicatorFieldName,
  dataItem,
  successIndicator,
  errorIndicator,
  handleOnRowClick,
}: Props) {
  return (
    <td
      role={"gridcell"}
      onClick={() => {
        if (handleOnRowClick) handleOnRowClick({ dataItem });
      }}
    >
      <AppIndicator
        isColumnIndicator={true}
        iconColor={
          dataItem[indicatorFieldName] === successIndicator.indicatorFieldValue
            ? successIndicator.iconColor
            : errorIndicator.iconColor
        }
        icon={
          dataItem[indicatorFieldName] === successIndicator.indicatorFieldValue
            ? successIndicator.icon
            : errorIndicator.icon
        }
        indicatorText={
          dataItem[indicatorFieldName] === successIndicator.indicatorFieldValue
            ? successIndicator.indicatorText
            : errorIndicator.indicatorText
        }
      />
    </td>
  );
}
