import { Dispatch, SetStateAction } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import AppLocationSearchField, {
  AppLocationSearchFieldKeys,
} from "./AppLocationSearchField";
import customFieldsNames from "../../enums/searchFields/customSearchFieldNames";
import AppProviderSearchField, {
  AppProviderSearchFieldKeys,
} from "./AppProviderSearchField";
import AppServiceChapterSearchField, {
  AppServiceChapterSearchFieldKeys,
} from "./AppServiceChapterSearchField";
import AppStackholderSearchField, {
  AppStackholderSearchFieldKeys,
} from "./AppStackholderSearchField";
import AppInvoiceEntitySearchField, {
  AppInvoiceEntitySearchFieldKeys,
} from "./AppInvoiceEntitySearchField";
import AppBillEntitySearchField, {
  AppBillEntitySearchFieldKeys,
} from "./AppBillEntitySearchField";
import AppBillPaymentEntitySearchField, {
  AppBillPaymentEntitySearchFieldKeys,
} from "./AppBillPaymentEntitySearchField";
import AppCountryCitySearchField, {
  AppCountryCitySearchFieldKeys,
} from "./AppCountryCitySearchField";
import AppAuthorizedSignatoryTypeClassSearchField, {
  AppAuthorizedSignatoryTypeClassSearchFieldKeys,
} from "./AppAuthorizedSignatoryTypeClassSearchField";
import AppReimbursementBatchClientSearchField, {
  AppReimbursementBatchClientSearchFieldKeys,
} from "./AppReimbursementBatchClientSearchField";
import AppCustomerEntitySearchField, {
  AppCustomerEntitySearchFieldKeys,
} from "./AppCustomerEntitySearchField";
import AppVendorEntitySearchField, {
  AppVendorEntitySearchFieldKeys,
} from "./AppVendorEntitySearchField";
import AppProviderClaimsSearchField, {
  AppProviderClaimsSearchFieldKeys,
} from "./AppProviderClaimsSearchField";
import AppBankWithBranchesSearchField, {
  AppBankWithBranchesSearchFieldKeys,
} from "./AppBankWithBranchesSearchField";
import AppAccountingTransactionFiscalYearPeriodSearchField, {
  AppAccountingTransactionFiscalYearPeriodSearchFieldKeys,
} from "./AppAccountingTransactionFiscalYearPeriodSearchField";
import AppAccountingTransactionEntitySearchField, {
  AppAccountingTransactionEntitySearchFieldKeys,
} from "./AppAccountingTransactionEntitySearchField";
import AppAccountingTransactionSystemEntitySearchField, {
  AppAccountingTransactionSystemEntitySearchFieldKeys,
} from "./AppAccountingTransactionSystemEntitySearchField";
import AppClaimFormTypeClaimTypeSearchField, {
  AppClaimFormTypeClaimTypeSearchFieldKeys,
} from "./AppClaimFormTypeClaimTypeSearchField";
import AppCountryGovernorateSearchField, {
  AppCountryGovernorateSearchFieldKeys,
} from "./AppCountryGovernorateSearchField";
import AppFileReviewStakeholderSearchField, {
  AppFileReviewStakeholderSearchFieldKeys,
} from "./AppFileReviewStakeholderSearchField";
import AppServiceSearchField, {
  AppServiceSearchFieldKeys,
} from "./AppServiceSearchField";
import AppSearchProviderSearchField, {
  AppSearchProviderSearchFieldKeys,
} from "./AppSearchProviderSearchField";
import AppTreasuryTransactionEntitySearchField, {
  AppTreasuryTransactionEntitySearchFieldKeys,
} from "./AppTreasuryTransactionEntitySearchField";
import AppReimbursementRequestClientSearchField, {
  AppReimbursementRequestClientSearchFieldKeys,
} from "./AppReimbursementRequestClientSearchField";
import AppServicePricingServiceSearchField, {
  AppServicePricingServiceSearchFieldKeys,
} from "./AppServicePricingSearchField";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
  customSearchFieldName: string;
}
export default function AppCustomSearchField({
  customSearchFieldName,
  setSearchFieldValues,
  removeSearchFieldValue,
  criteriaTitle,
  searchFieldName,
  setErrorMessage,
  defaultValue,
  parentObjectID,
}: Props) {
  if (customSearchFieldName === customFieldsNames.LOCATION) {
    return (
      <AppLocationSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.PROVIDER) {
    return (
      <AppProviderSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.SERVICE_CHAPTER) {
    return (
      <AppServiceChapterSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.STACKHOLDER) {
    return (
      <AppStackholderSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.INVOICE_ENTITY) {
    return (
      <AppInvoiceEntitySearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={false}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.BILL_ENTITY) {
    return (
      <AppBillEntitySearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={false}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.BILL_PAYMENT_ENTITY) {
    return (
      <AppBillPaymentEntitySearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={false}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.COUNTRY_CITY) {
    return (
      <AppCountryCitySearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (
    customSearchFieldName === customFieldsNames.AUTHORIZED_SIGNATORY_TYPE_CLASS
  ) {
    return (
      <AppAuthorizedSignatoryTypeClassSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
        defaultValue={defaultValue}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.REIMBURSEMENT_BATCH_CLIENT) {
    return (
      <AppReimbursementBatchClientSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
        defaultValue={defaultValue}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.CUSTOMER_ENTITY) {
    return (
      <AppCustomerEntitySearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
        defaultValue={defaultValue}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.VENDOR_ENTITY) {
    return (
      <AppVendorEntitySearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
        defaultValue={defaultValue}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.PROVIDER_CLAIMS) {
    return (
      <AppProviderClaimsSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.BANK_WITH_BRANCHES) {
    return (
      <AppBankWithBranchesSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (
    customSearchFieldName ===
    customFieldsNames.ACCOUNTING_TRANSACTION_FISCAL_YEAR_PERIOD_ENTITY
  ) {
    return (
      <AppAccountingTransactionFiscalYearPeriodSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (
    customSearchFieldName === customFieldsNames.ACCOUNTING_TRANSACTION_ENTITY
  ) {
    return (
      <AppAccountingTransactionEntitySearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (
    customSearchFieldName ===
    customFieldsNames.ACCOUNTING_TRANSACTION_SYSTEM_ENTITY
  ) {
    return (
      <AppAccountingTransactionSystemEntitySearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.CLAIM_FORM_TYPE_CLAIM_TYPE) {
    return (
      <AppClaimFormTypeClaimTypeSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.COUNTRY_GOVERNORATE) {
    return (
      <AppCountryGovernorateSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.FILE_REVIEW_STAKEHOLDER) {
    return (
      <AppFileReviewStakeholderSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.SERVICE) {
    return (
      <AppServiceSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
        defaultValue={defaultValue}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.SERVICE_PRICING_SERVICE) {
    return (
      <AppServicePricingServiceSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
        defaultValue={defaultValue}
        parentObjectID={parentObjectID}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.SEARCH_PROVIDER) {
    return (
      <AppSearchProviderSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
        defaultValue={defaultValue}
      />
    );
  }
  if (customSearchFieldName === customFieldsNames.TREASURY_TRANSACTION_ENTITY) {
    return (
      <AppTreasuryTransactionEntitySearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
        defaultValue={defaultValue}
      />
    );
  }
  if (
    customSearchFieldName === customFieldsNames.REIMBURSEMENT_REQUEST_CLIENT
  ) {
    return (
      <AppReimbursementRequestClientSearchField
        setSearchFieldValues={setSearchFieldValues}
        searchFieldName={searchFieldName}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        criteriaTitle={criteriaTitle}
        defaultShow={true}
        defaultValue={defaultValue}
      />
    );
  } else return null;
}

export const customComponentIncludesSearchField: (
  customFieldKey: string,
  searchFieldKey: string
) => boolean = (customFieldKey, searchFieldKey) => {
  switch (customFieldKey) {
    case customFieldsNames.LOCATION.toString():
      for (const value of Object.keys(AppLocationSearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.PROVIDER.toString():
      for (const value of Object.keys(AppProviderSearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.SERVICE_CHAPTER.toString():
      for (const value of Object.keys(AppServiceChapterSearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.STACKHOLDER.toString():
      for (const value of Object.keys(AppStackholderSearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.INVOICE_ENTITY.toString():
      for (const value of Object.keys(AppInvoiceEntitySearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.BILL_ENTITY.toString():
      for (const value of Object.keys(AppBillEntitySearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.BILL_PAYMENT_ENTITY.toString():
      for (const value of Object.keys(AppBillPaymentEntitySearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.COUNTRY_CITY.toString():
      for (const value of Object.keys(AppCountryCitySearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.AUTHORIZED_SIGNATORY_TYPE_CLASS.toString():
      for (const value of Object.keys(
        AppAuthorizedSignatoryTypeClassSearchFieldKeys
      )) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.REIMBURSEMENT_BATCH_CLIENT.toString():
      for (const value of Object.keys(
        AppReimbursementBatchClientSearchFieldKeys
      )) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.CUSTOMER_ENTITY.toString():
      for (const value of Object.keys(AppCustomerEntitySearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.VENDOR_ENTITY.toString():
      for (const value of Object.keys(AppVendorEntitySearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.PROVIDER_CLAIMS.toString():
      for (const value of Object.keys(AppProviderClaimsSearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.BANK_WITH_BRANCHES.toString():
      for (const value of Object.keys(AppBankWithBranchesSearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.ACCOUNTING_TRANSACTION_FISCAL_YEAR_PERIOD_ENTITY.toString():
      for (const value of Object.keys(
        AppAccountingTransactionFiscalYearPeriodSearchFieldKeys
      )) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.ACCOUNTING_TRANSACTION_ENTITY.toString():
      for (const value of Object.keys(
        AppAccountingTransactionEntitySearchFieldKeys
      )) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.ACCOUNTING_TRANSACTION_SYSTEM_ENTITY.toString():
      for (const value of Object.keys(
        AppAccountingTransactionSystemEntitySearchFieldKeys
      )) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.CLAIM_FORM_TYPE_CLAIM_TYPE.toString():
      for (const value of Object.keys(
        AppClaimFormTypeClaimTypeSearchFieldKeys
      )) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.COUNTRY_GOVERNORATE.toString():
      for (const value of Object.keys(AppCountryGovernorateSearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.FILE_REVIEW_STAKEHOLDER.toString():
      for (const value of Object.keys(
        AppFileReviewStakeholderSearchFieldKeys
      )) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.SERVICE.toString():
      for (const value of Object.keys(AppServiceSearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.SEARCH_PROVIDER.toString():
      for (const value of Object.keys(AppSearchProviderSearchFieldKeys)) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.TREASURY_TRANSACTION_ENTITY.toString():
      for (const value of Object.keys(
        AppTreasuryTransactionEntitySearchFieldKeys
      )) {
        if (searchFieldKey === value) return true;
      }
      break;
    case customFieldsNames.REIMBURSEMENT_REQUEST_CLIENT.toString():
      for (const value of Object.keys(
        AppReimbursementRequestClientSearchFieldKeys
      )) {
        if (searchFieldKey === value) return true;
      }
      break;

    case customFieldsNames.SERVICE_PRICING_SERVICE.toString():
      for (const value of Object.keys(
        AppServicePricingServiceSearchFieldKeys
      )) {
        if (searchFieldKey === value) return true;
      }
      break;
  }
  return false;
};
