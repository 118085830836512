import React, { createContext, ReactNode } from "react";
import ELanguageCodes from "../base/languageCodes";
import { LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import arMessages from "../locales/ar/arKendoMessages.json";

import { useTranslation } from "react-i18next";
import translationNamespaces from "../enums/locales/translationNamespaces";
import BusinessObjectModel from "../interfaces/BusinessObjectModel";
import settingsStorage from "../storage/settingsStorage";

interface Props {
  isRTL: boolean;
  setIsRTL: (isRTL: boolean) => void;
  locale: string;
  setLocale: (locale: string) => void;
  children: ReactNode;
  updateUserLanguage: (lang: string) => void;
  translationAdditionalProps: { [key: string]: any };
}
interface LanguageObj {
  name: string;
  code: {
    Name: string;
    Code: string;
    Localization: string;
    rtlActive: boolean;
  };
}
interface ContextProps {
  locale: string;
  isRTL: boolean;
  getNameField: () => string;
  translate: (value: string | undefined | null | any) => string;
  updateLanguage: (language: string) => void;
  getLanguageCode: () => {
    Name: string;
    Code: string;
    Localization: string;
    rtlActive: boolean;
  };
  getIsRTL: () => boolean;
  getTextAlign: () => string;
  getCurrentLanguage: () => LanguageObj | undefined;
  ELanguageCodes: any;
  translateIn: (value: string, code: string) => string;
  getFullName: (object: any) => string;
  getName: (object: BusinessObjectModel | undefined | null) => any;
  translationAdditionalProps: { [key: string]: any };
}
const languages: LanguageObj[] = [
  {
    name: "English",
    code: ELanguageCodes.ENGLISH,
  },
  {
    name: "Arabic",
    code: ELanguageCodes.ARABIC,
  },
];

loadMessages(arMessages, "ar");
export const LocalizationContext = createContext<ContextProps>({
  locale: "en",
  isRTL: false,
  getNameField: () => {
    return "";
  },
  translate: (value: string | undefined | null | any) => {
    return "";
  },
  updateLanguage: (language: string) => {},
  getLanguageCode: () => {
    return ELanguageCodes.ENGLISH;
  },
  getIsRTL: () => {
    return false;
  },
  getTextAlign: () => {
    return "";
  },
  getCurrentLanguage: () => {
    return languages[0];
  },
  ELanguageCodes: [],
  translateIn: (value: string, code: string) => {
    return value;
  },
  getFullName: (object: any) => {
    return "";
  },
  getName: (object: BusinessObjectModel | undefined | null) => {},
  translationAdditionalProps: {},
});

LocalizationContext.displayName = "LocalizationContext";

export default function AppLocalizationProvider({
  isRTL,
  setIsRTL,
  locale,
  setLocale,
  children,
  updateUserLanguage,
  translationAdditionalProps,
}: Props) {
  const { t, i18n } = useTranslation([
    translationNamespaces.TRANSLATION,
    translationNamespaces.BASE,
  ]);

  const getName = (object: BusinessObjectModel | undefined | null) => {
    // return isRTL ? object["LocalName"] : object["ForeignName"];
    if (object === undefined || object === null) return "";
    return isRTL
      ? object["LocalName"] && object["LocalName"].trim() === ""
        ? object["ForeignName"]
        : object["LocalName"]
      : object["ForeignName"] && object["ForeignName"].trim() === ""
        ? object["LocalName"]
        : object["ForeignName"];
  };

  const getFullName = (object: any) => {
    if (object === undefined || object === null) return "";
    return object["FirstName"] + " " + object["LastName"];
  };

  const getNameField = () => {
    const languageCode = getLanguageCode();
    if (!languageCode) return "Name";

    if (languageCode.Code === ELanguageCodes.ARABIC.Code) return "LocalName";

    if (languageCode.Code === ELanguageCodes.ENGLISH.Code) return "ForeignName";

    return "Name";
  };
  const translate = (value: string | undefined | null | any) => {
    //return i18n.t(value);

    //#region  Debugging Comments

    //#endregion

    //return t(value); //Can not ensure i18n.language because it backs to the defaults on rendering new components ==> Instead pass Lng from Context
    if (value === undefined || value === null) return "";
    return t(value, {
      lng: locale,
      ns: [translationNamespaces.TRANSLATION, translationNamespaces.BASE],
      ...(translationAdditionalProps || {}),
    });
  };

  // const updateLanguage = async (language) => {
  // 	const newRTL = language === "ar" ? true : false;
  // 	setLocale(language);
  // 	setIsRTL(newRTL);
  // 	await settingStore.storeLanguage(language);
  // 	i18n.locale = language;
  // 	i18n.isRTL = newRTL;
  // 	initReactI18next.I18nManager.forceRTL(newRTL);
  // 	//I18nManager.forceRTL(newRTL);
  // 	initReactI18next.I18nManager.allowRTL(newRTL);
  // 	//I18nManager.allowRTL(newRTL);
  // 	await updateUserLanguage(language);
  // 	if (I18nManager.isRTL !== newRTL) {
  // 		//Updates.reloadAsync();
  // 		i18n.changeLanguage(language);
  // 	}
  // };

  const updateLanguage = async (language: string) => {
    const newRTL = language === "ar";

    //#region enhanced i18next from https://medium.com/@saif.as/localization-react-i18-next-and-rtl-support-including-material-ui-to-a-react-project-eeab31817467
    i18n.changeLanguage(language);
    document.body.dir = i18n.dir(isRTL ? "rtl" : "ltr");
    //theme.direction = i18n.dir(language);
    //#endregion

    setLocale(language);
    setIsRTL(newRTL);
    settingsStorage.storeLanguage(language);
    await updateUserLanguage(language);
  };

  const getLanguageCode = () => {
    if (locale === "ar") {
      return ELanguageCodes.ARABIC;
    }
    return ELanguageCodes.ENGLISH;
  };

  const getIsRTL = () => {
    return isRTL;
  };
  const getTextAlign = () => {
    return isRTL ? "right" : "left";
  };
  const getCurrentLanguage = () => {
    return languages.find((item) => item.code === getLanguageCode());
  };
  const translateIn = (value: string, languageCode: string) => {
    return t(value, {
      lng: languageCode,
      ns: [translationNamespaces.TRANSLATION, translationNamespaces.BASE],
    });
  };

  return (
    <LocalizationContext.Provider
      value={{
        locale,
        isRTL,
        getNameField,
        getName,
        translate,
        updateLanguage,
        getLanguageCode,
        getIsRTL,
        getTextAlign,
        getCurrentLanguage,
        ELanguageCodes,
        translateIn,
        getFullName,
        translationAdditionalProps,
      }}
    >
      <LocalizationProvider language={isRTL ? "ar" : "en"}>
        {children}
      </LocalizationProvider>
    </LocalizationContext.Provider>
  );
}
