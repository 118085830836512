import {
  Map,
  MapLayers,
  MapMarkerLayer,
  MapTileLayer,
  TileUrlTemplateArgs,
  MapProps,
} from "@progress/kendo-react-map";
import { useContext, useEffect, useState } from "react";
import styleConstants from "../../constants/styleConstants";
import { LocalizationContext } from "../../context/localizationContext";
import icons from "../../constants/icons";
import { Button } from "@progress/kendo-react-buttons";

interface Props extends MapProps {
  markers?: any[];
}

export default function AppMap({
  center,
  markers,
  zoomable,
  pannable,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const [centerValue, setCenterValue] = useState(center);

  const tileUrl = (e: TileUrlTemplateArgs) =>
    `https://${e.subdomain}.tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;

  const attribution =
    '&copy; <a href="https://osm.org/copyright">OpenStreetMap contributors</a>';
  const tileSubdomains = ["a", "b", "c"];

  useEffect(() => {
    setCenterValue(center);
    // eslint-disable-next-line
  }, [centerValue, center]);

  return (
    <div style={{ direction: "rtl" }}>
      <Button
        style={{
          margin: styleConstants.GAP_LARGE,
          borderRadius: 99,
          position: "fixed",
          minWidth: 20,
          zIndex: 2,
          borderColor: "#e4e7eb",
        }}
        onClick={() => setCenterValue(undefined)}
        icon={icons.RELOAD}
      />
      {center && (
        <Map
          center={centerValue}
          zoom={18}
          maxZoom={19}
          zoomable={zoomable}
          pannable={pannable}
          wraparound={false}
          {...rest}
        >
          <MapLayers>
            <MapTileLayer
              urlTemplate={tileUrl}
              attribution={attribution}
              subdomains={tileSubdomains}
            />
            <MapMarkerLayer
              data={markers}
              locationField="latlng"
              titleField="name"
            />
          </MapLayers>
        </Map>
      )}
    </div>
  );
}
