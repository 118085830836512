import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const serviceGroups = {
  columns: [
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "200px",
    },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "200px",
    },
    {
      field: "FormCode",
      header: strings.FORM_CODE,
      width: "100px",
    },
    {
      field: "PrescriptionClaimType",
      header: strings.PRESCRIPTION,
      width: "100px",
    },
  ],
  autoCompleteEndpoint: endpoints.SERVICES + "/service-groups",
  autoCompleteSearchKey: "ServiceGroupName",
};

export default serviceGroups;
