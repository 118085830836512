import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import ClientDebitVoucherGroupModel from "../../../interfaces/clientDebitVouchers/ClientDebitVoucherGroupModel";
import clientDebitVouchersApi from "../../../api/clientDebitVouchers";
import ClientDebitVoucherModel from "../../../interfaces/clientDebitVouchers/ClientDebitVoucherModel";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<ClientDebitVoucherModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
}
export default function ClientDebitVoucherAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: "ClientDebitVoucherNumber",
      header: translate(strings.NUMBER),
      width: "175px",
    },
    {
      field: "Date",
      header: translate(strings.DATE),
      width: "175px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return clientDebitVouchersApi.getClientDebitVouchers(
      {
        ClientDebitVoucherIDStartsWith: inputString,
        ...searchCriteria,
      },
      { Page: 0, PageSize: pageDefaultSize }
    );
  };

  return (
    <AppAutoComplete<ClientDebitVoucherModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay="ClientDebitVoucherNumber"
      getData={getData}
      charNumber={1}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
