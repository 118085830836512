import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownSearchField from "../../common/components/search/AppDropDownSearchField";
import ProviderAutoComplete from "../../controls/providers/base/ProviderAutoComplete";
import definitions from "../../api/definitions";
import FileReviewTypeModel from "../../interfaces/fileReviews/FileReviewTypeModel";
import { getDefinitionList } from "../../common/api/searchDefinitionHelper";
import InsuredPersonInformationAutoComplete from "../../controls/insuredPersonInformations/InsuredPersonInformationAutoComplete";
import fileReviewTypeCodes from "../../enums/fileReviews/fileReviewTypeCodes";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppFileReviewStakeholderSearchFieldKeys = {
  FileReviewTypeID: "FileReviewTypeID",
  InsuredPersonInformationID: "InsuredPersonInformationID",
  ProviderID: "ProviderID",
};
export default function AppFileReviewStakeholderSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const [selectedFileReviewType, setSelectedFileReviewType] =
    useState<string>();
  const [entityTypes, setEntityTypes] = useState<FileReviewTypeModel[]>([]);

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = async () => {
    const objectType = definitions.CFileReviewTypeDefinition.objectType;
    const objectList: FileReviewTypeModel[] = await getDefinitionList<
      typeof objectType
    >(
      definitions.CFileReviewTypeDefinition.endpoint,
      definitions.CFileReviewTypeDefinition.callType,
      setErrorMessage
    );
    setEntityTypes(objectList);
  };

  const handleFileReviewTypeIDChange = async (event: any, searchValue: any) => {
    removeSearchFieldValue(
      AppFileReviewStakeholderSearchFieldKeys.InsuredPersonInformationID
    );
    removeSearchFieldValue(AppFileReviewStakeholderSearchFieldKeys.ProviderID);
    if (event.itemIndex !== 0) {
      setSelectedFileReviewType(event.item); // event.item: Name not ID
    } else {
      setSelectedFileReviewType("");
    }
  };

  const renderAutoComplete = (selectedFileReviewType?: string) => {
    const fileReviewType = entityTypes.find(
      (item) =>
        item.ForeignName.toString() === selectedFileReviewType ||
        item.LocalName.toString() === selectedFileReviewType
    );

    if (fileReviewType) {
      switch (fileReviewType.Code) {
        case fileReviewTypeCodes.INSURED:
          return (
            <InsuredPersonInformationAutoComplete
              columns={[]}
              dataList={[]}
              loadDataOnOpen
              placeholder={translate(strings.INSURED)}
              className="search-component"
              onChange={(event) => {
                if (event.value)
                  setSearchFieldValues(
                    AppFileReviewStakeholderSearchFieldKeys.InsuredPersonInformationID,
                    event.value.ID
                  );
                else
                  removeSearchFieldValue(
                    AppFileReviewStakeholderSearchFieldKeys.InsuredPersonInformationID
                  );
              }}
              // searchKey="Name"
            />
          );
        case fileReviewTypeCodes.PROVIDER:
          return (
            <ProviderAutoComplete
              columns={[]}
              dataList={[]}
              loadDataOnOpen
              placeholder={translate(strings.PROVIDER)}
              className="search-component"
              onChange={(event) => {
                if (event.value)
                  setSearchFieldValues(
                    AppFileReviewStakeholderSearchFieldKeys.ProviderID,
                    event.value.ID
                  );
                else
                  removeSearchFieldValue(
                    AppFileReviewStakeholderSearchFieldKeys.ProviderID
                  );
              }}
            />
          );
        default:
          break;
      }
    } else return null;
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AppDropDownSearchField
        definitionType={"CFileReviewTypeDefinition"}
        // definitionType={""}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={AppFileReviewStakeholderSearchFieldKeys.FileReviewTypeID}
        defaultShow={false}
        title={translate(strings.TYPE)}
        searchFieldName={searchFieldName}
        onChange={handleFileReviewTypeIDChange}
      />
      {renderAutoComplete(selectedFileReviewType)}
    </div>
  );
}
