import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const serviceTypes = {
  columns: [
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "200px",
    },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "200px",
    },
    {
      field: "ServiceCategory",
      header: strings.CATEGORY,
      width: "200px",
    },
    {
      field: "ServiceChapter",
      header: strings.CHAPTER,
      width: "200px",
    },
  ],
  autoCompleteEndpoint: endpoints.SERVICES + "/service-types",
  autoCompleteSearchKey: "Name",
};

export default serviceTypes;
