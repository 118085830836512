import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";

// Import all locales
// import languageEN from "../public/locales/en.json";
// import languageAR from "../public/locales/ar.json";
// import languageEN from "./locales/en/translations.json";
// import languageAR from "./locales/ar/translations.json";

// import languageEN from "../assets/locales/en/translation";
// import languageAR from "../assets/locales/ar/translation";
import ELanguageCodes from "../common/base/languageCodes";
// import errorCodesEN from "../assets/locales/en/errorCodes.json";
// import errorCodesAR from "../assets/locales/ar/errorCodes.json";
import urlBuilder from "./../common/helpers/urlBuilder";

//https://react.i18next.com/latest/using-with-hooks

const userLang = navigator.language || navigator.userLang;
const defaultLocale = userLang.substring(0, 2);

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  //init i18next
  //for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //debug: process.env.NODE_ENV === "development", //true,
    supportedLngs: [ELanguageCodes.ARABIC.Code, ELanguageCodes.ENGLISH.Code],
    //to enable adding variables to translations and then passing the values as t parameters
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ",",
    },
    //ns: ["translation", "errorCodes", "quickLinksDescriptions"], //used when calling useTranslation([nameSpace]): nameSpace is the name of the file that contains translations
    defaultNS: ["translation", "base"], //default nameSpace
    // resources: {
    // 	en: languageEN,
    // 	ar: languageAR,
    // },
    // resources: {
    //   en: { translation: languageEN, errorCodes: errorCodesEN },
    //   ar: { translation: languageAR, errorCodes: errorCodesAR },
    // },
    /* default language when load the website in browser */
    lng: defaultLocale, //"en",
    // react: {
    // 	useSuspense: false,
    // },
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: ELanguageCodes.ENGLISH.Code,
    //{{lng}} is passed when calling translate according to the current lng value
    backend: {
      // loadPath: "../assets/locales/{{lng}}/translation.json",
      loadPath: urlBuilder.getUrlPrefix() + "/locales/{{lng}}/{{ns}}.json",
      // parse: (data) => {
      // }
    },
    partialBundledLanguages: true,
  });

export default i18n;
