import { ReactNode } from "react";
import styleConstants from "../../constants/styleConstants";

interface Props {
  children: ReactNode;
  hasActionBar?: boolean;
  titleHasActions?: boolean;
  additionalHeight?: number;
}
export default function ContentContainer({
  children,
  hasActionBar,
  titleHasActions = false,
  additionalHeight,
}: Props) {
  return (
    <div
      style={{
        height:
          "calc(100% - " +
          ((titleHasActions
            ? styleConstants.PAGE_TITLE_HEIGHT_WITH_ACTIONS
            : styleConstants.PAGE_TITLE_HEIGHT) +
            (hasActionBar ? styleConstants.ACTION_BAR_HEIGHT : 0) +
            (additionalHeight || 0)) +
          "px)",
      }}
    >
      {children}
    </div>
  );
}
