import { Dispatch, SetStateAction, useContext, useState } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownButton from "../../common/components/buttons/AppDropDownButton";
import errorHandler from "../../common/utility/errorHandler";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import BusinessObjectModel from "../../common/interfaces/BusinessObjectModel";
import accountingFiscalYearsApi from "../../api/accountingFiscalYears";
import AccountingFiscalYearAutoComplete from "../../controls/accountingFiscalYears/base/AccountingFiscalYearAutoComplete";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppAccountingTransactionFiscalYearPeriodSearchFieldKeys = {
  AccountingFiscalYearID: "AccountingFiscalYearID",
  AccountingPeriodID: "AccountingPeriodID",
};
export default function AppAccountingTransactionFiscalYearPeriodSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { getNameField, translate, getName } = useContext(LocalizationContext);

  const resetObject = {
    ID: 0,
    LocalName: translate(strings.UNSET_THIS_VALUE),
    ForeignName: translate(strings.UNSET_THIS_VALUE),
  };

  const [accountingPeriodFieldName, setAccountingPeriodFieldName] = useState(
    translate(strings.ACCOUNTING_PERIOD)
  );

  const [showAccountingPeriod, setShowAccountingPeriod] = useState(false);

  const [accountingPeriods, setAccountingPeriods] = useState<
    BusinessObjectModel[]
  >([]);

  const getAccountingPeriods = async (fiscalYearID: number) => {
    const response = await accountingFiscalYearsApi.getAccountingPeriods({
      AccountingFiscalYearID: fiscalYearID,
    });

    if (response.ok) {
      if (response.data) setAccountingPeriods([resetObject, ...response.data]);
      else setAccountingPeriods([resetObject]);
    } else setErrorMessage(errorHandler.getErrorMessage(response));
  };

  const getObjectSearchValue = (dropDownObjectList: any[], value: string) => {
    let returnedValue: string = "";
    dropDownObjectList.filter((object) => {
      if (getName(object) === value) returnedValue = object[searchFieldName];
      return returnedValue;
    });
    return returnedValue;
  };

  const handleOnAccountingFiscalYearChanged = async (
    event: ComboBoxChangeEvent
  ) => {
    const value = event.value;

    setAccountingPeriodFieldName(translate(strings.ACCOUNTING_PERIOD));
    getAccountingPeriods(value.ID);
    setShowAccountingPeriod(true);
    onAccountingPeriodChanged(resetObject);

    setSearchFieldValues(
      AppAccountingTransactionFiscalYearPeriodSearchFieldKeys.AccountingFiscalYearID,
      value[searchFieldName]
    );
  };

  const onAccountingPeriodChanged = (accountingPeriod: any) => {
    if (!accountingPeriod) setShowAccountingPeriod(false);
    if (getName(accountingPeriod) === getName(resetObject)) {
      removeSearchFieldValue(
        AppAccountingTransactionFiscalYearPeriodSearchFieldKeys.AccountingPeriodID
      );
      setAccountingPeriodFieldName(translate(strings.ACCOUNTING_PERIOD));
    } else {
      setAccountingPeriodFieldName(
        translate(strings.ACCOUNTING_PERIOD) + ": " + getName(accountingPeriod)
      );
      const searchValue: string = getObjectSearchValue(
        accountingPeriods,
        getName(accountingPeriod)
      );
      setSearchFieldValues(
        AppAccountingTransactionFiscalYearPeriodSearchFieldKeys.AccountingPeriodID,
        searchValue
      );
    }
  };
  const handleAccountingPeriodClick = async (
    event: DropDownButtonItemClickEvent
  ) => {
    const value = event.item;
    onAccountingPeriodChanged(value);
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AccountingFiscalYearAutoComplete
        columns={[]}
        dataList={[]}
        loadDataOnOpen
        placeholder={translate(strings.FISCAL_YEAR)}
        className="search-component"
        onChange={(event) => {
          if (event.value) {
            handleOnAccountingFiscalYearChanged(event);
          } else {
            removeSearchFieldValue(
              AppAccountingTransactionFiscalYearPeriodSearchFieldKeys.AccountingFiscalYearID
            );
            setShowAccountingPeriod(false);
            onAccountingPeriodChanged(resetObject);
          }
        }}
      />
      {showAccountingPeriod && (
        <AppDropDownButton
          className="search-component"
          text={accountingPeriodFieldName}
          icon="k-icon k-font-icon k-i-arrow-chevron-down"
          items={accountingPeriods}
          textField={getNameField()}
          popupSettings={{ popupClass: "popup" }}
          themeColor={"primary"}
          onItemClick={handleAccountingPeriodClick}
        />
      )}
    </div>
  );
}
