import { lazy, Suspense } from "react";
import iconsFa from "../base/iconsFa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-light-svg-icons/faLoader";
import icons from "../common/constants/icons";

const iconSize = "1x";

const FaCircleArrowRight = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faCircleArrowRight").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaCircleC = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faCircleC").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaFolderXmark = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFolderXmark").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaFolderGear = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFolderGear").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaFileCircleCheck = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileCircleCheck").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaClipboardCheck = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faClipboardCheck").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaIdCardClip = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faIdCardClip").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaMoneyCheckDollarPen = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faMoneyCheckDollarPen").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaWreath = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faWreath").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaSwap = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSwap").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);
const FaPhoneVolume = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faPhoneVolume").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaUserLock = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserLock").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaHandHoldingBox = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faHandHoldingBox").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaSquarePollHorizontal = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSquarePollHorizontal").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaIdCard = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faIdCard").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaObjectGroup = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faObjectGroup").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaUsers = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUsers").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);
const FaHospital = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faHospital").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaRegistered = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faRegistered").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaBlock = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faBlock").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);
const FaStethoscope = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faStethoscope").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaKitMedical = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faKitMedical").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaSquareE = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSquareE").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaListTree = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faListTree").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaListAlt = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faListAlt").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaTag = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faTag").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);
const FaSitemap = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSitemap").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaHandHoldingMedical = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faHandHoldingMedical").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaChartNetwork = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faChartNetwork").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaSuitMedical = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSuitcaseMedical").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaBuilding = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faBuilding").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaDollar = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faDollar").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaLayerGroup = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faLayerGroup").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaList = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faList").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);
const FaShieldHalved = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faShieldHalved").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaFolder = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFolder").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaBooksMedical = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faBooksMedical").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaGrid2Plus = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faGrid2Plus").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaNoteMedical = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faNoteMedical").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaCapsules = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faCapsules").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaHourGlassClock = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faHourglassClock").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaShieldXMark = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faShieldXmark").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaShieldPlus = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faShieldPlus").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaMessagePen = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faMessagePen").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaMessageXMark = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faMessageXmark").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaHandshakeSimple = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faHandshakeSimple").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaHandshakeSimpleSlash = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faHandshakeSimpleSlash").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaPeopleRoof = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faPeopleRoof").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaSheetPlastic = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSheetPlastic").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaBook = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faBook").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);
const FaFilePen = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFilePen").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaFileSlash = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileSlash").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaPencilSlash = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faPencilSlash").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaFileArrowDown = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileArrowDown").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaFileCheck = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileCheck").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaFileImport = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileImport").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaFileLines = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileLines").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaSquarePen = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSquarePen").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaBuildingFlag = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faBuildingFlag").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaUserHelmetSafety = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserHelmetSafety").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaHouseChimney = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faHouseChimneyCrack").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaProvider = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserDoctorHair").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaUserShield = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserShield").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaCoins = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faCoins").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);

const FaBuildingShield = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faBuildingShield").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaCalendarClock = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faCalendarClock").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaUserTie = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserTie").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaRotateLeft = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faRotateLeft").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaBell = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faBell").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);
const FaFiles = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFiles").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);
const FaBank = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faBank").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);

const FaSquareC = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSquareC").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaSquareV = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSquareV").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaLockKeyhole = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faLockKeyhole").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaLockKeyholeOpen = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faLockKeyholeOpen").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaFileMedical = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileMedical").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaFileInvoiceDollar = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileInvoiceDollar").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaAddressCard = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faAddressCard").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaFileShield = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileShield").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaBoxesStacked = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faBoxesStacked").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaSackDollar = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSackDollar").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaFlaskGear = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFlaskGear").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaFlaskVial = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFlaskVial").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaPaperPlane = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faPaperPlane").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaSquareY = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faSquareY").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaArrowRightFromArc = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faArrowRightFromArc").then(
    ({ definition }) => ({
      default: () => (
        <FontAwesomeIcon icon={definition} size={iconSize} color="green" />
      ),
    })
  )
);
const FaArrowLeftToArc = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faArrowLeftToArc").then(
    ({ definition }) => ({
      default: () => (
        <FontAwesomeIcon icon={definition} size={iconSize} color="red" />
      ),
    })
  )
);
const FaArrowRightFromArcWhite = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faArrowRightFromArc").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaArrowLeftToArcWhite = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faArrowLeftToArc").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaRadar = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faRadar").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);

const FaCircle1 = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faCircle1").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaCircle2 = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faCircle2").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaCircle3 = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faCircle3").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaCircle4 = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faCircle4").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaUserHeadset = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faHeadset").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaFileExcel = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileExcel").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaHospitalUser = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faHospitalUser").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaPointer = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faBullseyePointer").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaHandsHoldingDollar = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faHandsHoldingDollar").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaArrowUpLeftFromCircle = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faArrowUpLeftFromCircle").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaUserCheck = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserCheck").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaUserTag = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserTag").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaUserSlash = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserSlash").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaShieldCheck = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faShieldCheck").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaUserPen = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserPen").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaFileSignature = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileSignature").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaUserDoctor = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserDoctor").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaMessageDots = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faMessageDots").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaUserDoctorMessage = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faUserDoctorMessage").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaMessageSms = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faMessageSms").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaEarthAmericas = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faEarthAmericas").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaFlag = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFlag").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);

const FaMapLocation = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faMapLocation").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaStaffSnake = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faStaffSnake").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaWallet = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faWallet").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaPrescriptionBottlePill = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faPrescriptionBottlePill").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const FaVault = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faVault").then(({ definition }) => ({
    default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
  }))
);

const FaListCheck = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faListCheck").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);

const FaFileExport = lazy(() =>
  import("@fortawesome/pro-light-svg-icons/faFileExport").then(
    ({ definition }) => ({
      default: () => <FontAwesomeIcon icon={definition} size={iconSize} />,
    })
  )
);
const getIcon = (iconKey: string) => {
  const fetchIcon = () => {
    switch (iconKey) {
      case iconsFa.CIRCLE_ARROW_RIGHT:
        return <FaCircleArrowRight />;
      case iconsFa.CIRCLE_C:
        return <FaCircleC />;
      case iconsFa.FOLDER_X_MARK:
        return <FaFolderXmark />;
      case iconsFa.FOLDER_GEAR:
        return <FaFolderGear />;
      case iconsFa.FILE_CIRCLE_CHECK:
        return <FaFileCircleCheck />;
      case iconsFa.ID_CLIPBOARD_CHECK:
        return <FaClipboardCheck />;
      case iconsFa.ID_CARD_CLIP:
        return <FaIdCardClip />;
      case iconsFa.MONEY_CHECK:
        return <FaMoneyCheckDollarPen />;
      case iconsFa.WREATH:
        return <FaWreath />;
      case iconsFa.SWAP:
        return <FaSwap />;
      case iconsFa.MEDIUM:
        return <FaPhoneVolume />;
      case iconsFa.USER_LOCK:
        return <FaUserLock />;
      case iconsFa.HAND_HOLDING_BOX:
        return <FaHandHoldingBox />;
      case iconsFa.SQUARE_POLL_HORIZONTAL:
        return <FaSquarePollHorizontal />;
      case iconsFa.CARD_ID:
        return <FaIdCard />;
      case iconsFa.OBJECT_GROUP:
        return <FaObjectGroup />;
      case iconsFa.USERS_GROUP:
        return <FaUsers />;
      case iconsFa.HOSPITAL:
        return <FaHospital />;
      case iconsFa.REGISTERED:
        return <FaRegistered />;
      case iconsFa.BLOCK:
        return <FaBlock />;
      case iconsFa.DIAGNOSIS:
        return <FaStethoscope />;
      case iconsFa.KIT_MEDICAL:
        return <FaKitMedical />;
      case iconsFa.SQUARE_E:
        return <FaSquareE />;
      case iconsFa.LIST_TREE:
        return <FaListTree />;
      case iconsFa.LIST_ALT:
        return <FaListAlt />;
      case iconsFa.TAG:
        return <FaTag />;
      case iconsFa.HIERARCHY:
        return <FaSitemap />;
      case iconsFa.SERVICE:
        return <FaHandHoldingMedical />;
      case iconsFa.NETWORK:
        return <FaChartNetwork />;
      case iconsFa.MEDICAL:
        return <FaSuitMedical />;
      case iconsFa.BUILDING:
        return <FaBuilding />;
      case iconsFa.DOLLAR:
        return <FaDollar />;
      case iconsFa.LAYER_GROUP:
        return <FaLayerGroup />;
      case iconsFa.LIST:
        return <FaList />;
      case iconsFa.SHIELD_HALVED:
        return <FaShieldHalved />;
      case iconsFa.FOLDER:
        return <FaFolder />;
      case iconsFa.BOOKS_MEDICAL:
        return <FaBooksMedical />;
      case iconsFa.GRID_2_PLUS:
        return <FaGrid2Plus />;
      case iconsFa.NOTE_MEDICAL:
        return <FaNoteMedical />;
      case iconsFa.CAPSULES:
        return <FaCapsules />;
      case iconsFa.HOURGLASS_CLOCK:
        return <FaHourGlassClock />;
      case iconsFa.SHIELD_XMARK:
        return <FaShieldXMark />;
      case iconsFa.SHIELD_PLUS:
        return <FaShieldPlus />;
      case iconsFa.MESSAGE_PEN:
        return <FaMessagePen />;
      case iconsFa.MESSAGE_XMARK:
        return <FaMessageXMark />;
      case iconsFa.HANDSHAKE_SIMPLE:
        return <FaHandshakeSimple />;
      case iconsFa.HANDSHAKE_SIMPLE_SLASH:
        return <FaHandshakeSimpleSlash />;
      case iconsFa.PEOPLE_ROOF:
        return <FaPeopleRoof />;
      case iconsFa.SHEET_PLASTIC:
        return <FaSheetPlastic />;
      case iconsFa.BOOK:
        return <FaBook />;
      case iconsFa.FILE_PEN:
        return <FaFilePen />;
      case iconsFa.FILE_PENCIL_SLASH:
        return <FaPencilSlash />;
      case iconsFa.FILE_SLASH:
        return <FaFileSlash />;
      case iconsFa.FILE_ARROW_DOWN:
        return <FaFileArrowDown />;
      case iconsFa.FILE_CHECK:
        return <FaFileCheck />;
      case iconsFa.FILE_IMPORT:
        return <FaFileImport />;
      case iconsFa.FILE_LINES:
        return <FaFileLines />;
      case iconsFa.SQUARE_PEN:
        return <FaSquarePen />;
      case iconsFa.BUILDING_FLAG:
        return <FaBuildingFlag />;
      case iconsFa.USER_HELMET:
        return <FaUserHelmetSafety />;
      case iconsFa.HOUSE_CHIMNEY:
        return <FaHouseChimney />;
      case iconsFa.PROVIDER:
        return <FaProvider />;
      case iconsFa.USER_SHIELD:
        return <FaUserShield />;
      case iconsFa.COINS:
        return <FaCoins />;
      case iconsFa.BUILDING_SHIELD:
        return <FaBuildingShield />;
      case iconsFa.CALENDAR_CLOCK:
        return <FaCalendarClock />;
      case iconsFa.ROTATE_LEFT:
        return <FaRotateLeft />;
      case iconsFa.BELL:
        return <FaBell />;
      case iconsFa.USER_TIE:
        return <FaUserTie />;
      case iconsFa.FILES:
        return <FaFiles />;
      case iconsFa.BANK:
        return <FaBank />;
      case iconsFa.SQUARE_C:
        return <FaSquareC />;
      case iconsFa.SQUARE_V:
        return <FaSquareV />;
      case iconsFa.FaLockKeyhole:
        return <FaLockKeyhole />;
      case iconsFa.FaLockKeyholeOpen:
        return <FaLockKeyholeOpen />;
      case iconsFa.FILE_MEDICAL:
        return <FaFileMedical />;
      case iconsFa.FILE_INVOICE_DOLLAR:
        return <FaFileInvoiceDollar />;
      case iconsFa.ADDRESS_CARD:
        return <FaAddressCard />;
      case iconsFa.FILE_SHIELD:
        return <FaFileShield />;
      case iconsFa.BOXES_STACKED:
        return <FaBoxesStacked />;
      case iconsFa.SACK_DOLLAR:
        return <FaSackDollar />;
      case iconsFa.INGREDIENTS:
        return <FaFlaskGear />;
      case iconsFa.CHEMICAL_COMPOUND:
        return <FaFlaskVial />;
      case iconsFa.SEND:
        return <FaPaperPlane />;
      case iconsFa.SQUARE_Y:
        return <FaSquareY />;
      case iconsFa.CHECK_IN:
        return <FaArrowLeftToArc />;
      case iconsFa.CHECK_OUT:
        return <FaArrowRightFromArc />;
      case iconsFa.CHECK_IN_WHITE:
        return <FaArrowLeftToArcWhite />;
      case iconsFa.CHECK_OUT_WHITE:
        return <FaArrowRightFromArcWhite />;
      case iconsFa.RADAR:
        return <FaRadar />;
      case iconsFa.LEVEL_1:
        return <FaCircle1 />;
      case iconsFa.LEVEL_2:
        return <FaCircle2 />;
      case iconsFa.LEVEL_3:
        return <FaCircle3 />;
      case iconsFa.LEVEL_4:
        return <FaCircle4 />;
      case iconsFa.USER_HEADSET:
        return <FaUserHeadset />;
      case iconsFa.EXCEL:
        return <FaFileExcel />;
      case iconsFa.POINTER:
        return <FaPointer />;
      case iconsFa.ARROW_UP_LEFT_FROM_CIRCULE:
        return <FaArrowUpLeftFromCircle />;
      case iconsFa.USER_CHECK:
        return <FaUserCheck />;
      case iconsFa.USER_SLASH:
        return <FaUserSlash />;
      case iconsFa.EDIT_USER:
        return <FaUserPen />;
      case iconsFa.SIGNATURE:
        return <FaFileSignature />;
      case iconsFa.HOSPITAL_USER:
        return <FaHospitalUser />;
      case iconsFa.HANDS_HOLDING_DOLLAR:
        return <FaHandsHoldingDollar />;
      case iconsFa.USER_TAG:
        return <FaUserTag />;
      case iconsFa.SHIELD_CHECK:
        return <FaShieldCheck />;
      case iconsFa.DOCTOR:
        return <FaUserDoctor />;
      case iconsFa.MESSAGE_DOTS:
        return <FaMessageDots />;
      case iconsFa.DOCTOR_COMMUNICATION:
        return <FaUserDoctorMessage />;
      case iconsFa.SMS:
        return <FaMessageSms />;
      case iconsFa.EARTH_AMERICAS:
        return <FaEarthAmericas />;
      case iconsFa.FLAG:
        return <FaFlag />;
      case iconsFa.MAP_LOCATION:
        return <FaMapLocation />;
      case iconsFa.STAFF_SNAKE:
        return <FaStaffSnake />;
      case iconsFa.PERSCRIPTION_BOTTLE_PILL:
        return <FaPrescriptionBottlePill />;
      case iconsFa.WALLET:
        return <FaWallet />;
      case iconsFa.VAULT:
        return <FaVault />;
      case iconsFa.LIST_CHECK:
        return <FaListCheck />;
      //fa-arrows-alt
      case iconsFa.FILE_EXPORT:
        return <FaFileExport />;
    }
  };
  return (
    <Suspense fallback={<FontAwesomeIcon icon={faLoader} />}>
      {fetchIcon()}
    </Suspense>
  );
  // switch (iconKey) {
  //   case iconsFa.MEDICAL:
  //     return lazy(() =>
  //       import("@fortawesome/pro-light-svg-icons/faSuitcaseMedical").then(
  //         ({ definition }) => ({
  //           default: () => (
  //             <FontAwesomeIcon icon={definition} size={iconSize} />
  //           ),
  //         })
  //       )
  //     );
  //   case iconsFa.BUILDING:
  //     return lazy(() =>
  //       import("@fortawesome/pro-light-svg-icons/faBuilding").then(
  //         ({ definition }) => ({
  //           default: () => (
  //             <FontAwesomeIcon icon={definition} size={iconSize} />
  //           ),
  //         })
  //       )
  //     );
  //   default:
  //     return <></>;
  // }
};
const iconFactory = { getIcon };

export default iconFactory;
