import { useContext } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import AppButton from "../buttons/AppButton";
import { LocalizationContext } from "../../context/localizationContext";
import uiHelper from "../../helpers/uiHelper";

interface AppButtonCellProps extends GridCellProps {
  handleOnRowClick?: (e: any) => void;
  getImage?: (dataItem: any) => any;
  icon?: string;
  iconClass?: string;
  imageUrl?: string;
  onClick?: (e: any) => void;
  // enabled?: boolean;
  customCellStatus?: (dataItem: any) => any;
  tooltipTitle?: string;
  tooltipField?: string;
  buttonWidth?: string;
  buttonClasses?: string;
}
export default function AppButtonCell({
  field,
  dataItem,
  handleOnRowClick,
  getImage,
  icon,
  iconClass,
  imageUrl,
  onClick,
  // enabled = true,
  customCellStatus,
  tooltipTitle,
  style,
  className,
  tooltipField,
  buttonWidth,
  buttonClasses,
  ...rest
}: AppButtonCellProps) {
  const { translate } = useContext(LocalizationContext);
  const handleClick = (e: any) => {
    if (handleOnRowClick) handleOnRowClick({ dataItem });
  };

  const handleOnButtonClicked = () => {
    if (onClick) onClick({ dataItem });
  };

  const getCellStatus = () => {
    if (customCellStatus) return customCellStatus(dataItem);
    else return true;
  };

  const renderTooltip = () => {
    let returnValue = "";
    if (tooltipTitle) returnValue = translate(tooltipTitle);
    if (tooltipField)
      // returnValue = dataItem[tooltipField];
      returnValue = uiHelper.getFieldValue(dataItem, tooltipField);
    return returnValue;
  };
  return (
    <td
      role={"gridcell"}
      // tabIndex={0}
      onClick={handleClick}
      title={renderTooltip()}
      style={style}
      className={className}
    >
      <AppButton
        // className="buttons-container-button"
        className={
          buttonClasses ||
          "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command command-button"
        }
        icon={icon}
        iconClass={iconClass}
        imageUrl={imageUrl}
        // imageUrl={getSource()}
        // disabled={!getSource()}
        disabled={!getCellStatus()}
        onClick={handleOnButtonClicked}
        style={
          buttonWidth
            ? { width: buttonWidth, height: "25px" }
            : { width: "30px", height: "25px" }
        }
        type="button"
      >
        {getImage ? getImage(dataItem) : field ? dataItem[field] : null}
      </AppButton>
    </td>
  );
}
