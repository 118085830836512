import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import FundModel from "../../../interfaces/funds/FundModel";
import fundsApi from "../../../api/funds";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<FundModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
}
export default function FundAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: "FundNumber",
      header: translate(strings.NUMBER),
      width: "120px",
    },
    {
      field: "FundStatus",
      header: translate(strings.STATUS),
      width: "120px",
    },
    {
      field: "FundType",
      header: translate(strings.TYPE),
      width: "120px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return fundsApi.getFunds(
      {
        ClientName: inputString,
        ...searchCriteria,
      },
      { Page: 0, PageSize: pageDefaultSize }
    );
  };

  return (
    <AppAutoComplete<FundModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay="FundNumber"
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
