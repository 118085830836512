import apiClient from "../common/api/client";
import PagingModel from "../common/interfaces/PagingModel";
import BaseResponse from "../common/interfaces/api/BaseResponse";
import AdvancePaymentModel from "../interfaces/advancePayments/AdvancePaymentModel";
import endpoints from "./endpoints";

const endpoint = endpoints.ADVANCE_PAYMENTS;

const getAdvancePayments = (
  criteria: Record<string, any>,
  paging: PagingModel
): BaseResponse<AdvancePaymentModel[]> => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...paging,
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const advancePaymentsApi = {
  getAdvancePayments,
};

export default advancePaymentsApi;
