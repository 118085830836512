import { useContext } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import PremiumFunderModel from "../../../interfaces/premiumFunders/PremiumFunderModel";
import partiesApi from "../../../api/parties";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<PremiumFunderModel>;
  loadDataOnOpen?: boolean;
  searchCriteria?: { [key: string]: any };
}

export default function PremiumFunderAutoComplete({
  dataList,
  columns,
  loadDataOnOpen,
  searchCriteria = {},
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    {
      field: "OrganizationModel",
      header: translate(strings.NAME),
      width: "380px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return partiesApi.getPremiumFunders({
      Name: inputString,
      PageSize: 10,
      Page: 0,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<PremiumFunderModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay={"OrganizationModel." + getNameField()}
      getData={getData}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
