import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const chronicForms = {
  columns: [
    {
      field: "ID",
      header: strings.ID,
      width: "100px",
    },
    {
      field: "CardNumber",
      header: strings.CARD_NUMBER,
      width: "100px",
    },
    {
      field: "InsuredPersonInformationModel",
      header: strings.INSURED,
      width: "150px",
    },
    {
      field: "MedicalCaseModel",
      header: strings.MEDICAL_CASE,
      width: "150px",
    },
    {
      field: "ChronicFormStatusModel",
      header: strings.STATUS,
      width: "100px",
    },
  ],
  autoCompleteEndpoint: endpoints.CHRONIC_FORMS + "/chronic-forms",
  autoCompleteSearchKey: "CardNumber",
};

export default chronicForms;
