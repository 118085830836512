import { useContext, useEffect, useState } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import uiHelper from "../../helpers/uiHelper";
import PillNotification from "../buttons/PillNotification";
import { NotificationCell } from "../../helpers/appGridColumnsHelper";
import { LocalizationContext } from "../../context/localizationContext";
import AppInput from "../inputs/AppInput";

interface Props extends GridCellProps {
  editable: boolean;
  onCloseEdit?: (e: any) => Promise<any>;
  handleOnRowClick?: (e: any) => void;
  editableInNew: boolean;
  inlineEdit: boolean;
  onCellClick?: (props: any) => void;
  notificationFieldList: NotificationCell[];
  getImage?: (dataItem: any) => any;
  showContentTooltip: boolean;
  extraClasses?: string;
  translatable?: boolean;
}

export default function AppGridTextBoxCell({
  dataItem,
  editable,
  onCloseEdit,
  field = "",
  onChange,
  handleOnRowClick,
  editableInNew,
  inlineEdit,
  onCellClick,
  notificationFieldList,
  getImage,
  showContentTooltip,
  extraClasses,
  translatable = false,
  ...rest
}: Props) {
  const { getName, translate } = useContext(LocalizationContext);

  const [value, setValue] = useState<string>(
    uiHelper.getFieldValue(dataItem, field) //for handling Complex Binding ADG.DM.getName
      ? uiHelper.getFieldValue(dataItem, field) instanceof Object
        ? getName(uiHelper.getFieldValue(dataItem, field))
        : uiHelper.getFieldValue(dataItem, field) //dataItem[field]
      : ""
  );

  const [originalValue, setOriginalValue] = useState<string>(
    uiHelper.getFieldValue(dataItem, field)
      ? uiHelper.getFieldValue(dataItem, field) instanceof Object
        ? getName(uiHelper.getFieldValue(dataItem, field))
        : uiHelper.getFieldValue(dataItem, field)
      : ""
  );

  const [isEditable, setIsEditable] = useState<boolean>(
    dataItem.inEdit && editable
  );

  useEffect(() => {
    const fieldValue = uiHelper.getFieldValue(dataItem, field);
    setValue(
      fieldValue //for handling Complex Binding ADG.DM.getName
        ? fieldValue instanceof Object
          ? getName(fieldValue)
          : fieldValue //dataItem[field]
        : ""
    );
    setOriginalValue(
      uiHelper.getFieldValue(dataItem, field)
        ? uiHelper.getFieldValue(dataItem, field) instanceof Object
          ? getName(uiHelper.getFieldValue(dataItem, field))
          : uiHelper.getFieldValue(dataItem, field) //dataItem[field]
        : ""
    );
    setIsEditable(dataItem.inEdit && editable);
    // eslint-disable-next-line
  }, [uiHelper.getFieldValue(dataItem, field)]);

  // const handleChange = (e: any) => {
  // if (onChange) {
  //   onChange({
  //     dataIndex: 0,
  //     dataItem: dataItem,
  //     field: field,
  //     syntheticEvent: e.syntheticEvent,
  //     value: e.target.value,
  //   });
  // }
  // };

  const handleClick = (e: any) => {
    if (handleOnRowClick)
      handleOnRowClick({
        dataItem,
      });

    if (dataItem.isNewItem && !editableInNew) return;

    if (inlineEdit === true) setIsEditable(true);
    else if (onCellClick) onCellClick({ dataItem });
  };

  const handleOnBlur = async (e: any) => {
    const newValue = e.target.value ? e.target.value : "";

    await invokeUpdate(e, newValue);
  };

  const handleOnKeyDown = async (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      const newValue = e.target.value ? e.target.value : 0;
      await invokeUpdate(e, newValue);
    }
  };

  const invokeUpdate = async (e: any, newValue: any) => {
    if (e.target.value === originalValue) {
      setValue(originalValue);
      setIsEditable(false);
      return;
    }
    if (dataItem.isNewItem) {
      if (onChange) {
        onChange({
          dataIndex: 0,
          dataItem,
          field,
          syntheticEvent: e.syntheticEvent,
          value: e.target.value,
        });
      }
    } else if (onCloseEdit && !dataItem.isNewItem) {
      const succeed = await onCloseEdit({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: newValue,
      });
      if (succeed === true) {
        setValue(newValue);
      } else {
        setValue(originalValue);
      }
    } else setValue(newValue);
    setIsEditable(false);
  };

  const renderNotifications = () => {
    return (
      <div
        style={{ display: "flex", padding: "2px", justifyContent: "center" }}
      >
        {notificationFieldList.map((notificationItem, index) => {
          const value = uiHelper.getFieldValue(
            dataItem,
            notificationItem.field
          );

          let popupText = "";
          if (notificationItem.notificationPopupTextField) {
            popupText = uiHelper.getFieldValue(
              dataItem,
              notificationItem.notificationPopupTextField
            );
          }

          return (
            value && (
              <PillNotification
                text={notificationItem.title}
                notificationStatus={notificationItem.notificationType}
                key={index}
                className="pill-notification-button"
                notificationPopupText={popupText}
              />
            )
          );
        })}
      </div>
    );
  };

  return (
    <td
      className={extraClasses && extraClasses}
      role={"gridcell"}
      onClick={handleClick}
      onKeyDown={handleOnKeyDown}
      onMouseLeave={() => {
        if (isEditable) setIsEditable(false);
      }}
      title={
        showContentTooltip ? (translatable ? translate(value) : value) : ""
      }
      // tabIndex={isEditable ? -1 : 0}
    >
      <span>{!isEditable && getImage && getImage(dataItem)} </span>
      {isEditable ? (
        <AppInput
          type="text"
          // onChange={handleChange}
          defaultValue={value}
          onBlur={handleOnBlur}
          disabled={!isEditable}
        />
      ) : translatable ? (
        translate(value)
      ) : (
        value
      )}
      {notificationFieldList &&
      !isEditable &&
      !uiHelper.isNullOrEmptyArray(notificationFieldList)
        ? renderNotifications()
        : null}
    </td>
  );
}
