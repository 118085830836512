import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.BROKERS;

const route_broker_contracts = "/broker-contracts";
const route_broker_contract_taxes = "/broker-contract-taxes";
const route_broker_bank_accounts = "/broker-bank-accounts";
const route_broker_direct_payment_recipients =
  "/broker-direct-payment-recipients";

const act_set_payment_method_type = "/set-payment-method-type";
const act_update = "/update";
const act_activate = "/activate";
const act_deactivate = "/deactivate";
const act_cancel = "/cancel";
const act_reinstate = "/reinstate";
const act_de_activate = "/de-activate";
const act_verify = "/verify";
const act_un_verify = "/un-verify";
const act_freeze = "/freeze";
const act_un_freeze = "/un-freeze";

const getBrokers = (criteria) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getBroker = (brokerID) => {
  return apiClient.get(endpoint + "/" + brokerID);
};

const addBroker = (data) => {
  return apiClient.post(endpoint, data);
};

const updateBroker = (brokerID, data) => {
  return apiClient.put(endpoint + "/" + brokerID + act_update, data);
};

const deleteBroker = (brokerID) => {
  return apiClient.delete(endpoint + "/" + brokerID);
};

const addBrokerContract = (brokerID, data) => {
  return apiClient.post(
    endpoint + "/" + brokerID + route_broker_contracts,
    data
  );
};

const updateBrokerContract = (brokerID, brokerContractID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_contracts +
      "/" +
      brokerContractID +
      act_update,
    data
  );
};

const deleteBrokerContract = (brokerID, brokerContractID) => {
  return apiClient.delete(
    endpoint + "/" + brokerID + route_broker_contracts + "/" + brokerContractID
  );
};

const activateBrokerContract = (brokerID, brokerContractID) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_contracts +
      "/" +
      brokerContractID +
      act_activate
  );
};

const deactivateBrokerContract = (brokerID, brokerContractID) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_contracts +
      "/" +
      brokerContractID +
      act_deactivate
  );
};

const cancelBrokerContract = (brokerID, brokerContractID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_contracts +
      "/" +
      brokerContractID +
      act_cancel,
    data
  );
};

const reinstateBrokerContract = (brokerID, brokerContractID) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_contracts +
      "/" +
      brokerContractID +
      act_reinstate
  );
};

const addBrokerContractTax = (brokerID, brokerContractID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      brokerID +
      route_broker_contracts +
      "/" +
      brokerContractID +
      route_broker_contract_taxes,
    data
  );
};

const updateBrokerContractTax = (
  brokerID,
  brokerContractID,
  brokerContractTaxID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_contracts +
      "/" +
      brokerContractID +
      route_broker_contract_taxes +
      "/" +
      brokerContractTaxID +
      act_update,
    data
  );
};

const deleteBrokerContractTax = (
  brokerID,
  brokerContractID,
  brokerContractTaxID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      brokerID +
      route_broker_contracts +
      "/" +
      brokerContractID +
      route_broker_contract_taxes +
      "/" +
      brokerContractTaxID
  );
};

const cancelBrokerContractTax = (
  brokerID,
  brokerContractID,
  brokerContractTaxID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_contracts +
      "/" +
      brokerContractID +
      route_broker_contract_taxes +
      "/" +
      brokerContractTaxID +
      act_cancel,
    data
  );
};

const reinstateBrokerContractTax = (
  brokerID,
  brokerContractID,
  brokerContractTaxID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_contracts +
      "/" +
      brokerContractID +
      route_broker_contract_taxes +
      "/" +
      brokerContractTaxID +
      act_reinstate
  );
};

const addBrokerBankAccount = (brokerID, data) => {
  return apiClient.post(
    endpoint + "/" + brokerID + route_broker_bank_accounts,
    data
  );
};

const removeBrokerBankAccount = (brokerID, brokerBankAccountID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      brokerID +
      route_broker_bank_accounts +
      "/" +
      brokerBankAccountID
  );
};

const activateBrokerBankAccount = (brokerID, brokerBankAccountID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_bank_accounts +
      "/" +
      brokerBankAccountID +
      act_activate,
    data
  );
};

const deActivateBrokerBankAccount = (brokerID, brokerBankAccountID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_bank_accounts +
      "/" +
      brokerBankAccountID +
      act_de_activate,
    data
  );
};

const verifyBrokerBankAccount = (brokerID, brokerBankAccountID) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_bank_accounts +
      "/" +
      brokerBankAccountID +
      act_verify
  );
};

const unverifyBrokerBankAccount = (brokerID, brokerBankAccountID) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_bank_accounts +
      "/" +
      brokerBankAccountID +
      act_un_verify
  );
};

const freezeBrokerBankAccount = (brokerID, brokerBankAccountID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_bank_accounts +
      "/" +
      brokerBankAccountID +
      act_freeze,
    data
  );
};

const unfreezeBrokerBankAccount = (brokerID, brokerBankAccountID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_bank_accounts +
      "/" +
      brokerBankAccountID +
      act_un_freeze,
    data
  );
};

const getBrokerBankAccounts = (brokerID) => {
  return apiClient.get(endpoint + "/" + brokerID + route_broker_bank_accounts);
};

const getBrokerDirectPaymentRecipients = (brokerID) => {
  return apiClient.get(
    endpoint + "/" + brokerID + route_broker_direct_payment_recipients
  );
};

const addBrokerDirectPaymentRecipient = (brokerID, data) => {
  return apiClient.post(
    endpoint + "/" + brokerID + route_broker_direct_payment_recipients,
    data
  );
};

const updateBrokerDirectPaymentRecipient = (
  brokerID,
  brokerDirectPaymentRecipientID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_direct_payment_recipients +
      "/" +
      brokerDirectPaymentRecipientID +
      act_update,
    data
  );
};

const deleteBrokerDirectPaymentRecipient = (
  brokerID,
  brokerDirectPaymentRecipientID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      brokerID +
      route_broker_direct_payment_recipients +
      "/" +
      brokerDirectPaymentRecipientID
  );
};

const activateBrokerDirectPaymentRecipient = (
  brokerID,
  brokerDirectPaymentRecipientID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_direct_payment_recipients +
      "/" +
      brokerDirectPaymentRecipientID +
      act_activate,
    data
  );
};

const deActivateBrokerDirectPaymentRecipient = (
  brokerID,
  brokerDirectPaymentRecipientID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      brokerID +
      route_broker_direct_payment_recipients +
      "/" +
      brokerDirectPaymentRecipientID +
      act_de_activate
  );
};

const setBrokerPaymentMethodType = (brokerID, data) => {
  return apiClient.put(
    endpoint + "/" + brokerID + act_set_payment_method_type,
    data
  );
};

const brokersApi = {
  getBrokers,
  getBroker,
  addBroker,
  updateBroker,
  deleteBroker,
  addBrokerContract,
  updateBrokerContract,
  deleteBrokerContract,
  activateBrokerContract,
  deactivateBrokerContract,
  cancelBrokerContract,
  reinstateBrokerContract,
  addBrokerContractTax,
  updateBrokerContractTax,
  deleteBrokerContractTax,
  cancelBrokerContractTax,
  reinstateBrokerContractTax,
  addBrokerBankAccount,
  removeBrokerBankAccount,
  activateBrokerBankAccount,
  deActivateBrokerBankAccount,
  verifyBrokerBankAccount,
  unverifyBrokerBankAccount,
  freezeBrokerBankAccount,
  unfreezeBrokerBankAccount,
  getBrokerBankAccounts,
  getBrokerDirectPaymentRecipients,
  addBrokerDirectPaymentRecipient,
  updateBrokerDirectPaymentRecipient,
  deleteBrokerDirectPaymentRecipient,
  activateBrokerDirectPaymentRecipient,
  deActivateBrokerDirectPaymentRecipient,
  setBrokerPaymentMethodType,
};

export default brokersApi;
