import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.PERSONS;

const route_roles = "/roles";
const route_person_bank_accounts = "/person-bank-accounts";
const route_person_contacts = "/person-contacts";
const route_person_direct_payment_recipients =
  "/person-direct-payment-recipients";
const route_person_history = "/person-history";
const route_person_workspaces = "/person-workspaces";
const route_person_draft_field_values = "/person-draft-field-values";
const route_person_workspace_matches = "/person-workspace-matches";

const act_set_payment_method_type = "/set-payment-method-type";
const act_activate = "/activate";
const act_de_activate = "/de-activate";
const act_verify = "/verify";
const act_un_verify = "/un-verify";
const act_freeze = "/freeze";
const act_un_freeze = "/un-freeze";
const act_update = "/update";
const act_get_workspace_summary = "/workspace-summary";
const act_download_person_workspace_report =
  "/download-person-workspace-report";
const act_import_person_workspace_drafts = "/import-person-workspace-drafts";
const act_map_values = "/map-values";
const act_check_all_values_mapped = "/check-all-values-mapped";
const act_validate_person_drafts = "/validate-person-drafts";
const act_check_duplicates = "/check-duplicates";
const act_resolve_duplicate = "/resolve-duplicate";
const act_commit = "/commit";
const act_check_matches = "/check-matches";
const act_resolve_match = "/resolve-match";
const act_rollback_match = "/rollback-match";
const act_resolve_all_matches = "/resolve-all-matches";

const getPersons = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addPerson = (data) => {
  return apiClient.post(endpoint, data);
};

const updatePerson = (personID, data) => {
  return apiClient.put(endpoint + "/" + personID, data);
};

const deletePerson = (personID) => {
  return apiClient.delete(endpoint + "/" + personID);
};

const getPersonRoles = (personID) => {
  return apiClient.get(endpoint + "/" + personID + route_roles);
};

const getPersonBankAccounts = (personID) => {
  return apiClient.get(endpoint + "/" + personID + route_person_bank_accounts);
};

const getPersonContacts = (personID) => {
  return apiClient.get(endpoint + "/" + personID + route_person_contacts);
};

const getPersonDirectPaymentRecipients = (personID) => {
  return apiClient.get(
    endpoint + "/" + personID + route_person_direct_payment_recipients
  );
};

const getPersonHistory = (personID) => {
  return apiClient.get(endpoint + "/" + personID + route_person_history);
};

const setPersonPaymentMethodType = (personID, data) => {
  return apiClient.put(
    endpoint + "/" + personID + act_set_payment_method_type,
    data
  );
};

const addPersonBankAccount = (personID, data) => {
  return apiClient.post(
    endpoint + "/" + personID + route_person_bank_accounts,
    data
  );
};

const removePersonBankAccount = (personID, personBankAccountID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      personID +
      route_person_bank_accounts +
      "/" +
      personBankAccountID
  );
};

const activatePersonBankAccount = (personID, personBankAccountID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      personID +
      route_person_bank_accounts +
      "/" +
      personBankAccountID +
      act_activate,
    data
  );
};

const deActivatePersonBankAccount = (personID, personBankAccountID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      personID +
      route_person_bank_accounts +
      "/" +
      personBankAccountID +
      act_de_activate,
    data
  );
};

const verifyPersonBankAccount = (personID, personBankAccountID) => {
  return apiClient.put(
    endpoint +
      "/" +
      personID +
      route_person_bank_accounts +
      "/" +
      personBankAccountID +
      act_verify
  );
};

const unverifyPersonBankAccount = (personID, personBankAccountID) => {
  return apiClient.put(
    endpoint +
      "/" +
      personID +
      route_person_bank_accounts +
      "/" +
      personBankAccountID +
      act_un_verify
  );
};

const freezePersonBankAccount = (personID, personBankAccountID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      personID +
      route_person_bank_accounts +
      "/" +
      personBankAccountID +
      act_freeze,
    data
  );
};

const unfreezePersonBankAccount = (personID, personBankAccountID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      personID +
      route_person_bank_accounts +
      "/" +
      personBankAccountID +
      act_un_freeze,
    data
  );
};

const addPersonContact = (personID, data) => {
  return apiClient.post(
    endpoint + "/" + personID + route_person_contacts,
    data
  );
};

const deletePersonContact = (personID, personContactID) => {
  return apiClient.delete(
    endpoint + "/" + personID + route_person_contacts + "/" + personContactID
  );
};

const addPersonDirectPaymentRecipient = (personID, data) => {
  return apiClient.post(
    endpoint + "/" + personID + route_person_direct_payment_recipients,
    data
  );
};

const updatePersonDirectPaymentRecipient = (
  personID,
  personDirectPaymentRecipientID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      personID +
      route_person_direct_payment_recipients +
      "/" +
      personDirectPaymentRecipientID +
      act_update,
    data
  );
};

const deletePersonDirectPaymentRecipient = (
  personID,
  personDirectPaymentRecipientID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      personID +
      route_person_direct_payment_recipients +
      "/" +
      personDirectPaymentRecipientID
  );
};

const activatePersonDirectPaymentRecipient = (
  personID,
  personDirectPaymentRecipientID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      personID +
      route_person_direct_payment_recipients +
      "/" +
      personDirectPaymentRecipientID +
      act_activate,
    data
  );
};

const deActivatePersonDirectPaymentRecipient = (
  personID,
  personDirectPaymentRecipientID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      personID +
      route_person_direct_payment_recipients +
      "/" +
      personDirectPaymentRecipientID +
      act_de_activate
  );
};

const getPersonWorkspaces = (data) => {
  return apiClient.post(endpoint + route_person_workspaces, data, {
    headers: { "X-HTTP-Method-Override": "GET" },
  });
};

const createPersonWorkspace = (data) => {
  return apiClient.post(
    endpoint + route_person_workspaces,
    JSON.stringify(data)
  );
};

const getPersonWorkspaceSummary = (personWorkspaceID) => {
  return apiClient.get(
    endpoint +
      route_person_workspaces +
      "/" +
      personWorkspaceID +
      act_get_workspace_summary
  );
};

const deletePersonWorkspace = (personWorkspaceID) => {
  return apiClient.delete(
    endpoint + route_person_workspaces + "/" + personWorkspaceID
  );
};

const downloadPersonWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint + route_person_workspaces + act_download_person_workspace_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importPersonWorkspaceDrafts = (
  personWorkspaceID,
  formData,
  onUploadProgress
) => {
  return apiClient.post(
    endpoint + route_person_workspaces + act_import_person_workspace_drafts,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        PersonWorkspaceID: personWorkspaceID,
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const checkDuplicatePersonDrafts = (personWorkspaceID, statusCode) => {
  return apiClient.get(
    endpoint +
      route_person_workspaces +
      "/" +
      personWorkspaceID +
      act_check_duplicates +
      "/" +
      statusCode
  );
};

const resolveDuplicatePersonDraft = (personWorkspaceID, criteria) => {
  return apiClient.put(
    endpoint +
      route_person_workspaces +
      "/" +
      personWorkspaceID +
      act_resolve_duplicate,
    JSON.stringify({ ...criteria })
  );
};

const getPersonDraftFieldValues = (personWorkspaceID, fieldCode) => {
  return apiClient.get(
    endpoint +
      route_person_workspaces +
      "/" +
      personWorkspaceID +
      route_person_draft_field_values +
      "/" +
      fieldCode
  );
};

const updatePersonDraftFieldValues = (personWorkspaceID, fieldCode, data) => {
  return apiClient.put(
    endpoint +
      route_person_workspaces +
      "/" +
      personWorkspaceID +
      route_person_draft_field_values +
      "/" +
      fieldCode +
      act_map_values,
    data
  );
};

const validatePersonDrafts = (personWorkspaceID, data) => {
  return apiClient.put(
    endpoint +
      route_person_workspaces +
      "/" +
      personWorkspaceID +
      act_validate_person_drafts,
    data
  );
};

const commitPersonWorkspace = (personWorkspaceID) => {
  return apiClient.put(
    endpoint + route_person_workspaces + "/" + personWorkspaceID + act_commit
  );
};

const checkAllValuesAreMapped_PersonWorkspace = (workspaceID, fieldCode) => {
  return apiClient.put(
    endpoint +
      route_person_workspaces +
      "/" +
      workspaceID +
      route_person_draft_field_values +
      "/" +
      fieldCode +
      act_check_all_values_mapped
  );
};

const checkPersonDraftMatches = (personWorkspaceID, statusCode) => {
  return apiClient.get(
    endpoint +
      route_person_workspaces +
      "/" +
      personWorkspaceID +
      route_person_workspace_matches +
      act_check_matches +
      "/" +
      statusCode
  );
};

const resolvePersonDraftMatch = (personWorkspaceID, criteria) => {
  return apiClient.put(
    endpoint +
      route_person_workspaces +
      "/" +
      personWorkspaceID +
      route_person_workspace_matches +
      act_resolve_match,
    JSON.stringify({ ...criteria })
  );
};

const rollbackPersonDraftMatch = (personWorkspaceID, personDraftMatchID) => {
  return apiClient.put(
    endpoint +
      route_person_workspaces +
      "/" +
      personWorkspaceID +
      route_person_workspace_matches +
      act_rollback_match +
      "/" +
      personDraftMatchID
  );
};

const resolveAllPersonDraftMatches = (personWorkspaceID) => {
  return apiClient.put(
    endpoint +
      route_person_workspaces +
      "/" +
      personWorkspaceID +
      route_person_workspace_matches +
      act_resolve_all_matches
  );
};

const personsApi = {
  getPersons,
  addPerson,
  updatePerson,
  deletePerson,
  getPersonRoles,
  getPersonBankAccounts,
  getPersonContacts,
  getPersonDirectPaymentRecipients,
  getPersonHistory,
  setPersonPaymentMethodType,
  addPersonBankAccount,
  removePersonBankAccount,
  activatePersonBankAccount,
  deActivatePersonBankAccount,
  verifyPersonBankAccount,
  unverifyPersonBankAccount,
  freezePersonBankAccount,
  unfreezePersonBankAccount,
  addPersonContact,
  deletePersonContact,
  addPersonDirectPaymentRecipient,
  updatePersonDirectPaymentRecipient,
  deletePersonDirectPaymentRecipient,
  activatePersonDirectPaymentRecipient,
  deActivatePersonDirectPaymentRecipient,
  getPersonWorkspaces,
  createPersonWorkspace,
  deletePersonWorkspace,
  getPersonWorkspaceSummary,
  downloadPersonWorkspaceReport,
  importPersonWorkspaceDrafts,
  getPersonDraftFieldValues,
  updatePersonDraftFieldValues,
  checkAllValuesAreMapped_PersonWorkspace,
  validatePersonDrafts,
  checkDuplicatePersonDrafts,
  resolveDuplicatePersonDraft,
  commitPersonWorkspace,
  checkPersonDraftMatches,
  resolvePersonDraftMatch,
  rollbackPersonDraftMatch,
  resolveAllPersonDraftMatches,
};

export default personsApi;
