import { Dispatch, SetStateAction, useContext, useState } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownSearchField from "../../common/components/search/AppDropDownSearchField";
import PolicyAutoComplete from "../../controls/policy/base/PolicyAutoComplete";
import ICMPaymentVoucherGroupAutoComplete from "../../controls/pvis/base/ICMPaymentVoucherGroupAutoComplete";
import ClientDebitVoucherAutoComplete from "../../controls/clientDebitVouchers/base/ClientDebitVoucherAutoComplete";
import CheckupAgreementAutoComplete from "../../controls/checkup/base/CheckupAgreementAutoComplete";
import CheckupInvoiceAutoComplete from "../../controls/checkup/base/CheckupInvoiceAutoComplete";
import FundAutoComplete from "../../controls/funds/base/FundAutoComplete";
import ClaimInvoiceAutoComplete from "../../controls/claims/base/ClaimInvoiceAutoComplete";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppInvoiceEntitySearchFieldKeys = {
  EntityType: "EntityType",
  EntityID: "EntityID",
};
export default function AppInvoiceEntitySearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "Code",
  setErrorMessage,
}: Props) {
  const { translate, translateIn } = useContext(LocalizationContext);

  const [entityType, setEntityType] = useState<string>();

  const EEntities = {
    POLICY: translate(strings.POLICY),
    FUND: translate(strings.FUND),
    CHECKUP_AGREEMENT: translate(strings.CHECKUP_AGREEMENT),
    CHECKUP_INVOICE: translate(strings.CHECKUP_INVOICE),
    PVI_GROUP: translate(strings.PVI_GROUP),
    CLIENT_DEBIT_VOUCHER: translate(strings.CDV),
    CLAIM_INVOICE: translate(strings.CLAIM_INVOICE),
  };

  const getEntityTypes = async () => {
    const entityTypes = [
      {
        ID: 1,
        LocalName: translateIn(strings.POLICY, "ar"),
        ForeignName: translateIn(strings.POLICY, "en"),
        Code: "POLICY",
      },
      {
        ID: 2,
        LocalName: translateIn(strings.FUND, "ar"),
        ForeignName: translateIn(strings.FUND, "en"),
        Code: "FUND",
      },
      {
        ID: 3,
        LocalName: translateIn(strings.CHECKUP_AGREEMENT, "ar"),
        ForeignName: translateIn(strings.CHECKUP_AGREEMENT, "en"),
        Code: "CHECKUP_AGREEMENT",
      },
      {
        ID: 4,
        LocalName: translateIn(strings.CHECKUP_INVOICE, "ar"),
        ForeignName: translateIn(strings.CHECKUP_INVOICE, "en"),
        Code: "CHECKUP_INVOICE",
      },
      {
        ID: 5,
        LocalName: translateIn(strings.PVI_GROUP, "ar"),
        ForeignName: translateIn(strings.PVI_GROUP, "en"),
        Code: "PVI_GROUP",
      },
      {
        ID: 6,
        LocalName: translateIn(strings.CDV, "ar"),
        ForeignName: translateIn(strings.CDV, "en"),
        Code: "CLIENT_DEBIT_VOUCHER",
      },
      {
        ID: 7,
        LocalName: translateIn(strings.CLAIM_INVOICE, "ar"),
        ForeignName: translateIn(strings.CLAIM_INVOICE, "en"),
        Code: "CLAIM_INVOICE",
      },
    ];

    return entityTypes;
  };

  const handleEntityTypeChange = async (
    event: DropDownButtonItemClickEvent,
    searchValue: string
  ) => {
    removeSearchFieldValue(AppInvoiceEntitySearchFieldKeys.EntityID);
    if (event.itemIndex !== 0) {
      setEntityType(event.item);
    } else {
      setEntityType("");
    }
  };

  const renderAutoComplete = (entityType?: string) => {
    switch (entityType) {
      case EEntities.POLICY:
        return (
          <PolicyAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.POLICY)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppInvoiceEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppInvoiceEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.FUND:
        return (
          <FundAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.FUND)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppInvoiceEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppInvoiceEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.CHECKUP_AGREEMENT:
        return (
          <CheckupAgreementAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.CHECKUP_AGREEMENT)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppInvoiceEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppInvoiceEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.CHECKUP_INVOICE:
        return (
          <CheckupInvoiceAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.CHECKUP_INVOICE)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppInvoiceEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppInvoiceEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.PVI_GROUP:
        return (
          <ICMPaymentVoucherGroupAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PVI_GROUP)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppInvoiceEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppInvoiceEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.CLIENT_DEBIT_VOUCHER:
        return (
          <ClientDebitVoucherAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.CDV)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppInvoiceEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppInvoiceEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.CLAIM_INVOICE:
        return (
          <ClaimInvoiceAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.CLAIM_INVOICE)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppInvoiceEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppInvoiceEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      default:
        break;
    }
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AppDropDownSearchField
        definitionType={""}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={AppInvoiceEntitySearchFieldKeys.EntityType}
        defaultShow={false}
        title={translate(strings.ENTITY)}
        searchFieldName={searchFieldName}
        onChange={handleEntityTypeChange}
        useCustomBinding
        getCustomList={getEntityTypes}
      />
      {renderAutoComplete(entityType)}
    </div>
  );
}
