import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const accountingFiscalYears = {
  columns: [
    { field: "ID", header: strings.ID, width: "75px" },
    { field: "LocalName", header: strings.LOCAL_NAME, width: "100px" },
    { field: "ForeignName", header: strings.FOREIGN_NAME, width: "100px" },
    {
      field: "FromDate",
      header: strings.FROM_DATE,
      width: "100px",
    },
    {
      field: "ToDate",
      header: strings.TO_DATE,
      width: "100px",
    },
    {
      field: "InstallmentPeriod",
      header: strings.PERIOD_TYPE,
      width: "100px",
    },
  ],
  autoCompleteEndpoint: endpoints.ACCOUNTING_FISCAL_YEARS,
  autoCompleteSearchKey: "AccountingFiscalYearName",
};

export default accountingFiscalYears;
