import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import BrokerModel from "../../../interfaces/brokers/BrokerModel";
import brokersApi from "../../../api/brokers";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<BrokerModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
}
export default function BrokerAutocomplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    // { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: "LocalName",
      header: translate(strings.LOCAL_NAME),
      width: "120px",
    },
    {
      field: "ForeignName",
      header: translate(strings.FOREIGN_NAME),
      width: "120px",
    },
    {
      field: "InsuranceCompanyModel",
      header: translate(strings.INSURANCE_COMPANY),
      width: "250px",
    },
    {
      field: "CountryModel",
      header: translate(strings.COUNTRY),
      width: "150px",
    },
    {
      field: "CityModel",
      header: translate(strings.CITY),
      width: "250px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return brokersApi.getBrokers({
      Name: inputString,
      Page: 0,
      PageSize: pageDefaultSize,
    });
  };

  return (
    <AppAutoComplete<BrokerModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
