import { Loader } from "@progress/kendo-react-indicators";
import { useContext } from "react";
import baseStrings from "../../common/base/baseStrings";
import { LocalizationContext } from "../../common/context/localizationContext";

export default function SuspenseComponent() {
  const { translate } = useContext(LocalizationContext);
  return (
    <div className="not-found">
      <div className="middle">
        <Loader type="infinite-spinner" size="large" />
        <p>{translate(baseStrings.WORKING)}</p>
      </div>
    </div>
  );
}
