import { Dispatch, SetStateAction, useContext, useState } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownSearchField from "../../common/components/search/AppDropDownSearchField";
import ProviderAutoComplete from "../../controls/providers/base/ProviderAutoComplete";
import BrokerAutocomplete from "../../controls/brokers/base/BrokerAutocomplete";
import OrganizationAutoComplete from "../../controls/organizations/base/OrganizationAutoComplete";
import PersonAutoComplete from "../../controls/persons/base/PersonAutoComplete";
import InsuredPersonInformationAutoComplete from "../../controls/insuredPersonInformations/InsuredPersonInformationAutoComplete";
import VendorAutoComplete from "../../controls/vendors/base/VendorAutoComplete";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppBillPaymentEntitySearchFieldKeys = {
  EntityType: "EntityType",
  EntityID: "EntityID",
};
export default function AppBillPaymentEntitySearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "Code",
  setErrorMessage,
}: Props) {
  const { translate, translateIn } = useContext(LocalizationContext);

  const [entityType, setEntityType] = useState<string>();

  const EEntities = {
    PROVIDER: translate(strings.PROVIDER),
    BROKER: translate(strings.BROKER),
    TPA_COMPANY: translate(strings.TPA_COMPANY),
    ORGANIZATION: translate(strings.ORGANIZATION),
    PERSON: translate(strings.PERSON),
    INSURED: translate(strings.INSURED),
    VENDOR: translate(strings.VENDOR),
  };

  const getEntityTypes = async () => {
    const entityTypes = [
      {
        ID: 1,
        LocalName: translateIn(strings.PROVIDER, "ar"),
        ForeignName: translateIn(strings.PROVIDER, "en"),
        Code: "PROVIDER",
      },
      {
        ID: 2,
        LocalName: translateIn(strings.BROKER, "ar"),
        ForeignName: translateIn(strings.BROKER, "en"),
        Code: "BROKER",
      },
      {
        ID: 3,
        LocalName: translateIn(strings.TPA_COMPANY, "ar"),
        ForeignName: translateIn(strings.TPA_COMPANY, "en"),
        Code: "TPA_COMPANY",
      },
      {
        ID: 4,
        LocalName: translateIn(strings.ORGANIZATION, "ar"),
        ForeignName: translateIn(strings.ORGANIZATION, "en"),
        Code: "ORGANIZATION",
      },
      {
        ID: 5,
        LocalName: translateIn(strings.PERSON, "ar"),
        ForeignName: translateIn(strings.PERSON, "en"),
        Code: "PERSON",
      },
      {
        ID: 6,
        LocalName: translateIn(strings.INSURED, "ar"),
        ForeignName: translateIn(strings.INSURED, "en"),
        Code: "INSURED",
      },
      {
        ID: 7,
        LocalName: translateIn(strings.VENDOR, "ar"),
        ForeignName: translateIn(strings.VENDOR, "en"),
        Code: "VENDOR",
      },
    ];

    return entityTypes;
  };

  const handleEntityTypeChange = async (
    event: DropDownButtonItemClickEvent,
    searchValue: string
  ) => {
    removeSearchFieldValue(AppBillPaymentEntitySearchFieldKeys.EntityID);
    if (event.itemIndex !== 0) {
      setEntityType(event.item);
    } else {
      setEntityType("");
    }
  };

  const renderAutoComplete = (entityType?: string) => {
    switch (entityType) {
      case EEntities.PROVIDER:
        return (
          <ProviderAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PROVIDER)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppBillPaymentEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppBillPaymentEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.BROKER:
        return (
          <BrokerAutocomplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.BROKER)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppBillPaymentEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppBillPaymentEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.TPA_COMPANY:
        return (
          <AppDropDownSearchField
            definitionType="CTPACompanyDefinition"
            setErrorMessage={setErrorMessage}
            removeSearchFieldValue={removeSearchFieldValue}
            setSearchFieldValues={setSearchFieldValues}
            criteriaTitle={AppBillPaymentEntitySearchFieldKeys.EntityID}
            defaultShow={true}
            title={translate(strings.TPA_COMPANY)}
            searchFieldName={"ID"}
          />
        );
      case EEntities.ORGANIZATION:
        return (
          <OrganizationAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.ORGANIZATION)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppBillPaymentEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppBillPaymentEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.PERSON:
        return (
          <PersonAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PERSON)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppBillPaymentEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppBillPaymentEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.INSURED:
        return (
          <InsuredPersonInformationAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.INSURED)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppBillPaymentEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppBillPaymentEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      case EEntities.VENDOR:
        return (
          <VendorAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.VENDOR)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppBillPaymentEntitySearchFieldKeys.EntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppBillPaymentEntitySearchFieldKeys.EntityID
                );
            }}
          />
        );
      default:
        break;
    }
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AppDropDownSearchField
        definitionType={""}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={AppBillPaymentEntitySearchFieldKeys.EntityType}
        defaultShow={false}
        title={translate(strings.ENTITY)}
        searchFieldName={searchFieldName}
        onChange={handleEntityTypeChange}
        useCustomBinding
        getCustomList={getEntityTypes}
      />
      {renderAutoComplete(entityType)}
    </div>
  );
}
