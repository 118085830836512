import { useContext } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import ClientModel from "../../../interfaces/client/ClientModel";
import clientsApi from "../../../api/clients";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<ClientModel>;
  loadDataOnOpen?: boolean;
  searchCriteria?: { [key: string]: any };
  displayContractName?: boolean;
}

export default function ClientAutoComplete({
  dataList,
  columns,
  loadDataOnOpen,
  searchCriteria = {},
  displayContractName,
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const nameColumns = displayContractName
    ? [
        {
          field: "Contract.LocalName",
          header: strings.LOCAL_NAME,
          width: "380px",
        },
        {
          field: "Contract.ForeignName",
          header: strings.FOREIGN_NAME,
          width: "400px",
        },
      ]
    : [
        {
          field: "LocalName",
          header: translate(strings.LOCAL_NAME),
          width: "380px",
        },
        {
          field: "ForeignName",
          header: translate(strings.FOREIGN_NAME),
          width: "400px",
        },
      ];

  const objectColumns = [
    {
      field: "ID",
      header: translate(strings.ID),
      width: "100px",
    },
    // {
    //   field: "LocalName",
    //   header: translate(strings.LOCAL_NAME),
    //   width: "380px",
    // },
    // {
    //   field: "ForeignName",
    //   header: translate(strings.FOREIGN_NAME),
    //   width: "400px",
    // },
    ...nameColumns,
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return clientsApi.getClients({
      ClientName: inputString,
      PageSize: 10,
      Page: 0,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<ClientModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay={
        displayContractName ? "Contract." + getNameField() : getNameField()
      }
      getData={getData}
      loadDataOnOpen={loadDataOnOpen}
      searchCriteria={searchCriteria}
      {...rest}
    />
  );
}
