import {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import errorHandler from "../../common/utility/errorHandler";
import strings from "../../base/strings";
import officialLettersApi from "../../api/officialLetters";
import { dummyItemID } from "../../common/constants/appConstants";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import AppDropDownButton from "../../common/components/buttons/AppDropDownButton";
import OfficialLetterTypeModel from "../../interfaces/officialLetters/OfficialLetterTypeModel";
import definitions from "../../api/definitions";
import { getDefinitionList } from "../../common/api/searchDefinitionHelper";
import BusinessObjectModel from "../../common/interfaces/BusinessObjectModel";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppAuthorizedSignatoryTypeClassSearchFieldKeys = {
  OfficialLetterTypeID: "OfficialLetterTypeID",
  AuthorizedSignatoryClassID: "AuthorizedSignatoryClassID",
};
export default function AppAuthorizedSignatoryTypeClassSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
  defaultValue,
}: Props) {
  const { translate, getNameField, getName } = useContext(LocalizationContext);

  const resetObject = {
    ID: dummyItemID,
    LocalName: translate(strings.UNSET_THIS_VALUE),
    ForeignName: translate(strings.UNSET_THIS_VALUE),
  };

  const [officialLetterTypeFieldName, setOfficialLetterTypeFieldName] =
    useState(translate(strings.OFFICIAL_LETTER_TYPE));
  const [
    authorizedSignatoryClassFieldName,
    setAuthorizedSignatoryClassFieldName,
  ] = useState(translate(strings.AUTHORIZED_SIGNATORY_CLASS));

  const [officialLetterTypes, setOfficialLetterTypes] = useState<
    OfficialLetterTypeModel[]
  >([]);
  const [authorizedSignatoryClasses, setAuthorizedSignatoryClasses] = useState<
    BusinessObjectModel[]
  >([]);

  const [showAuthorizedSignatoryClass, setShowAuthorizedSignatoryClass] =
    useState<boolean>(false);

  const getAuthorizedSignatoryClasses = async (
    officialLetterTypeID: number
  ) => {
    const response = await officialLettersApi.getAuthorizedSignatoryClasses({
      OfficialLetterTypeID: officialLetterTypeID,
    });
    if (!response.ok) {
      setAuthorizedSignatoryClasses([resetObject]);
      setErrorMessage(errorHandler.getErrorMessage(response));
    } else {
      if (response.data)
        setAuthorizedSignatoryClasses([resetObject, ...response.data]);
      else setAuthorizedSignatoryClasses([resetObject]);
    }
  };

  const getObjectSearchValue = (dropDownObjectList: any[], value: string) => {
    let returnedValue: string = "";
    dropDownObjectList.filter((object) => {
      if (getName(object) === value) returnedValue = object[searchFieldName];
      return returnedValue;
    });
    return returnedValue;
  };

  const handleOfficialLetterTypeCLick = async (
    event: DropDownButtonItemClickEvent
  ) => {
    const value = event.item;
    if (!value) setShowAuthorizedSignatoryClass(false);
    setAuthorizedSignatoryClassFieldName(
      translate(strings.AUTHORIZED_SIGNATORY_CLASS)
    );
    if (getName(event.item) === getName(resetObject)) {
      removeSearchFieldValue(
        AppAuthorizedSignatoryTypeClassSearchFieldKeys.OfficialLetterTypeID
      );
      setOfficialLetterTypeFieldName(translate(strings.OFFICIAL_LETTER_TYPE));
      setShowAuthorizedSignatoryClass(false);
      onAuthorizedSignatoryClassChanged(resetObject);
    } else {
      await getAuthorizedSignatoryClasses(event.item.ID);
      setOfficialLetterTypeFieldName(
        translate(strings.OFFICIAL_LETTER_TYPE) + ": " + getName(event.item)
      );
      setShowAuthorizedSignatoryClass(true);
      const searchValue: string = getObjectSearchValue(
        officialLetterTypes,
        getName(value)
      );
      onAuthorizedSignatoryClassChanged(resetObject);
      setSearchFieldValues(
        AppAuthorizedSignatoryTypeClassSearchFieldKeys.OfficialLetterTypeID,
        searchValue
      );
    }
  };

  const onAuthorizedSignatoryClassChanged = (authorizedSignatoryClass: any) => {
    if (!authorizedSignatoryClass) setShowAuthorizedSignatoryClass(false);
    if (getName(authorizedSignatoryClass) === getName(resetObject)) {
      removeSearchFieldValue(
        AppAuthorizedSignatoryTypeClassSearchFieldKeys.AuthorizedSignatoryClassID
      );
      setAuthorizedSignatoryClassFieldName(
        translate(strings.AUTHORIZED_SIGNATORY_CLASS)
      );
    } else {
      setAuthorizedSignatoryClassFieldName(
        translate(strings.AUTHORIZED_SIGNATORY_CLASS) +
          ": " +
          getName(authorizedSignatoryClass)
      );
      const searchValue: string = getObjectSearchValue(
        authorizedSignatoryClasses,
        getName(authorizedSignatoryClass)
      );
      setSearchFieldValues(
        AppAuthorizedSignatoryTypeClassSearchFieldKeys.AuthorizedSignatoryClassID,
        searchValue
      );
    }
  };

  const handleAuthorizedSignatoryClassClick = async (
    event: DropDownButtonItemClickEvent
  ) => {
    const value = event.item;
    onAuthorizedSignatoryClassChanged(value);
  };

  const getOfficialLetterTypes = async () => {
    const officialLetterTypeObjectType =
      definitions.COfficialLetterTypeDefinition.objectType;
    const officialLetterTypeList: OfficialLetterTypeModel[] =
      await getDefinitionList<typeof officialLetterTypeObjectType>(
        definitions.COfficialLetterTypeDefinition.endpoint,
        definitions.COfficialLetterTypeDefinition.callType,
        setErrorMessage
      );
    setOfficialLetterTypes([resetObject, ...officialLetterTypeList]);

    if (defaultValue) {
      setSearchFieldValues(
        AppAuthorizedSignatoryTypeClassSearchFieldKeys.OfficialLetterTypeID,
        defaultValue.ID
      );
      setOfficialLetterTypeFieldName(
        translate(strings.OFFICIAL_LETTER_TYPE) + ": " + getName(defaultValue)
      );
      setShowAuthorizedSignatoryClass(true);
      await getAuthorizedSignatoryClasses(defaultValue.ID);
    }
  };

  useEffect(() => {
    getOfficialLetterTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ display: "contents" }}>
      <AppDropDownButton
        className="search-component"
        text={officialLetterTypeFieldName}
        icon="k-icon k-font-icon k-i-arrow-chevron-down"
        items={officialLetterTypes}
        textField={getNameField()}
        popupSettings={{ popupClass: "popup" }}
        themeColor={"primary"}
        onItemClick={handleOfficialLetterTypeCLick}
        disabled={!!defaultValue}
      />
      {showAuthorizedSignatoryClass && (
        <AppDropDownButton
          className="search-component"
          text={authorizedSignatoryClassFieldName}
          icon="k-icon k-font-icon k-i-arrow-chevron-down"
          items={authorizedSignatoryClasses}
          textField={getNameField()}
          popupSettings={{ popupClass: "popup" }}
          themeColor={"primary"}
          onItemClick={handleAuthorizedSignatoryClassClick}
        />
      )}
    </div>
  );
}
