import { useContext, useState } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import ProviderModel from "./../../../interfaces/providers/ProviderModel";
import providers from "./../../../api/providers";
import strings from "../../../base/strings";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";

interface ProviderAutoCompleteProps extends MultiColumnComboBoxProps {
  dataList?: Array<ProviderModel>;
  provider?: ProviderModel | null;
  searchCriteria?: Object;
  charNumber?: number;
  loadDataOnOpen?: boolean;
  isFormComponent?: boolean;
  searchKey?: string;
}

export default function ProviderAutoComplete({
  dataList,
  columns,
  provider,
  charNumber = 2,
  loadDataOnOpen,
  isFormComponent,
  searchKey = "ProviderNumberOrName",
  ...rest
}: ProviderAutoCompleteProps) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const [data, setData] = useState(dataList);

  const objectColumns = [
    {
      field: "ProviderNumber",
      header: translate(strings.NUMBER),
      width: "75px",
    },
    { field: getNameField(), header: translate(strings.Name), width: "150px" },
    {
      field: "ProviderCode",
      header: translate(strings.CODE),
      width: "75px",
    },
    {
      // field: "getName(ProviderTypeModel)",
      field: "ProviderTypeModel",
      header: translate(strings.PROVIDER_TYPE),
      width: "100px",
    },
    {
      field: "ProviderSpecialtyModel",
      header: translate(strings.PROVIDER_SPECIALTY),
      width: "100px",
    },
    {
      field: "CityModel",
      header: translate(strings.CITY),
      width: "75px",
    },
    {
      field: "LocationModel",
      header: translate(strings.LOCATION),
      width: "75px",
    },
    {
      field: "Mobile",
      header: translate(strings.MOBILE),
      width: "100px",
    },
  ];

  const getData = async (inputString?: string, searchCriteria?: Object) => {
    const response = await providers.getProviders({
      [searchKey]: inputString,
      PageSize: 10,
      Page: 0,
      ...searchCriteria,
    });
    if (response.ok) {
      setData(response.data);
    }

    return response;
  };

  return (
    // <>
    //   <FormMultiColumnComboBox
    //     filterable={true}
    //     dataList={providerList}
    //     textField={getNameField()}
    //     columns={objectColumns}
    //     onFilterChange={handleOnFilterChange}
    //     // opened={true}
    //     // noDataMessage={messages.NO_DATA}
    //     itemRender={itemRender}
    //     loadingData={loading}
    //     onOpen={handleOpen}
    //     {...rest}
    //   />
    //   {errorMessage && (
    //     <Alert message={errorMessage} setMessage={setErrorMessage} />
    //   )}
    // </>
    <AppAutoComplete<ProviderModel>
      dataList={data || []}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      loadDataOnOpen={loadDataOnOpen}
      charNumber={charNumber}
      isFormComponent={isFormComponent}
      {...rest}
    />
  );
}
