import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import ProviderAutoComplete from "../../controls/providers/base/ProviderAutoComplete";
import AppDatePicker from "../../common/components/inputs/AppDatePicker";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppProviderSearchFieldKeys = {
  ProviderID: "ProviderID",
  AtDate: "AtDate",
};
export default function AppProviderSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const [showAtDate, setShowAtDate] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleProviderChange = async (event: any) => {
    const value = event.value;
    if (!value) {
      setShowAtDate(false);
      removeSearchFieldValue(AppProviderSearchFieldKeys.ProviderID);
      removeSearchFieldValue(AppProviderSearchFieldKeys.AtDate);
    } else {
      setShowAtDate(true);
      const searchValue: number = value.ID;
      setSearchFieldValues(AppProviderSearchFieldKeys.ProviderID, searchValue);
    }
  };

  const handleAtDateChange = async (event: any) => {
    const value = event.value;

    if (value) {
      const searchValue: Date = value;
      setSearchFieldValues(AppProviderSearchFieldKeys.AtDate, searchValue);
    } else {
      removeSearchFieldValue(AppProviderSearchFieldKeys.AtDate);
    }
  };

  return (
    <div style={{ display: "inline-flex" }}>
      <span className="search-component">
        <ProviderAutoComplete
          columns={[]}
          onChange={handleProviderChange}
          placeholder={translate(strings.PROVIDER)}
        />
      </span>
      {showAtDate && (
        <AppDatePicker
          className="search-component"
          onChange={handleAtDateChange}
          placeholder={translate(strings.AT_DATE)}
          rounded="full"
          fillMode={"outline"}
        />
      )}
    </div>
  );
}
