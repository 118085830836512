import SearchFieldModel from "../../interfaces/SearchFieldModel";
import AppInput from "../inputs/AppInput";

export default function AppTextSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  criteriaTitle,
  title,
  defaultShow,
  defaultValue,
  showSearchFieldLabel,
}: SearchFieldModel) {
  const handleChange = (event: any) => {
    const value = event.target.value;
    if (value.length === 0) removeSearchFieldValue(criteriaTitle);
    else setSearchFieldValues(criteriaTitle, value);
  };
  return (
    <AppInput
      className="search-component"
      onChange={handleChange}
      placeholder={showSearchFieldLabel ? "" : title}
      autoFocus={!defaultShow}
      defaultValue={defaultValue}
      label={showSearchFieldLabel ? title : ""}
    />
  );
}
