import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.FUNDS;

const route_fund_organization_branches = "/fund-organization-branches";
const route_fund_taxes = "/fund-taxes";
const route_fund_amounts = "/fund-amounts";
const route_fund_installments = "/fund-installments";
const route_fund_coverage_plans = "/fund-coverage-plans";
const route_fund_coverage_plan_products = "/fund-coverage-plan-products";
const route_fund_coverage_plan_insured = "/fund-coverage-plan-insured";
const route_fund_coverage_plan_benefits = "/fund-coverage-plan-benefits";

const act_update_fund = "/update-fund";
const act_issue_fund = "/issue-fund";
const act_unissue_fund = "/unissue-fund";
const act_close_fund = "/close-fund";
const act_reopen_fund = "/reopen-fund";
const act_cancel_fund = "/cancel-fund";
const act_reinstate_fund = "/reinstate-fund";
const act_sync_fund = "/sync-fund";
const act_recalculate_fund = "/recalculate-fund";
const act_create_fund_endorsement = "/create-fund-endorsement";

const act_update_fund_organization_branch = "/update-fund-organization-branch";
const act_cancel_fund_organization_branch = "/cancel-fund-organization-branch";
const act_reinstate_fund_organization_branch =
  "/reinstate-fund-organization-branch";
const act_update_fund_tax = "/update-fund-tax";
const act_cancel_fund_tax = "/cancel-fund-tax";
const act_update_fund_amount = "/update-fund-amount";
const act_issue_fund_amount = "/issue-fund-amount";
const act_un_issue_fund_amount = "/un-issue-fund-amount";

const act_update_fund_coverage_plan = "/update-fund-coverage-plan";
const act_cancel_fund_coverage_plan = "/cancel-fund-coverage-plan";
const act_reinstate_fund_coverage_plan = "/reinstate-fund-coverage-plan";

const act_cancel_fund_coverage_plan_insured =
  "/cancel-fund-coverage-plan-insured";
const act_reinstate_fund_coverage_plan_insured =
  "/reinstate-fund-coverage-plan-insured";

const act_update_fund_coverage_plan_product =
  "/update-fund-coverage-plan-product";
const act_cancel_fund_coverage_plan_product =
  "/cancel-fund-coverage-plan-product";
const act_reinstate_fund_coverage_plan_product =
  "/reinstate-fund-coverage-plan-product";

const act_update_fund_coverage_plan_benefit =
  "/update-fund-coverage-plan-benefit";
const act_cancel_fund_coverage_plan_benefit =
  "/cancel-fund-coverage-plan-benefit";
const act_reinstate_fund_coverage_plan_benefit =
  "/reinstate-fund-coverage-plan-benefit";

const getFunds = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getFund = (fundID) => {
  return apiClient.get(endpoint + "/" + fundID);
};

const createFund = (data) => {
  return apiClient.post(endpoint, data);
};

const updateFund = (fundID, data) => {
  return apiClient.put(endpoint + "/" + fundID + act_update_fund, data);
};

const deleteFund = (fundID) => {
  return apiClient.delete(endpoint + "/" + fundID);
};

const issueFund = (fundID, data) => {
  return apiClient.put(endpoint + "/" + fundID + act_issue_fund, data);
};

const unIssueFund = (fundID) => {
  return apiClient.put(endpoint + "/" + fundID + act_unissue_fund);
};

const closeFund = (fundID) => {
  return apiClient.put(endpoint + "/" + fundID + act_close_fund);
};

const reopenFund = (fundID) => {
  return apiClient.put(endpoint + "/" + fundID + act_reopen_fund);
};

const cancelFund = (fundID, data) => {
  return apiClient.put(endpoint + "/" + fundID + act_cancel_fund, data);
};

const reInstateFund = (fundID) => {
  return apiClient.put(endpoint + "/" + fundID + act_reinstate_fund);
};

const syncFund = (fundID) => {
  return apiClient.put(endpoint + "/" + fundID + act_sync_fund);
};

const recalculateFund = (fundID) => {
  return apiClient.put(endpoint + "/" + fundID + act_recalculate_fund);
};

const createFundEndorsement = (fundID, policyID) => {
  return apiClient.put(
    endpoint + "/" + fundID + act_create_fund_endorsement + "/" + policyID
  );
};

const addFundOrganizationBranch = (fundID, data) => {
  return apiClient.post(
    endpoint + "/" + fundID + route_fund_organization_branches,
    data
  );
};

const updateFundOrganizationBranch = (
  fundID,
  fundOrganizationBranchID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_organization_branches +
      "/" +
      fundOrganizationBranchID +
      act_update_fund_organization_branch,
    data
  );
};

const removeFundOrganizationBranch = (fundID, fundOrganizationBranchID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      fundID +
      route_fund_organization_branches +
      "/" +
      fundOrganizationBranchID
  );
};

const cancelFundOrganizationBranch = (
  fundID,
  fundOrganizationBranchID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_organization_branches +
      "/" +
      fundOrganizationBranchID +
      act_cancel_fund_organization_branch,
    data
  );
};

const reinstateFundOrganizationBranch = (fundID, fundOrganizationBranchID) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_organization_branches +
      "/" +
      fundOrganizationBranchID +
      act_reinstate_fund_organization_branch
  );
};

const addFundTax = (fundID, data) => {
  return apiClient.post(endpoint + "/" + fundID + route_fund_taxes, data);
};

const updateFundTax = (fundID, fundTaxID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_taxes +
      "/" +
      fundTaxID +
      act_update_fund_tax,
    data
  );
};

const removeFundTax = (fundID, fundTaxID) => {
  return apiClient.delete(
    endpoint + "/" + fundID + route_fund_taxes + "/" + fundTaxID
  );
};

const cancelFundTax = (fundID, fundTaxID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_taxes +
      "/" +
      fundTaxID +
      act_cancel_fund_tax,
    data
  );
};

const addFundAmount = (fundID, data) => {
  return apiClient.post(endpoint + "/" + fundID + route_fund_amounts, data);
};

const updateFundAmount = (fundID, fundAmountID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_amounts +
      "/" +
      fundAmountID +
      act_update_fund_amount,
    data
  );
};

const removeFundAmount = (fundID, fundAmountID) => {
  return apiClient.delete(
    endpoint + "/" + fundID + route_fund_amounts + "/" + fundAmountID
  );
};

const issueFundAmount = (fundID, fundAmountID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_amounts +
      "/" +
      fundAmountID +
      act_issue_fund_amount,
    data
  );
};

const unIssueFundAmount = (fundID, fundAmountID) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_amounts +
      "/" +
      fundAmountID +
      act_un_issue_fund_amount
  );
};

const addFundInstallment = (fundID, fundAmountID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      fundID +
      route_fund_amounts +
      "/" +
      fundAmountID +
      route_fund_installments,
    data
  );
};

const updateFundInstallment = (
  fundID,
  fundAmountID,
  fundInstallmentID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_amounts +
      "/" +
      fundAmountID +
      route_fund_installments +
      "/" +
      fundInstallmentID,
    data
  );
};

const removeFundInstallment = (fundID, fundAmountID, fundInstallmentID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      fundID +
      route_fund_amounts +
      "/" +
      fundAmountID +
      route_fund_installments +
      "/" +
      fundInstallmentID
  );
};

const getFundCoveragePlanInsured = (fundCoveragePlanID) => {
  return apiClient.get(
    endpoint +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_insured
  );
};

const getFundCoveragePlanProducts = (fundCoveragePlanID) => {
  return apiClient.get(
    endpoint +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products
  );
};

const addFundCoveragePlan = (fundID, data) => {
  return apiClient.post(
    endpoint + "/" + fundID + route_fund_coverage_plans,
    data
  );
};

const updateFundCoveragePlan = (fundID, fundCoveragePlanID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      act_update_fund_coverage_plan,
    data
  );
};

const removeFundCoveragePlan = (fundID, fundCoveragePlanID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID
  );
};

const cancelFundCoveragePlan = (fundID, fundCoveragePlanID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      act_cancel_fund_coverage_plan,
    data
  );
};

const reinstateFundCoveragePlan = (fundID, fundCoveragePlanID) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      act_reinstate_fund_coverage_plan
  );
};

const addFundCoveragePlanInsured = (fundID, fundCoveragePlanID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_insured,
    data
  );
};

const removeFundCoveragePlanInsured = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanInsredID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_insured +
      "/" +
      fundCoveragePlanInsredID
  );
};

const cancelFundCoveragePlanInsured = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanInsredID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_insured +
      "/" +
      fundCoveragePlanInsredID +
      act_cancel_fund_coverage_plan_insured,
    data
  );
};

const reinstateFundCoveragePlanInsured = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanInsredID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_insured +
      "/" +
      fundCoveragePlanInsredID +
      act_reinstate_fund_coverage_plan_insured
  );
};

const addFundCoveragePlanProducts = (fundID, fundCoveragePlanID, data) => {
  return apiClient.post(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products,
    data
  );
};

const updateFundCoveragePlanProduct = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanProductID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products +
      "/" +
      fundCoveragePlanProductID +
      act_update_fund_coverage_plan_product,
    data
  );
};

const removeFundCoveragePlanProduct = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanProductID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products +
      "/" +
      fundCoveragePlanProductID
  );
};

const cancelFundCoveragePlanProduct = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanProductID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products +
      "/" +
      fundCoveragePlanProductID +
      act_cancel_fund_coverage_plan_product,
    data
  );
};

const reinstateFundCoveragePlanProduct = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanProductID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products +
      "/" +
      fundCoveragePlanProductID +
      act_reinstate_fund_coverage_plan_product
  );
};

const addFundCoveragePlanBenefits = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanProductID,
  data
) => {
  return apiClient.post(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products +
      "/" +
      fundCoveragePlanProductID +
      route_fund_coverage_plan_benefits,
    data
  );
};

const updateFundCoveragePlanBenefit = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanProductID,
  fundCoveragePlanBenefitID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products +
      "/" +
      fundCoveragePlanProductID +
      route_fund_coverage_plan_benefits +
      "/" +
      fundCoveragePlanBenefitID +
      act_update_fund_coverage_plan_benefit,
    data
  );
};

const removeFundCoveragePlanBenefit = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanProductID,
  fundCoveragePlanBenefitID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products +
      "/" +
      fundCoveragePlanProductID +
      route_fund_coverage_plan_benefits +
      "/" +
      fundCoveragePlanBenefitID
  );
};

const cancelFundCoveragePlanBenefit = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanProductID,
  fundCoveragePlanBenefitID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products +
      "/" +
      fundCoveragePlanProductID +
      route_fund_coverage_plan_benefits +
      "/" +
      fundCoveragePlanBenefitID +
      act_cancel_fund_coverage_plan_benefit,
    data
  );
};

const reinstateFundCoveragePlanBenefit = (
  fundID,
  fundCoveragePlanID,
  fundCoveragePlanProductID,
  fundCoveragePlanBenefitID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      fundID +
      route_fund_coverage_plans +
      "/" +
      fundCoveragePlanID +
      route_fund_coverage_plan_products +
      "/" +
      fundCoveragePlanProductID +
      route_fund_coverage_plan_benefits +
      "/" +
      fundCoveragePlanBenefitID +
      act_reinstate_fund_coverage_plan_benefit
  );
};
const fundsApi = {
  getFunds,
  getFund,
  createFund,
  updateFund,
  deleteFund,
  issueFund,
  unIssueFund,
  closeFund,
  reopenFund,
  cancelFund,
  reInstateFund,
  syncFund,
  recalculateFund,
  createFundEndorsement,

  addFundOrganizationBranch,
  updateFundOrganizationBranch,
  removeFundOrganizationBranch,
  cancelFundOrganizationBranch,
  reinstateFundOrganizationBranch,
  addFundTax,
  updateFundTax,
  removeFundTax,
  cancelFundTax,
  addFundAmount,
  updateFundAmount,
  removeFundAmount,
  issueFundAmount,
  unIssueFundAmount,
  addFundInstallment,
  updateFundInstallment,
  removeFundInstallment,
  getFundCoveragePlanInsured,
  getFundCoveragePlanProducts,
  addFundCoveragePlan,
  updateFundCoveragePlan,
  removeFundCoveragePlan,
  cancelFundCoveragePlan,
  reinstateFundCoveragePlan,
  addFundCoveragePlanInsured,
  removeFundCoveragePlanInsured,
  cancelFundCoveragePlanInsured,
  reinstateFundCoveragePlanInsured,
  addFundCoveragePlanProducts,
  updateFundCoveragePlanProduct,
  removeFundCoveragePlanProduct,
  cancelFundCoveragePlanProduct,
  reinstateFundCoveragePlanProduct,
  addFundCoveragePlanBenefits,
  updateFundCoveragePlanBenefit,
  removeFundCoveragePlanBenefit,
  cancelFundCoveragePlanBenefit,
  reinstateFundCoveragePlanBenefit,
};

export default fundsApi;
