import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import CheckupAgreementModel from "../../../interfaces/checkup/CheckupAgreementModel";
import checkupApi from "../../../api/checkup";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<CheckupAgreementModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
}
export default function CheckupAgreementAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: "CheckupStatus",
      header: translate(strings.STATUS),
      width: "120px",
    },
    {
      field: "Policy.PolicyNumber",
      header: translate(strings.POLICY_NUMBER),
      width: "175px",
    },
    {
      field: "OrganizationBranch",
      header: translate(strings.ORGANIZATION_BRANCH),
      width: "175px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return checkupApi.getCheckupAgreements(
      {
        ClientName: inputString,
        ...searchCriteria,
      },
      { Page: 0, PageSize: pageDefaultSize }
    );
  };

  return (
    <AppAutoComplete<CheckupAgreementModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay="CheckupAgreementName"
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
