import { useContext } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import InsuranceCompanyBranchModel from "../../../interfaces/insuranceCompany/InsuranceCompanyBranchModel";
import insuranceCompanies from "../../../api/insuranceCompanies";

interface Props extends MultiColumnComboBoxProps {
  dataList?: Array<InsuranceCompanyBranchModel>;
  insuranceCompanyBranch?: InsuranceCompanyBranchModel | null;
  searchCriteria?: Object;
  charNumber?: number;
  loadDataOnOpen?: boolean;
  isFormComponent?: boolean;
}

export default function InsuranceCompanyBranchAutoComplete({
  dataList,
  columns,
  insuranceCompanyBranch,
  charNumber = 3,
  loadDataOnOpen,
  isFormComponent,
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    { field: getNameField(), header: translate(strings.Name), width: "300px" },
    { field: "Code", header: translate(strings.CODE), width: "100px" },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return insuranceCompanies.getInsuranceCompanyBranches({
      Name: inputString,
    });
  };

  return (
    <AppAutoComplete<InsuranceCompanyBranchModel>
      dataList={dataList || []}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      loadDataOnOpen={loadDataOnOpen}
      charNumber={charNumber}
      isFormComponent={isFormComponent}
      {...rest}
    />
  );
}
