export default Object.freeze({
  PAGE_TITLE_HEIGHT: 90,
  PAGE_TITLE_HEIGHT_WITH_ACTIONS: 108,
  POPUP_TITLE_HEIGHT: 96,
  GAP_HUGE: 25,
  GAP_LARGEST: 20,
  GAP_LARGER: 15,
  GAP_LARGE: 10,
  GAP_MEDIUM: 5,
  GAP_SMALL: 2,
  MARGIN_SCROLLBAR: 6,
  APP_SCROLLBAR_EXTRA_HEIGHT: 39,
  MARGIN_LARGE: 10,
  MARGIN_MEDIUM: 5,
  MARGIN_SMALL: 2,
  PADDING_LARGE: 10,
  PADDING_MEDIUM: 5,
  PADDING_SMALL: 2,
  DIALOG_WIDTH_XXLARGE: 750,
  DIALOG_WIDTH_XXXLARGE: 900,
  DIALOG_WIDTH_XLARGE: 650,
  DIALOG_WIDTH_LARGE: 550,
  DIALOG_WIDTH_MEDIUM: 450,
  DIALOG_WIDTH_SMALL: 350,
  DIALOG_MAX_HEIGHT_MOBILE: 400,
  DIALOG_MAX_HEIGHT_TABLET: 500,
  DIALOG_MAX_HEIGHT_DESKTOP: 600,
  DIALOG_CUSTOM_ACTION_BAR_HEIGHT: 30,
  DIALOG_HEIGHT_LARGE: 650,
  DIALOG_HEIGHT_XLARGE: 800,
  ACTION_BAR_HEIGHT: 40,
  GRID_HEIGHT_LARGER: "55em",
  GRID_HEIGHT_LARGE: "45em", // 600px
  GRID_HEIGHT_MEDIUM: "30em", // 350px
  GRID_HEIGHT_SMALL: "20em", // 250px
  GRID_HEIGHT_SMALLER: "15em",
  GRID_HEIGHT_SMALLEST: "10em",
  GRID_MIN_HEIGHT: "75px",
  WINDOW_HEIGHT_SMALL: 300,
  WINDOW_HEIGHT_MEDIUM: 400,
  WINDOW_HEIGHT_LARGE: 500,
  WINDOW_HEIGHT_XLARGE: 600,
  WINDOW_HEIGHT_XXLARGE: 700,
  PAGE_FOOTER_HEIGHT: 50,
  DROP_DOWN_WIDTH: 250,
  CHECKBOX_WIDTH: 40,
  CHECKBOX_WIDTH_SMALL: 25,
});
