import { useContext, useEffect, useState } from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Label } from "@progress/kendo-react-labels";
import { LocalizationContext } from "../../common/context/localizationContext";
import CountryModel from "../../interfaces/geography/CountryModel";
import AppStackLayout from "../../common/components/stack/AppStackLayout";
import AppLinkedDropDowns from "../../common/components/dropDown/AppLinkedDropDowns";
import AppInput from "../../common/components/inputs/AppInput";
import AppDropDownList from "../../common/components/dropDown/AppDropDownList";

interface Props extends FieldRenderProps {
  label?: string;
  country?: CountryModel;
  disabled?: boolean;
}

export interface MobileValue {
  defaultTelFormat: string;
  mobileDialingCode: string;
  mobileNumber: string;
  mobileNumberLengthWithoutPrefix: number;
}

export default function FormLabelMobile({
  country,
  validationMessage,
  visited,
  label,
  id,
  valid,
  onChange,
  value,
  disabled,
  ...others
}: Props) {
  const { translate } = useContext(LocalizationContext);
  const [mobileDialingCode, setMobileDialingCode] = useState<string>();
  const [mobileDialingCodes, setMobileDialingCodes] = useState<
    string[] | undefined
  >([]);
  const [mobileValue, setMobileValue] = useState<MobileValue | undefined>(
    value
  );
  useEffect(() => {
    if (!country) {
      setMobileValue(undefined);
      setMobileDialingCodes(undefined);
      setMobileDialingCode(undefined);
    } else {
      setMobileValue(mobileValue);
      setMobileDialingCodes(country?.MobileDialingCodes.split(","));
      setMobileDialingCode(country?.MobileDialingCodes.split(",")[0]);
    }
    // eslint-disable-next-line
  }, [country]);

  useEffect(() => {
    if (!value) {
      setMobileValue(undefined);
    } else {
      setMobileValue(value);
      setMobileDialingCode(value?.mobileDialingCode);
    }
    // eslint-disable-next-line
  }, [value]);

  const showValidationMessage: string | false | null =
    visited && validationMessage;
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {translate(label)}
      </Label>
      <AppStackLayout
        orientation="horizontal"
        align={{ horizontal: "start", vertical: "middle" }}
        gap={0}
      >
        <AppLinkedDropDowns colSpan={3}>
          <AppInput
            className="flex-25"
            value={country ? country.DefaultTelFormat : ""}
            id={id}
            {...others}
          />
          <AppDropDownList
            className="flex-30"
            data={country ? mobileDialingCodes : []}
            defaultItem={mobileDialingCode}
            value={mobileDialingCode}
            addDummyItem={false}
            objectBinding={false}
            transparentMode={true}
            valid={valid}
            resetValueOnChange={false}
            disabled={disabled}
            enableFiltering={false}
            onChange={(e: any) => {
              setMobileDialingCode(e.value);
              onChange({
                target: e.target,
                value: {
                  defaultTelFormat: country?.DefaultTelFormat,
                  mobileDialingCode: e.value,
                  mobileNumber: mobileValue?.mobileNumber,
                  mobileNumberLengthWithoutPrefix:
                    country?.MobileNumberLengthWithoutPrefix,
                },
              });
            }}
            {...others}
          />
          <AppInput
            className="flex-45"
            // defaultValue={value?.mobileNumber}
            value={mobileValue?.mobileNumber}
            disabled={disabled}
            onChange={(e: any) => {
              onChange({
                target: e.target,
                value: {
                  defaultTelFormat: country?.DefaultTelFormat,
                  mobileDialingCode,
                  mobileNumber: e.value,
                  mobileNumberLengthWithoutPrefix:
                    country?.MobileNumberLengthWithoutPrefix,
                },
              });
            }}
            valid={valid}
            id={id}
            type="number"
          />
        </AppLinkedDropDowns>
      </AppStackLayout>
      {showValidationMessage && <Error>{translate(validationMessage)}</Error>}
    </FieldWrapper>
  );
}
