import apiClient from "./client";
import errorHandler from "../utility/errorHandler";

export const getDefinitionList = async <T,>(
  definition: string,
  callType: "post" | "get",
  setErrorMessage: (message: string) => void,
  searchCriteria?: object
) => {
  let returnValue: T[];
  const response = await apiClient[callType](
    definition,
    callType === "post" ? JSON.stringify({ ...searchCriteria }) : {},
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
  if (response.ok) {
    returnValue = response.data as T[];
  } else {
    setErrorMessage(errorHandler.getErrorMessage(response));
    returnValue = [];
  }
  return returnValue;
};
