import React, { ReactElement } from "react";
import ConfirmDialog from "../dialog/ConfirmDialog";
import { GridCellProps } from "@progress/kendo-react-grid";
// import "../../styles/appGridCommandBtn.scss";
import BusinessObjectModel from "../../interfaces/BusinessObjectModel";
import baseStrings from "../../base/baseStrings";
import AppButton from "../buttons/AppButton";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import icons from "../../constants/icons";
import AppDropDownButton from "../buttons/AppDropDownButton";
import { Icon } from "@progress/kendo-react-common";

const baseButton =
  "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command command-button";

const primaryButton =
  "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command command-button";

interface AppCommandCellProps<T> extends GridCellProps {
  editField: string;
  isEditable: boolean;
  isDeletable: boolean;
  editForm?: ReactElement;
  singleCellEdit: boolean;
  add: (dataItem: any) => Promise<void>;
  update: (dataItem: any) => void;
  discard: (dataItem: any) => void;
  cancel: (dataItem: any) => void;
  edit: (dataItem: any) => void;
  remove: (dataItem: any) => void;
  setShowForm?: (showform: boolean) => void;
  setEditedDataItem?: (businessObject: T) => void;
  // isCustom?: boolean;
  // renderActionsCommandButton?: (dataItem: T) => JSX.Element;
  menu?: {
    getMenuItems: (dataItem: any) => any[];
    handleMenuItemClick: (event: DropDownButtonItemClickEvent) => void;
  };
}

export default function AppCommandCell<T extends BusinessObjectModel>({
  dataItem,
  editField,
  isEditable,
  isDeletable,
  editForm,
  singleCellEdit,
  rowType,
  style,
  className,
  add,
  update,
  discard,
  cancel,
  edit,
  remove,
  setShowForm,
  setEditedDataItem,
  // isCustom,
  // renderActionsCommandButton,
  menu,
  ...rest
}: AppCommandCellProps<T>) {
  const inEdit = dataItem[editField];
  const isNewItem = dataItem.isNewItem;

  const [visibleDeleteForm, setVisibleDeleteForm] =
    React.useState<boolean>(false);

  const toggleDeleteDialog = () => {
    setVisibleDeleteForm(!visibleDeleteForm);
  };
  const toggleEditDialog = () => {
    setShowForm && setShowForm(true);
    setEditedDataItem && setEditedDataItem(dataItem);
  };

  if (rowType === "groupHeader") {
    return null;
  }
  return inEdit ? (
    <td style={style} className={className}>
      <button
        className={baseButton}
        onClick={() => (isNewItem ? add(dataItem) : update(dataItem))}
      >
        {isNewItem ? (
          <span className="k-icon k-font-icon k-i-plus"></span>
        ) : singleCellEdit ? (
          ""
        ) : (
          <span className="k-icon k-font-icon k-i-check"></span>
        )}
      </button>
      <button
        className={baseButton}
        onClick={() => (isNewItem ? discard(dataItem) : cancel(dataItem))}
      >
        {isNewItem ? (
          <span className="k-icon k-font-icon k-i-minus"></span>
        ) : singleCellEdit ? (
          ""
        ) : (
          <span className="k-icon k-font-icon k-i-cancel"></span>
        )}
      </button>
    </td>
  ) : (
    <td style={style} className={className}>
      {isEditable ? (
        <button
          className={primaryButton}
          onClick={
            editForm != null ? () => toggleEditDialog() : () => edit(dataItem)
          }
        >
          <Icon
            className="k-icon k-font-icon k-i-edit k-i-pencil"
            themeColor="primary"
          ></Icon>
        </button>
      ) : (
        ""
      )}

      {isDeletable ? (
        <button
          className={baseButton}
          onClick={() => toggleDeleteDialog()}
          type="button"
        >
          <span className="k-icon k-font-icon k-i-delete"></span>
        </button>
      ) : (
        ""
      )}
      {menu ? (
        <AppDropDownButton
          className="drop-down-icon"
          key="toolbarMenu"
          icon={icons.MORE}
          onItemClick={menu?.handleMenuItemClick}
          items={menu?.getMenuItems(dataItem)}
          textField={"key"}
        />
      ) : (
        <></>
      )}

      {/* {isCustom ? (
        <AppButton className={baseButton} type="button">
          {renderActionsCommandButton && renderActionsCommandButton(dataItem)}
        </AppButton>
      ) : (
        ""
      )} */}
      {visibleDeleteForm && (
        <ConfirmDialog
          title={baseStrings.PLEASE_CONFIRM}
          message={baseStrings.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ITEM}
          onYesClick={() => {
            remove(dataItem);
          }}
        ></ConfirmDialog>
      )}
    </td>
  );
}
