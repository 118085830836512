import React, { ReactNode } from "react";
import { CardBody, StackLayout } from "@progress/kendo-react-layout";

import styleConstants from "../../constants/styleConstants";
import useBreakpoint from "use-breakpoint";
import { Breakpoints } from "../../constants/breakpoints";
import breakpointKeys from "../../constants/breakpointKeys";

interface Props {
  hasTitle: boolean;
  Title?: ReactNode;
  orientation: "vertical" | "horizontal";
  minComponentWidth: number;
  numColumnsHighRes?: number;
  numColumns?: number;
  numColumnsMobile?: number;
  numColumnsTablet?: number;
  maxColumns?: number;
  ignoreWidthCalculation?: boolean;
  contentAlignment?: "stretch" | "start" | "center" | "end";
  customGap?: number;
  layoutClasses?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: ReactNode;
}
export default function AppCardBody({
  hasTitle,
  Title,
  children,
  orientation,
  minComponentWidth,
  numColumnsHighRes,
  numColumns,
  numColumnsMobile,
  numColumnsTablet,
  maxColumns,
  ignoreWidthCalculation = false,
  contentAlignment = "stretch",
  customGap,
  layoutClasses,
  ...rest
}: Props) {
  const { breakpoint } = useBreakpoint(Breakpoints, breakpointKeys.DESKTOP);
  const GAP = customGap || styleConstants.GAP_LARGE;

  const getNumberOfColumns = () => {
    if (breakpoint === breakpointKeys.HIGH_RES && numColumnsHighRes)
      return numColumnsHighRes;
    if (breakpoint === breakpointKeys.MOBILE && numColumnsMobile)
      return numColumnsMobile;
    if (breakpoint === breakpointKeys.TABLET && numColumnsTablet)
      return numColumnsTablet;
    return numColumns;
  };
  const getMinWidth = () => {
    const actualNumColumns = getNumberOfColumns();
    if (actualNumColumns)
      return {
        width:
          minComponentWidth * actualNumColumns + GAP * (actualNumColumns - 1),
        minWidth:
          minComponentWidth * actualNumColumns + GAP * (actualNumColumns - 1),
      };
    return {};
  };
  const getMaxWidth = () => {
    if (maxColumns)
      return {
        maxWidth: minComponentWidth * maxColumns + GAP * (maxColumns - 1),
      };
    return {};
  };
  const getWidthStyle = () => {
    if (orientation === "horizontal") {
      return {
        ...getMinWidth(),
        ...getMaxWidth(),
      };
    } else {
      if (ignoreWidthCalculation)
        return {
          minWidth: minComponentWidth,
        };
      else
        return {
          width: minComponentWidth,
          minWidth: minComponentWidth,
        };
    }
  };
  const renderChildren = (children: React.ReactNode) => {
    return (
      <StackLayout
        orientation={orientation}
        gap={GAP}
        className={
          (orientation === "horizontal" ? "rowStackLayout" : "") +
          " " +
          layoutClasses
        }
        style={getWidthStyle()}
        align={{ horizontal: contentAlignment }}
      >
        {children}
      </StackLayout>
    );
  };
  return (
    <CardBody {...rest}>
      {hasTitle ? (
        <StackLayout orientation="vertical" gap={GAP}>
          {Title}
          {renderChildren(children)}
        </StackLayout>
      ) : (
        renderChildren(children)
      )}
    </CardBody>
  );
}
