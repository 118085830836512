import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import servicesApi from "../../api/services";
import errorHandler from "../../common/utility/errorHandler";
import strings from "../../base/strings";
import AppDropDownFilterSearchField from "../../common/components/search/AppDropDownFilterSearchField";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppServiceChapterSearchFieldKeys = {
  ServiceChapterID: "ServiceChapterID",
  ServiceCategoryID: "ServiceCategoryID",
};
export default function AppServiceChapterSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const [serviceCategoryID, setServiceCategoryID] = useState<number>();
  const [showServiceCategory, setShowServiceCategory] =
    useState<boolean>(false);

  const getServiceCategories = async () => {
    const response = await servicesApi.getServiceCategories({
      ServiceChapterID: serviceCategoryID,
    });
    if (!response.ok) {
      setErrorMessage(errorHandler.getErrorMessage(response));
      return [];
    } else return response.data;
  };

  const handleChapterChange = async (
    event: DropDownListChangeEvent,
    searchValue: string
  ) => {
    if (searchValue) {
      await setShowServiceCategory(false);
      await setServiceCategoryID(parseInt(searchValue));
      await setShowServiceCategory(true);
    } else {
      setShowServiceCategory(false);
      setServiceCategoryID(0);
    }
  };

  useEffect(() => {
    setServiceCategoryID(serviceCategoryID);
  }, [showServiceCategory]);

  return (
    <div style={{ display: "contents" }}>
      <AppDropDownFilterSearchField
        definitionType={"CServiceChapterDefinition"}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={AppServiceChapterSearchFieldKeys.ServiceChapterID}
        defaultShow={true}
        title={translate(strings.SERVICE_CHAPTER)}
        searchFieldName={searchFieldName}
        onChange={handleChapterChange}
      />
      {showServiceCategory && (
        <AppDropDownFilterSearchField
          definitionType={""}
          setErrorMessage={setErrorMessage}
          removeSearchFieldValue={removeSearchFieldValue}
          setSearchFieldValues={setSearchFieldValues}
          criteriaTitle={AppServiceChapterSearchFieldKeys.ServiceCategoryID}
          defaultShow={true}
          title={translate(strings.SERVICE_CATEGORIES)}
          searchFieldName={searchFieldName}
          useCustomBinding
          reset={showServiceCategory}
          getCustomList={getServiceCategories}
        />
      )}
    </div>
  );
}
