import { useContext } from "react";
import { StackLayout } from "@progress/kendo-react-layout";
import AppButton from "./AppButton";
import styleConstants from "../../constants/styleConstants";
import { LocalizationContext } from "../../context/localizationContext";

interface Props {
  buttonList: { title: string; icon: string; onClick: () => void }[];
}

export default function AppButtonList({ buttonList }: Props) {
  const { translate } = useContext(LocalizationContext);
  return (
    <StackLayout
      orientation="horizontal"
      align={{ horizontal: "start", vertical: "middle" }}
      gap={styleConstants.GAP_MEDIUM}
    >
      {buttonList.map((button, index) => (
        <AppButton
          key={index}
          type="button"
          className="k-button k-button-solid-primary"
          icon={button.icon}
          onClick={button.onClick}
        >
          {translate(button.title)}
        </AppButton>
      ))}
    </StackLayout>
  );
}
