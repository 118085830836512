import { Dispatch, SetStateAction, useContext, useState } from "react";
import strings from "../../../base/strings";
import AppGrid from "../../../common/components/grid/AppGrid";
import { LocalizationContext } from "../../../common/context/localizationContext";
import routes from "../../../base/routes";
import { GridProps } from "@progress/kendo-react-grid";
import { EDisplayModes } from "../../../base/EDisplayModes";
import { AppGridColumnType } from "../../../common/ui/AppGridColumnType";
import AppGridPanel from "../../../common/components/grid/AppGridPanel";
import { AppGridColumnPropsWithType } from "../../../common/ui/AppGridColumnProps";
import { ApiResponse } from "apisauce";
import ProviderModel from "../../../interfaces/providers/ProviderModel";
import styleConstants from "../../../common/constants/styleConstants";
import icons from "../../../common/constants/icons";
import { Icon } from "@progress/kendo-react-common";
import uiHelper from "../../../common/helpers/uiHelper";
import AppDialog from "../../../common/components/dialog/AppDialog";
import AppMap from "../../../common/components/map/AppMap";

interface Props extends GridProps {
  data: ProviderModel[];
  fixedHeight?: boolean;
  errorMessage?: string;
  mode?: EDisplayModes;
  showToolbar?: boolean;
  showRefresh?: boolean;
  showCounter?: boolean;
  showScroll?: boolean;
  filterable?: boolean;
  loadOnDemand?: {
    pageSize: number;
    setGridData: (data: ProviderModel[]) => void;
    loadData: (
      pageNumber: number,
      pageSize: number,
      criterias: any
    ) => Promise<ApiResponse<any, any>>;
    externalDataHandling: boolean;
    reset: boolean;
    setReset: Dispatch<SetStateAction<boolean>>;
    searchCriteria?: any;
  };
  handleSelectedApproval?: (event: any) => Promise<any>;
  showDeleteButton?: boolean;
  onDeleteItem?: ((dataItemID: number) => Promise<boolean>) | undefined;
}

export default function ProvidersGrid({
  data,
  errorMessage,
  mode = EDisplayModes.FULL,
  showToolbar = false,
  showRefresh = false,
  showCounter = true,
  filterable = false,
  showScroll,
  loadOnDemand,
  handleSelectedApproval,
  showDeleteButton,
  fixedHeight,
  onDeleteItem,
  ...rest
}: Props) {
  const { getNameField, translate } = useContext(LocalizationContext);

  const [showLocationForm, setShowLocationForm] = useState<{
    showForm: boolean;
    center: number[] | undefined;
    markers: { latlng: (number | undefined)[]; name: string }[];
  }>({ showForm: false, center: [], markers: [{ latlng: [], name: "" }] });

  const renderLocationImage = (dataItem: any) => {
    if (uiHelper.isNullOrEmptyNumber(dataItem.GeographicLocationModel))
      return <></>;
    return (
      <Icon
        name={icons.MAP_MERKER_TARGET}
        themeColor={dataItem.IsOpenNow ? "success" : "error"}
      />
    );
  };

  const handleOnLocationClicked = async ({ dataItem }: any) => {
    const center =
      dataItem.GeographicLocationModel.Latitude &&
      dataItem.GeographicLocationModel.Longitude
        ? [
            dataItem.GeographicLocationModel.Latitude,
            dataItem.GeographicLocationModel.Longitude,
          ]
        : undefined;

    const markers = [
      {
        latlng: [
          dataItem.GeographicLocationModel.Latitude,
          dataItem.GeographicLocationModel.Longitude,
        ],
        name: translate(strings.LOCATION),
      },
    ];

    setShowLocationForm({ showForm: true, center, markers });
  };

  const gridColumns: AppGridColumnPropsWithType[] = [
    {
      columnType: "hyperlink" as AppGridColumnType,
      field: "ProviderNumber",
      title: translate(strings.PROVIDER_NUMBER),
      minResizableWidth: 100,
      minWidth: 100,
      filterable: true,
      sortable: true,
      navigateField: "ID",
      navigatePath: routes.MEDICAL_NETWORK + routes.PROVIDERS + routes.BROWSE,
      hidden: mode === EDisplayModes.SEARCH_MEDICAL_NETWORK,
    },
    {
      columnType: "text" as AppGridColumnType,
      field: getNameField(),
      title: translate(strings.Name),
      minResizableWidth: 100,
      minWidth: 100,
      filterable: true,
      groupable: true,
      sortable: true,
    },
    {
      columnType: "text" as AppGridColumnType,
      field: "ProviderTypeModel." + getNameField(),
      title: translate(strings.TYPE),
      minResizableWidth: 100,
      hidden: mode === EDisplayModes.PROVIDER_BRANCHES,
      minWidth: 100,
      filterable: true,
      groupable: true,
      sortable: true,
    },
    {
      columnType: "text" as AppGridColumnType,
      field: "ProviderSpecialtyModel." + getNameField(),
      title: translate(strings.SPECIALTY),
      minResizableWidth: 100,
      hidden: mode === EDisplayModes.PROVIDER_BRANCHES,
      minWidth: 100,
      filterable: true,
      groupable: true,
      sortable: true,
    },
    {
      columnType: "text" as AppGridColumnType,
      field: "CityModel." + getNameField(),
      title: translate(strings.CITY),
      minResizableWidth: 100,
      minWidth: 100,
      filterable: true,
      groupable: true,
      sortable: true,
    },
    {
      columnType: "text" as AppGridColumnType,
      field: "LocationModel." + getNameField(),
      title: translate(strings.LOCATION),
      minResizableWidth: 100,
      minWidth: 100,
      filterable: true,
      groupable: true,
      sortable: true,
    },
    {
      columnType: "text" as AppGridColumnType,
      field: "DetailedAddress",
      title: translate(strings.ADDRESS),
      minResizableWidth: 100,
      minWidth: 100,
      filterable: true,
      groupable: true,
      sortable: true,
    },
    {
      columnType: "numeric" as AppGridColumnType,
      field: "TelephoneNumber",
      title: translate(strings.TELEPHONE),
      minResizableWidth: 100,
      hidden: mode === EDisplayModes.PROVIDER_BRANCHES,
      minWidth: 100,
      filterable: true,
      groupable: true,
      sortable: true,
    },
    {
      columnType: "numeric" as AppGridColumnType,
      field: "Mobile",
      title: translate(strings.MOBILE),
      minResizableWidth: 100,
      hidden: mode === EDisplayModes.PROVIDER_BRANCHES,
      minWidth: 100,
      filterable: true,
      groupable: true,
      sortable: true,
    },
    {
      columnType: "boolean" as AppGridColumnType,
      field: "IsMainBranch",
      title: translate(strings.MAIN_BRANCH),
      minResizableWidth: 100,
      hidden: mode !== EDisplayModes.PROVIDER_BRANCHES,
      minWidth: 100,
      filterable: true,
      groupable: true,
      sortable: true,
    },
    {
      columnType: "text" as AppGridColumnType,
      field: "Hotline",
      title: translate(strings.HOTLINE),
      minResizableWidth: 100,
      hidden: mode !== EDisplayModes.SEARCH_MEDICAL_NETWORK,
      minWidth: 100,
    },
    {
      columnType: "commandButton",
      field: "",
      title: "",
      width: "50",
      getImage: renderLocationImage,
      onClick: handleOnLocationClicked,
      tooltipTitle: strings.LOCATION,
      hidden: mode !== EDisplayModes.SEARCH_MEDICAL_NETWORK,
    },
  ];

  return (
    <>
      <AppGridPanel
        Grid={
          <AppGrid<ProviderModel>
            gridData={data}
            style={
              fixedHeight
                ? {
                    minHeight: styleConstants.GRID_HEIGHT_SMALLEST,
                    maxHeight: styleConstants.GRID_HEIGHT_MEDIUM,
                  }
                : undefined
            }
            showToolbar={showToolbar}
            showRefresh={showRefresh}
            filterable={filterable}
            groupable={false}
            sortable={true}
            showCounter={showCounter}
            columns={gridColumns}
            showScroll={showScroll}
            loadOnDemand={loadOnDemand}
            showDeleteButton={showDeleteButton}
            onDeleteItem={onDeleteItem}
            {...rest}
          />
        }
      />
      {showLocationForm.showForm && (
        <AppDialog
          title={strings.LOCATION}
          isForm={true}
          dialogWidth={"xxxlarge"}
          onClose={() =>
            setShowLocationForm({ showForm: false, center: [], markers: [] })
          }
        >
          <AppMap
            center={showLocationForm.center}
            markers={showLocationForm.markers}
          />
        </AppDialog>
      )}
    </>
  );
}
