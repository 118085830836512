import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const transmittals = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    { field: "ProviderModel", header: strings.PROVIDER, width: "120px" },
    {
      field: "TransmittalStatusModel",
      header: strings.STATUS,
      width: "120px",
    },
    {
      field: "Client",
      header: strings.POLICY_HOLDER,
      width: "120px",
    },
  ],
  autoCompleteEndpoint: endpoints.TRANSMITTALS + "/transmittals",
  autoCompleteSearchKey: "TransmittalIDStartsWith",
};

export default transmittals;
