import baseMessages from "../../common/base/baseMessages";
import { MobileValue } from "../formComponents/FormLabelMobile";

export const mobileValidator = (value: MobileValue) => {
  if (value) {
    if (value.mobileNumber) {
      if (!value.mobileDialingCode) return baseMessages.FIELD_IS_REQUIRED;
      if (value.mobileNumber?.length !== value?.mobileNumberLengthWithoutPrefix)
        return baseMessages.INVALID_NUMBER;
    }
  }
};

export const requiredMobileNumber = (value: MobileValue) => {
  if (value) {
    if (!value.mobileNumber) return baseMessages.FIELD_IS_REQUIRED;
    if (!value.mobileDialingCode) return baseMessages.FIELD_IS_REQUIRED;
    if (value.mobileNumber?.length !== value?.mobileNumberLengthWithoutPrefix)
      return baseMessages.INVALID_NUMBER;
  } else {
    return baseMessages.FIELD_IS_REQUIRED;
  }
};
