import { createRef, useContext, useEffect, useRef, useState } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import attachmentsHelper from "../../helpers/attachmentsHelper";
import uiHelper from "../../helpers/uiHelper";
import { LocalizationContext } from "../../context/localizationContext";
import { Icon } from "@progress/kendo-react-common";
import { Popover, Tooltip } from "@progress/kendo-react-tooltip";
import { Upload, UploadFileInfo } from "@progress/kendo-react-upload";
import styleConstants from "../../constants/styleConstants";
// import PersonPicture from "../../assets/images/person.png";
import baseStrings from "./../../base/baseStrings";

interface AppImageCellProps extends GridCellProps {
  handleOnRowClick?: (e: any) => void;
  imageSource?: any;
  tooltipTitle?: string;
  tooltipField?: string;
  getImage?: (dataItem: any) => any;
  editable?: boolean;
  onUploadImage?: (dataItem: any, file: UploadFileInfo) => void;
  onDeleteImage?: (dataItem: any) => void;
  onLoadImage?: (dataItem: any) => Promise<Uint8Array | null>;
  imageIcon?: string;
  extraClasses?: string;
  imageInfoField?: string;
}
export default function AppImageCell({
  field,
  dataItem,
  handleOnRowClick,
  imageSource,
  tooltipTitle,
  tooltipField,
  getImage,
  editable = false,
  onUploadImage,
  onDeleteImage,
  onLoadImage,
  imageIcon = "k-icon k-font-icon k-i-user secondary-color",
  extraClasses,
  imageInfoField,
  ...rest
}: AppImageCellProps) {
  const { getFullName, translate } = useContext(LocalizationContext);

  const spanRef = useRef<HTMLElement>(null);

  const uploadRef = createRef<Upload>();

  const [base64Image, setBase64Image] = useState<string | null>(null);
  const [showActions, setShowActions] = useState<boolean>(editable);

  const [showTooltipWithActions, setShowTooltipWithActions] =
    useState<boolean>(false);

  useEffect(() => {
    setShowActions(editable);
    // eslint-disable-next-line
  }, [editable]);

  useEffect(() => {
    if (
      field &&
      !onLoadImage &&
      !uiHelper.isNullOrEmptyString(uiHelper.getObjectProperty(dataItem, field))
    ) {
      setBase64Image(
        attachmentsHelper.convertToBase64(
          uiHelper.getObjectProperty(dataItem, field)
        )
      );
    }
    // eslint-disable-next-line
  }, [dataItem]);

  const handleClick = async (e: any) => {
    if (handleOnRowClick) handleOnRowClick({ dataItem });

    if (onLoadImage && !showTooltipWithActions) {
      const imageBytes = await onLoadImage(dataItem);
      if (imageBytes)
        setBase64Image(attachmentsHelper.convertToBase64(imageBytes));
    }

    const renderImageActionsWrapper = showActions || onLoadImage !== undefined;

    if (renderImageActionsWrapper)
      setShowTooltipWithActions(!showTooltipWithActions);
  };

  const hasImage = () => {
    return (
      (getImage && getImage(dataItem)) ||
      imageSource ||
      (field && uiHelper.getObjectProperty(dataItem, field))
    );
  };

  const onSave = async (
    files: UploadFileInfo[],
    options: {
      formData: FormData;
      requestOptions: any;
    },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ) => {
    const promise = new Promise<{ uid: string }>((resolve, reject) => {
      const file = files[0];
      if (file && onUploadImage) {
        onUploadImage(dataItem, files[0]);
        return resolve({ uid: file.uid });
      }
    });
    setShowTooltipWithActions(false);
    return promise;
  };

  const handleDeleteImage = async () => {
    if (onDeleteImage) {
      onDeleteImage(dataItem);
    }
    setShowTooltipWithActions(false);
  };
  return (
    <td
      className={extraClasses && extraClasses}
      role={"gridcell"}
      // tabIndex={0}
      onClick={handleClick}
      title={
        tooltipField
          ? field
            ? uiHelper.getFieldValue(dataItem, field)
              ? tooltipField === "Name"
                ? getFullName(uiHelper.getFieldValue(dataItem, field))
                : uiHelper.getFieldValue(dataItem, tooltipField)
              : undefined
            : uiHelper.getFieldValue(dataItem, tooltipField)
          : tooltipTitle
      }
    >
      <Tooltip position="bottom" anchorElement="target" parentTitle={true}>
        {getImage && getImage(dataItem)}
        {imageSource && (
          <img
            src={imageSource}
            alt="Cell"
            style={{ width: "18px", height: "18px" }}
          />
        )}
        {base64Image && base64Image != null && !onLoadImage && (
          <img
            style={{ width: "20px", height: "20px" }}
            src={base64Image}
            alt="Cell"
          />
        )}
        {onLoadImage &&
          field &&
          uiHelper.getObjectProperty(dataItem, field) && ( //Render Person Image
            // <img
            //   style={{ width: "20px", height: "20px" }}
            //   src={PersonPicture}
            //   ref={anchor}
            // />
            <span className={imageIcon} ref={spanRef}></span>
          )}
        {base64Image && showTooltipWithActions && (
          <Popover
            // show={showTooltipWithActions}
            show={true}
            anchor={spanRef.current}
            position={"top"}
            callout={false}
            popoverClass={"image-viewer-popover"}
            title={
              imageInfoField
                ? translate(baseStrings.IMAGE) +
                  " - " +
                  uiHelper.getFieldValue(dataItem, imageInfoField)
                : undefined
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "200px", height: "200px" }}
                alt="Cell"
                src={base64Image}
              />
              {(showActions || onLoadImage) && (
                <div
                  style={{
                    paddingTop: styleConstants.PADDING_LARGE,
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  {showActions && hasImage() && (
                    <Icon
                      name="trash"
                      onClick={handleDeleteImage}
                      themeColor="primary" //"error"
                      style={{ flexGrow: "1" }}
                    ></Icon>
                  )}
                  {showActions && hasImage() && (
                    <Icon
                      name="edit"
                      themeColor="primary" //"success"
                      onClick={() => {
                        if (uploadRef)
                          uploadRef.current?.actionElement?.click();
                      }}
                      style={{ flexGrow: "1" }}
                    ></Icon>
                  )}
                  <Icon
                    name="close"
                    onClick={() => {
                      setShowTooltipWithActions(!showTooltipWithActions);
                    }}
                    themeColor="primary" //"secondary"
                    style={{ flexGrow: "1" }}
                  ></Icon>
                </div>
              )}
            </div>
          </Popover>
        )}
        {showActions && onUploadImage && !hasImage() && (
          <div
            style={{
              paddingTop: "5px",
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Icon
              name="image-insert"
              onClick={() => {
                if (uploadRef) uploadRef.current?.actionElement?.click();
              }}
              // themeColor="tertiary" //secondary
              className="secondary-color"
            ></Icon>
          </div>
        )}
        {showActions && (
          <div style={{ display: "none" }}>
            <Upload
              ref={uploadRef}
              multiple={false}
              batch={false}
              defaultFiles={[]}
              withCredentials={false}
              restrictions={{
                allowedExtensions: [".jpg", ".jpeg", ".png", ".svg"],
              }}
              saveUrl={onSave}
            />
          </div>
        )}
      </Tooltip>
    </td>
  );
}
