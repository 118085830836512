import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const policies = {
  columns: [
    {
      field: "ID",
      header: strings.ID,
      width: "100px",
    },
    {
      field: "Client",
      header: strings.POLICY_HOLDER,
      width: "300px",
    },
    {
      field: "ClientType",
      header: strings.POLICY_HOLDER_TYPE,
      width: "100px",
    },
    // {
    //   field: "PolicyNumber",
    //   header: strings.ID,
    //   width: "100px",
    // },
  ],
  autoCompleteEndpoint: endpoints.POLICIES + "/policy-headers",
  autoCompleteSearchKey: "ClientName",
  customObjectDisplay: "Client",
};

export default policies;
