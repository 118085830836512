import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const costCenters = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    { field: "LocalName", header: strings.LOCAL_NAME, width: "120px" },
    { field: "ForeignName", header: strings.FOREIGN_NAME, width: "120px" },
    {
      field: "ParentCostCenter",
      header: strings.PARENT_COST_CENTER,
      width: "120px",
    },
  ],
  autoCompleteEndpoint: endpoints.COST_CENTERS,
  autoCompleteSearchKey: "CostCenterName",
};

export default costCenters;
