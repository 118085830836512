import { useContext } from "react";
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars-2";
import { LocalizationContext } from "../../context/localizationContext";

interface Props extends ScrollbarProps {}
export default function AppScrollbar({ children, ...rest }: Props) {
  const { isRTL } = useContext(LocalizationContext);
  // const computedHeight = heightToExclude
  //   ? "calc(100vh - " + heightToExclude + "px)"
  //   : "100vh";
  return (
    // <div
    //   style={{
    //     background: "red",
    //     // margin: "20px",
    //     height: "100%",
    //     // // position: "relative",
    //     overflow: "hidden",
    //     // flex: "1 1 auto",
    //     // height: "100%",
    //   }}
    // >
    <Scrollbars
      //autoHeight
      // autoHeightMin={computedHeight} //{"calc(100% - 56px)"}
      // autoHeightMax={computedHeight} //{"calc(100% - 56px)"}
      //autoHeight
      style={{
        height: "100%",
        // height: computedHeight, //"calc(100% - 56px)",
        //overflow: "visible",
        // minWidth: "600px",
        // height: computedHeight,
        // width: window.innerWidth - 100,
        // height: window.innerHeight - 169,
      }}
      autoHide
      // Hide delay in ms
      autoHideTimeout={1000}
      // Duration for hide animation in ms.
      autoHideDuration={200}
      hideTracksWhenNotNeeded
      renderView={(props) => (
        <div
          {...props}
          style={{
            ...props.style,
            marginLeft: isRTL ? props.style.marginRight : 0,
            marginRight: isRTL ? 0 : props.style.marginRight,
            paddingRight: isRTL ? 0 : 10,
            paddingLeft: isRTL ? 10 : 0,
          }}
        />
      )}
      renderTrackVertical={(props) => (
        <div
          {...props}
          style={{
            ...props.style,
            top: 2,
            bottom: 2,
            left: isRTL ? 2 : undefined,
            right: isRTL ? undefined : 2,
          }}
        />
      )}
      {...rest}
    >
      {children}
    </Scrollbars>
    // </div>
  );
}
