import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.PVI;
const route_icm_payment_voucher_groups = "/icm-payment-voucher-groups";
const route_icm_payment_voucher_group = "/icm-payment-voucher-group";
const route_icm_payment_vouchers = "/icm-payment-vouchers";
const route_icm_payment_voucher = "/icm-payment-voucher";
const route_pvi_group = "/pvi-group";
const act_taxes = "/taxes";
const act_broker_commissions = "/broker-commissions";
const act_create_icm_payment_voucher_group =
  "/create-icm-payment-voucher-group";
const act_delete = "/delete";
const act_checkin_group = "/checkin-group";
const act_checkout_group = "/checkout-group";
const act_submit_group = "/submit-group";
const act_rollback_group = "/rollback-group";
const act_recalculate_group = "/recalculate-group";
const act_un_audit_group_pvi = "/unAudit-group-pvi";
const act_resync_group_benefit_mapping = "/resync-group-benefit-mapping";
const act_sync_group_payer_data = "/sync-group-payer-data";
const act_create_audit_pvi_group_task = "/create-audit-pvi-group-task";
const act_create_generate_pvp_group_from_pvi_group_task =
  "/create-generate-pvp-group-from-pvi-group-task";
const act_create_generate_pvc_group_from_pvi_group_task =
  "/create-generate-pvc-group-from-pvi-group-task";
const act_detach_pvi_group_task = "/detach-pvi-group-task";
const act_create_icm_payment_voucher = "/create-icm-payment-voucher";
const act_checkin = "/checkin";
const act_checkout = "/checkout";
const act_recalculate = "/recalculate";
const act_audit_icm_payment_voucher = "/audit-icm-payment-voucher";
const act_un_audit_icm_payment_voucher = "/unaudit-icm-payment-voucher";
const act_exclude_from_group_collection = "/exclude-from-group-collection";
const act_include_to_group_collection = "/include-to-group-collection";
const act_resync_benefit_mapping = "/resync-benefit-mapping";
const act_remove_custom_rebate = "/remove-custom-rebate";
const act_set_custom_rebate = "/set-custom-rebate";
const act_change_group = "/change-group";
const act_add_claim_invoices = "/add-claim-invoices";
const route_claim_invoice = "/claim-invoice";
const act_remove_claim_invoice = "/remove-claim-invoice";
const act_pvp_groups = "/pvp-groups";
const act_pvc_groups = "/pvc-groups";
const act_generate_provider_payment_vouchers =
  "/generate-provider-payment-vouchers";
const act_generate_client_payment_vouchers =
  "/generate-client-payment-vouchers";
const act_download_pvi_settlement_report = "/download-pvi-settlement-report";
const act_download_pvi_report = "/download-pvi-report";
const act_download_pvi_settlement_claim_report =
  "/download-pvi-settlement-claim-report";
const act_pvi_settlement_report_claims = "/pvi-settlement-report-claims";
const act_download_pvi_group_report = "/download-pvi-group-report";
const act_download_pvi_group_by_claim_type_report =
  "/download-pvi-group-by-claim-type-report";
const act_download_pvi_group_un_mapped_benefits_report =
  "/download-pvi-group-unmapped-benefits-report";
const act_download_pvi_state_details_report =
  "/download-pvi-state-details-report";
const act_download_pvi_group_by_claim_type_details_report =
  "/download-pvi-group-by-claim-type-details-report";
const act_download_pvi_summary_report = "/download-pvi-summary-report";
const act_download_sic_claims_report = "/download-sic-claims-report";
const act_download_sisc_claims_report = "/download-sisc-claims-report";
const act_download_pvi_client_settlement_report =
  "/download-pvi-client-settlement-report";

const act_related_clients = "/related-clients";
const act_pvi_group_pvi_summary = "/pvi-group-pvi-summary";
const act_pvi_group_invoice_summary = "/pvi-group-invoice-summary";

const getICMPaymentVoucherGroups = (criteria, paging) => {
  return apiClient.post(
    endpoint + route_icm_payment_voucher_groups,
    JSON.stringify({ ...paging, ...criteria }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getICMPaymentVoucherGroup = (icmPaymentVoucherGroupID) =>
  apiClient.get(
    endpoint + route_icm_payment_voucher_group + "/" + icmPaymentVoucherGroupID
  );

const getICMPaymentVoucherGroupTaxes = (icmPaymentVoucherGroupID) =>
  apiClient.get(
    endpoint +
      route_icm_payment_voucher_group +
      "/" +
      icmPaymentVoucherGroupID +
      act_taxes
  );

const getICMPaymentVoucherGroupBrokerCommissions = (icmPaymentVoucherGroupID) =>
  apiClient.get(
    endpoint +
      route_icm_payment_voucher_group +
      "/" +
      icmPaymentVoucherGroupID +
      act_broker_commissions
  );

const getICMPaymentVouchers = (criteria, paging) => {
  return apiClient.post(
    endpoint + route_icm_payment_vouchers,
    JSON.stringify({ ...paging, ...criteria }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getICMPaymentVoucher = (icmPaymentVoucherID) =>
  apiClient.get(
    endpoint + route_icm_payment_voucher + "/" + icmPaymentVoucherID
  );

const createICMPaymentVoucherGroup = (criteria) => {
  return apiClient.post(
    endpoint + act_create_icm_payment_voucher_group,
    JSON.stringify({ ...criteria })
  );
};

const deleteICMPaymentVoucherGroup = (icmPaymentVoucherGroupID) => {
  return apiClient.delete(
    endpoint + "/" + icmPaymentVoucherGroupID + act_delete
  );
};

const checkinICMPaymentVoucherGroup = (icmPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherGroupID + act_checkin_group
  );
};

const checkoutICMPaymentVoucherGroup = (icmPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherGroupID + act_checkout_group
  );
};

const submitICMPaymentVoucherGroup = (icmPaymentVoucherGroupID, criteria) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherGroupID + act_submit_group,
    JSON.stringify({ ...criteria })
  );
};

const rollbackICMPaymentVoucherGroup = (icmPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherGroupID + act_rollback_group
  );
};

const recalculateICMPaymentVoucherGroup = (icmPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherGroupID + act_recalculate_group
  );
};

const unAuditICMPaymentVoucherGroupPVI = (icmPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherGroupID + act_un_audit_group_pvi
  );
};

const resyncICMPaymentVoucherGroupBenefitMapping = (
  icmPaymentVoucherGroupID
) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherGroupID + act_resync_group_benefit_mapping
  );
};

const syncClaimFormPayerData = (icmPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherGroupID + act_sync_group_payer_data
  );
};

const createAuditPVIGroupTask = (icmPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherGroupID + act_create_audit_pvi_group_task
  );
};

const createGeneratePVPGroupFromPVIGroupTask = (
  icmPaymentVoucherGroupID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      icmPaymentVoucherGroupID +
      act_create_generate_pvp_group_from_pvi_group_task,
    JSON.stringify({ ...criteria })
  );
};

const createGeneratePVCGroupFromPVIGroupTask = (
  icmPaymentVoucherGroupID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      icmPaymentVoucherGroupID +
      act_create_generate_pvc_group_from_pvi_group_task,
    JSON.stringify({ ...criteria })
  );
};

const detachICMPaymentVoucherGroupTask = (icmPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherGroupID + act_detach_pvi_group_task
  );
};

const createICMPaymentVoucher = (criteria) => {
  return apiClient.post(
    endpoint + act_create_icm_payment_voucher,
    JSON.stringify({ ...criteria })
  );
};

const deleteICMPaymentVoucher = (icmPaymentVoucherID) => {
  return apiClient.delete(
    endpoint +
      route_icm_payment_voucher +
      "/" +
      icmPaymentVoucherID +
      act_delete
  );
};

const checkinICMPaymentVoucher = (icmPaymentVoucherID) => {
  return apiClient.put(endpoint + "/" + icmPaymentVoucherID + act_checkin);
};

const checkoutICMPaymentVoucher = (icmPaymentVoucherID) => {
  return apiClient.put(endpoint + "/" + icmPaymentVoucherID + act_checkout);
};

const recalculateICMPaymentVoucher = (icmPaymentVoucherID) => {
  return apiClient.put(endpoint + "/" + icmPaymentVoucherID + act_recalculate);
};

const auditICMPaymentVoucher = (icmPaymentVoucherID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherID + act_audit_icm_payment_voucher
  );
};

const unAuditICMPaymentVoucher = (icmPaymentVoucherID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherID + act_un_audit_icm_payment_voucher
  );
};

const setICMPaymentVoucherExcludedFromGroupCollection = (
  icmPaymentVoucherID
) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherID + act_exclude_from_group_collection
  );
};

const setICMPaymentVoucherIncludedToGroupCollection = (icmPaymentVoucherID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherID + act_include_to_group_collection
  );
};

const resyncICMPaymentVoucherBenefitMapping = (icmPaymentVoucherID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherID + act_resync_benefit_mapping
  );
};

const removeICMPaymentVoucherCustomRebate = (icmPaymentVoucherID) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherID + act_remove_custom_rebate
  );
};

const setICMPaymentVoucherCustomRebate = (icmPaymentVoucherID, criteria) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherID + act_set_custom_rebate,
    JSON.stringify({ ...criteria })
  );
};

const setICMPaymentVoucherGroup = (
  icmPaymentVoucherID,
  icmPaymentVoucherGroupID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      icmPaymentVoucherID +
      route_icm_payment_voucher_group +
      "/" +
      icmPaymentVoucherGroupID +
      act_change_group
  );
};

const addClaimInvoices = (icmPaymentVoucherID, criteria) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherID + act_add_claim_invoices,
    JSON.stringify({
      ...criteria,
    })
  );
};

const removeICMPaymentVoucherClaimInvoice = (
  icmPaymentVoucherID,
  claimInvoiceID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      icmPaymentVoucherID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_remove_claim_invoice
  );
};

const getICMPaymentVoucherGroupRelatedPVPGroups = (
  icmPaymentVoucherGroupID
) => {
  return apiClient.get(
    endpoint + "/" + icmPaymentVoucherGroupID + act_pvp_groups
  );
};

const getICMPaymentVoucherGroupRelatedPVCGroups = (
  icmPaymentVoucherGroupID
) => {
  return apiClient.get(
    endpoint + "/" + icmPaymentVoucherGroupID + act_pvc_groups
  );
};

const generateProviderPaymentVouchersFromPVI = (
  icmPaymentVoucherID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      icmPaymentVoucherID +
      act_generate_provider_payment_vouchers,
    criteria && JSON.stringify({ ...criteria })
  );
};

const generateClientPaymentVouchersFromPVI = (
  icmPaymentVoucherID,
  criteria
) => {
  return apiClient.put(
    endpoint + "/" + icmPaymentVoucherID + act_generate_client_payment_vouchers,
    JSON.stringify({ ...criteria })
  );
};

const downloadPVISettlementReport = (criteria, onDownloadProgress) => {
  return apiClient.post(
    endpoint + act_download_pvi_settlement_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
      onDownloadProgress: (progress) =>
        onDownloadProgress(
          Math.round((100 * progress.loaded) / progress.total)
        ),
    }
  );
};

const downloadPVIReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvi_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVISettlementClaimReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvi_settlement_claim_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getICMPaymentVoucherSettlementReportClaims = (criteria) => {
  return apiClient.post(
    endpoint + act_pvi_settlement_report_claims,
    JSON.stringify({ ...criteria }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVIGroupReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvi_group_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVIGroupByClaimTypeReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvi_group_by_claim_type_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVIGroupUnMappedBenefitsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvi_group_un_mapped_benefits_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVIStateDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvi_state_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVIGroupByClaimTypeDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvi_group_by_claim_type_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVISummaryReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvi_summary_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadSICClaimsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_sic_claims_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadSISCClaimsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_sisc_claims_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVIClientSettlementReport = (criteria, onDownloadProgress) => {
  return apiClient.post(
    endpoint + act_download_pvi_client_settlement_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
      onDownloadProgress: (progress) =>
        onDownloadProgress(
          Math.round((100 * progress.loaded) / progress.total)
        ),
    }
  );
};

const getPVIGroupRelatedClients = (pviGroupID) => {
  return apiClient.get(
    endpoint +
      route_icm_payment_voucher_group +
      "/" +
      pviGroupID +
      act_related_clients
  );
};

const getPVIGroupPVISummary = (pviGroupID, criteria) => {
  return apiClient.post(
    endpoint + route_pvi_group + "/" + pviGroupID + act_pvi_group_pvi_summary,
    JSON.stringify(criteria),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPVIGroupInvoiceSummary = (pviGroupID, criteria) => {
  return apiClient.post(
    endpoint +
      route_pvi_group +
      "/" +
      pviGroupID +
      act_pvi_group_invoice_summary,
    JSON.stringify(criteria),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const pviApi = {
  getICMPaymentVoucherGroups,
  getICMPaymentVoucherGroup,
  getICMPaymentVoucherGroupTaxes,
  getICMPaymentVoucherGroupBrokerCommissions,
  getICMPaymentVouchers,
  getICMPaymentVoucher,
  createICMPaymentVoucherGroup,
  deleteICMPaymentVoucherGroup,
  checkinICMPaymentVoucherGroup,
  checkoutICMPaymentVoucherGroup,
  submitICMPaymentVoucherGroup,
  rollbackICMPaymentVoucherGroup,
  recalculateICMPaymentVoucherGroup,
  unAuditICMPaymentVoucherGroupPVI,
  resyncICMPaymentVoucherGroupBenefitMapping,
  syncClaimFormPayerData,
  createAuditPVIGroupTask,
  createGeneratePVPGroupFromPVIGroupTask,
  createGeneratePVCGroupFromPVIGroupTask,
  detachICMPaymentVoucherGroupTask,
  createICMPaymentVoucher,
  deleteICMPaymentVoucher,
  checkinICMPaymentVoucher,
  checkoutICMPaymentVoucher,
  recalculateICMPaymentVoucher,
  auditICMPaymentVoucher,
  unAuditICMPaymentVoucher,
  setICMPaymentVoucherExcludedFromGroupCollection,
  setICMPaymentVoucherIncludedToGroupCollection,
  resyncICMPaymentVoucherBenefitMapping,
  removeICMPaymentVoucherCustomRebate,
  setICMPaymentVoucherCustomRebate,
  setICMPaymentVoucherGroup,
  addClaimInvoices,
  removeICMPaymentVoucherClaimInvoice,
  getICMPaymentVoucherGroupRelatedPVPGroups,
  getICMPaymentVoucherGroupRelatedPVCGroups,
  generateProviderPaymentVouchersFromPVI,
  generateClientPaymentVouchersFromPVI,
  downloadPVISettlementReport,
  downloadPVIReport,
  downloadPVISettlementClaimReport,
  getICMPaymentVoucherSettlementReportClaims,
  downloadPVIGroupReport,
  downloadPVIGroupByClaimTypeReport,
  downloadPVIGroupUnMappedBenefitsReport,
  downloadPVIStateDetailsReport,
  downloadPVIGroupByClaimTypeDetailsReport,
  downloadPVISummaryReport,
  downloadSICClaimsReport,
  downloadSISCClaimsReport,
  downloadPVIClientSettlementReport,
  getPVIGroupRelatedClients,
  getPVIGroupPVISummary,
  getPVIGroupInvoiceSummary,
};

export default pviApi;
