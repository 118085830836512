import SearchFieldModel from "../../interfaces/SearchFieldModel";
import AppInput from "../inputs/AppInput";

export default function AppNumericSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  criteriaTitle,
  title,
  defaultShow,
  defaultValue,
}: SearchFieldModel) {
  const handleChange = (event: any) => {
    const value = event.target.value;
    if (value.length === 0) removeSearchFieldValue(criteriaTitle);
    else setSearchFieldValues(criteriaTitle, value);
  };
  return (
    <AppInput
      min="0"
      pattern="[0-9]"
      type="number"
      className="search-component"
      onChange={handleChange}
      placeholder={title}
      autoFocus={!defaultShow}
      defaultValue={defaultValue}
    />
  );
}
