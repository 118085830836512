import { StackLayout, StackLayoutProps } from "@progress/kendo-react-layout";
import { useBreakpoint } from "use-breakpoint";
import breakpointKeys from "../../constants/breakpointKeys";
import { Breakpoints } from "../../constants/breakpoints";
import styleConstants from "../../constants/styleConstants";

interface Props extends StackLayoutProps {
  switchOnTablet?: boolean;
  switchOnMobile?: boolean;
  ref?: any;
}
export default function AppStackLayout({
  children,
  switchOnTablet,
  switchOnMobile,
  orientation,
  ref,
  gap = styleConstants.GAP_MEDIUM,
  ...rest
}: Props) {
  const { breakpoint } = useBreakpoint(Breakpoints, breakpointKeys.DESKTOP);
  const actualOrientation = () => {
    if (
      breakpoint === breakpointKeys.MOBILE &&
      (switchOnMobile || switchOnTablet)
    )
      return orientation === "horizontal" ? "vertical" : "horizontal";
    if (breakpoint === breakpointKeys.TABLET && switchOnTablet)
      return orientation === "horizontal" ? "vertical" : "horizontal";
    return orientation;
  };
  return (
    <StackLayout
      orientation={actualOrientation()}
      gap={gap}
      ref={ref}
      {...rest}
    >
      {children}
    </StackLayout>
  );
}
