// import userHelper from "../../base/userHelper";
import BaseUserModel from "../interfaces/BaseUserModel";

const buildUrl: (
  getUserPortal: (user: any, token: string) => string,
  user: BaseUserModel,
  token: string,
  route: string,
  objectID?: number
) => string = (
  getUserPortal: (user: any, token: string) => string,
  user: BaseUserModel,
  token: string,
  route: string,
  objectID?: number
) => {
  return getUserPortal(user, token) + route + (objectID ? "/" + objectID : "");
};

const buildTemplateFileUrl = (fileName: string) => {
  return (
    // "http://" + process.env.REACT_APP_URL
    process.env.PUBLIC_URL + process.env.REACT_APP_TEMPLATES_PATH + fileName
  );
};

const buildImageUrl = (fileName: string) => {
  return process.env.PUBLIC_URL + "/assets/img/" + fileName;
};

const buildWindowUrl: (
  getUserPortal: (user: any, token: string) => string,
  user: BaseUserModel,
  token: string,
  route: string,
  objectID?: number
) => string = (
  getUserPortal: (user: any, token: string) => string,
  user: BaseUserModel,
  token: string,
  route: string,
  objectID?: number
) => {
  return (
    (process.env.REACT_APP_ROUTER_BASENAME?process.env.REACT_APP_ROUTER_BASENAME:"") +
    getUserPortal(user, token) +
    route +
    (objectID ? "/" + objectID : "")
  );
};

const getUrlPrefix = () => {
  return process.env.REACT_APP_ROUTER_BASENAME
    ? process.env.REACT_APP_ROUTER_BASENAME
    : // process.env.REACT_APP_ROUTER_BASENAME.substring(
      //     0,
      //     process.env.REACT_APP_ROUTER_BASENAME.length - 1
      //   )

      "";
};

const urlBuilder = {
  buildUrl,
  buildTemplateFileUrl,
  buildImageUrl,
  buildWindowUrl,
  getUrlPrefix,
};

export default urlBuilder;
