import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const diagnoses = {
  columns: [
    { field: "DiagnosisCode", header: strings.CODE, width: "100px" },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "200px",
    },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "200px",
    },
  ],
  autoCompleteEndpoint: endpoints.DIAGNOSES,
  autoCompleteSearchKey: "DiagnosisName",
};

export default diagnoses;
