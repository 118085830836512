import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const serviceChapters = {
  columns: [
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "200px",
    },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "200px",
    },
  ],
  autoCompleteEndpoint: endpoints.SERVICES + "/service-chapters",
  autoCompleteSearchKey: "Name",
};

export default serviceChapters;
