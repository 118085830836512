import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.INSURED;
const insured_dependents_endpoint = "/insured-dependents";
const insured_person_information = "/insured-person-information";
const route_insured_person_information_pictures =
  "/insured-person-information-pictures";
const route_beneficiary_policy_insured_revisions =
  "/beneficiary-policy-insured-revisions";
const route_insured_person_comments = "/insured-person-comments";
const route_insured_person_supervision = "/insured-person-supervision";
const route_insured_medical_notes = "/insured-medical-notes";
const route_insured_person_information_bank_accounts =
  "/insured-person-information-bank-accounts";
const route_insured_person_information_direct_payment_recipients =
  "/insured-person-information-direct-payment-recipients";
const route_insured_mobile_number_workspaces =
  "/insured-mobile-number-workspaces";

const act_set_as_vip = "/set-as-vip";
const act_unset_vip = "/unset-vip";
const act_add_person_picture = "/add-insured-picture";
const act_remove_insured_current_picture = "/remove-insured-current-picture";
const act_set_insured_current_picture = "/set-insured-current-picture";
const act_delete_insured_person_picture = "/delete-insured-person-picture";
const act_upload_insured_picture = "/upload-insured-picture";
const act_set_under_supervision = "/set-under-supervision";
const act_remove_supervision = "/remove-supervision";
const act_activate = "/activate";
const act_de_activate = "/de-activate";
const act_verify = "/verify";
const act_un_verify = "/un-verify";
const act_freeze = "/freeze";
const act_un_freeze = "/un-freeze";
const act_update = "/update";
const act_deny_sms_notification = "/deny-sms-notification";
const act_set_payment_method_type = "/set-payment-method-type";
const act_set_mobile_number = "/set-mobile-number";
const act_download_insured_supervision_report =
  "/download-insured-supervision-report";
const act_workspace_summary = "/workspace-summary";
const act_download_insured_mobile_number_workspace_report =
  "/download-insured-mobile-number-workspace-report";
const act_import_insured_mobile_number_workspace_details =
  "/import-insured-mobile-number-workspace-details";
const act_commit = "/commit";
const act_check_workspace_duplicates = "/check-workspace-duplicates";

const getInsuredDependents = (insuredPersonInformationID) => {
  return apiClient.get(
    endpoint + "/" + insuredPersonInformationID + insured_dependents_endpoint
  );
};

const getInsuredPersonInformations = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + insured_person_information,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getInsuredPersonInformation = (InsuredPersonInformationID) => {
  return apiClient.get(endpoint + "/" + InsuredPersonInformationID);
};

const getInsuredPersonSupervision = (insuredPersonInformationID) => {
  return apiClient.get(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_supervision
  );
};

const getInsuredPersonInformationPictures = (insuredPersonInformationID) => {
  return apiClient.get(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_pictures
  );
};

const updateInsuredPersonInformation = (insuredPersonInformationID, data) => {
  return apiClient.put(endpoint + "/" + insuredPersonInformationID, data);
};

const setInsuredPersonAsVIP = (insuredPersonInformationID, data) => {
  return apiClient.put(
    endpoint + "/" + insuredPersonInformationID + act_set_as_vip,
    data
  );
};

const unSetInsuredPersonVIP = (insuredPersonInformationID) => {
  return apiClient.put(
    endpoint + "/" + insuredPersonInformationID + act_unset_vip
  );
};

const addInsuredPicture = (insuredPersonInformationID, personPictureID) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      act_add_person_picture +
      "/" +
      personPictureID
  );
};

const removeInsuredCurrentPicture = (insuredPersonInformationID) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      act_remove_insured_current_picture
  );
};

const setInsuredCurrentPicture = (
  insuredPersonInformationID,
  personPictureID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      act_set_insured_current_picture +
      "/" +
      personPictureID
  );
};

const deleteInsuredPersonInformationPicture = (
  insuredPersonInformationID,
  insuredPersonInformationPictureID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      act_delete_insured_person_picture +
      "/" +
      insuredPersonInformationPictureID
  );
};

const uploadInsuredPicture = (
  formData,
  applicationID,
  cardNumber,
  pinCode,
  onUploadProgress
) => {
  return apiClient.post(endpoint + act_upload_insured_picture, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      ApplicationID: applicationID,
      CardNumber: cardNumber,
      PinCode: pinCode,
    },
    onUploadProgress: (progress) =>
      onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
  });
};

const getBeneficiaryPolicyInsuredRevisions = (insuredPersonInformationID) => {
  return apiClient.get(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_beneficiary_policy_insured_revisions
  );
};

const getInsuredPersonComments = (insuredPersonInformationID) => {
  return apiClient.get(
    endpoint + "/" + insuredPersonInformationID + route_insured_person_comments
  );
};

const addInsuredPersonComment = (ipiID, data) => {
  return apiClient.post(
    endpoint + "/" + ipiID + route_insured_person_comments,
    data
  );
};

const deleteInsuredPersonComment = (ipiID, insuredPersonCommentID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      ipiID +
      route_insured_person_comments +
      "/" +
      insuredPersonCommentID
  );
};

const setInsuredPersonUnderSupervision = (ipiID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      ipiID +
      route_insured_person_supervision +
      act_set_under_supervision,
    data
  );
};

const removeInsuredPersonSupervision = (ipiID, data) => {
  return apiClient.put(
    endpoint +
      "/" +
      ipiID +
      route_insured_person_supervision +
      act_remove_supervision,
    data
  );
};

const getInsuredMedicalNotes = (insuredPersonInformationID) => {
  return apiClient.get(
    endpoint + "/" + insuredPersonInformationID + route_insured_medical_notes
  );
};

const addInsuredMedicalNote = (insuredPersonInformationID, data) => {
  return apiClient.post(
    endpoint + "/" + insuredPersonInformationID + route_insured_medical_notes,
    data
  );
};

const updateInsuredMedicalNote = (
  insuredPersonInformationID,
  insuredMedicalNoteID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_medical_notes +
      "/" +
      insuredMedicalNoteID,
    data
  );
};

const deleteInsuredMedicalNote = (
  insuredPersonInformationID,
  insuredMedicalNoteID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_medical_notes +
      "/" +
      insuredMedicalNoteID
  );
};

const getInsuredPersonInformationBankAccounts = (
  insuredPersonInformationID
) => {
  return apiClient.get(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_bank_accounts
  );
};

const getInsuredPersonInformationDirectPaymentRecipients = (
  insuredPersonInformationID
) => {
  return apiClient.get(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_direct_payment_recipients
  );
};

const addInsuredPersonInformationBankAccount = (
  insuredPersonInformationID,
  data
) => {
  return apiClient.post(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_bank_accounts,
    data
  );
};

const removeInsuredPersonInformationBankAccount = (
  insuredPersonInformationID,
  personBankAccountID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_bank_accounts +
      "/" +
      personBankAccountID
  );
};

const activateInsuredPersonInformationBankAccount = (
  insuredPersonInformationID,
  personBankAccountID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_bank_accounts +
      "/" +
      personBankAccountID +
      act_activate,
    data
  );
};

const deActivateInsuredPersonInformationBankAccount = (
  insuredPersonInformationID,
  personBankAccountID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_bank_accounts +
      "/" +
      personBankAccountID +
      act_de_activate,
    data
  );
};

const verifyInsuredPersonInformationBankAccount = (
  insuredPersonInformationID,
  personBankAccountID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_bank_accounts +
      "/" +
      personBankAccountID +
      act_verify
  );
};

const unverifyInsuredPersonInformationBankAccount = (
  insuredPersonInformationID,
  personBankAccountID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_bank_accounts +
      "/" +
      personBankAccountID +
      act_un_verify
  );
};

const freezeInsuredPersonInformationBankAccount = (
  insuredPersonInformationID,
  personBankAccountID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_bank_accounts +
      "/" +
      personBankAccountID +
      act_freeze,
    data
  );
};

const unfreezeInsuredPersonInformationBankAccount = (
  insuredPersonInformationID,
  personBankAccountID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_bank_accounts +
      "/" +
      personBankAccountID +
      act_un_freeze,
    data
  );
};

const addInsuredPersonInformationDirectPaymentRecipient = (
  insuredPersonInformationID,
  data
) => {
  return apiClient.post(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_direct_payment_recipients,
    data
  );
};

const updateInsuredPersonInformationDirectPaymentRecipient = (
  insuredPersonInformationID,
  insuredPersonInformationDirectPaymentRecipientID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_direct_payment_recipients +
      "/" +
      insuredPersonInformationDirectPaymentRecipientID +
      act_update,
    data
  );
};

const deleteInsuredPersonInformationDirectPaymentRecipient = (
  insuredPersonInformationID,
  insuredPersonInformationDirectPaymentRecipientID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_direct_payment_recipients +
      "/" +
      insuredPersonInformationDirectPaymentRecipientID
  );
};

const activateInsuredPersonInformationDirectPaymentRecipient = (
  insuredPersonInformationID,
  insuredPersonInformationDirectPaymentRecipientID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_direct_payment_recipients +
      "/" +
      insuredPersonInformationDirectPaymentRecipientID +
      act_activate,
    data
  );
};

const deActivateInsuredPersonInformationDirectPaymentRecipient = (
  insuredPersonInformationID,
  insuredPersonInformationDirectPaymentRecipientID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      route_insured_person_information_direct_payment_recipients +
      "/" +
      insuredPersonInformationDirectPaymentRecipientID +
      act_de_activate
  );
};

const setInsuredDenySMSNotification = (
  insuredPersonInformationID,
  denySMSNotification
) => {
  return apiClient.put(
    endpoint +
      "/" +
      insuredPersonInformationID +
      act_deny_sms_notification +
      "/" +
      denySMSNotification
  );
};

const setInsuredPersonInformationPaymentMethodType = (
  insuredPersonInformationID,
  data
) => {
  return apiClient.put(
    endpoint + "/" + insuredPersonInformationID + act_set_payment_method_type,
    data
  );
};

const updateInsuredMobileNumber = (insuredPersonInformationID, data) => {
  return apiClient.put(
    endpoint + "/" + insuredPersonInformationID + act_set_mobile_number,
    data
  );
};

const downloadInsuredSupervisionReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_insured_supervision_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getInsuredMobileNumberWorkspaces = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_insured_mobile_number_workspaces,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createNewInsuredMobileNumberWorkspace = (data) => {
  return apiClient.post(
    endpoint + route_insured_mobile_number_workspaces,
    data
  );
};

const getInsuredMobileNumberWorkspaceSummary = (
  insuredMobileNumberWorkspaceID
) => {
  return apiClient.get(
    endpoint +
      route_insured_mobile_number_workspaces +
      "/" +
      insuredMobileNumberWorkspaceID +
      act_workspace_summary
  );
};

const deleteInsuredMobileNumberWorkspace = (insuredMobileNumberWorkspaceID) => {
  return apiClient.delete(
    endpoint +
      route_insured_mobile_number_workspaces +
      "/" +
      insuredMobileNumberWorkspaceID
  );
};

const downloadInsuredMobileNumberWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_insured_mobile_number_workspace_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importInsuredMobileNumberWorkspaceDetails = (
  insuredMobileNumberWorkspaceID,
  formData,
  onUploadProgress
) => {
  return apiClient.post(
    endpoint + act_import_insured_mobile_number_workspace_details,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        InsuredMobileNumberWorkspaceID: insuredMobileNumberWorkspaceID,
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const commitInsuredMobileNumberWorkspace = (insuredMobileNumberWorkspaceID) => {
  return apiClient.put(
    endpoint +
      route_insured_mobile_number_workspaces +
      "/" +
      insuredMobileNumberWorkspaceID +
      act_commit
  );
};

const checkDuplicateDetails = (insuredMobileNumberWorkspaceID) => {
  return apiClient.get(
    endpoint +
      route_insured_mobile_number_workspaces +
      "/" +
      insuredMobileNumberWorkspaceID +
      act_check_workspace_duplicates
  );
};

export default {
  getInsuredDependents,
  getInsuredPersonInformations,
  getInsuredPersonInformation,
  getInsuredPersonSupervision,
  getInsuredPersonInformationPictures,
  updateInsuredPersonInformation,
  setInsuredPersonAsVIP,
  unSetInsuredPersonVIP,
  addInsuredPicture,
  removeInsuredCurrentPicture,
  setInsuredCurrentPicture,
  deleteInsuredPersonInformationPicture,
  uploadInsuredPicture,
  getBeneficiaryPolicyInsuredRevisions,
  addInsuredPersonComment,
  deleteInsuredPersonComment,
  getInsuredPersonComments,
  setInsuredPersonUnderSupervision,
  removeInsuredPersonSupervision,
  getInsuredMedicalNotes,
  addInsuredMedicalNote,
  updateInsuredMedicalNote,
  deleteInsuredMedicalNote,
  getInsuredPersonInformationBankAccounts,
  getInsuredPersonInformationDirectPaymentRecipients,
  addInsuredPersonInformationBankAccount,
  removeInsuredPersonInformationBankAccount,
  activateInsuredPersonInformationBankAccount,
  deActivateInsuredPersonInformationBankAccount,
  verifyInsuredPersonInformationBankAccount,
  unverifyInsuredPersonInformationBankAccount,
  freezeInsuredPersonInformationBankAccount,
  unfreezeInsuredPersonInformationBankAccount,
  addInsuredPersonInformationDirectPaymentRecipient,
  updateInsuredPersonInformationDirectPaymentRecipient,
  deleteInsuredPersonInformationDirectPaymentRecipient,
  activateInsuredPersonInformationDirectPaymentRecipient,
  deActivateInsuredPersonInformationDirectPaymentRecipient,
  setInsuredDenySMSNotification,
  setInsuredPersonInformationPaymentMethodType,
  updateInsuredMobileNumber,
  downloadInsuredSupervisionReport,
  getInsuredMobileNumberWorkspaces,
  createNewInsuredMobileNumberWorkspace,
  deleteInsuredMobileNumberWorkspace,
  getInsuredMobileNumberWorkspaceSummary,
  downloadInsuredMobileNumberWorkspaceReport,
  importInsuredMobileNumberWorkspaceDetails,
  commitInsuredMobileNumberWorkspace,
  checkDuplicateDetails,
};
