import { StackLayout } from "@progress/kendo-react-layout";
import styleConstants from "../../constants/styleConstants";
import React, { useContext } from "react";
import { LocalizationContext } from "../../context/localizationContext";
import { Label } from "@progress/kendo-react-labels";

interface Props {
  children: React.ReactNode;
  colSpan?: number;
  label?: string;
}

export default function AppLinkedDropDowns({ children, label }: Props) {
  const { isRTL, translate } = useContext(LocalizationContext);

  // Classes Explanation
  // 1. when using this component outside a Form, its children (first, middle and last) are span or input.
  // 2. when using this component from a Form, its children are wrapped into (FormWrapper) which is div and inside the div we have span or input.
  return (
    <>
      {label ? <Label>{translate(label)}</Label> : null}
      <StackLayout
        className={
          React.Children.toArray(children).length === 1
            ? ""
            : isRTL
              ? "linked-drop-downs-rtl"
              : "linked-drop-downs"
        }
        orientation="horizontal"
        gap={styleConstants.GAP_LARGE}
      >
        {children}
      </StackLayout>
    </>
  );
}
