import { Dispatch, SetStateAction, useContext } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import ClientAutoComplete from "../../controls/clients/base/ClientAutoComplete";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppReimbursementBatchClientSearchFieldKeys = {
  ClientCompanyID: "ClientCompanyID",
};
export default function AppReimbursementBatchClientSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <ClientAutoComplete
        columns={[]}
        dataList={[]}
        loadDataOnOpen
        placeholder={translate(strings.CLIENT)}
        className="search-component"
        searchCriteria={{ IgnoreClientPerson: true }}
        onChange={(event) => {
          if (event.value)
            setSearchFieldValues(
              AppReimbursementBatchClientSearchFieldKeys.ClientCompanyID,
              event.value.ID
            );
          else
            removeSearchFieldValue(
              AppReimbursementBatchClientSearchFieldKeys.ClientCompanyID
            );
        }}
      />
    </div>
  );
}
