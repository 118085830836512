import { useContext } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Link } from "react-router-dom";
import urlBuilder from "../../helpers/urlBuilder";
import { AuthenticationContext } from "../../context/authenticationContext";
import { UserPortalContext } from "../../context/userPortalContext";
import { NotificationCell } from "../../helpers/appGridColumnsHelper";
import uiHelper from "../../helpers/uiHelper";
import PillNotification from "../buttons/PillNotification";

interface Props extends GridCellProps {
  field: string;
  navigateField?: string;
  navigatePath: string;
  getNavigationPath?: (dataItem: any) => string;
  disableNavigation?: (dataItem: any) => boolean;
  getImage?: (dataItem: any) => any;
  disableNavigationTextType?: "link";
  onClick?: (e: any, dataItem?: any) => void;
  notificationFieldList: NotificationCell[];
}

export default function AppGridHyperlinkCell({
  dataItem,
  field,
  navigatePath,
  getNavigationPath,
  rowType,
  navigateField,
  disableNavigation,
  getImage,
  disableNavigationTextType,
  onClick,
  notificationFieldList,
}: Props) {
  const { user, token } = useContext(AuthenticationContext);
  const { getUserPortal } = useContext(UserPortalContext);

  const fieldSet = field.split("."); // to use when the hyperlink is for nested properties like (SomeObject.ApprovalModel.ID)
  let data = dataItem;
  fieldSet.forEach((fieldItem) => {
    if (data) data = data[fieldItem];
  });
  const navigateFieldSet = navigateField && navigateField.split(".");
  let browseData = dataItem;
  navigateFieldSet &&
    navigateFieldSet.forEach((fieldItem) => {
      if (browseData) browseData = browseData[fieldItem];
    });

  const renderNotifications = () => {
    return (
      <div
        style={{ display: "flex", padding: "2px", justifyContent: "center" }}
      >
        {notificationFieldList.map((notificationItem, index) => {
          const value = uiHelper.getFieldValue(
            dataItem,
            notificationItem.field
          );

          let popupText = "";
          if (notificationItem.notificationPopupTextField) {
            popupText = uiHelper.getFieldValue(
              dataItem,
              notificationItem.notificationPopupTextField
            );
          }

          return (
            value && (
              <PillNotification
                text={notificationItem.title}
                notificationStatus={notificationItem.notificationType}
                key={index}
                className="pill-notification-button"
                notificationPopupText={popupText}
              />
            )
          );
        })}
      </div>
    );
  };

  if (rowType === "groupHeader") {
    return null;
  }
  return (
    <td>
      {disableNavigation && disableNavigation(dataItem) ? (
        disableNavigationTextType ? (
          disableNavigationTextType === "link" && (
            <span
              onClick={(event) => onClick && onClick(event, dataItem)}
              className="grid-hyperlink"
            >
              {data}
            </span>
          )
        ) : (
          data
        )
      ) : (
        <Link
          className="grid-hyperlink"
          to={urlBuilder.buildUrl(
            getUserPortal,
            user,
            token,
            getNavigationPath ? getNavigationPath(dataItem) : navigatePath,
            navigateField ? browseData : data
          )}
          // onClick={() => localStorage.setItem("data", dataItem)}
          target="_blank"
        >
          {getImage ? getImage(dataItem) : data}
        </Link>
      )}
      {notificationFieldList &&
      !uiHelper.isNullOrEmptyArray(notificationFieldList)
        ? renderNotifications()
        : null}
    </td>
  );
}
