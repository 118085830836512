import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import pvcApi from "../../../api/pvc";
import ClientPaymentVoucherModel from "../../../interfaces/pvc/ClientPaymentVoucherModel";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<ClientPaymentVoucherModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
}
export default function ClientPaymentVoucherAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  ...rest
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: "ClientPaymentVoucherNumber",
      header: translate(strings.NUMBER),
      width: "120px",
    },
    {
      field: "ClientPaymentVoucherStatus",
      header: translate(strings.STATUS),
      width: "120px",
    },
    {
      field: "ClientName",
      header: translate(strings.CLIENT),
      width: "175px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return pvcApi.getClientPaymentVouchers(
      {
        ClientName: inputString,
        ...searchCriteria,
      },
      { Page: 0, PageSize: pageDefaultSize }
    );
  };

  return (
    <AppAutoComplete<ClientPaymentVoucherModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay="ClientName"
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
