import { useEffect, useState } from "react";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { GridCellProps } from "@progress/kendo-react-grid";
import AppDatePicker from "../inputs/AppDatePicker";
import uiHelper from "../../helpers/uiHelper";

interface Props extends GridCellProps {
  editable: boolean;
  handleOnRowClick?: (e: any) => void;
  editableInNew: boolean;
  // onValueChanged?: (e: any) => Promise<any>;
  onCloseEdit?: (e: any) => Promise<any>;
}

//#endregion message

// When we need to edit the dateTime Cell,
// dont use the onValueChanged Event because this will be fired on every key the user presses
// (if the user is writing the date, not selecting from the picker),
// instead use onCloseEdit which will be triggered on Blur or on Enter Key pressed

//#endregion

export default function AppDatePickerCell({
  dataItem,
  editable,
  field = "",
  onChange,
  handleOnRowClick,
  editableInNew,
  // onValueChanged,
  onCloseEdit,
  ...rest
}: Props) {
  const [value, setValue] = useState<string>(
    uiHelper.getFieldValue(dataItem, field)
  );

  const [dateValue, setDateValue] = useState<Date | undefined>(
    uiHelper.getDateValue(uiHelper.getFieldValue(dataItem, field))
  );

  const [isEditable, setIsEditable] = useState<boolean>(
    dataItem.inEdit && editable
  );

  useEffect(() => {
    setValue(uiHelper.getFieldValue(dataItem, field));
    setDateValue(
      uiHelper.getDateValue(uiHelper.getFieldValue(dataItem, field))
    );

    // eslint-disable-next-line
  }, [dataItem]);

  const handleClick = (e: any) => {
    if (handleOnRowClick)
      handleOnRowClick({
        dataItem,
      });

    if (dataItem.isNewItem && !editableInNew) return;

    if (editable) setIsEditable(true);
  };

  const handleOnBlur = async (e: any) => {
    let response;
    response = onCloseEdit && (await onCloseEdit({ ...e, dataItem }));
    if (!response.ok) {
      setValue(value);
    }
    if (dataItem.isNewItem) {
      if (onChange) {
        onChange({
          dataIndex: 0,
          dataItem,
          field,
          syntheticEvent: e.syntheticEvent,
          value: e.target.value,
        });
      }
    }
    // else if (onValueChanged && !dataItem.isNewItem) {
    //   const succeed = await onValueChanged({
    //     dataIndex: 0,
    //     dataItem: dataItem,
    //     field: field,
    //     syntheticEvent: e.syntheticEvent,
    //     value: dateValue,
    //   });
    //   if (succeed === true) {
    //     setValue(value);
    //   } else {
    //     setValue(uiHelper.getFieldValue(dataItem, field));
    //   }
    // }
    else setValue(value);
    setIsEditable(false);
  };
  const handleOnKeyDown = async (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      let response;
      response = onCloseEdit && (await onCloseEdit({ ...e, dataItem }));
      if (!response.ok) {
        setValue(uiHelper.getFieldValue(dataItem, field));
      }
      setIsEditable(false);
    }
  };

  const invokeUpdate = async (e: any, newValue: any) => {
    setDateValue(newValue);
  };

  const handleChange = (event: DatePickerChangeEvent) => {
    invokeUpdate(event, event.value);
  };

  return (
    <td
      role={"gridcell"}
      onClick={handleClick}
      onKeyDown={handleOnKeyDown}
      onMouseLeave={() => {
        if (isEditable) setIsEditable(false);
      }}
    >
      {isEditable ? (
        <AppDatePicker
          onChange={handleChange}
          onBlur={handleOnBlur}
          format={"yyyy-MM-dd"}
          //   disabled={!isEditable}
          value={dateValue}
        />
      ) : (
        value
      )}
    </td>
  );
}
