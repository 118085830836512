import { useContext } from "react";
import { StackLayout } from "@progress/kendo-react-layout";
import { LocalizationContext } from "../../context/localizationContext";
import AppButtonList from "../buttons/AppButtonList";
import PillNotification from "../buttons/PillNotification";
import styleConstants from "../../constants/styleConstants";
import { AuthenticationContext } from "../../context/authenticationContext";
import { colorCategory } from "../../base/colorCategory";
import baseUserHelper from "../../helpers/baseUserHelper";
import baseStrings from "../../base/baseStrings";
import BaseUserModel from "../../interfaces/BaseUserModel";

interface PillProps {
  text: string;
  popupText?: string;
  notificationTitle?: string;
  color: colorCategory;
}

interface Props {
  title: string;
  subTitle?: string;
  buttonList?: { title: string; icon: string; onClick: () => void }[];
  showStatusPill?: boolean;
  statusPillText?: string;
  statusPillColor?: colorCategory;
  // | "red"
  // | "green"
  // | "blue"
  // | "orange"
  // | "yellow"
  // | "link"
  // | "purple"
  // | "gray"
  // | "under-processing"
  // | "needs-submission"
  // | "accepted"
  // | "rejected";
  checkoutByUser?: BaseUserModel | null;
  statusReasonTitle?: string;
  statusReasonText?: string;
  pills?: PillProps[];
  subTitleNode?: React.ReactElement;
  customButtons?: React.ReactElement;
}

export default function PageTitle({
  title,
  subTitle,
  buttonList,
  showStatusPill,
  statusPillText,
  statusPillColor,
  checkoutByUser,
  statusReasonTitle,
  statusReasonText,
  pills,
  subTitleNode,
  customButtons,
}: Props) {
  const { translate, isRTL } = useContext(LocalizationContext);
  const { user } = useContext(AuthenticationContext);

  return (
    <StackLayout
      className={"page-title " + (isRTL ? "isRTL" : "isLTR")}
      orientation="vertical"
      gap={styleConstants.GAP_LARGER}
    >
      <StackLayout orientation="vertical">
        <StackLayout
          orientation="horizontal"
          align={{ horizontal: "start", vertical: "middle" }}
        >
          <StackLayout
            className="title"
            style={{ flexGrow: "1" }}
            align={{ horizontal: "start" }}
          >
            {translate(title)}
            {showStatusPill && (
              <PillNotification
                text={statusPillText || ""}
                notificationPopupText={statusReasonText}
                notificationTitle={statusReasonTitle}
                notificationStatus={
                  statusPillColor || colorCategory.WARNING
                  // statusPillColor === "red"
                  //   ? "error"
                  //   : statusPillColor === "green"
                  //   ? "success"
                  //   : statusPillColor === "blue"
                  //   ? "blue"
                  //   : statusPillColor === "orange"
                  //   ? "orange"
                  //   : statusPillColor === "link"
                  //   ? "link"
                  //   : statusPillColor === "purple"
                  //   ? "purple"
                  //   : statusPillColor === "gray"
                  //   ? "disabled"
                  //   : statusPillColor === "under-processing"
                  //   ? "under-processing"
                  //   : statusPillColor === "needs-submission"
                  //   ? "needs-submission"
                  //   : statusPillColor === "accepted"
                  //   ? "accepted"
                  //   : statusPillColor === "rejected"
                  //   ? "rejected"
                  //   : "warning"
                }
              />
            )}
            {pills &&
              pills.map((pill: PillProps, index) => (
                <PillNotification
                  text={pill.text}
                  notificationPopupText={pill.popupText}
                  notificationTitle={pill.notificationTitle}
                  notificationStatus={pill.color}
                  key={index}
                />
              ))}
          </StackLayout>
          {checkoutByUser && (
            <PillNotification
              className="none-transformation"
              text={
                translate(baseStrings.CHECKED_OUT_BY) +
                " " +
                baseUserHelper.getUserName(checkoutByUser)
              }
              notificationStatus={
                checkoutByUser.ID === user.ID
                  ? colorCategory.SUCCESS
                  : colorCategory.WARNING
              }
            />
          )}
        </StackLayout>
        {subTitle && (
          <StackLayout className="sub-title">{translate(subTitle)}</StackLayout>
        )}
        {subTitleNode}
      </StackLayout>
      {(buttonList || customButtons) && (
        <StackLayout
          orientation="horizontal"
          align={{ horizontal: "start" }}
          gap={styleConstants.GAP_MEDIUM}
        >
          {buttonList && <AppButtonList buttonList={buttonList} />}
          {customButtons}
        </StackLayout>
      )}
    </StackLayout>
  );
}
