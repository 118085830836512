import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const medicalCases = {
  columns: [
    { field: "Code", header: strings.CODE, width: "100px" },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "200px",
    },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "200px",
    },
  ],
  autoCompleteEndpoint: endpoints.DIAGNOSES + "/medical-cases",
  autoCompleteSearchKey: "Name",
};

export default medicalCases;
