import { DatePicker, DatePickerProps } from "@progress/kendo-react-dateinputs";
import { AppCalendar } from "./AppCalendar ";

interface AppDatePickerProps extends DatePickerProps {
  showOnlyMonthPicker?: boolean;
}
export default function AppDatePicker({
  showOnlyMonthPicker,
  ...rest
}: AppDatePickerProps) {
  return (
    <DatePicker
      rounded="full"
      fillMode="outline"
      format={"dd-MM-yyyy"}
      calendar={(props: any) => (
        <AppCalendar
          {...props}
          bottomView={showOnlyMonthPicker ? "year" : undefined}
        />
      )}
      {...rest}
    />
  );
}
