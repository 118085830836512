import { Loader } from "@progress/kendo-react-indicators";

interface Props {
  isDialogLoader?: boolean;
}
export default function LoadingPanel({ isDialogLoader = false }: Props) {
  return (
    <div className="k-loading-mask">
      <div className="k-loading-color"></div>
      <Loader
        type="infinite-spinner" //"converging-spinner"
        style={{
          position: "absolute",
          top: isDialogLoader ? "35%" : "45%",
          left: isDialogLoader ? "40%" : "50%",
          right: isDialogLoader ? "" : "50%",
        }}
        size="large"
      />
    </div>
  );
  //   <div style={{ height: "50px", width: "100%" }}>
  //   <div style={{ position: "absolute", width: "100%" }}>
  //     <div className="k-loading-image" />
  //   </div>
  // </div>
}
