//import * as ImageManipulator from "expo-image-manipulator";
import { Buffer } from "buffer";
import FormData from "form-data";

const SUPPORTED_EXTENSIONS = [
  "JPEG",
  "JPG",
  "PNG",
  "PDF",
  "DOC",
  "DOCX",
  "TXT",
  "XLSX",
  "XLS",
];

const IMAGE_EXTENSIONS = ["JPEG", "JPG", "PNG"];

const ATTACHMENT_MAX_FILE_SIZE = 2048;
const ATTACHMENT_IMAGE_MAX_SIZE = 1024;

const ATTACHMENT_SOURCE_TYPES = {
  CAMERA: "CAMERA",
  DEVICE: "DEVICE",
};

const getSourceTypes = () => {
  return ATTACHMENT_SOURCE_TYPES;
};

const getDeviceSourceType = () => {
  return ATTACHMENT_SOURCE_TYPES.DEVICE;
};

const getCameraSourceType = () => {
  return ATTACHMENT_SOURCE_TYPES.CAMERA;
};
const getFileName = (fileUri) => {
  return fileUri.substring(fileUri.toLowerCase().lastIndexOf("/") + 1);
};

const getFileExtension = (fileUri) => {
  return fileUri.substring(fileUri.toLowerCase().lastIndexOf(".") + 1);
  // return fileUri.match(/[^:/]\w+(?=;|,)/)[0];
};

const isFileExtensionSupported = (fileUri) => {
  const extension = getFileExtension(fileUri);

  if (!SUPPORTED_EXTENSIONS.includes(extension.toUpperCase())) return false;

  return true;
};

const isImage = (fileUri) => {
  const extension = getFileExtension(fileUri);

  if (!IMAGE_EXTENSIONS.includes(extension.toUpperCase())) return false;

  return true;
};

const validateFileSize = (fileUri, fileSize) => {
  if (isImage(fileUri)) return !(fileSize / 1024 > ATTACHMENT_IMAGE_MAX_SIZE);

  return !(fileSize / 1024 > ATTACHMENT_MAX_FILE_SIZE);
};

const getFormData = (file) => {
  if (!file.data || !file.name) return;

  const data = new FormData();

  const fileObject = {};
  Object.assign(fileObject, {
    // uri: file.dataUrl, //file.data,
    uri: file.data, //file.Uri,
    type: "*/*",
    name: file.name,
  });

  // data.append(`File`, {
  //   // uri: file.Uri,
  //   uri: file.data,
  //   type: "*/*",
  //   // name: file.FileName,
  //   name: file.name,
  // });
  // data.append(`File`, fileObject);

  data.append("File", fileObject);
  // data.append("File", file.data, file.name);

  return data;
};

// const processImage = async (imageUri) => {
//   return await ImageManipulator.manipulateAsync(
//     imageUri,
//     [{ resize: { width: 400 } }],
//     { format: "png" }
//   );
// };

const convertToBase64 = (byteArray) => {
  return `data:image/png;base64,${Buffer.from(byteArray).toString("base64")}`;
};

const isThumbnailSupported = (fileUri) => {
  const extension = getFileExtension(fileUri);

  if (
    !IMAGE_EXTENSIONS.includes(extension.toUpperCase()) &&
    // extension != "pdf" &&
    // extension != "txt" &&
    // extension != "docx"
    (extension === "xls" || extension === "xlsx" || extension === "xlx")
  )
    return false;

  return true;
};

const attachmentsHelper = {
  getFileName,
  getFormData,
  // processImage,
  convertToBase64,
  isFileExtensionSupported,
  validateFileSize,
  isImage,
  getSourceTypes,
  getDeviceSourceType,
  getCameraSourceType,
  isThumbnailSupported,
  getFileExtension,
};

export default attachmentsHelper;
