import { useContext, Dispatch, SetStateAction } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import { pageDefaultSize } from "../../../common/constants/appConstants";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import CustomerModel from "../../../interfaces/customers/CustomerModel";
import customersApi from "../../../api/customers";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<CustomerModel>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  loadDataOnOpen?: boolean;
  searchCriteria?: Object;
}
export default function CustomerAutoComplete({
  dataList,
  columns,
  setErrorMessage,
  loadDataOnOpen,
  searchCriteria,
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    {
      field: getNameField(),
      header: translate(strings.NAME),
      width: "100px",
    },
    {
      field: "CountryModel",
      header: translate(strings.COUNTRY),
      width: "100px",
    },
    {
      field: "CityModel",
      header: translate(strings.CITY),
      width: "100px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return customersApi.getCustomers({
      CustomerName: inputString,
      Page: 0,
      PageSize: pageDefaultSize,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<CustomerModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      charNumber={2}
      loadDataOnOpen={loadDataOnOpen}
      searchCriteria={searchCriteria}
      {...rest}
    />
  );
}
