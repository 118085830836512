export default Object.freeze({
  CHANGE_ASSIGNMENT: "CHANGE_ASSIGNMENT",
  REMOVE_ASSIGNMENT: "REMOVE_ASSIGNMENT",
  APPROVAL_ACCEPTED: "APPROVAL_ACCEPTED",
  APPROVAL_REJECTED: "APPROVAL_REJECTED",
  CHRONIC_FORM_ACCEPTED: "CHRONIC_FORM_ACCEPTED",
  TICKET_RESOLVED: "TICKET_RESOLVED",
  TEST_RESULT_ADDITION: "TEST_RESULT_ADDITION",
  SEND_NEWS: "SEND_NEWS",
  APPROVAL_RETURNED: "APPROVAL_RETURNED",
  MEDICAL_FILE_SHARED: "MEDICAL_FILE_SHARED",
  APPROVAL_AUTHORIZED: "APPROVAL_AUTHORIZED",
  APPROVAL_REQUEST_REJECTED: "APPROVAL_REQUEST_REJECTED",
  APPROVAL_REQUEST_RETURNED_TO_SENDER: "APPROVAL_REQUEST_RETURNED_TO_SENDER",
  REIMBURSEMENT_REQUEST_RETURNED_TO_SENDER:
    "REIMBURSEMENT_REQUEST_RETURNED_TO_SENDER",
  REIMBURSEMENT_REQUEST_REJECTED: "REIMBURSEMENT_REQUEST_REJECTED",
  REIMBURSEMENT_REQUEST_VALIDATED: "REIMBURSEMENT_REQUEST_VALIDATED",
  REIMBURSEMENT_TRANSFER_SENT_FOR_PAYMENT:
    "REIMBURSEMENT_TRANSFER_SENT_FOR_PAYMENT",
  APPROVAL_REQUEST_PENDING: "APPROVAL_REQUEST_PENDING",
  APPOINTMENT_CONFIRMED: "APPOINTMENT_CONFIRMED",
  APPOINTMENT_CANCELLED: "APPOINTMENT_CANCELLED",
  PRESCRIPTION_SUBMITTED: "PRESCRIPTION_SUBMITTED",
  PROVIDER_SUBMISSION_GENERATED: "PROVIDER_SUBMISSION_GENERATED",
  APPROVAL_RECEIVED: "APPROVAL_RECEIVED",
  TICKET_RECEIVED: "TICKET_RECEIVED",
  REIMBURSEMENT_REQUEST_RECEIVED: "REIMBURSEMENT_REQUEST_RECEIVED",
  PROVIDER_WEB_REQUEST_RECEIVED: "PROVIDER_WEB_REQUEST_RECEIVED",
  APPROVAL_REQUEST_RECEIVED: "APPROVAL_REQUEST_RECEIVED",
  APPOINTMENT_RECEIVED: "APPOINTMENT_RECEIVED",
  TRANSMITTAL_RECEIVED: "TRANSMITTAL_RECEIVED",
  BATCH_RECEIVED: "BATCH_RECEIVED",
  CHRONIC_FORM_RECEIVED: "CHRONIC_FORM_RECEIVED",
  USER_TASK_RECEIVED: "USER_TASK_RECEIVED",
});
