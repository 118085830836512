import { useContext, useEffect, useState } from "react";
import strings from "../../../base/strings";
import { LocalizationContext } from "../../context/localizationContext";
import SearchFieldModel from "../../interfaces/SearchFieldModel";
import AppDropDownButton from "../buttons/AppDropDownButton";
import {
  DropDownButtonCloseEvent,
  DropDownButtonItemClickEvent,
} from "@progress/kendo-react-buttons";
import BusinessObjectModel from "../../interfaces/BusinessObjectModel";
import { getDefinitionList } from "./../../api/searchDefinitionHelper";
import { ApplicationContext } from "./../../context/applicationContext";

interface Props extends SearchFieldModel {
  searchFieldName?: string;
  definitionType: string;
  callType?: "post" | "get";
  setErrorMessage: (message: string) => void;
  searchCriteria?: {};
  useCustomBinding?: boolean;
  getCustomList?: (searchKey: string) => Promise<BusinessObjectModel[]>;
  onChange?: (event: DropDownButtonItemClickEvent, searchValue: string) => void;
}

export default function AppDropDownSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  criteriaTitle,
  title,
  definitionType,
  callType = "get",
  defaultShow,
  searchFieldName = "Code",
  setErrorMessage,
  searchCriteria,
  reset,
  useCustomBinding = false,
  getCustomList,
  onChange,
  defaultValue,
}: Props) {
  const { getName, translate, isRTL } = useContext(LocalizationContext);
  const { definitions } = useContext(ApplicationContext);

  const [fieldName, setFieldName] = useState(title);
  const [dropDownObjectList, setDropDownObjectList] = useState<
    BusinessObjectModel[]
  >([]);
  const [opened, setOpened] = useState<boolean>(false);

  const dropDownlist = dropDownObjectList.map((object) => getName(object));
  const items = [translate(strings.UNSET_THIS_VALUE)].concat(dropDownlist);

  const getList = async () => {
    let list: BusinessObjectModel[] = [];
    if (definitionType) {
      const objectType = definitions[definitionType].objectType;
      // const list: typeof objectType[] =
      list = await getDefinitionList<typeof objectType>(
        definitions[definitionType].endpoint,
        definitions[definitionType].callType,
        setErrorMessage,
        searchCriteria
      );
    } else if (useCustomBinding && getCustomList) {
      list = await getCustomList(criteriaTitle); //criteriaTitle = searchField.SearchKey
    }
    setDropDownObjectList(list);

    if (defaultValue) {
      let objectName: string = "";
      list.filter((object: any) => {
        if (object[searchFieldName] === defaultValue)
          objectName = getName(object);
        return objectName;
      });
      setFieldName(translate(title) + ": " + objectName);
    }
  };

  useEffect(() => {
    getList();
    setOpened(!(defaultShow || !reset));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (defaultValue) {
      let objectName: string = "";
      dropDownObjectList.filter((object: any) => {
        if (object[searchFieldName] === defaultValue)
          objectName = getName(object);
        return objectName;
      });
      setFieldName(translate(title) + ": " + objectName);
    } else setFieldName(title);
    // eslint-disable-next-line
  }, [isRTL]);

  const getObjectSearchValue = (dropDownObjectList: any[], value: string) => {
    let code: string = "";
    dropDownObjectList.filter((object) => {
      if (getName(object) === value) code = object[searchFieldName];
      return code;
    });
    return code;
  };

  const onItemClick = (event: DropDownButtonItemClickEvent) => {
    const value = event.item;
    if (event.item === translate(strings.UNSET_THIS_VALUE)) {
      removeSearchFieldValue(criteriaTitle);
      setFieldName(translate(title));
    } else {
      setFieldName(translate(title) + ": " + event.item);
      const searchValue: string = getObjectSearchValue(
        dropDownObjectList,
        value
      );
      setSearchFieldValues(criteriaTitle, searchValue);
    }

    onChange &&
      onChange(event, getObjectSearchValue(dropDownObjectList, value));
    setOpened(false);
  };

  return (
    <AppDropDownButton
      className="search-component"
      text={fieldName}
      icon="k-icon k-font-icon k-i-arrow-chevron-down"
      items={items}
      popupSettings={{ popupClass: "popup" }}
      themeColor={"primary"}
      onItemClick={onItemClick}
      opened={opened || undefined}
      onClose={(event: DropDownButtonCloseEvent) => {
        if (event.syntheticEvent.type === "click") setOpened(false);
        //opened ? setOpened(false) : setOpened(opened);
      }}
      // onOpen={() => {
      //!opened ? setOpened(true) : setOpened(opened);
      // }}
    />
  );
}
