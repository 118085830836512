import { MouseEventHandler, useContext } from "react";
import { LocalizationContext } from "../../context/localizationContext";
import ResetButton from "../buttons/ResetButton";
import SubmitButton from "../buttons/SubmitButton";
import baseStrings from "../../base/baseStrings";

interface Props {
  submit: boolean;
  reset: boolean;
  previous?: boolean;
  stepperActionWrapper?: boolean;
  handleReset: () => any;
  submitTitle: string;
  resetTitle: string;
  submitDisabled?: boolean;
  handlePreviousClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function ActionWrapper(props: Props) {
  const {
    submit,
    reset,
    previous = false,
    // handleSubmit,
    handleReset,
    submitTitle,
    resetTitle,
    submitDisabled,
    handlePreviousClick,
    stepperActionWrapper,
  } = props;
  const { isRTL } = useContext(LocalizationContext);
  return (
    <div className={"k-form-buttons"}>
      {submit && <SubmitButton title={submitTitle} disabled={submitDisabled} />}
      {previous && (
        <ResetButton
          title={baseStrings.PREVIOUS}
          handleClick={handlePreviousClick}
        />
      )}
      {reset && (
        <ResetButton
          title={resetTitle}
          handleClick={handleReset}
          style={
            stepperActionWrapper
              ? isRTL
                ? { marginRight: "auto" }
                : { marginLeft: "auto" }
              : undefined
          }
        />
      )}
    </div>
  );
}
