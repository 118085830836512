import apiClient from "../common/api/client";
import { encode } from "js-base64";
import endpoints from "./endpoints";

const endpoint = endpoints.USERS;
const act_login = "/generate-user-authentication-token";
const act_activate = "/activate-user-account";
const act_getUser = "/GetUser";
const act_update_user_language = "user-language";
const act_user_has_access_to_command = "user-has-access-to-command";
const route_departments = "/departments";
const route_department_teams = "/department-teams";
const route_users = "/users";
const act_modules = "/modules";
const act_actions = "/actions";
const act_create_new_module = "/create-new-module";
const act_update_module = "/update-module";
const act_delete = "/delete";
const route_module = "/module";
const route_action = "/action";
const route_command = "/command";
const route_role = "/role";
const route_role_command = "/roll-command";
const route_role_action = "/roll-action";
const route_group = "/group";
const route_group_role = "/group-role";
const route_user = "/user";
const act_create_new_action = "/create-new-action";
const act_update_action = "/update-action";
const act_commands = "/commands";
const act_create_new_command = "/create-new-command";
const act_update_command = "/update-command";
const act_roles = "/roles";
const act_create_new_role = "/create-new-role";
const act_update_role = "/update-role";
const act_add_role_command = "/add-role-command";
const act_remove_role_command = "/remove-role-command";
const act_add_role_action = "/add-role-action";
const act_remove_role_action = "/remove-role-action";
const act_groups = "/groups";
const act_create_new_group = "/create-new-group";
const act_update_group = "/update-group";
const act_add_group_role = "/add-group-role";
const act_remove_group_role = "/remove-group-role";
const act_create_new_user = "/create-new-user";
const act_update_user = "/update-user";
const act_update_user_name = "/update-user-name";
const act_change_user_group = "/change-user-group";
const act_enable_user = "/enable-user";
const act_disable_user = "/disable-user";
const act_reset_admin_user_password = "/reset-admin-user-password";
const act_full_user = "/full-user";
const act_user_login = "/user-login";
const act_user_logoff = "/user-logoff";
const act_user_current_status = "/user-current-status";
const act_download_command_actions_report = "/download-command-actions-report"

const login = (username, password, languageCode) => {
  return apiClient.put(
    endpoint + act_login,
    {
      //RegistrationToken: "", //"162020@@$",
      LanguageCode: languageCode,
    },
    {
      headers: {
        Authorization: "Basic " + encode(username + ":" + password),
      },
    }
  );
};

const activateAccount = (userName, activationCode, password) => {
  return apiClient.put(endpoint + act_activate, {
    userName,
    activationCode,
    password,
  });
};

const getUserByID = (id) => {
  return apiClient.get(endpoint + act_getUser + "/" + id);
};

const updateUserLanguage = (languageCode) => {
  return apiClient.put(
    endpoint + "/" + act_update_user_language + "/" + languageCode
  );
};
const userHasAccessToCommand = (commandName) => {
  return apiClient.get(
    endpoint + "/" + act_user_has_access_to_command + "/" + commandName
  );
};

const getDepartments = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_departments,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getDepartmentTeams = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_department_teams,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getUsers = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_users,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getModules = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_modules,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createNewModule = (module) => {
  return apiClient.post(
    endpoint + act_create_new_module,
    JSON.stringify({ ...module })
  );
};

const updateModule = (moduleID, criteria) => {
  return apiClient.put(
    endpoint + route_module + "/" + moduleID + act_update_module,
    JSON.stringify({ ...criteria })
  );
};

const deleteModule = (moduleID) => {
  return apiClient.delete(
    endpoint + route_module + "/" + moduleID + act_delete
  );
};

const getActions = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_actions,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createNewAction = (action) => {
  return apiClient.post(
    endpoint + act_create_new_action,
    JSON.stringify({ ...action })
  );
};

const updateAction = (actionID, criteria) => {
  return apiClient.put(
    endpoint + route_action + "/" + actionID + act_update_action,
    JSON.stringify({ ...criteria })
  );
};

const deleteAction = (actionID) => {
  return apiClient.delete(
    endpoint + route_action + "/" + actionID + act_delete
  );
};

const getCommands = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_commands,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createNewCommand = (command) => {
  return apiClient.post(
    endpoint + act_create_new_command,
    JSON.stringify({ ...command })
  );
};

const updateCommand = (commandID, criteria) => {
  return apiClient.put(
    endpoint + route_command + "/" + commandID + act_update_command,
    JSON.stringify({ ...criteria })
  );
};

const deleteCommand = (commandID) => {
  return apiClient.delete(
    endpoint + route_command + "/" + commandID + act_delete
  );
};

const getRoles = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_roles,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createNewRole = (role) => {
  return apiClient.post(
    endpoint + act_create_new_role,
    JSON.stringify({ ...role })
  );
};

const updateRole = (roleID, criteria) => {
  return apiClient.put(
    endpoint + route_role + "/" + roleID + act_update_role,
    JSON.stringify({ ...criteria })
  );
};

const deleteRole = (roleID) => {
  return apiClient.delete(endpoint + route_role + "/" + roleID + act_delete);
};

const getRole = (roleID) => {
  return apiClient.get(endpoint + route_role + "/" + roleID);
};

const removeRoleCommand = (roleID, roleCommandID) => {
  return apiClient.delete(
    endpoint +
      route_role +
      "/" +
      roleID +
      route_role_command +
      "/" +
      roleCommandID +
      act_remove_role_command
  );
};

const removeRoleAction = (roleID, roleActionID) => {
  return apiClient.delete(
    endpoint +
      route_role +
      "/" +
      roleID +
      route_role_action +
      "/" +
      roleActionID +
      act_remove_role_action
  );
};

const addRoleCommand = (roleID, commandID) => {
  return apiClient.post(
    endpoint +
      route_role +
      "/" +
      roleID +
      route_command +
      "/" +
      commandID +
      act_add_role_command
  );
};

const addRoleAction = (roleID, actionID) => {
  return apiClient.post(
    endpoint +
      route_role +
      "/" +
      roleID +
      route_action +
      "/" +
      actionID +
      act_add_role_action
  );
};

const getGroups = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_groups,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createNewGroup = (group) => {
  return apiClient.post(
    endpoint + act_create_new_group,
    JSON.stringify({ ...group })
  );
};

const updateGroup = (groupID, criteria) => {
  return apiClient.put(
    endpoint + route_group + "/" + groupID + act_update_group,
    JSON.stringify({ ...criteria })
  );
};

const deleteGroup = (groupID) => {
  return apiClient.delete(endpoint + route_group + "/" + groupID + act_delete);
};

const getGroup = (groupID) => {
  return apiClient.get(endpoint + route_group + "/" + groupID);
};

const addGroupRole = (groupID, roleID) => {
  return apiClient.post(
    endpoint +
      route_group +
      "/" +
      groupID +
      route_role +
      "/" +
      roleID +
      act_add_group_role
  );
};

const removeGroupRole = (groupID, groupRoleID) => {
  return apiClient.delete(
    endpoint +
      route_group +
      "/" +
      groupID +
      route_group_role +
      "/" +
      groupRoleID +
      act_remove_group_role
  );
};

const createNewUser = (user) => {
  return apiClient.post(
    endpoint + act_create_new_user,
    JSON.stringify({ ...user })
  );
};

const updateUser = (userID, criteria) => {
  return apiClient.put(
    endpoint + route_user + "/" + userID + act_update_user,
    JSON.stringify({ ...criteria })
  );
};

const deleteUser = (userID, deletionComment) => {
  return apiClient.put(
    endpoint + route_user + "/" + userID + act_delete,
    JSON.stringify({ Comment: deletionComment })
  );
};

const updateUserUsername = (userID, username) => {
  return apiClient.put(
    endpoint + route_user + "/" + userID + act_update_user_name,
    JSON.stringify({ UserName: username })
  );
};

const changeUserGroup = (userID, groupID) => {
  return apiClient.put(
    endpoint +
      route_user +
      "/" +
      userID +
      route_group +
      "/" +
      groupID +
      act_change_user_group
  );
};

const enableUser = (userID) => {
  return apiClient.put(endpoint + route_user + "/" + userID + act_enable_user);
};

const disableUser = (userID) => {
  return apiClient.put(endpoint + route_user + "/" + userID + act_disable_user);
};

const resetAdminUserPassword = (userID, password) => {
  return apiClient.put(
    endpoint + route_user + "/" + userID + act_reset_admin_user_password,
    JSON.stringify({ Password: password })
  );
};

const getFullUser = (userID) => {
  return apiClient.get(endpoint + "/" + userID + act_full_user);
};

const userLogin = (userID) => {
  return apiClient.put(endpoint + route_user + "/" + userID + act_user_login);
};

const userLogoff = (userID) => {
  return apiClient.put(endpoint + route_user + "/" + userID + act_user_logoff);
};

const getUserCurrentStatus = (userID) => {
  return apiClient.get(
    endpoint + route_user + "/" + userID + act_user_current_status
  );
};

const getCommandActionsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_command_actions_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const usersApi = {
  login,
  activateAccount,
  getUserByID,
  updateUserLanguage,
  userHasAccessToCommand,
  getDepartments,
  getDepartmentTeams,
  getUsers,
  getModules,
  createNewModule,
  updateModule,
  deleteModule,
  getActions,
  createNewAction,
  updateAction,
  deleteAction,
  getCommands,
  createNewCommand,
  updateCommand,
  deleteCommand,
  getRoles,
  createNewRole,
  updateRole,
  deleteRole,
  getRole,
  removeRoleCommand,
  removeRoleAction,
  addRoleCommand,
  addRoleAction,
  getGroups,
  createNewGroup,
  updateGroup,
  deleteGroup,
  getGroup,
  addGroupRole,
  removeGroupRole,
  createNewUser,
  updateUser,
  deleteUser,
  updateUserUsername,
  resetAdminUserPassword,
  changeUserGroup,
  enableUser,
  disableUser,
  getFullUser,
  userLogin,
  userLogoff,
  getUserCurrentStatus,
  getCommandActionsReport,
};

export default usersApi;
