import { MouseEventHandler, useContext } from "react";
import { Button, ButtonProps } from "@progress/kendo-react-buttons";
import { LocalizationContext } from "../../context/localizationContext";

interface Props extends ButtonProps {
  title?: string;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function ResetButton({
  title = "Reset",
  handleClick,
  rounded,
  ...reset
}: Props) {
  const { translate } = useContext(LocalizationContext);
  return (
    <Button
      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
      onClick={handleClick}
      rounded={rounded}
      type="button"
      {...reset}
    >
      {translate(title)}
    </Button>
  );
}
