import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const pricingSchemes = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "200px",
    },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "200px",
    },
    {
      field: "Provider",
      header: strings.PROVIDER,
      width: "150px",
    },
    {
      field: "PricingSchemeDate",
      header: strings.PRICING_SCHEME_DATE,
      width: "150px",
    },
  ],
  autoCompleteEndpoint: endpoints.PRICING_SCHEMES,
  autoCompleteSearchKey: "PricingSchemeName",
};

export default pricingSchemes;
