import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const roles = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "120px",
    },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "120px",
    },
    {
      field: "Code",
      header: strings.CODE,
      width: "120",
    },
  ],
  autoCompleteEndpoint: endpoints.USERS + "/roles",
  autoCompleteSearchKey: "RoleName",
};

export default roles;
