// import secureLocalStorage from "react-secure-storage";

import Cookies from "universal-cookie";

const prefix = "settings";
const key_locale = "locale";
const cookies = new Cookies();

const storeLanguage = (language: string) => {
  cookies.set(prefix + key_locale, language, {
    path: "/",
  });
};

const getLanguage = () => {
  const language = cookies.get(prefix + key_locale);
  return language?.toString();
};

const settingsStorage = {
  getLanguage,
  storeLanguage,
};
export default settingsStorage;
