import { ApiResponse } from "apisauce";
import { createContext, ReactNode } from "react";

interface ContextProps {
  getNotificationsCount: (criteria: {}) => Promise<ApiResponse<any, any>>;
}
interface Props extends ContextProps {
  children: ReactNode;
}
export const NotificationContext = createContext<ContextProps>({
  getNotificationsCount: (criteria: {}) => {
    return new Promise((resolve, reject) => {});
  },
});

export default function NotificationContextProvider({
  getNotificationsCount,
  children,
}: Props) {
  return (
    <NotificationContext.Provider
      value={{
        getNotificationsCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
