import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.CLIENT_DEBIT_VOUCHERS;

const route_client_debit_voucher_groups = "/client-debit-voucher-groups";
const route_client_debit_voucher_group = "/client-debit-voucher-group";
const act_delete = "/delete";
const act_checkin_group = "/checkin-group";
const act_checkout_group = "/checkout-group";
const act_recaclulate_group = "/recalculate-group";
const route_client_debit_vouchers = "/client-debit-vouchers";
const route_client_debit_voucher = "/client-debit-voucher";
const act_checkin = "/checkin";
const act_checkout = "/checkout";
const act_recalculate = "/recalculate";
const act_audit_client_debit_voucher = "/audit-client-debit-voucher";
const act_un_audit_client_debit_voucher = "/unaudit-client-debit-voucher";
const route_claim_invoice = "/claim-invoice";
const act_remove_claim_invoice = "/remove-claim-invoice";
const act_add_claim_invoices = "/add-claim-invoices";
const act_exclude_claim_invoice = "/exclude-claim-invoice";
const act_include_claim_invoice = "/include-claim-invoice";
const route_client_debit_voucher_taxes = "/client-debit-voucher-taxes";
const route_client_debit_voucher_broker_commissions =
  "/client-debit-voucher-broker-commissions";
const act_invoice_generation = "/invoice-generation";
const act_create_client_debit_voucher_group =
  "/create-client-debit-voucher-group";
const act_create_client_debit_voucher = "/create-client-debit-voucher";
const act_download_cdv_group_report = "/download-cdv-group-report";
const act_download_cdv_report = "/download-cdv-report";

const getClientDebitVoucherGroups = (criteria, paging) => {
  return apiClient.post(
    endpoint + route_client_debit_voucher_groups,
    JSON.stringify({ ...paging, ...criteria }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getClientDebitVoucherGroup = (clientDebitVoucherGroupID) =>
  apiClient.get(
    endpoint +
      route_client_debit_voucher_group +
      "/" +
      clientDebitVoucherGroupID
  );

const deleteClientDebitVoucherGroup = (clientDebitVoucherGroupID) => {
  return apiClient.delete(
    endpoint + "/" + clientDebitVoucherGroupID + act_delete
  );
};

const checkinClientDebitVoucherGroup = (clientDebitVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + clientDebitVoucherGroupID + act_checkin_group
  );
};

const checkoutClientDebitVoucherGroup = (clientDebitVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + clientDebitVoucherGroupID + act_checkout_group
  );
};

const recalculateClientDebitVoucherGroup = (clientDebitVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + clientDebitVoucherGroupID + act_recaclulate_group
  );
};

const getClientDebitVouchers = (criteria, paging) => {
  return apiClient.post(
    endpoint + route_client_debit_vouchers,
    JSON.stringify({ ...paging, ...criteria }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getClientDebitVoucher = (clientDebitVoucherID) =>
  apiClient.get(
    endpoint + route_client_debit_voucher + "/" + clientDebitVoucherID
  );

const deleteClientDebitVoucher = (clientDebitVoucherID) => {
  return apiClient.delete(
    endpoint +
      route_client_debit_voucher +
      "/" +
      clientDebitVoucherID +
      act_delete
  );
};

const checkinClientDebitVoucher = (clientDebitVoucherID) => {
  return apiClient.put(endpoint + "/" + clientDebitVoucherID + act_checkin);
};

const checkoutClientDebitVoucher = (clientDebitVoucherID) => {
  return apiClient.put(endpoint + "/" + clientDebitVoucherID + act_checkout);
};

const recalculateClientDebitVoucher = (clientDebitVoucherID) => {
  return apiClient.put(endpoint + "/" + clientDebitVoucherID + act_recalculate);
};

const auditClientDebitVoucher = (clientDebitVoucherID) => {
  return apiClient.put(
    endpoint + "/" + clientDebitVoucherID + act_audit_client_debit_voucher
  );
};

const unAuditClientDebitVoucher = (clientDebitVoucherID) => {
  return apiClient.put(
    endpoint + "/" + clientDebitVoucherID + act_un_audit_client_debit_voucher
  );
};

const removeClientDebitVoucherClaimInvoice = (
  clientDebitVoucherID,
  claimInvoiceID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientDebitVoucherID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_remove_claim_invoice
  );
};

const addClaimInvoices = (clientDebitVoucherID, criteria) => {
  return apiClient.put(
    endpoint + "/" + clientDebitVoucherID + act_add_claim_invoices,
    JSON.stringify({
      ...criteria,
    })
  );
};

const excludeClientDebitVoucherClaimInvoice = (
  clientDebitVoucherID,
  claimInvoiceID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientDebitVoucherID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_exclude_claim_invoice
  );
};

const includeClientDebitVoucherClaimInvoice = (
  clientDebitVoucherID,
  claimInvoiceID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientDebitVoucherID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_include_claim_invoice
  );
};

const getClientDebitVoucherTaxes = (clientDebitVoucherID) => {
  return apiClient.get(
    endpoint + "/" + clientDebitVoucherID + route_client_debit_voucher_taxes
  );
};

const getClientDebitVoucherBrokerCommissions = (clientDebitVoucherID) => {
  return apiClient.get(
    endpoint +
      "/" +
      clientDebitVoucherID +
      route_client_debit_voucher_broker_commissions
  );
};

const setClientDebitVoucherInvoiceGeneration = (
  clientDebitVoucherID,
  generateInvoicePerInsured
) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientDebitVoucherID +
      act_invoice_generation +
      "/" +
      generateInvoicePerInsured
  );
};

const createClientDebitVoucherGroup = (criteria) => {
  return apiClient.post(
    endpoint + act_create_client_debit_voucher_group,
    JSON.stringify({ ...criteria })
  );
};

const createClientDebitVoucher = (criteria) => {
  return apiClient.post(
    endpoint + act_create_client_debit_voucher,
    JSON.stringify({ ...criteria })
  );
};

const downloadCDVGroupReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_cdv_group_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadCDVReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_cdv_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const clientDebitVouchersApi = {
  getClientDebitVoucherGroups,
  getClientDebitVoucherGroup,
  deleteClientDebitVoucherGroup,
  checkinClientDebitVoucherGroup,
  checkoutClientDebitVoucherGroup,
  recalculateClientDebitVoucherGroup,
  getClientDebitVouchers,
  getClientDebitVoucher,
  deleteClientDebitVoucher,
  checkinClientDebitVoucher,
  checkoutClientDebitVoucher,
  recalculateClientDebitVoucher,
  auditClientDebitVoucher,
  unAuditClientDebitVoucher,
  removeClientDebitVoucherClaimInvoice,
  addClaimInvoices,
  excludeClientDebitVoucherClaimInvoice,
  includeClientDebitVoucherClaimInvoice,
  getClientDebitVoucherTaxes,
  getClientDebitVoucherBrokerCommissions,
  setClientDebitVoucherInvoiceGeneration,
  createClientDebitVoucherGroup,
  createClientDebitVoucher,
  downloadCDVGroupReport,
  downloadCDVReport,
};

export default clientDebitVouchersApi;
