import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const plans = {
  columns: [
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "200px",
    },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "100px",
    },
    {
      field: "InsuranceCompany",
      header: strings.INSURANCE_COMPANY,
      width: "100px",
    },
    {
      field: "InsuranceType",
      header: strings.INSURANCE_TYPE,
      width: "100px",
    },
    {
      field: "DefinitionDate",
      header: strings.DEFINITION_DATE,
      width: "100px",
    },
    {
      field: "IsActive",
      header: strings.ACTIVE,
      width: "50px",
    },
  ],
  autoCompleteEndpoint: endpoints.PLANS,
  autoCompleteSearchKey: "Name",
};

export default plans;
