import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.WORKFLOW_OBJECTS;

const route_user = "/user";
const route_business_object = "/business-object";
const route_business_object_assignment = "/business-object-assignment";
const act_mailbox_items_total_count = "/mailbox-items-total-count";
const act_mailbox_items_count = "/mailbox-items-count";
const act_specific_mailbox_workflow_object =
  "/specific-mailbox-workflow-object";
const act_set_is_read = "/set-is-read";
const act_assign_to_user = "/assign-to-user";
const act_assign_to_department = "/assign-to-department";
const act_assign_to_department_team = "/assign-to-department-team";
const act_remove_assignment = "/remove-assignment";

const getMailboxItemsTotalCount = (userID) =>
  apiClient.get(
    endpoint + route_user + "/" + userID + act_mailbox_items_total_count
  );

const getMailboxItemsCount = (userID) =>
  apiClient.get(endpoint + route_user + "/" + userID + act_mailbox_items_count);

const getSpecificMailboxWorkflowObject = (userID, criteria, paging) => {
  return apiClient.post(
    endpoint + route_user + "/" + userID + act_specific_mailbox_workflow_object,
    JSON.stringify({
      ...criteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const setBusinessObjectAssignmentIsRead = (businessObjectAssignmentID) => {
  return apiClient.put(
    endpoint +
      route_business_object_assignment +
      "/" +
      businessObjectAssignmentID +
      act_set_is_read
  );
};

const assignWorkflowObjectToUser = (
  businessObjectID,
  workflowObjectClassID,
  userID,
  assignmentCommentText
) => {
  return apiClient.put(
    endpoint +
      route_business_object +
      "/" +
      businessObjectID +
      act_assign_to_user,
    JSON.stringify({
      UserID: userID,
      AssignmentCommentText: assignmentCommentText,
      WorkflowObjectClassID: workflowObjectClassID,
    })
  );
};

const assignWorkflowObjectToDepartment = (
  businessObjectID,
  workflowObjectClassID,
  departmentID,
  assignmentCommentText
) => {
  return apiClient.put(
    endpoint +
      route_business_object +
      "/" +
      businessObjectID +
      act_assign_to_department,
    JSON.stringify({
      DepartmentID: departmentID,
      AssignmentCommentText: assignmentCommentText,
      WorkflowObjectClassID: workflowObjectClassID,
    })
  );
};

const assignWorkflowObjectToDepartmentTeam = (
  businessObjectID,
  workflowObjectClassID,
  departmentTeamID,
  assignmentCommentText
) => {
  return apiClient.put(
    endpoint +
      route_business_object +
      "/" +
      businessObjectID +
      act_assign_to_department_team,
    JSON.stringify({
      DepartmentTeamID: departmentTeamID,
      AssignmentCommentText: assignmentCommentText,
      WorkflowObjectClassID: workflowObjectClassID,
    })
  );
};

const removeWorkflowObjectAssignment = (
  businessObjectID,
  workflowObjectClassID
) => {
  return apiClient.put(
    endpoint +
      route_business_object +
      "/" +
      businessObjectID +
      act_remove_assignment,
    JSON.stringify({
      WorkflowObjectClassID: workflowObjectClassID,
    })
  );
};

const workflowObjectsApi = {
  getMailboxItemsTotalCount,
  getMailboxItemsCount,
  getSpecificMailboxWorkflowObject,
  setBusinessObjectAssignmentIsRead,
  assignWorkflowObjectToUser,
  assignWorkflowObjectToDepartment,
  assignWorkflowObjectToDepartmentTeam,
  removeWorkflowObjectAssignment,
};

export default workflowObjectsApi;
