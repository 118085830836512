import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const reinsurers = {
  columns: [
    {
      field: "ID",
      header: strings.ID,
      width: "50px",
    },
    {
      field: "ORGANIZATION",
      header: strings.NAME,
      width: "150px",
    },
  ],
  autoCompleteEndpoint: endpoints.REINSURERS,
  autoCompleteSearchKey: "Name",
};

export default reinsurers;
