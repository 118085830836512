import apiClient from "../common/api/client";
import endpoints from "./endpoints";
import AccountingFiscalYearModel from "../interfaces/accountingFiscalYears/AccountingFiscalYearModel";
import BaseResponse from "../common/interfaces/api/BaseResponse";
import AccountingPeriodModel from "../interfaces/accountingPeriods/AccountingPeriodModel";
import {
  AccountingFiscalYearAndPeriodModel,
  AccountingFiscalYearRequestModel,
} from "../interfaces/api/AccountingFiscalYearsApiModels";
import PagingModel from "../common/interfaces/PagingModel";

const endpoint = endpoints.ACCOUNTING_FISCAL_YEARS;

const route_accounting_fiscal_year = "/accounting-fiscal-year";
const route_accounting_periods = "/accounting-periods";
const route_accounting_period = "/accounting-period";
const act_create_new_accounting_fiscal_year =
  "/create-new-accounting-fiscal-year";
const act_update_accounting_fiscal_year = "/update-accounting-fiscal-year";
const act_delete = "/delete";
const act_generate_accounting_periods = "/generate-accounting-periods";
const act_rollback_accounting_periods = "/rollback-accounting-periods";
const act_open_accounting_period = "/open-accounting-period";
const act_close_accounting_period = "/close-accounting-period";

const getAccountingFiscalYears = (
  criteria: Record<string, any>,
  paging: PagingModel
): BaseResponse<AccountingFiscalYearModel[]> =>
  apiClient.post(endpoint, JSON.stringify({ ...paging, ...criteria }), {
    headers: { "X-HTTP-Method-Override": "GET" },
  });

const createNewAccountingFiscalYear = (
  accountingFiscalYear: Partial<AccountingFiscalYearRequestModel>
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.post(
    endpoint + act_create_new_accounting_fiscal_year,
    JSON.stringify({ ...accountingFiscalYear })
  );
};

const updateAccountingFiscalYear = (
  accountingFiscalYearID: number,
  criteria: Partial<AccountingFiscalYearRequestModel>
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_update_accounting_fiscal_year,
    JSON.stringify({ ...criteria })
  );
};

const deleteAccountingFiscalYear = (
  accountingFiscalYearID: number
): BaseResponse<null> => {
  return apiClient.delete(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_delete
  );
};

const getAccountingFiscalYear = (
  accountingFiscalYearID: string
): BaseResponse<AccountingFiscalYearModel> =>
  apiClient.get(
    endpoint + route_accounting_fiscal_year + "/" + accountingFiscalYearID
  );

const getAccountingPeriods = (criteria: {
  AccountingFiscalYearID: number;
}): BaseResponse<AccountingPeriodModel[]> =>
  apiClient.post(
    endpoint + route_accounting_periods,
    JSON.stringify({ ...criteria }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );

const generateAccountingPeriods = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_generate_accounting_periods
  );
};

const rollbackAccountingPeriods = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_rollback_accounting_periods
  );
};

const openAccountingPeriod = (
  accountingFiscalYearID: number,
  accountingPeriodID: number
): BaseResponse<AccountingFiscalYearAndPeriodModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      route_accounting_period +
      "/" +
      accountingPeriodID +
      act_open_accounting_period
  );
};

const closeAccountingPeriod = (
  accountingFiscalYearID: number,
  accountingPeriodID: number
): BaseResponse<AccountingFiscalYearAndPeriodModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      route_accounting_period +
      "/" +
      accountingPeriodID +
      act_close_accounting_period
  );
};

const accountingFiscalYearsApi = {
  getAccountingFiscalYears,
  createNewAccountingFiscalYear,
  updateAccountingFiscalYear,
  deleteAccountingFiscalYear,
  getAccountingFiscalYear,
  getAccountingPeriods,
  generateAccountingPeriods,
  rollbackAccountingPeriods,
  openAccountingPeriod,
  closeAccountingPeriod,
};

export default accountingFiscalYearsApi;
