import { Suspense, useContext } from "react";
import { Navigate } from "react-router-dom";
import SuspenseComponent from "../../pages/general/SuspenseComponent";
import { AuthenticationContext } from "../context/authenticationContext";
import { UserPortalContext } from "../context/userPortalContext";
import urlBuilder from "../helpers/urlBuilder";

const ProtectedLogin = ({ component: Component, ...rest }: any) => {
  const authContext = useContext(AuthenticationContext);
  const { getUserPortal } = useContext(UserPortalContext);
  if (!authContext || !authContext.isAuthenticated())
    return (
      <Suspense fallback={<SuspenseComponent />}>
        <Component {...rest} />
      </Suspense>
    );
  else
    return (
      <Navigate
        to={urlBuilder.buildUrl(
          getUserPortal,
          authContext.user,
          authContext.token,
          ""
        )}
      />
    );
};

export default ProtectedLogin;
