import { useContext, useState } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import countriesAPI from "../../../api/countries";
import LocationModel from "../../../interfaces/geography/Location";

interface Props extends MultiColumnComboBoxProps {
  dataList?: Array<LocationModel>;
  searchCriteria?: Object;
  charNumber?: number;
  loadDataOnOpen?: boolean;
  isFormComponent?: boolean;
}

export default function LocationAutoComplete({
  dataList,
  columns,
  charNumber = 3,
  loadDataOnOpen,
  isFormComponent,
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const [data, setData] = useState(dataList);

  const objectColumns = [
    {
      field: "ForeignName",
      header: translate(strings.FOREIGN_NAME),
      width: "100px",
    },
    {
      field: "LocalName",
      header: translate(strings.LOCAL_NAME),
      width: "100px",
    },
  ];

  const getData = async (inputString?: string, searchCriteria?: Record<string, any>) => {
    const response = await countriesAPI.getLocationList({
      Name: inputString,
      pageSize: 10,
      page: 0,
      ...searchCriteria,
    });
    if (response.ok) {
      setData(response.data ?? []);
    }

    return response;
  };

  return (
    <AppAutoComplete<LocationModel>
      dataList={data || []}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      loadDataOnOpen={loadDataOnOpen}
      charNumber={charNumber}
      isFormComponent={isFormComponent}
      {...rest}
    />
  );
}
