import {
  MutableRefObject,
  useContext,
  useRef,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Avatar,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { LocalizationContext } from "../context/localizationContext";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import PopupMenu from "../components/menu/PopupMenu";
import { AuthenticationContext } from "../context/authenticationContext";
import LabelOnly from "../components/Labels/LabelOnly";
import styleConstants from "../constants/styleConstants";
import baseStrings from "./../base/baseStrings";
import MenuItemData from "../ui/MenuItemData";
import AppButton from "../components/buttons/AppButton";
import AppStackLayout from "../components/stack/AppStackLayout";
import { ViewAsContext } from "./../context/viewAsContext";
import authStorage from "./../storage/authStorage";
import baseRoutes from "../base/baseRoutes";
import { useNavigate } from "react-router-dom";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import { MailboxContext } from "../context/MailboxContext";
import { NotificationContext } from "../context/NotificationContext";
import uiHelper from "../helpers/uiHelper";
import workflowStorage from "../storage/workflowStorage";
import workflowStorageKeys from "../enums/workflow/workflowStorageKeys";

interface Props {
  handleClick: () => void;
  popupMenuItems?: MenuItemData[];
  logo?: string;
  title?: string;
  showLanguage: boolean;
  drawerButtonImage?: string;
  PopupComponent?: React.ElementType;
  additionalInfo?: string;
  additionalButton?: {
    title: string;
    link: string;
    additionalClass?: string;
  }[];
  hideUsername?: boolean;
  hideUserContextMenu?: boolean;
  hideDrawerButton?: boolean;
  showMailboxButton?: boolean;
  appBarRef?: MutableRefObject<any>;
  MailboxTreeComponent?: React.ElementType;
  showNotificationButton?: boolean;
  NotificationComponent?: React.ElementType;
  mailboxItemsCount?: number;
  setMailboxItemsCount?: Dispatch<SetStateAction<number>>;
  mailboxIsOldData?: boolean;
  setMailboxIsOldData?: Dispatch<SetStateAction<boolean>>;
  notificationIsOldData?: boolean;
  setNotificationIsOldData?: Dispatch<SetStateAction<boolean>>;
  notificationsCount?: number;
  setNotificationsCount?: Dispatch<SetStateAction<number>>;
}

export default function MainAppBar({
  handleClick,
  popupMenuItems = [],
  logo,
  title,
  showLanguage,
  drawerButtonImage,
  PopupComponent,
  additionalInfo,
  hideUsername = false,
  additionalButton,
  hideUserContextMenu = false,
  hideDrawerButton = false,
  showMailboxButton = false,
  appBarRef,
  MailboxTreeComponent,
  showNotificationButton = false,
  NotificationComponent,
  mailboxItemsCount = 0,
  setMailboxItemsCount,
  mailboxIsOldData = false,
  setMailboxIsOldData,
  notificationsCount = 0,
  setNotificationsCount,
  notificationIsOldData = false,
  setNotificationIsOldData,
}: Props) {
  const { ELanguageCodes, getLanguageCode, updateLanguage, translate } =
    useContext(LocalizationContext);
  const { getMailboxItemsTotalCount } = useContext(MailboxContext);
  const { getNotificationsCount } = useContext(NotificationContext);
  //const offSet = React.useRef<Offset>({ left: 0, top: 0 });
  const [showMenu, setShowMenu] = useState(false);
  // const [showNotificationList, setShowNotificationList] = useState(false);
  const menuAnchor = useRef<HTMLElement | null>(null);
  const { logOut, user, token, userCurrentStatus } = useContext(
    AuthenticationContext
  );
  const { viewAsUser, setViewAsUser } = useContext(ViewAsContext);
  const navigate = useNavigate();
  const [showMailboxTreeForm, setShowMailboxTreeForm] =
    useState<boolean>(false);
  const [showNotificationsForm, setShowNotificationsForm] =
    useState<boolean>(false);
  // const profile1Action = () => {
  // };

  const logoutAction = () => {
    logOut();
  };
  // const notificationData = [
  //   {
  //     title: "First Item",
  //     subTitle: "Details about first item",
  //     icon: "k-i-user",
  //     onPress: () => {},
  //   },
  //   {
  //     title: "Second Item",
  //     subTitle: "Details about second item",
  //     icon: "k-i-folder",
  //     onPress: () => {},
  //   },
  //   {
  //     title: "Third Item",
  //     subTitle: "Details about third item",
  //     icon: "k-i-user",
  //     onPress: () => {},
  //   },
  //   {
  //     title: "Fourth Item",
  //     subTitle: "Details about fourth item",
  //     icon: "k-i-folder",
  //     onPress: () => {},
  //   },
  // ];
  const menuItemData = [
    // {
    //   key: "PROFILE",
    //   text: translate(strings.PROFILE),
    //   icon: "user",
    //   disabled: false,
    //   items: [
    //     {
    //       key: "PROFILE1",
    //       text: "profile1",
    //       icon: "settings",
    //       action: profile1Action,
    //     },
    //   ],
    // },

    ...popupMenuItems,
    {
      key: "LOGOUT",
      text: translate(baseStrings.LOGOUT),
      icon: "logout",
      additionalCss: "popup-menu-item ",
      action: logoutAction,
      visible: true,
      // disabled: true,
    },
  ];

  const handleLanguageSelect = ({ value }: any) => {
    updateLanguage(value.Code);
  };
  const handleContextMenu = (e: any) => {
    e.preventDefault();
    //offSet.current = { left: e.clientX - 220, top: e.clientY + 20 };
    setShowMenu(!showMenu);
  };
  // const handleNotificationIconClick = (e: any) => {
  //   e.preventDefault();
  //   //offSet.current = { left: e.clientX - 220, top: e.clientY + 20 };
  //   setShowNotificationList(!showNotificationList);
  // };
  // React.useEffect(() => {
  //   document.addEventListener("click", () => {
  //     if (show) setShow(false);
  //   });
  // }, []);

  const fetchMailboxItemsTotalCount = async () => {
    const response = await getMailboxItemsTotalCount(user.ID);

    if (response.ok) {
      if (response.data) {
        // change storage only if the count was changed
        const oldCount =
          Number.parseInt(workflowStorage.getMailboxItemsCount()) || 0;

        if (oldCount !== response.data)
          workflowStorage.storeMailboxItemsCount(response.data);
      } else workflowStorage.storeMailboxItemsCount("0");
    }
  };

  const fetchNotificationsCount = async () => {
    const response = await getNotificationsCount({
      NotifiedUserID: user.ID,
      ValidAtDate: uiHelper.formatDateTimeZoneOffset(new Date()),
    });

    if (response.ok) {
      if (response.data) {
        // change storage only if the count was changed
        const oldCount =
          Number.parseInt(workflowStorage.getNotificationsCount()) || 0;

        if (oldCount !== response.data)
          workflowStorage.storeNotificationsCount(response.data);
      } else workflowStorage.storeNotificationsCount("0");
    }
  };

  const initializeAppBar = async () => {
    await fetchMailboxItemsTotalCount();
    await fetchNotificationsCount();

    setMailboxItemsCount &&
      setMailboxItemsCount(
        Number.parseInt(workflowStorage.getMailboxItemsCount()) || 0
      );
    setNotificationsCount &&
      setNotificationsCount(
        Number.parseInt(workflowStorage.getNotificationsCount()) || 0
      );
  };

  useEffect(() => {
    initializeAppBar();
  }, []);

  const handleClickOutside = (e: any) => {
    if (document.getElementById("notificationBox")?.contains(e.target)) {
      // Clicked in box
    } else {
      // Clicked outside the box
      setShowNotificationsForm(false);
    }

    /* if (document.getElementById("mailboxBox")?.contains(e.target)) {
      // Clicked in box
    } else {
      // Clicked outside the box
      setShowMailboxTreeForm(false);
    } */
  };

  const onStorageUpdate = (e: StorageEvent) => {
    const { newValue, key } = e;

    if (
      key ===
      workflowStorageKeys.PREFIX + workflowStorageKeys.MAILBOX_ITEMS_COUNT_KEY
    ) {
      if (newValue)
        setMailboxItemsCount && setMailboxItemsCount(Number.parseInt(newValue));
      else setMailboxItemsCount && setMailboxItemsCount(0);

      setMailboxIsOldData && setMailboxIsOldData(true);
    }

    if (
      key ===
      workflowStorageKeys.PREFIX + workflowStorageKeys.NOTIFICATIONS_COUNT_KEY
    ) {
      if (newValue)
        setNotificationsCount &&
          setNotificationsCount(Number.parseInt(newValue));
      else setNotificationsCount && setNotificationsCount(0);

      setNotificationIsOldData && setNotificationIsOldData(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("storage", onStorageUpdate);

    return () => {
      window.removeEventListener("storage", onStorageUpdate);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <AppBar ref={appBarRef}>
      {!hideDrawerButton && (
        <AppBarSection>
          <Button
            icon="layout"
            fillMode="flat"
            onClick={handleClick}
            imageUrl={drawerButtonImage}
          />
        </AppBarSection>
      )}

      <AppBarSection>
        {title && <h1 className="title">{title}</h1>}
        {logo && <img src={logo} className="appbar-logo" alt="logo"></img>}
      </AppBarSection>

      {additionalButton && (
        <AppBarSection>
          <AppStackLayout orientation="horizontal" switchOnMobile gap={5}>
            {additionalButton.map((item: any, idx: number) => (
              <AppButton
                key={idx}
                onClick={() => window.open(item.link, "_blank")}
                additionalClass={item.additionalClass}
              >
                {item.title}
              </AppButton>
            ))}
          </AppStackLayout>
        </AppBarSection>
      )}

      <AppBarSpacer />

      {showNotificationButton && NotificationComponent && (
        <div id="notificationBox">
          <AppBarSection>
            <BadgeContainer>
              <Badge
                themeColor="warning"
                style={{
                  fontSize: "0.85em",
                  zIndex: 100,
                  display: !notificationsCount ? "none" : undefined,
                }}
              >
                {notificationsCount}
              </Badge>
              <Avatar
                type="icon"
                style={{
                  marginRight: styleConstants.MARGIN_MEDIUM,
                  marginLeft: styleConstants.MARGIN_MEDIUM,
                  cursor: "pointer",
                }}
              >
                <span
                  className="k-icon k-font-icon k-i-bell"
                  onClick={() => {
                    setShowNotificationsForm(!showNotificationsForm);
                    setNotificationIsOldData && setNotificationIsOldData(false);
                  }}
                  style={{ padding: 20 }}
                />
              </Avatar>
            </BadgeContainer>
          </AppBarSection>
          {showNotificationsForm && (
            <NotificationComponent
              setIsFormVisible={setShowNotificationsForm}
              setNotificationsCount={setNotificationsCount}
              notificationIsOldData={notificationIsOldData}
              setNotificationIsOldData={setNotificationIsOldData}
            />
          )}
          <AppBarSection>
            <span className="k-appbar-separator" />
          </AppBarSection>
        </div>
      )}

      {showMailboxButton && MailboxTreeComponent && (
        <div id="mailboxBox">
          <AppBarSection>
            <BadgeContainer>
              <Badge
                themeColor="warning"
                style={{
                  fontSize: "0.85em",
                  zIndex: 100,
                  display: !mailboxItemsCount ? "none" : undefined,
                }}
              >
                {mailboxItemsCount}
              </Badge>
              <Avatar
                type="icon"
                style={{
                  marginRight: styleConstants.MARGIN_MEDIUM,
                  marginLeft: styleConstants.MARGIN_MEDIUM,
                  cursor: "pointer",
                }}
              >
                <span
                  className="k-icon k-font-icon k-i-grid-layout"
                  onClick={() => {
                    setShowMailboxTreeForm(!showMailboxTreeForm);
                    setMailboxIsOldData && setMailboxIsOldData(false);
                  }}
                  style={{ padding: 20 }}
                />
              </Avatar>
            </BadgeContainer>
          </AppBarSection>
          {showMailboxTreeForm && (
            <MailboxTreeComponent
              isFormVisible={showMailboxTreeForm}
              setIsFormVisible={setShowMailboxTreeForm}
              setMailboxItemsCount={setMailboxItemsCount}
              mailboxIsOldData={mailboxIsOldData}
              setMailboxIsOldData={setMailboxIsOldData}
            />
          )}
          <AppBarSection>
            <span className="k-appbar-separator" />
          </AppBarSection>
        </div>
      )}

      {showLanguage && (
        <>
          <AppBarSection /*className="actions"*/>
            {/* <span>{translate("language")}</span> */}
            <DropDownList
              textField={"Name"}
              dataItemKey={"Code"}
              data={[ELanguageCodes.ENGLISH, ELanguageCodes.ARABIC]}
              value={getLanguageCode()}
              onChange={handleLanguageSelect}
            />
          </AppBarSection>

          <AppBarSection>
            <span className="k-appbar-separator" />
          </AppBarSection>
        </>
      )}

      {viewAsUser && (
        <>
          <AppBarSpacer />
          <AppBarSection>
            <span className="subtitle">
              Viewing As {"  "}
              {viewAsUser.Username} &nbsp;
            </span>
            <Button
              onClick={() => {
                setViewAsUser(null);
                authStorage.removeViewAsUser();
                navigate(baseRoutes.LOGIN);
              }}
            >
              Exit Mode
            </Button>
          </AppBarSection>
        </>
      )}

      {additionalInfo && (
        <>
          <AppBarSection>
            <span>{additionalInfo}</span>
          </AppBarSection>
        </>
      )}
      {/* <AppBarSection className="actions">
        <button
          className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
          onClick={handleNotificationIconClick}
        >
          <BadgeContainer>
            <span className="k-icon k-font-icon k-i-bell" ref={notificationAnchor} />
            <Badge
              shape="dot"
              themeColor="info"
              size="small"
              position="inside"
            />
            <Popup
              anchor={notificationAnchor?.current}
              show={showNotificationList}
              //popupClass="popup-content"
              //onOpen={onOpen}
              anchorAlign={{ horizontal: "center", vertical: "bottom" }}
              popupAlign={{
                horizontal: isRTL ? "left" : "right",
                vertical: "top",
              }}
            >
              <AppListView
                data={notificationData}
                item={AppListItem}
                style={{ width: "100%" }}
                //   header={MyHeader}
                //   footer={MyFooter}
              />
            </Popup>
          </BadgeContainer>
        </button>
      </AppBarSection> */}

      {!hideUserContextMenu && (
        <AppBarSection>
          <span
            ref={menuAnchor}
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleContextMenu}
          >
            <Avatar
              type="icon"
              style={{
                marginRight: styleConstants.MARGIN_MEDIUM,
                marginLeft: styleConstants.MARGIN_MEDIUM,
              }}
            >
              <span
                className="k-icon k-font-icon k-i-user"
                //onContextMenu={handleContextMenu}
                // style={{ border: 10, borderWidth: 10, borderStyle: "solid" }}
                style={{
                  color:
                    user.IsSystemUser && userCurrentStatus
                      ? userCurrentStatus.IsOnline
                        ? "green"
                        : "red"
                      : undefined,
                }}
              ></span>
            </Avatar>
            {!hideUsername && (
              <LabelOnly
                labelTitle={user.Fullname}
                //labelTitle={user.FullName}
                minWidth={100}
                skipTranslate
              />
            )}
          </span>
          {PopupComponent ? (
            <PopupComponent
              show={showMenu}
              setShow={setShowMenu}
              anchor={menuAnchor}
            />
          ) : (
            <PopupMenu
              show={showMenu}
              setShow={setShowMenu}
              anchor={menuAnchor}
              menuItems={menuItemData}
            ></PopupMenu>
          )}
        </AppBarSection>
      )}
    </AppBar>
  );
}
