import { useContext, useState, useEffect } from "react";
import { FormRenderProps } from "@progress/kendo-react-form";
import providersApi from "../../api/providers";
import strings from "../../base/strings";
import AppForm from "../../common/components/forms/AppForm";
import ProvidersGrid from "../../controls/providers/grids/ProvidersGrid";
import AppFormSection from "../../common/components/forms/AppFormSection";
import AppField from "../../common/components/forms/AppField";
import ProviderTypeModel from "../../interfaces/providers/ProviderTypeModel";
import ProviderSpecialtyModel from "../../interfaces/providers/ProviderSpecialtyModel";
import CountryModel from "../../interfaces/geography/CountryModel";
import CityModel from "../../interfaces/geography/CityModel";
import LocationModel from "../../interfaces/geography/Location";
import FormLabelInput from "../../common/components/formComponents/formLabelInput";
import FormDropDownList from "../../common/components/formComponents/formDropDownList";
import { LocalizationContext } from "../../common/context/localizationContext";
import definitions from "../../api/definitions";
import { getDefinitionList } from "../../common/api/searchDefinitionHelper";
import errorHandler from "../../common/utility/errorHandler";
import countriesAPI from "../../api/countries";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  dummyItemID,
  pageDefaultSize,
} from "../../common/constants/appConstants";
import AppLinkedDropDowns from "../../common/components/dropDown/AppLinkedDropDowns";
import ProviderModel from "../../interfaces/providers/ProviderModel";
import { EDisplayModes } from "../../base/EDisplayModes";
import Alert from "../../common/components/general/Alert";
import styleConstants from "../../common/constants/styleConstants";
import SectionTitle from "../../common/components/forms/SectionTitle";
import AppCard from "../../common/components/cards/AppCard";
import AppCardBody from "../../common/components/cards/AppCardBody";
import AppStackLayout from "../../common/components/stack/AppStackLayout";
import LabelTitle from "../../common/components/Labels/LabelTitle";
import ClaimTypeModel from "../../interfaces/claims/ClaimTypeModel";
import OfferedServiceModel from "../../interfaces/offeredServices/OfferedServiceModel";
import LoadingPanel from "../../common/components/general/LoadingPanel";
import MainAppBar from "../../common/layout/MainAppBar";
import images from "../../base/images";
import AppScrollbar from "../../common/components/scrollbar/AppScrollbar";
import ContentContainer from "../../common/components/container/ContentContainer";

interface MedicalNetworkFormData {
  CardNumber: string;
  ClaimType: ClaimTypeModel | null;
  ProviderName: string;
  ProviderType: ProviderTypeModel | null;
  ProviderSpecialty: ProviderSpecialtyModel | null;
  Country: CountryModel | null;
  City: CityModel | null;
  Location: LocationModel | null;
  OfferedService: OfferedServiceModel | null;
}

enum ESearchMedicalNetworkFields {
  CARD_NUMBER = "CardNumber",
  CLAIM_TYPE = "ClaimType",
  PROVIDER_NAME = "ProviderName",
  PROVIDER_TYPE = "ProviderType",
  PROVIDER_SPECIALTY = "ProviderSpecialty",
  COUNTRY = "Country",
  CITY = "City",
  LOCATION = "Location",
  OFFERED_SERVICE = "OfferedService",
}

export default function SearchMedicalNetworkPage() {
  const { getNameField, translate } = useContext(LocalizationContext);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [resetPageNumber, setResetPageNumber] = useState<boolean>(false);
  const [searchCriteria, setSearchCriteria] = useState<{
    CardNumber: string;
    ClaimTypeID: number | undefined;
    ProviderTypeID: number | undefined;
    ProviderSpecialtyID: number | undefined;
    ProviderCountryID: number | undefined;
    ProviderCityID: number | undefined;
    ProviderLocationID: number | undefined;
    OfferedServiceID: number | undefined;
  }>();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [claimTypes, setClaimTypes] = useState<ClaimTypeModel[]>([]);
  const [providerTypes, setProviderTypes] = useState<ProviderTypeModel[]>([]);
  const [providerSpecialties, setProviderSpecialties] = useState<
    ProviderSpecialtyModel[]
  >([]);
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [cities, setCities] = useState<CityModel[]>([]);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [offeredServices, setOfferedServices] = useState<OfferedServiceModel[]>(
    []
  );

  const loadProviderData = async (
    pageNumber: number,
    pageSize: number,
    criteria: { [key: string]: any } | undefined
  ) => {
    return await providersApi.getMedicalNetworkProviders(
      {
        ...criteria,
        IncludeVirtualProviders: false,
        IncludeOutsideNetworkProviders: false,
        IncludeInhouseProviders: false,
        SearchFacilities: true,
      },
      {
        Page: pageNumber,
        PageSize: pageSize,
      }
    );
  };

  const getClaimTypes = async () => {
    const claimTypeObjectType = definitions.ClaimTypeDefinition.objectType;
    const claimTypeList: ClaimTypeModel[] = await getDefinitionList<
      typeof claimTypeObjectType
    >(
      definitions.ClaimTypeDefinition.endpoint,
      definitions.ClaimTypeDefinition.callType,
      setErrorMessage,
      { ShowOnProviderSearch: true }
    );

    if (claimTypeList && claimTypeList.length > 0) {
      setClaimTypes([...claimTypeList]);
    } else {
      setClaimTypes([]);
    }
  };

  const getProviderTypes = async () => {
    const providerTypeObjectType =
      definitions.CProviderTypeDefinition.objectType;
    const providerTypeList: ProviderTypeModel[] = await getDefinitionList<
      typeof providerTypeObjectType
    >(
      definitions.CProviderTypeDefinition.endpoint,
      definitions.CProviderTypeDefinition.callType,
      setErrorMessage
    );

    if (providerTypeList && providerTypeList.length > 0) {
      setProviderTypes([...providerTypeList]);
    } else {
      setProviderTypes([]);
    }
  };

  const getCountries = async () => {
    const countryObjectType = definitions.CountryDefinition.objectType;
    const countryList: CountryModel[] = await getDefinitionList<
      typeof countryObjectType
    >(
      definitions.CountryDefinition.endpoint,
      definitions.CountryDefinition.callType,
      setErrorMessage
    );

    if (countryList && countryList.length > 0) {
      setCountries([...countryList]);
    } else {
      setCountries([]);
    }
  };

  const getCities = async (countryID: number) => {
    setLoading(true);
    const response = await countriesAPI.getCities(countryID);

    setLoading(false);
    if (response.ok) {
      setCities(response.data ?? []);
    } else setErrorMessage(errorHandler.getErrorMessage(response));
  };

  const getLocations = async (cityID?: number) => {
    if (!cityID) setLocations([]);
    else {
      setLoading(true);

      const response = await countriesAPI.getLocations(cityID);

      setLoading(false);
      if (response.ok) {
        setLocations(response.data ?? []);
      } else setErrorMessage(errorHandler.getErrorMessage(response));
    }
  };

  const getProviderSpecialties = async (providerTypeID: number) => {
    setLoading(true);

    const response =
      await providersApi.getProviderTypeSpecialitiesByProviderTypeID(
        providerTypeID
      );

    setLoading(false);
    if (response.ok) {
      setProviderSpecialties(response.data);
    } else setErrorMessage(errorHandler.getErrorMessage(response));
  };

  const getOfferedServices = async () => {
    const offeredServiceObjectType =
      definitions.COfferedServiceDefinition.objectType;
    const offeredServiceList: OfferedServiceModel[] = await getDefinitionList<
      typeof offeredServiceObjectType
    >(
      definitions.COfferedServiceDefinition.endpoint,
      definitions.COfferedServiceDefinition.callType,
      setErrorMessage
    );

    if (offeredServiceList && offeredServiceList.length > 0) {
      setOfferedServices([...offeredServiceList]);
    } else {
      setOfferedServices([]);
    }
  };

  const initializeFormData = async () => {
    setLoading(true);

    await getClaimTypes();
    await getProviderTypes();
    await getCountries();
    await getOfferedServices();
    setIsInitialized(true);

    setLoading(false);
  };

  useEffect(() => {
    initializeFormData();
    // eslint-disable-next-line
  }, []);

  const mapFormData = (data: MedicalNetworkFormData) => {
    return {
      CardNumber: data.CardNumber,
      ProviderName: data.ProviderName,
      ClaimTypeID:
        data.ClaimType && data.ClaimType.ID !== dummyItemID
          ? data.ClaimType.ID
          : undefined,
      ProviderTypeID:
        data.ProviderType && data.ProviderType.ID !== dummyItemID
          ? data.ProviderType.ID
          : undefined,
      ProviderSpecialtyID:
        data.ProviderSpecialty && data.ProviderSpecialty?.ID !== dummyItemID
          ? data.ProviderSpecialty.ID
          : undefined,
      ProviderCountryID:
        data.Country && data.Country.ID !== dummyItemID
          ? data.Country.ID
          : undefined,
      ProviderCityID:
        data.City && data.City.ID !== dummyItemID ? data.City.ID : undefined,
      ProviderLocationID:
        data.Location && data.Location.ID !== dummyItemID
          ? data.Location.ID
          : undefined,
      OfferedServiceID:
        data.OfferedService && data.OfferedService.ID !== dummyItemID
          ? data.OfferedService.ID
          : undefined,
    };
  };

  const handleSubmit = async (data: { [name: string]: any }, event: any) => {
    const medicalNetworkFormData = data as MedicalNetworkFormData;

    const mappedData = mapFormData(medicalNetworkFormData);

    setSearchCriteria(mappedData);
    setResetPageNumber(true);
  };

  const handleOnProviderTypeChanged = async (
    event: DropDownListChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    formRenderProps.onChange(ESearchMedicalNetworkFields.PROVIDER_SPECIALTY, {
      value: null,
    });

    setProviderSpecialties([]);

    if (event.value) {
      const providerType: ProviderTypeModel = event.value;
      if (providerType.ID !== dummyItemID && providerType.IsDoctor)
        await getProviderSpecialties(event.value.ID);
    }
  };

  const handleOnCountryChanged = async (
    event: DropDownListChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    formRenderProps.onChange(ESearchMedicalNetworkFields.CITY, {
      value: null,
    });
    formRenderProps.onChange(ESearchMedicalNetworkFields.LOCATION, {
      value: null,
    });

    setCities([]);
    setLocations([]);

    if (event.value) {
      if (event.value.ID !== dummyItemID) await getCities(event.value.ID);
    }
  };

  const handleOnCityChanged = async (
    event: DropDownListChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    formRenderProps.onChange(ESearchMedicalNetworkFields.LOCATION, {
      value: null,
    });

    setLocations([]);

    if (event.value) {
      if (event.value.ID !== dummyItemID) await getLocations(event.value.ID);
    }
  };

  if (!isInitialized) return <></>;

  return (
    <>
      <MainAppBar
        handleClick={() => {}}
        logo={images.ETPA_TEXT_LOGO}
        showLanguage
        hideUserContextMenu
        hideDrawerButton
      />
      <AppStackLayout className="external-container">
        <ContentContainer>
          <AppScrollbar>
            <div className="form-container">
              <img
                src={process.env.REACT_APP_COMPANY_LOGO}
                alt="etpa logo"
                style={{ objectFit: "contain", width: "400px" }}
              />
              <AppForm
                addActionWrapper
                isFormVisible
                popupTitle={strings.MEDICAL_NETWORK}
                onSubmit={handleSubmit}
                submitButtonTitle={strings.SEARCH}
                showCancelButton={false}
                loading={loading}
                setLoading={setLoading}
                ignoreModified
                render={(formRenderProps: FormRenderProps) => (
                  <AppCard style={{ marginTop: "2%" }}>
                    <LabelTitle
                      title={translate(strings.SEARCH_MEDICAL_NETWORK)}
                      isExtremeLarge
                    />
                    <AppCardBody
                      hasTitle={false}
                      orientation={"horizontal"}
                      minComponentWidth={0}
                    >
                      <AppFormSection numColumns={3}>
                        <AppField
                          id={ESearchMedicalNetworkFields.CARD_NUMBER}
                          name={ESearchMedicalNetworkFields.CARD_NUMBER}
                          label={strings.CARD_NUMBER}
                          component={FormLabelInput}
                        />
                        <AppField
                          id={ESearchMedicalNetworkFields.CLAIM_TYPE}
                          name={ESearchMedicalNetworkFields.CLAIM_TYPE}
                          label={strings.SERVICE_TYPE}
                          component={FormDropDownList}
                          textField={getNameField()}
                          useObjectBinding={true}
                          data={claimTypes}
                          resetValueOnChange={false}
                          addDummyItem={true}
                        />
                        <AppField
                          id={ESearchMedicalNetworkFields.OFFERED_SERVICE}
                          name={ESearchMedicalNetworkFields.OFFERED_SERVICE}
                          label={strings.OFFERED_SERVICE}
                          component={FormDropDownList}
                          textField={getNameField()}
                          useObjectBinding={true}
                          data={offeredServices}
                          resetValueOnChange={false}
                          addDummyItem={true}
                          enableFiltering={true}
                          filterByLocalAndForeignNames={true}
                        />
                        <AppLinkedDropDowns colSpan={3}>
                          <AppField
                            id={ESearchMedicalNetworkFields.PROVIDER_NAME}
                            name={ESearchMedicalNetworkFields.PROVIDER_NAME}
                            label={strings.PROVIDER}
                            component={FormLabelInput}
                          />
                          <AppField
                            id={ESearchMedicalNetworkFields.PROVIDER_TYPE}
                            name={ESearchMedicalNetworkFields.PROVIDER_TYPE}
                            label={strings.MEDICAL_FIELD}
                            component={FormDropDownList}
                            textField={getNameField()}
                            useObjectBinding={true}
                            data={providerTypes}
                            resetValueOnChange={false}
                            onChange={(event) =>
                              handleOnProviderTypeChanged(
                                event,
                                formRenderProps
                              )
                            }
                            addDummyItem={true}
                          />
                          <AppField
                            id={ESearchMedicalNetworkFields.PROVIDER_SPECIALTY}
                            name={
                              ESearchMedicalNetworkFields.PROVIDER_SPECIALTY
                            }
                            label={strings.PROVIDER_SPECIALTY}
                            component={FormDropDownList}
                            textField={getNameField()}
                            useObjectBinding={true}
                            data={providerSpecialties}
                            resetValueOnChange={false}
                            addDummyItem={true}
                            enableFiltering={true}
                            filterByLocalAndForeignNames={true}
                          />
                        </AppLinkedDropDowns>
                        <AppLinkedDropDowns colSpan={3}>
                          <AppField
                            id={ESearchMedicalNetworkFields.COUNTRY}
                            name={ESearchMedicalNetworkFields.COUNTRY}
                            label={strings.COUNTRY}
                            component={FormDropDownList}
                            textField={getNameField()}
                            useObjectBinding={true}
                            data={countries}
                            resetValueOnChange={false}
                            addDummyItem={true}
                            onChange={(event) =>
                              handleOnCountryChanged(event, formRenderProps)
                            }
                          />
                          <AppField
                            id={ESearchMedicalNetworkFields.CITY}
                            name={ESearchMedicalNetworkFields.CITY}
                            label={strings.CITY}
                            component={FormDropDownList}
                            textField={getNameField()}
                            useObjectBinding={true}
                            data={cities}
                            resetValueOnChange={false}
                            addDummyItem={true}
                            enableFiltering={true}
                            filterByLocalAndForeignNames={true}
                            onChange={(event) =>
                              handleOnCityChanged(event, formRenderProps)
                            }
                          />
                          <AppField
                            id={ESearchMedicalNetworkFields.LOCATION}
                            name={ESearchMedicalNetworkFields.LOCATION}
                            label={strings.LOCATION}
                            component={FormDropDownList}
                            textField={getNameField()}
                            useObjectBinding={true}
                            data={locations}
                            resetValueOnChange={false}
                            addDummyItem={true}
                            enableFiltering={true}
                            filterByLocalAndForeignNames={true}
                          />
                        </AppLinkedDropDowns>
                      </AppFormSection>
                    </AppCardBody>
                  </AppCard>
                )}
              />
            </div>
            <AppStackLayout
              orientation="vertical"
              style={{ paddingTop: styleConstants.PADDING_LARGE }}
            >
              <SectionTitle title={strings.PROVIDERS} />
              <ProvidersGrid
                data={providers}
                mode={EDisplayModes.SEARCH_MEDICAL_NETWORK}
                showCounter={false}
                fixedHeight
                loadOnDemand={{
                  pageSize: pageDefaultSize,
                  setGridData: setProviders,
                  loadData: loadProviderData,
                  externalDataHandling: false,
                  reset: resetPageNumber,
                  setReset: setResetPageNumber,
                  searchCriteria,
                }}
              />
            </AppStackLayout>
          </AppScrollbar>
        </ContentContainer>
      </AppStackLayout>
      <div
        style={{
          height: "29px",
          display: "flex",
          marginTop: "1px",
          flexDirection: "row-reverse",
          paddingInlineEnd: "30px",
        }}
      >
        <img src={images.POWERED_BY_TRIAMETIS} alt="Powered By Triametis" />
      </div>
      {errorMessage && (
        <Alert message={errorMessage} setMessage={setErrorMessage} />
      )}
      {loading && <LoadingPanel />}
    </>
  );
}
