import AutoCompleteObject from "../common/interfaces/AutoCompleteObject";
import approvals from "../controls/approvals/base/autocompleteApprovals";
import cards from "../controls/cards/base/autocompleteCards";
import clients from "../controls/clients/base/autocompleteClients";
import diagnoses from "../controls/diagnoses/base/autocompleteDiagnoses";
import providers from "../controls/providers/base/autocompleteProviders";
import services from "../controls/services/base/autocompleteServices";
import transmittals from "./../controls/transmittals/base/autoCompleteTransmittals";
import serviceTypes from "../controls/services/base/autocompleteServiceTypes";
import serviceCategories from "../controls/services/base/autocompleteServiceCategories";
import serviceGroups from "../controls/services/base/autocompleteServiceGroups";
import pricingSchemes from "../controls/services/base/autocompletePricingSchemes";
import plans from "../controls/plans/base/autoCompletePlans";
import packs from "./../controls/packs/base/autoCompletePacks";
import reinsurers from "../controls/reinsurers/base/autoCompleteReinsurers";
import medicalCases from "../controls/diagnoses/base/autocompleteMedicalCases";
import diagnosisBlocks from "../controls/diagnoses/base/autocompleteDiagnosisBlocks";
import diagnosisChapters from "../controls/diagnoses/base/autocompleteDiagnosisChapters";
import departments from "../controls/users/base/autocompleteDepatments";
import departmentTeams from "../controls/users/base/autocompleteDepatmentTeams";
import organizations from "./../controls/organizations/base/autoCompleteOrganizations";
import insuranceCompanies from "./../controls/insuranceCompanies/base/autoCompleteInsuranceCompanies";
import insuredPersonInformations from "./../controls/insuredPersonInformations/base/autoCompleteInsuredPersonInformation";
import persons from "./../controls/persons/base/autoCompletePersons";
import premiumFunders from "./../controls/parties/base/autoCompletePremiumFunder";
import relationOfficers from "./../controls/corporateRelations/base/autoCompleteRelationOfficers";
import brokers from "./../controls/brokers/base/autoCompleteBrokers";
import vendors from "./../controls/vendors/base/autoCompleteVendors";
import providerPaymentVoucherGroups from "./../controls/pvps/base/autoCompleteProviderPaymentVoucherGroups";
import chronicForms from "./../controls/chronicForms/base/autoCompleteChronicForms";
import serviceChapters from "../controls/services/base/autocompleteServiceChapters";
import providerSpecialties from "../controls/providers/base/autocompleteProviderSpecialties";
import providerGroups from "../controls/providers/base/autocompleteProviderGroups";
import policies from "../controls/policy/base/autocompletePolicies";
import users from "./../controls/users/base/autocompleteUsers";
import operationCategories from "../controls/services/base/autocompleteOperationCategories";
import modules from "../controls/users/base/autoCompleteModules";
import roles from "../controls/users/base/autoCompleteRoles";
import groups from "../controls/users/base/autoCompleteGroups";
import costCenters from "./../controls/costCenters/base/autoCompleteCostCenters";
import accountingFiscalYears from "./../controls/accountingFiscalYears/base/autoCompleteAccountingFiscalYears";
import insuranceCompanyBranches from "../controls/insuranceCompanies/base/autoCompleteInsuranceCompanyBranches";
//import mainOrganizations from "../controls/organizations/base/autoCompleteMainOrganizations";
import clientCompanies from "../controls/clients/base/autocompleteClientCompanies";
import accounts from "./../controls/accounts/base/autoCompleteAccounts";
import actions from "../controls/users/base/autoCompleteActions";
import commands from "../controls/users/base/autoCompleteCommands";
const autocompleteObjects: { [key: string]: AutoCompleteObject } = {
  cards,
  diagnoses,
  providers,
  services,
  clients,
  approvals,
  transmittals,
  serviceTypes,
  serviceCategories,
  serviceGroups,
  pricingSchemes,
  plans,
  packs,
  reinsurers,
  medicalCases,
  diagnosisBlocks,
  diagnosisChapters,
  departments,
  departmentTeams,
  organizations,
  insuranceCompanies,
  insuredPersonInformations,
  persons,
  premiumFunders,
  relationOfficers,
  brokers,
  vendors,
  providerPaymentVoucherGroups,
  chronicForms,
  serviceChapters,
  providerSpecialties,
  providerGroups,
  policies,
  users,
  operationCategories,
  modules,
  roles,
  groups,
  costCenters,
  accountingFiscalYears,
  insuranceCompanyBranches,
  // mainOrganizations,
  clientCompanies,
  accounts,
  actions,
  commands,
};

export default autocompleteObjects;
