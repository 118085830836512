import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.EMPLOYEES;

const route_employee = "/employee";
const route_bank_account = "/bank-account";
const route_direct_payment_recipient = "/direct-payment-recipient";
const act_create_new_employee = "/create-new-employee";
const act_update_employee = "/update-employee";
const act_delete = "/delete";
const act_add_employee_bank_account = "/add-employee-bank-account";
const act_update_employee_bank_account = "/update-employee-bank-account";
const act_delete_employee_bank_account = "/delete-employee-bank-account";
const act_add_employee_direct_payment_recipient =
  "/add-employee-direct-payment-recipient";
const act_update_employee_direct_payment_recipient =
  "/update-employee-direct-payment-recipient";
const act_delete_employee_direct_payment_recipient =
  "/delete-employee-direct-payment-recipient";

const getEmployees = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const createNewEmployee = (employee) => {
  return apiClient.post(
    endpoint + act_create_new_employee,
    JSON.stringify({ ...employee })
  );
};

const updateEmployee = (employeeID, employee) => {
  return apiClient.put(
    endpoint + route_employee + "/" + employeeID + act_update_employee,
    JSON.stringify({ ...employee })
  );
};

const deleteEmployee = (employeeID) => {
  return apiClient.delete(
    endpoint + route_employee + "/" + employeeID + act_delete
  );
};

const getEmployee = (employeeID) => apiClient.get(endpoint + "/" + employeeID);

const addEmployeeBankAccount = (employeeID, bankAccount) => {
  return apiClient.post(
    endpoint +
      route_employee +
      "/" +
      employeeID +
      act_add_employee_bank_account,
    JSON.stringify({ ...bankAccount })
  );
};

const updateEmployeeBankAccount = (employeeID, bankAccountID, criteria) => {
  return apiClient.put(
    endpoint +
      route_employee +
      "/" +
      employeeID +
      route_bank_account +
      "/" +
      bankAccountID +
      act_update_employee_bank_account,
    JSON.stringify({ ...criteria })
  );
};

const deleteEmployeeBankAccount = (employeeID) => {
  return apiClient.put(
    endpoint +
      route_employee +
      "/" +
      employeeID +
      act_delete_employee_bank_account
  );
};

const addEmployeeDirectPaymentRecipient = (
  employeeID,
  directPaymentRecipient
) => {
  return apiClient.post(
    endpoint +
      route_employee +
      "/" +
      employeeID +
      act_add_employee_direct_payment_recipient,
    JSON.stringify({ ...directPaymentRecipient })
  );
};

const updateEmployeeDirectPaymentRecipient = (
  employeeID,
  directPaymentRecipientID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_employee +
      "/" +
      employeeID +
      route_direct_payment_recipient +
      "/" +
      directPaymentRecipientID +
      act_update_employee_direct_payment_recipient,
    JSON.stringify({ ...criteria })
  );
};

const deleteEmployeeDirectPaymentRecipient = (employeeID) => {
  return apiClient.put(
    endpoint +
      route_employee +
      "/" +
      employeeID +
      act_delete_employee_direct_payment_recipient
  );
};

const employeesApi = {
  getEmployees,
  createNewEmployee,
  updateEmployee,
  deleteEmployee,
  getEmployee,
  addEmployeeBankAccount,
  updateEmployeeBankAccount,
  deleteEmployeeBankAccount,
  addEmployeeDirectPaymentRecipient,
  updateEmployeeDirectPaymentRecipient,
  deleteEmployeeDirectPaymentRecipient,
};

export default employeesApi;
