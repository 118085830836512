import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.PVC;

const route_client_payment_vouchers = "/client-payment-vouchers";
const route_client_payment_voucher_groups = "/client-payment-voucher-groups";
const act_create_client_payment_voucher_group =
  "/create-client-payment-voucher-group";
const route_client_payment_voucher_group = "/client-payment-voucher-group";
const act_delete = "/delete";
const act_checkin_group = "/checkin-group";
const act_checkout_group = "/checkout-group";
const act_recaclulate_group = "/recalculate-group";
const act_submit_group = "/submit-group";
const act_rollback_group = "/rollback-group";
const act_create_audit_pvc_group_task = "/create-audit-pvc-group-task";
const act_detach_pvc_group_task = "/detach-pvc-group-task";
const act_unaudit_group = "/unaudit-group";
const act_change_group = "/change-group";
const route_client_payment_voucher = "/client-payment-voucher";
const act_checkin = "/checkin";
const act_checkout = "/checkout";
const act_recalculate = "/recalculate";
const act_audit_client_payment_voucher = "/audit-client-payment-voucher";
const act_un_audit_client_payment_voucher = "/unaudit-client-payment-voucher";
const act_reset_client_payment_voucher_as_settled =
  "/reset-client-payment-voucher-as-settled";
const act_reset_client_payment_voucher_as_collected =
  "/reset-client-payment-voucher-as-collected";
const route_claim_invoice = "/claim-invoice";
const act_remove_claim_invoice = "/remove-claim-invoice";
const act_add_claim_invoices = "/add-claim-invoices";
const act_create_client_payment_voucher = "/create-client-payment-voucher";
const act_update_group_description = "/update-group-description";
const act_add_client_payment_vouchers = "/add-client-payment-vouchers";
const act_remove_client_payment_voucher = "/remove-client-payment-voucher";
const act_set_bill_generation = "/set-bill-generation";
const act_download_pvc_group_clients_with_frozen_bank_account_report =
  "/download-pvc-group-clients-with-frozen-bank-account-report";
const act_download_pvc_group_pvc_unverified_bank_accounts_report =
  "/download-pvc-group-pvc-unverified-bank-accounts-report";
const act_download_pvc_group_pvc_list_report =
  "/download-pvc-group-pvc-list-report";
const act_download_pvc_details_report = "/download-pvc-details-report";
const act_pending_reimbursement_payments_count =
  "/pending-reimbursement-payments-count";
const act_download_client_payment_voucher_report =
  "/download-client-payment-voucher-report";
const act_download_pvc_group_payment_information_report =
  "/download-pvc-group-payment-information-report";

const getClientPaymentVouchers = (criteria, paging) => {
  return apiClient.post(
    endpoint + route_client_payment_vouchers,
    JSON.stringify({ ...paging, ...criteria }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getClientPaymentVoucherGroups = (criteria, paging) => {
  return apiClient.post(
    endpoint + route_client_payment_voucher_groups,
    JSON.stringify({ ...paging, ...criteria }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const createClientPaymentVoucherGroup = (criteria) => {
  return apiClient.post(
    endpoint + act_create_client_payment_voucher_group,
    JSON.stringify({ ...criteria })
  );
};

const getClientPaymentVoucherGroup = (clientPaymentVoucherGroupID) =>
  apiClient.get(
    endpoint +
      route_client_payment_voucher_group +
      "/" +
      clientPaymentVoucherGroupID
  );

const deleteClientPaymentVoucherGroup = (clientPaymentVoucherGroupID) => {
  return apiClient.delete(
    endpoint + "/" + clientPaymentVoucherGroupID + act_delete
  );
};

const checkinClientPaymentVoucherGroup = (clientPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherGroupID + act_checkin_group
  );
};

const checkoutClientPaymentVoucherGroup = (clientPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherGroupID + act_checkout_group
  );
};

const recalculateClientPaymentVoucherGroup = (clientPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherGroupID + act_recaclulate_group
  );
};

const submitClientPaymentVoucherGroup = (clientPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherGroupID + act_submit_group
  );
};

const rollbackClientPaymentVoucherGroup = (clientPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherGroupID + act_rollback_group
  );
};

const createAuditClientPaymentVoucherGroupTask = (
  clientPaymentVoucherGroupID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientPaymentVoucherGroupID +
      act_create_audit_pvc_group_task
  );
};

const detachClientPaymentVoucherGroupTask = (clientPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherGroupID + act_detach_pvc_group_task
  );
};

const unauditClientPaymentVoucherGroup = (clientPaymentVoucherGroupID) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherGroupID + act_unaudit_group
  );
};

const updateClientPaymentVoucherPVCGroup = (
  clientPaymentVoucherID,
  clientPaymentVoucherGroupID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientPaymentVoucherID +
      route_client_payment_voucher_group +
      "/" +
      clientPaymentVoucherGroupID +
      act_change_group
  );
};

const getClientPaymentVoucher = (clientPaymentVoucherID) =>
  apiClient.get(
    endpoint + route_client_payment_voucher + "/" + clientPaymentVoucherID
  );

const deleteClientPaymentVoucher = (clientPaymentVoucherID) => {
  return apiClient.delete(
    endpoint +
      route_client_payment_voucher +
      "/" +
      clientPaymentVoucherID +
      act_delete
  );
};

const checkinClientPaymentVoucher = (clientPaymentVoucherID) => {
  return apiClient.put(endpoint + "/" + clientPaymentVoucherID + act_checkin);
};

const checkoutClientPaymentVoucher = (clientPaymentVoucherID) => {
  return apiClient.put(endpoint + "/" + clientPaymentVoucherID + act_checkout);
};

const recalculateClientPaymentVoucher = (clientPaymentVoucherID) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherID + act_recalculate
  );
};

const auditClientPaymentVoucher = (clientPaymentVoucherID) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherID + act_audit_client_payment_voucher
  );
};

const unAuditClientPaymentVoucher = (clientPaymentVoucherID) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientPaymentVoucherID +
      act_un_audit_client_payment_voucher
  );
};

const resetClientPaymentVoucherAsSettled = (clientPaymentVoucherID) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientPaymentVoucherID +
      act_reset_client_payment_voucher_as_settled
  );
};

const resetClientPaymentVoucherAsCollected = (clientPaymentVoucherID) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientPaymentVoucherID +
      act_reset_client_payment_voucher_as_collected
  );
};

const removeClientPaymentVoucherClaimInvoice = (
  clientPaymentVoucherID,
  claimInvoiceID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientPaymentVoucherID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_remove_claim_invoice
  );
};

const addClaimInvoices = (clientPaymentVoucherID, criteria) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherID + act_add_claim_invoices,
    JSON.stringify({
      ...criteria,
    })
  );
};

const createClientPaymentVoucher = (criteria) => {
  return apiClient.post(
    endpoint + act_create_client_payment_voucher,
    JSON.stringify({ ...criteria })
  );
};

const updateClientPaymentVoucherGroupDescription = (
  clientPaymentVoucherGroupID,
  criteria
) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherGroupID + act_update_group_description,
    JSON.stringify({ ...criteria })
  );
};

const addClientPaymentVouchersToPVCGroup = (
  clientPaymentVoucherGroupID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientPaymentVoucherGroupID +
      act_add_client_payment_vouchers,
    JSON.stringify({
      ...criteria,
    })
  );
};

const removeClientPaymentVoucherFromPVCGroup = (
  clientPaymentVoucherGroupID,
  clientPaymentVoucherID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientPaymentVoucherGroupID +
      route_client_payment_voucher +
      "/" +
      clientPaymentVoucherID +
      act_remove_client_payment_voucher
  );
};

const setClientPaymentVoucherBillGeneration = (
  clientPaymentVoucherID,
  criteria
) => {
  return apiClient.put(
    endpoint + "/" + clientPaymentVoucherID + act_set_bill_generation,
    JSON.stringify({ ...criteria })
  );
};

const downloadPVCGroupClientsWithFrozenBankAccountReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvc_group_clients_with_frozen_bank_account_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVCGroupPVCUnVerifiedBankAccountsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvc_group_pvc_unverified_bank_accounts_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVCGroupPVCListReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvc_group_pvc_list_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVCDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvc_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getPendingReimbursementPaymentsCount = () => {
  return apiClient.get(endpoint + act_pending_reimbursement_payments_count);
};

const downloadClientPaymentVoucherReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_client_payment_voucher_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPVCGroupPaymentInformationReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_pvc_group_payment_information_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const pvcApi = {
  getClientPaymentVouchers,
  createClientPaymentVoucherGroup,
  getClientPaymentVoucherGroups,
  getClientPaymentVoucherGroup,
  deleteClientPaymentVoucherGroup,
  checkinClientPaymentVoucherGroup,
  checkoutClientPaymentVoucherGroup,
  recalculateClientPaymentVoucherGroup,
  submitClientPaymentVoucherGroup,
  rollbackClientPaymentVoucherGroup,
  createAuditClientPaymentVoucherGroupTask,
  detachClientPaymentVoucherGroupTask,
  unauditClientPaymentVoucherGroup,
  updateClientPaymentVoucherPVCGroup,
  getClientPaymentVoucher,
  deleteClientPaymentVoucher,
  checkinClientPaymentVoucher,
  checkoutClientPaymentVoucher,
  recalculateClientPaymentVoucher,
  auditClientPaymentVoucher,
  unAuditClientPaymentVoucher,
  resetClientPaymentVoucherAsSettled,
  resetClientPaymentVoucherAsCollected,
  removeClientPaymentVoucherClaimInvoice,
  addClaimInvoices,
  createClientPaymentVoucher,
  updateClientPaymentVoucherGroupDescription,
  addClientPaymentVouchersToPVCGroup,
  removeClientPaymentVoucherFromPVCGroup,
  setClientPaymentVoucherBillGeneration,
  downloadPVCGroupClientsWithFrozenBankAccountReport,
  downloadPVCGroupPVCUnVerifiedBankAccountsReport,
  downloadPVCGroupPVCListReport,
  downloadPVCDetailsReport,
  getPendingReimbursementPaymentsCount,
  downloadClientPaymentVoucherReport,
  downloadPVCGroupPaymentInformationReport,
};

export default pvcApi;
