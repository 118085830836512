import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const departments = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "200px",
    },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "200px",
    },
  ],
  autoCompleteEndpoint: endpoints.USERS + "/departments",
  autoCompleteSearchKey: "DepartmentName",
};

export default departments;
