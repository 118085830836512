import { useContext, useRef, useState } from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { LocalizationContext } from "../../context/localizationContext";
import AppInput from "../inputs/AppInput";
import useBreakpoint from "use-breakpoint";
import { Breakpoints } from "../../constants/breakpoints";
import breakpointKeys from "../../constants/breakpointKeys";
import { Popover } from "@progress/kendo-react-tooltip";
import { Icon } from "@progress/kendo-react-common";

export default function FormLabelInput({
  validationMessage,
  visited,
  hintMessage,
  inputType,
  label,
  id,
  valid,
  hasCustomLabelIcon,
  customIconName,
  onCustomIconClick,
  ...others
}: FieldRenderProps) {
  const { breakpoint } = useBreakpoint(Breakpoints, breakpointKeys.DESKTOP);

  const { translate } = useContext(LocalizationContext);
  const [showTitleNotificationPopup, setShowTitleNotificationPopup] =
    useState<boolean>(false);

  const anchor = useRef<HTMLElement>(null);

  const toggleTitleNotificationPopup = () => {
    setShowTitleNotificationPopup((oldValue) => !oldValue);
  };

  const handleOnClick = () => {
    //if (handleClick) handleClick();
    // else
    if (
      breakpoint !== breakpointKeys.DESKTOP &&
      breakpoint !== breakpointKeys.HIGH_RES
    )
      toggleTitleNotificationPopup();
  };

  const showValidationMessage: string | false | null =
    visited && validationMessage;

  return (
    <FieldWrapper>
      {label ? (
        <Label editorId={id} editorValid={valid}>
          <span onClick={handleOnClick} ref={anchor}>
            {translate(label)}
          </span>
          {hasCustomLabelIcon && (
            <Icon
              name={customIconName}
              onClick={onCustomIconClick}
              themeColor="primary"
              style={{ cursor: "pointer", alignSelf: "center" }}
            />
          )}
          {label && (
            <Popover
              className="app-pill-notification"
              show={showTitleNotificationPopup}
              anchor={anchor.current && anchor.current}
              position={"bottom"}
              callout={true}
              style={{ maxWidth: "200px" }}
            >
              <span className="pill-notification-text">{translate(label)}</span>
            </Popover>
          )}
        </Label>
      ) : null}
      <AppInput valid={valid} id={id} {...others} type={inputType} />
      {/* //type={"email"} */}
      {!showValidationMessage && hintMessage && <Hint>{hintMessage}</Hint>}
      {showValidationMessage && <Error>{translate(validationMessage)}</Error>}
    </FieldWrapper>
  );
}
