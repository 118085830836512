import {
  CSSProperties,
  KeyboardEventHandler,
  ReactElement,
  ReactNode,
  useContext,
} from "react";
import { StackLayout } from "@progress/kendo-react-layout";
import { LocalizationContext } from "../../context/localizationContext";

interface Props {
  Grid: JSX.Element;
  title?: string;
  ellipsisCells?: boolean;
  titleType?: "primary" | "secondary" | "section-title";
  style?: CSSProperties;
  handleKeyDown?: KeyboardEventHandler<HTMLDivElement>;
}

export default function AppGridPanel({
  title,
  Grid,
  ellipsisCells = true,
  titleType = "section-title",
  style,
  handleKeyDown,
}: Props) {
  const { translate } = useContext(LocalizationContext);
  return (
    <StackLayout
      orientation="vertical"
      gap={0}
      className="app-grid-panel"
      align={{ vertical: "top" }}
      style={style || {}}
    >
      <>
        {title && (
          <div className={"grid-title " + titleType}>
            {title && translate(title)}
          </div>
        )}

        {ellipsisCells ? (
          <div className={"ellipsis_cell"} onKeyDown={handleKeyDown}>
            {Grid}
          </div>
        ) : (
          { Grid }
        )}
      </>
    </StackLayout>
  );
}
