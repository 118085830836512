const EImageConstants = {
  ETPA: process.env.REACT_APP_ROUTER_BASENAME + "/assets/img/etpa.png",
  LOGO: process.env.REACT_APP_ROUTER_BASENAME + "/assets/img/IMPA_New_Logo.png",
  ETPA_TEXT_LOGO:
    process.env.REACT_APP_ROUTER_BASENAME + "/assets/img/etpa_text.png",
  POWERED_BY_TRIAMETIS:
    process.env.REACT_APP_ROUTER_BASENAME +
    "/assets/img/powered_by_triametis_logo.png",
};

export default EImageConstants;
