import React, { useContext, useEffect } from "react";
import { Icon } from "@progress/kendo-react-common";
import AppIndicatorProps from "../../ui/AppIndicatorProps";
import { LocalizationContext } from "../../context/localizationContext";
import AppStackLayout from "../stack/AppStackLayout";
import uiHelper from "../../helpers/uiHelper";
import iconFactory from "../../../helpers/faIconsFactory";
import { getColor } from "../../helpers/colorsHelper";

export default function AppIndicator({
  iconColor,
  iconBackground,
  indicatorText,
  onClick,
  icon,
  iconSize = "xlarge",
  indicatorTextClassName,
  additionalStyle,
  indicatorTextStyle,
  isColumnIndicator = false,
}: AppIndicatorProps) {
  const { translate } = useContext(LocalizationContext);

  return isColumnIndicator === false ? (
    <span>
      <span>
        {icon?.slice(0, 2) === "fa" ? (
          uiHelper.renderCustomIcon(iconFactory, icon, {
            minWidth: "25px",
            color: getColor(iconColor!),
            background: iconBackground,
            cursor: onClick ? "pointer" : "",
            ...additionalStyle,
          })
        ) : (
          <Icon
            className={icon}
            style={{
              color: getColor(iconColor!),
              cursor: onClick ? "pointer" : "",
              background: iconBackground,
              ...additionalStyle,
            }}
            size={iconSize}
          />
        )}
      </span>

      <span
        style={{
          marginLeft: "5px",
          ...indicatorTextStyle,
        }}
        className={indicatorTextClassName}
        onClick={onClick}
      >
        {translate(indicatorText)}
      </span>
    </span>
  ) : (
    <AppStackLayout orientation="horizontal" gap={0}>
      <span style={{ flex: 10 }}>
        {icon?.slice(0, 2) === "fa" ? (
          uiHelper.renderCustomIcon(iconFactory, icon, {
            minWidth: "25px",
            color: getColor(iconColor!),
            background: iconBackground,
            cursor: onClick ? "pointer" : "",
            ...additionalStyle,
          })
        ) : (
          <Icon
            className={icon}
            style={{
              color: getColor(iconColor!),
              cursor: onClick ? "pointer" : "",
              background: iconBackground,
              ...additionalStyle,
            }}
            size={iconSize}
          />
        )}
      </span>

      <span
        style={{
          marginLeft: "5px",
          ...indicatorTextStyle,
          flex: 90,
        }}
        className={indicatorTextClassName}
        onClick={onClick}
      >
        {translate(indicatorText)}
      </span>
    </AppStackLayout>
  );
}
