import { ApiResponse } from "apisauce";
import { createContext, ReactNode } from "react";

interface ContextProps {
  getMailboxItemsTotalCount: (userID: number) => Promise<ApiResponse<any, any>>;
}
interface Props extends ContextProps {
  children: ReactNode;
}
export const MailboxContext = createContext<ContextProps>({
  getMailboxItemsTotalCount: (userID: number) => {
    return new Promise((resolve, reject) => {});
  },
});

export default function MailboxContextProvider({
  getMailboxItemsTotalCount,
  children,
}: Props) {
  return (
    <MailboxContext.Provider
      value={{
        getMailboxItemsTotalCount,
      }}
    >
      {children}
    </MailboxContext.Provider>
  );
}
