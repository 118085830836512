import { useContext, useEffect, useState } from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Error } from "@progress/kendo-react-labels";
import { NumericTextBoxBlurEvent } from "@progress/kendo-react-inputs";
import { LocalizationContext } from "../../context/localizationContext";
import uiHelper from "../../helpers/uiHelper";
import AppNumericTextBox from "../inputs/AppNumericTextBox";
import baseMessages from "../../base/baseMessages";
import { ApplicationContext } from './../../context/applicationContext';

interface Props extends GridCellProps {
  editable: boolean;
  min?: number;
  max?: number;
  onCloseEdit?: (e: any) => Promise<any>;
  handleOnRowClick?: (e: any) => void;
  editableInNew: boolean;
  disabled?: boolean;
  currency?: string;
  fixedCurrency?: string;
}

export default function AppNumericTextBoxCell({
  dataItem,
  editable,
  field = "",
  min,
  max,
  onCloseEdit,
  onChange,
  handleOnRowClick,
  editableInNew,
  disabled,
  currency = "",
  fixedCurrency,
  ...rest
}: Props) {
  const { translate, getName } = useContext(LocalizationContext);
  const { formatNumber } = useContext(ApplicationContext);

  const [value, setValue] = useState<number>(
    uiHelper.getFieldValue(dataItem, field) //for handling Complex Binding ADG.DM.getName
      ? uiHelper.getFieldValue(dataItem, field) instanceof Object
        ? getName(uiHelper.getFieldValue(dataItem, field))
        : uiHelper.getFieldValue(dataItem, field) //dataItem[field]
      : 0
  );
  const [originalValue] = useState<number>(
    uiHelper.getFieldValue(dataItem, field) //for handling Complex Binding ADG.DM.getName
      ? uiHelper.getFieldValue(dataItem, field) instanceof Object
        ? getName(uiHelper.getFieldValue(dataItem, field))
        : uiHelper.getFieldValue(dataItem, field) //dataItem[field]
      : 0
  );
  const [isEditable, setIsEditable] = useState<boolean>(
    dataItem.inEdit && editable
  );
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    const fieldValue = uiHelper.getFieldValue(dataItem, field);
    setValue(
      fieldValue //for handling Complex Binding ADG.DM.getName
        ? fieldValue instanceof Object
          ? getName(fieldValue)
          : fieldValue //dataItem[field]
        : 0
    );
    setIsEditable(dataItem.inEdit && editable);
    // eslint-disable-next-line
  }, [uiHelper.getFieldValue(dataItem, field)]);

  const minMaxValidation = () => {
    let isValid = true;
    if (min) if (value < min) isValid = false;
    if (max) if (value > max) isValid = false;
    // dataItem.IsValid = isValid; //Useless
    return isValid;
  };

  const handleClick = (e: any) => {
    if (handleOnRowClick) handleOnRowClick({ dataItem });

    if (disabled) {
      setIsEditable(false);
      return;
    }
    if (dataItem.isNewItem && !editableInNew) return;
    setIsEditable(true);
    setVisited(true);
  };

  const handleOnBlur = async (e: NumericTextBoxBlurEvent) => {
    const newValue = e.target.value ? e.target.value : 0;

    await invokeUpdate(e, newValue);
  };

  const handleOnKeyDown = async (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      const newValue = e.target.value ? e.target.value : 0;

      await invokeUpdate(e, newValue);
    }
  };

  const getUpdatedDataItem = () => {
    const validated = minMaxValidation();

    let updatedDataItem = { IsValid: true };
    if ("IsValid" in dataItem)
      updatedDataItem = { ...dataItem, IsValid: validated };
    else updatedDataItem = Object.assign({ IsValid: validated }, dataItem);

    return updatedDataItem;
  };

  const invokeUpdate = async (e: any, newValue: any) => {
    setVisited(false);

    const updatedDataItem = getUpdatedDataItem();

    if (!updatedDataItem.IsValid) {
      //Op1
      // setValue(originalValue);
      // setIsEditable(false);

      //Op2
      setVisited(true); //to keep validation message
      return;
    }

    if (e.target.value === originalValue) {
      // setValue(originalValue);
      setIsEditable(false);
      return;
    }
    if (dataItem.isNewItem) {
      if (onChange) {
        onChange({
          dataIndex: 0,
          dataItem,
          field,
          syntheticEvent: e.syntheticEvent,
          value: e.target.value,
        });
      }
    } else if (onCloseEdit && !dataItem.isNewItem) {
      const succeed = await onCloseEdit({
        dataIndex: 0,
        dataItem: updatedDataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: newValue,
      });
      // if (succeed === true) {
      //   setValue(newValue);
      // } else {
      //   setValue(originalValue);
      // }
    } //else setValue(newValue);

    setIsEditable(false);
  };

  return (
    <td
      role={"gridcell"}
      onClick={handleClick}
      onKeyDown={handleOnKeyDown}
      onMouseLeave={() => {
        if (isEditable) setIsEditable(false);
      }}
    // tabIndex={isEditable ? -1 : 0}
    >
      {isEditable ? (
        <AppNumericTextBox
          // onChange={handleChange}
          value={value}
          onBlur={handleOnBlur}
          min={min}
          max={max}
        />
      ) : currency ? (
        formatNumber(value) +
        (uiHelper.getFieldValue(dataItem, currency)
          ? " " + uiHelper.getFieldValue(dataItem, currency)
          : "")
      ) : fixedCurrency ? (
        <span>
          <span
            style={{
              paddingRight: "2px",
              fontSize: "inherit",
              color: "inherit",
            }}
          >
            {fixedCurrency}
          </span>
          {formatNumber(value)}
        </span>
      ) : (
        formatNumber(value)
      )}

      {visited && !minMaxValidation() && (
        <Error>{translate(baseMessages.INVALID_DATA)}</Error>
      )}
    </td>
  );
}
