import {
  NumericTextBox,
  NumericTextBoxProps,
} from "@progress/kendo-react-inputs";

interface Props extends NumericTextBoxProps {
  showSpinners?: boolean;
}
export default function AppNumericTextBox({
  showSpinners = true,
  ...rest
}: Props) {
  return <NumericTextBox spinners={showSpinners} rounded="full" {...rest} />;
}
