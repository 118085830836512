const baseMessages = {
  LOGIN_SUCCESSFULLY: "LOGIN_SUCCESSFULLY",
  ACTIVATED_SUCCESSFULLY: "ACTIVATED_SUCCESSFULLY",
  FIELD_IS_REQUIRED: "FIELD_IS_REQUIRED",
  EMAIL_IS_REQUIRED: "EMAIL_IS_REQUIRED",
  INVALID_EMAIL_FORMAT: "INVALID_EMAIL_FORMAT",
  USERNAME_REQUIRED: "USERNAME_REQUIRED",
  PHONE_IS_REQUIRED: "PHONE_IS_REQUIRED",
  INVALID_PHONE_NUMBER: "INVALID_PHONE_NUMBER",
  REQUIRED_EMAIL_FORMAT: "REQUIRED_EMAIL_FORMAT",
  CREATED_SUCCESSFULLY: "CREATED_SUCCESSFULLY",
  NO_DATA: "NO_DATA",
  INFORMATION_MUST_BE_CHANGED: "INFORMATION_MUST_BE_CHANGED",
  CHANGES_WILL_BE_LOST_SURE_TO_CONTINUE:
    "CHANGES_WILL_BE_LOST_SURE_TO_CONTINUE",
  INVALID_DATA: "INVALID_DATA",
  INVALID_NUMBER: "INVALID_NUMBER",
  FILL_IN_THE_FOLLOWING_FIELDS: "FILL_IN_THE_FOLLOWING_FIELDS",
  ADDRESS_IS_REQUIRED: "ADDRESS_IS_REQUIRED",
  PASSWORD_AT_LEAST_FOUR_CHARACTERS: "PASSWORD_AT_LEAST_FOUR_CHARACTERS",
  NO_ITEMS_SELECTED: "NO_ITEMS_SELECTED",
  CURRENCY_IS_REQUIRED: "CURRENCY_IS_REQUIRED",
  PASSWORD_IS_REQUIRED: "PASSWORD_IS_REQUIRED",
  AN_ACTIVATION_CODE_HAS_BEEN_SENT_TO_YOUR_EMAIL_ADDRESS:
    "AN_ACTIVATION_CODE_HAS_BEEN_SENT_TO_YOUR_EMAIL_ADDRESS",
  NUMBER_MUST_BE_GREATER_THAN_ZERO: "NUMBER_MUST_BE_GREATER_THAN_ZERO",
  INVALID_PERCENTAGE: "INVALID_PERCENTAGE",
  USER_DELETED: "USER_DELETED",
  THE_ACTIVATION_EMAIL_BEEN_SENT_TO_USER_EMAIL_ADDRESS:
    "AN_ACTIVATION_CODE_HAS_BEEN_SENT_TO_YOUR_EMAIL_ADDRESS",
  THE_ACTIVATION_CODE_IS_CHANGED_AND_THE_ACTIVATION_EMAIL_BEEN_SENT_TO_USER_EMAIL_ADDRESS:
    "THE_ACTIVATION_CODE_IS_CHANGED_AND_THE_ACTIVATION_EMAIL_BEEN_SENT_TO_USER_EMAIL_ADDRESS",
  EMAIL_SENT_SUCCESSFULLY: "EMAIL_SENT_SUCCESSFULLY",
  INVALID_AMOUNT: "INVALID_AMOUNT",
  MOBILE_NUMBER_SYNC_SUCCESSFULLY: "MOBILE_NUMBER_SYNC_SUCCESSFULLY",
  UPDATED_SUCCESSFULLY: "UPDATED_SUCCESSFULLY",
  INVALID_AMOUNT_RANGE: "INVALID_AMOUNT_RANGE",
  REMOVED_SUCCESSFULLY: "REMOVED_SUCCESSFULLY",
  SUBMITTED_SUCCESSFULLY: "SUBMITTED_SUCCESSFULLY",
};

export default baseMessages;
