import { useContext, useEffect, useState } from "react";
import strings from "../../../base/strings";
import { LocalizationContext } from "../../context/localizationContext";
import SearchFieldModel from "../../interfaces/SearchFieldModel";
import BusinessObjectModel from "../../interfaces/BusinessObjectModel";
import { getDefinitionList } from "../../api/searchDefinitionHelper";
import { ApplicationContext } from "../../context/applicationContext";
import AppDropDownList from "../dropDown/AppDropDownList";
import {
  DropDownListChangeEvent,
  DropDownListCloseEvent,
} from "@progress/kendo-react-dropdowns";
import uiHelper from "../../helpers/uiHelper";

interface Props extends SearchFieldModel {
  searchFieldName?: string;
  definitionType: string;
  callType?: "post" | "get";
  setErrorMessage: (message: string) => void;
  searchCriteria?: {};
  useCustomBinding?: boolean;
  getCustomList?: (searchKey: string) => Promise<BusinessObjectModel[]>;
  onChange?: (event: DropDownListChangeEvent, searchValue: string) => void;
}

export default function AppDropDownFilterSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  criteriaTitle,
  title,
  definitionType,
  callType = "get",
  defaultShow,
  searchFieldName = "Code",
  setErrorMessage,
  searchCriteria,
  reset,
  useCustomBinding = false,
  getCustomList,
  onChange,
}: Props) {
  const { getName, translate, getNameField } = useContext(LocalizationContext);
  const { definitions } = useContext(ApplicationContext);

  const [dropDownObjectList, setDropDownObjectList] = useState<
    BusinessObjectModel[]
  >([]);
  const [opened, setOpened] = useState<boolean>(false);

  const defaultItem = {
    ID: 0,
    LocalName: title,
    ForeignName: title,
    disabled: true,
  };

  const UnSelect = {
    ID: -1,
    LocalName: translate(strings.UNSET_THIS_VALUE),
    ForeignName: translate(strings.UNSET_THIS_VALUE),
  };

  const [dropDownValue, setDropDownValue] = useState(defaultItem);

  const getList = async () => {
    let list: BusinessObjectModel[] = [];
    if (definitionType) {
      const objectType = definitions[definitionType].objectType;
      list = await getDefinitionList<typeof objectType>(
        definitions[definitionType].endpoint,
        definitions[definitionType].callType,
        setErrorMessage,
        searchCriteria
      );
    } else if (useCustomBinding && getCustomList) {
      list = await getCustomList(criteriaTitle);
    }

    if (uiHelper.isNullOrEmptyArray(list)) setDropDownObjectList([]);
    else setDropDownObjectList([UnSelect, ...list]);
  };

  useEffect(() => {
    getList();
    setOpened(!(defaultShow || !reset));
    // eslint-disable-next-line
  }, []);

  const getObjectSearchValue = (dropDownObjectList: any[], value: string) => {
    let code: string = "";
    dropDownObjectList.filter((object) => {
      if (getName(object) === value) code = object[searchFieldName];
      return code;
    });
    return code;
  };

  const onItemClick = (event: DropDownListChangeEvent) => {
    const value = event.target.value;
    if (value?.ID === UnSelect.ID || value?.ID === defaultItem.ID) {
      removeSearchFieldValue(criteriaTitle);
      setDropDownValue(defaultItem);
    } else {
      const searchValue: string = getObjectSearchValue(
        dropDownObjectList,
        getName(value)
      );
      setDropDownValue(value);
      setSearchFieldValues(criteriaTitle, searchValue);
    }
    onChange &&
      onChange(event, getObjectSearchValue(dropDownObjectList, getName(value)));
    setOpened(false);
  };

  return (
    <AppDropDownList
      value={dropDownValue}
      className="search-component"
      onChange={onItemClick}
      transparentMode={true}
      opened={opened || undefined}
      onClose={(event: DropDownListCloseEvent) => {
        if (event.syntheticEvent.type === "click") setOpened(false);
      }}
      skipDisabledItems={true}
      defaultItem={defaultItem}
      data={dropDownObjectList}
      textField={getNameField()}
      objectBinding
    />
  );
}
