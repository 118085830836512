import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const insuredPersonInformations = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    { field: "LocalName", header: strings.LOCAL_NAME, width: "120px" },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "120px",
    },
    {
      field: "Identity",
      header: strings.IDENTITY,
      width: "120px",
    },
    {
      field: "CardNumber",
      header: strings.CARD_NO,
      width: "120px",
    },
  ],
  autoCompleteEndpoint: endpoints.INSURED + "/insured-person-information",
  autoCompleteSearchKey: "CardNumberStartsWith",
};

export default insuredPersonInformations;
