import { useState, useEffect } from "react";
import {
  TextArea,
  TextAreaChangeEvent,
  TextAreaProps,
} from "@progress/kendo-react-inputs";

interface Props extends TextAreaProps {
  // text: string | null;
  value: string;
}
export default function AppTextArea({
  value: propsValue,
  rows,
  placeholder,
  disabled,
  onChange,
  ...rest
}: Props) {
  const [value, setValue] = useState<string>(propsValue);
  const handleOnChange = (e: TextAreaChangeEvent) => {
    if (onChange) onChange(e);
    setValue(e.value);
  };

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  return (
    <TextArea
      rows={rows}
      placeholder={placeholder}
      onChange={handleOnChange}
      value={value}
      disabled={disabled}
      {...rest}
    />
  );
}
