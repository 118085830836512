import { ActionsLayout, DialogActionsBar } from "@progress/kendo-react-dialogs";

interface Props {
  layout?: ActionsLayout;
  children: any;
}

export default function AppDialogActionsBar({ children, ...rest }: Props) {
  return <DialogActionsBar {...rest}>{children}</DialogActionsBar>;
}
