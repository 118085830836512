import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const commands = {
  columns: [
    { field: "ID", header: strings.ID, width: "100px" },
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "120px",
    },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "120px",
    },
    {
      field: "Key",
      header: strings.KEY,
      width: "120",
    },
  ],
  autoCompleteEndpoint: endpoints.USERS + "/commands",
  autoCompleteSearchKey: "CommandName",
};

export default commands;
